import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import WIPMultiChoice from "./WIPMultiChoice";
import WIPDragDrop from "./WIPDragDrop";
import { connect } from "react-redux";
import { wipSubmit, wipCompleteSubmit } from "../../../store/actions/submitActions";
import { selectPrompt } from "../../../store/actions/userActions";
import { WIPPrompts } from "./Configs";
// import { Container, Row, Col } from 'react-bootstrap';
import './WIPAssessment.css';
import { WIPDisplayStates } from "./Configs";
// import ProgressBar from '../../ProgressBar/ProgressBar';
import firebase from '../../../config/fbConfig';

const WIPAssessment = ({ currentPromptId, wipSubmit, selectPrompt, onClick, auth, wipCompleteSubmit }) => {

  const [ disabled, setDisabled ] = useState(false);

  const [ prevPrompt, setPrevPrompt ] = useState(null);
 
  // Create question number counter.
  const [count, setCount] = useState(0);

  // Total number of questions.
  const numTotalQuestions = 42;

  // // Progress.
  // let progress = Math.round((count)/numTotalQuestions*100*10)/10;

  // Default prompt from the Config file.
  const defaultPrompt = WIPPrompts[count]; // Needs to be checking for the existence of data in the database. If that doesn't exist, then I set it to what I have here.

  let currentPrompt;
  if (prevPrompt !== null) {
    currentPrompt = prevPrompt;
  } else {
    // Update currentPrompt to whatever is stored in the database.
    currentPrompt = defaultPrompt;
  }

  
  // Create title for the column.
  const title = "Drag and drop to rank options.";
  
  const isDone = count === WIPPrompts.length;
  
  // Initialize values for answers.
  const [order, setOrder] = useState(defaultPrompt.order || []);
  const [multiValues, setMultiValues] = useState(
    defaultPrompt.answerOptions || []
    );

  // Check for user's last answer and start there. Useful if the user reloads the page or
  // accidentally navigates away, they can pick up where they left off.
  useEffect(() => {
    let isMounted = true; // Add boolean to handle no-op warning.
    // Get data from this user's 'wip' document, if it exists.
    firebase.firestore().collection('wip').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let wipAnswers = null;
        let rbAnswers = null;
        if (doc.exists) {
          wipAnswers = doc.data().wipAnswers;
          rbAnswers = doc.data().rbAnswers;
        }

        let filledRBAnswers = [];
        try {
          if (rbAnswers) {
            filledRBAnswers = Object.keys(rbAnswers).filter(element => element.value);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
        if (filledRBAnswers.length === 0) {
          // User hasn't gotten to radio button answers yet.
          try {
            setCount(Object.keys(wipAnswers).length-1);
          } catch (error) {
            setCount(0);
          }
        } else {
          // User has gotten to radio button answers.
          try {
            setCount(Object.keys(filledRBAnswers).length-1);
          } catch (error) {
            setCount(0);
          }
        }
        return [
          {
            id: 'wip',
            data: wipAnswers
          },
          {
            id: 'rb',
            data: rbAnswers
          }
        ];
      }
    });
    // Clean-up.
    return () => { isMounted = false };
  }, [auth]);

  // Watch currentPromptId for changes, update initial display order upon change.
  useEffect(() => {   
    let isMounted = true; // Add boolean to handle no-op warning.
    // Get data from this user's 'wip' document, if it exists.
    const getData = firebase.firestore().collection('wip').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let wipAnswers = null;
        let rbAnswers = null;
        if (doc.exists) {
          wipAnswers = doc.data().wipAnswers;
          rbAnswers = doc.data().rbAnswers;
        }
        try {
        } catch (error) {
        }
        return [
          {
            id: 'wip',
            data: wipAnswers
          },
          {
            id: 'rb',
            data: rbAnswers
          }
        ];
      }
    });

    const output = () => {
      getData.then((result) => {
        if (isMounted) {
          const newPrompt = promptCheck(count, result);
          if (prevPrompt !== null && newPrompt !== null) {
            if (newPrompt.id !== prevPrompt.id) {
              setPrevPrompt(promptCheck(count, result));
            }
          } else {
            setPrevPrompt(promptCheck(count, result));
          }
        }
      })
    }
    output();

    if (currentPrompt.isDnd) {
      setOrder(currentPrompt.order);
      setDisabled(false);
    }

    if (currentPrompt.isMulti) {
      setMultiValues(currentPrompt.answerOptions);
      if (currentPrompt.answerOptions[0].isSelected !== true && currentPrompt.answerOptions[1].isSelected !== true) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

    // Clean-up.
    return () => { isMounted = false };
  }, [currentPromptId, defaultPrompt, auth.uid, count, currentPrompt.isDnd, currentPrompt.isMulti, currentPrompt.order, prevPrompt, currentPrompt.answerOptions]);

  // Reorder function.
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Set dnd state after each element is dragged and dropped.
  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If there is no destination, do nothing.
    if (!destination) {
      return;
    }

    // If the draggable ends in the same place that it started, do nothing.
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Reorder 'order' to match the new drag-and-drop position for this question and set the state.
    const newOrder = reorder(order, source.index, destination.index);
    setOrder(newOrder);
  };

  // Changes selected radio button based on user selection.
  const handleMultiChange = (event) => {
    setDisabled(false);
    const newValues = [...multiValues].map((multiValue) => {
      // return { ...multiValue, isSelected: !multiValue.isSelected };
      return { ...multiValue, isSelected: multiValue.value === event.target.name };
    });
    setMultiValues(newValues);
  };

  const handleNextPress = (e) => {
    e.preventDefault();

    if (currentPrompt.isDnd) {
      wipSubmit({
        id: currentPrompt.id,
        order,
        isDnd: currentPrompt.isDnd,
        isMulti: currentPrompt.isMulti
      }); // Sends results to Firestore.
    }
    if (currentPrompt.isMulti) {
      wipSubmit({
        id: currentPrompt.id,
        multiValues,
        answerOptions: multiValues,
        isDnd: currentPrompt.isDnd,
        isMulti: currentPrompt.isMulti
      }); // Sends results to Firestore.
    }

    // Increment 'count'.
    setCount(count + 1);
  };

  const handlePreviousPress = (e) => {
    if (count !== 0) {
      if (currentPrompt.isDnd) {
        wipSubmit({
          id: currentPrompt.id,
          order,
          isDnd: currentPrompt.isDnd,
          isMulti: currentPrompt.isMulti
        }); // Sends results to Firestore.
      }
      if (currentPrompt.isMulti) {
        wipSubmit({
          id: currentPrompt.id,
          multiValues,
          answerOptions: multiValues,
          isDnd: currentPrompt.isDnd,
          isMulti: currentPrompt.isMulti
        }); // Sends results to Firestore.
      }

      // selectPrompt(WIPPrompts[count - 1].id);
      setCount(count - 1);
    }
  };

  const handleSubmitPress = (e) => {
    if (currentPrompt.isMulti) {
      wipSubmit({
        id: currentPrompt.id,
        multiValues,
        answerOptions: multiValues,
        isDnd: currentPrompt.isDnd,
        isMulti: currentPrompt.isMulti
      }); // Sends results to Firestore.
    }

    // Set 'wipComplete' to 'true' in user document.
    wipCompleteSubmit(); // Sends complete = true boolean to Firestore.

    // Go to 'WIPComplete'.
    onClick(WIPDisplayStates.COMPLETE);
  }

  // Check if this is a multiple choice question. If it is, check if either radio button
  // has been selected. If not, disable the 'next' button.
  useEffect(() => {
    if (currentPrompt.isMulti) {
      if (multiValues.length > 0) {
        try {
          const checkYes = multiValues[0].isSelected;
          const checkNo = multiValues[1].isSelected;
          if (!checkYes && !checkNo) {
            // Disable next button.
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    }
  }, [multiValues, currentPrompt]);

  // Array of 21 empty wipAnswers elements.
  const [ wipOnline ] = useState(Array(21).fill(null));
  
  // Array of 21 empty rbAnswers elements.
  const [ rbOnline ] = useState(Array(21).fill(null));

  return (
    <div className="wipass">
      <div className="wipass__mainBox">
        {count < 21 ? ( 
          <div className="wipass__body">
            Please order the following statements with 1 being the most important to you
            and 5 being the least important.
            
            <br /><br />If you're on a mobile or tablet device, please
            press and hold to select an option.
          </div>
        ) : (
          <div className="wipass__body">
            Now, for each of the following work statements choose Yes if you feel the statement
            is important to you, otherwise choose No. You must respond to each statement before
            you can proceed.
          </div>
        )
        }
        <div className="wipass__header">
          For my IDEAL JOB it is important that:
        </div>
        {isDone ? (
            <div>Go to home page.</div>
          ) : (
            <div className='wipass__itemBox'>
              {currentPrompt && currentPrompt.isDnd && (
                <DragDropContext onDragEnd={onDragEnd}>
                  {
                    <WIPDragDrop
                      droppableId={title}
                      currentPrompt={currentPrompt}
                      order={order}
                    />
                  }
                </DragDropContext>
              )}
              {currentPrompt && currentPrompt.isMulti && (
                <WIPMultiChoice
                  defaultPrompt={defaultPrompt}
                  multiValues={multiValues}
                  onChange={handleMultiChange}
                />
              )}
            </div>
          )
        }
        {/* <div className="wipass__progressBar">
          <ProgressBar completed={progress} bgColor='#25479433' />
        </div> */}

        <div className="wipass__questionBoxes">
          <div className="wipass__questionBoxesRow">
            { wipOnline.map((val, ind) => (   
              ind < count ? (
                <input
                  type="radio"
                  checked={ind === count}
                  className="wipass__questionBox wipass__questionBox-answered"
                  readOnly={true}
                  key={ind}
                />
              ) : (
                <input
                  type="radio"
                  checked={ind === count}
                  className="wipass__questionBox"
                  readOnly={true}
                  key={ind}
                />
              )         
              ))
            }
          </div>
          <div className="wipass__questionBoxesRow">
            { rbOnline.map((val, ind) => (   
              ind + 21 < count ? (
                <input
                  type="radio"
                  checked={ind + 21 === count}
                  className="wipass__questionBox wipass__questionBox-answered"
                  readOnly={true}
                  key={ind}
                />
              ) : (
                <input
                  type="radio"
                  checked={ind + 21 === count}
                  className="wipass__questionBox"
                  readOnly={true}
                  key={ind}
                />
              )         
              ))
            }
          </div>
        </div>

        <div className="wipass__questionCount">
          Question {count+1} of {Math.round(numTotalQuestions)}
        </div>

        { count > 0 ? (
          count === 41 ? (
            <div className="wipass__buttonBox">
              <button className='wipass__pageButton wipass__pageButton-back' onClick={handlePreviousPress}><span>{'<<'}</span></button>
              { disabled ? (
                <button className="wipass__pageButton wipass__pageButton-next" disabled={disabled} onClick={handleSubmitPress}><span>{'>>'}</span></button>
              ) : (
                <button className="wipass__pageButton wipass__pageButton-next" onClick={handleSubmitPress}><span>{'>>'}</span></button>
              ) }
            </div>
          ) : (
            <div className="wipass__buttonBox">
              <button className='wipass__pageButton wipass__pageButton-back' onClick={handlePreviousPress}><span>{'<<'}</span></button>
              { disabled ? (
                <button className={"wipass__pageButton wipass__pageButton-next wipass__pageButton-disabled"} style={{ backgroundColor: '#b1b1b1' }} disabled={disabled} onClick={handleNextPress}><span>{'>>'}</span></button>
              ) : (
                <button className="wipass__pageButton wipass__pageButton-next" onClick={handleNextPress}><span>{'>>'}</span></button>
              ) }
            </div>
          )
        ) : (
          <div className="wipass__buttonBox">
            <button className="wipass__pageButton wipass__pageButton-next" onClick={handleNextPress}><span>{'>>'}</span></button>
          </div>
        ) }
      </div>
    </div>
  );
};

const promptCheck = (count, answers) => {
  let prevPrompt = null;
  if (count > 20) {
    // Check Firebase for count within ipAnswers.
    if (answers) {
      const ipIndex = answers.findIndex(
        ({ id }) => id === 'rb'
      )
      const ipData = answers[ipIndex].data;

      // Check if ipData contains data for this question.
      if (ipData !== null) {
        // Search for current question's answer in database.
        const fbIPID = `id${count+1}`;
        if (ipData[fbIPID] !== undefined) {
          // Question has been answered.
          prevPrompt = ipData[fbIPID];
        }
      }
    }
  } else {
    // Check Firebase for count within wipAnswers.
    if (answers) {
      const wipIndex = answers.findIndex(
        ({ id }) => id === 'wip'
      )
        
      const wipData = answers[wipIndex].data;
      
      // Check if wipData contains data for this question.
      if (wipData !== null) {
        // Search for current question's answer in database.
        const fbWIPID = `id${count+1}`;
        if (wipData[fbWIPID] !== undefined) {
          // Question has been answered.
          prevPrompt = wipData[fbWIPID];
        }
      }
    }
  }
  return prevPrompt;
}

const mapStateToProps = (state) => {
  const { userState } = state;
  return {
    currentPromptId: userState.currentPromptId || "WIP-Prompt-1",
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    wipSubmit: (result) =>
      dispatch(
        wipSubmit(result)
      ),
    wipCompleteSubmit: (result) =>
    dispatch(
      wipCompleteSubmit(result)
    ),
    selectPrompt: (promptId) => {
      dispatch(selectPrompt(promptId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WIPAssessment);