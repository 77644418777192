import React, { useState } from 'react';
// import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';
// import { Container, Row, Col } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import './SignedInAssessments.css';
// import { FaChevronDown } from 'react-icons/fa';

// require('dotenv').config();

// const laptopPic = `/images/purchaseImages/Laptop-phone.png`;
// const assessmentsPic = `/images/purchaseImages/purchase-page-icons.png`;
// const giftPic = `/images/purchaseImages/gift-shorter.png`;

const SignedInAssessments2 = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  // const [ clicked, setClicked ] = useState(false);
  // const [ personal, setPersonal ] = useState(true);
  // const [ gift, setGift ] = useState(false);
  // const [ dropValue, setDropValue ] = useState(1);
  // const [ dropOpen, setDropOpen ] = useState(false);
  // const [ total, setTotal ] = useState(process.env.REACT_APP_TELOS_PACKAGE_PRICE);
  // const wrapperRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  // function useOutsideAlerter(ref) {
  //   useEffect(() => {
  //     /**
  //      * Alert if clicked on outside of element
  //      */
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setDropOpen(false);
  //       }
  //     }
  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       // Unbind the event listener on clean up
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref]);
  // }
  // useOutsideAlerter(wrapperRef);

  // Find last page.
  const lastLocation = useLastLocation();
  let path;
  try {
    if (lastLocation !== null) {
      path = lastLocation.pathname;
    } else {
      path = '/nopath';
    }
  } catch (error) {
    console.log(error);
    path = '/nopath';
  }

  // Close popup.
  const popupClick = (e) => {
    setOpen(false);
  }

  // // Go to payment page.
  // const onClick = (e) => {
  //   setClicked(true);
  // }

  // const personalClick = (e) => {
  //   setPersonal(true);
  //   setGift(false);
  //   setTotal(process.env.REACT_APP_TELOS_PACKAGE_PRICE);
  //   setDropValue(1);
  // }

  // const giftClick = (e) => {
  //   setGift(true);
  //   setPersonal(false);
  // }

  // const plans = [
  //   'Single Plan',
  //   'Two Plans',
  //   'Five Plans',
  //   'Ten Plans +'
  // ];

  // const prices = [
  //   '$' + Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE*100)/100,
  //   '$' + Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE*0.92*100)/100,
  //   '$' + Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE*0.82*100)/100,
  //   '$' + Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE*0.67*100)/100
  // ];

  // const dropClick = (e) => {
  //   setDropOpen(!dropOpen);
  // }

  // const dropSelect = (e) => {
  //   setDropOpen(false);
  //   setDropValue(e.target.id);
  //   if (e.target.id === 1) {
  //     setTotal(e.target.id * process.env.REACT_APP_TELOS_PACKAGE_PRICE * 1);
  //   } else if (e.target.id > 1 && e.target.id < 5) {
  //     setTotal(e.target.id * Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE * 0.92 * 100)/100);
  //   } else if (e.target.id > 4 && e.target.id < 10) {
  //     setTotal(e.target.id * Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE * 0.82 * 100)/100);
  //   } else if (e.target.id > 9) {
  //     setTotal(e.target.id * Math.round(process.env.REACT_APP_TELOS_PACKAGE_PRICE * 0.67 * 100)/100);
  //   }
  // }

  // const dropOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  // // If 'clicked' is set to true, redirect to the '/payment' page.
  // if (clicked) return <Redirect to='/payment' />

  return (
    <div className='main-box'>
      {path === '/signup'
      && 
      <div>
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <div>
            <div className='signedin-assessments-popup'>
              <button className='signedin-assessments-popup-close' onClick={popupClick}>
                &times;
              </button>
              <div className='signedin-assessments-popup-primary' onClick={closeModal}>
                Your Account was Successfully Created!
              </div>
              <div className="signedin-assessments-popup-sub">
                An e-mail has been sent to you for confirmation.
              </div>
            </div>
          </div>
        </Popup>
      </div>
    }
    <div className="signedinAssessments">
      <Redirect
        to={{
          pathname: `/payment`,
          // state: { location },
        }}
      />
      {/* <img src={laptopPic} alt="purchase-pic" className='signedinAssessments__image' />
      <div className="signedinAssessments__main">
        <div className="signedinAssessments__options">
          <button className={`signedinAssessments__select ${!personal && "signedinAssessments__select-off"}`} onClick={personalClick}>
            Personal Plan
          </button>
          <button className={`signedinAssessments__select ${!gift && "signedinAssessments__select-off"}`} onClick={giftClick}>
            Gift Telos
          </button>
          <div className="signedinAssessments__hr" />
          <div className="signedinAssessments__hr2" />
          <div className="signedinAssessments__hr3" />
        </div>

        {personal && <div className="signedinAssessments__personal">
          <div className="signedinAssessments__body">
            The Telos package includes access to <span>eight</span> research-backed assessments
            and your personalized results. These assessments evaluate your:
          </div>
          <img src={assessmentsPic} alt="assessments-pic" className="signedinAssessments__image2" />
          <div className="signedinAssessments__body">
            In addition, your results will be fed through our proprietary algorithm to determine:
          </div>

          <div className="signedinAssessments__body  signedinAssessments__body-flex">
            <div className="signedinAssessments__number">
              1
            </div>
            <div className="signedinAssessments__body signedinAssessments__body-bold">
              Which career paths are most likely to align with you
            </div>
          </div>

          <div className="signedinAssessments__body signedinAssessments__body-flex">
            <div className="signedinAssessments__number">
              2
            </div>
            <div className="signedinAssessments__body signedinAssessments__body-bold">
              Details of each career path, such as typical job requirements
            </div>
          </div>

          <div className="signedinAssessments__body signedinAssessments__body-flex">
            <div className="signedinAssessments__number">
              3
            </div>
            <div className="signedinAssessments__body signedinAssessments__body-bold">
              The job categories you most closely align to, in case you don't yet want to commit
              to a specific career path
            </div>
          </div>

        </div> }

        { gift && <div className="signedinAssessments__gift">
          <div className="signedinAssessments__body signedinAssessments__body-bold2">
            Because who doesn't want to share a good thing?
          </div>
          <div className="signedinAssessments__body">
            Give the gift of research-backed guidance in an easy to use <i>and </i>
            understand format to friends and family.
          </div>
          <div className="signedinAssessments__giftOptions">
            <table className="signedinAssessments__table">
              <thead className="signedinAssessments__thead">
                <tr className="signedinAssessments__tr">
                  <th className="signedinAssessments__th">Plans</th>
                  <th className="signedinAssessments__th">Each</th>
                </tr>
                {plans.map((val, ind) => (
                  <tr className="signedinAssessments__tr" key={ind}>
                    <td className="signedinAssessments__td">{val}</td>
                    <td className="signedinAssessments__td">{prices[ind]}</td>
                  </tr>
                ))}
              </thead>
            </table>
            <img src={giftPic} alt="gift-pic" className="signedinAssessments__imageGift" />
          </div>

        </div> }
          
        <div className="signedinAssessments__singlePay">
          {personal ? (
            <div className="signedinAssessments__price">
              ${process.env.REACT_APP_TELOS_PACKAGE_PRICE}
            </div>
          ) : (
            <div className="signedinAssessments__dropdown" ref={wrapperRef} onClick={dropClick}>
              Qty: <span>{dropValue}<FaChevronDown className='signedinAssessments__down' /></span>
              <div className={`signedinAssessments__list ${dropOpen ? `signedinAssessments__list-open` : ''}`}>
                <ul>
                  {dropOptions.map((element, ind) => (
                    <li
                      className='signedinAssessments__listOption'
                      onClick={dropSelect}
                      key={ind}
                      id={ind+1}
                    >
                      {element}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) }
          <button className="signedinAssessments__button" onClick={onClick}>
            Add to Cart
          </button>
        </div>
            
      </div> */}
    </div>
      {/* <Container fluid className='signedin-assessments'>
        <Row>
          <Col>
            <div className='signedin-assessments__primary'>
              <div className="signedin-assessments__text">
                The Telos package includes:
              </div>
              <div className="signedin-assessments__text signedin-assessments__text-bold">
                All assessments with downloadable results, printouts, and access
                to our online resource library for one year.
              </div>
              <div className="signedin-assessments__purchase">
                <div className="signedin-assessments__price">
                  ${process.env.REACT_APP_TELOS_PACKAGE_PRICE}
                </div>
                <button className="signedin-assessments__button" onClick={onClick}>
                  Order Now
                </button>
              </div>
              {/* <div className="signedin-assessments__subtext">
                Are you a former or current service member?&nbsp;
                <button className="signedin-assessments__discount">
                  Click here
                </button>
                &nbsp;to receive a 50% discount.
              </div> */}
            {/* </div>
          </Col>
        </Row>
      </Container> */}
    </div>
  )
}

export default SignedInAssessments2
