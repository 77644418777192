import React from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CP.css';

const InstructionsQ9 = ({ setDisplay }) => {
  return (
    <div className='cp-main-box cp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 5 - Name Comparison
      </div>
      <div className='cp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Don't forget that in this section, you'll lose one (1) point for each wrong answer
        (but not for those you don't answer).
      </div>
      <div className="cp-question cp-question__q9">
        <div className="cp-question__number">
          9.
        </div>
        <div className="cp-question__content">
          DeMarkus Byron Realty &ndash; DeMarkus Byron Realty
        </div>

        <div className="cp-question__answers">
          <label className="cp-question__answer-option">
            <div className="cp-question__answer-text">
              S
            </div>
            <input
              type="checkbox"
              className='question__radio-button question__radio-button-cp'
              checked={false}
              readOnly={true}
            />
          </label>
          <label className="cp-question__answer-option">
            <div className="cp-question__answer-text">
              D
            </div>
            <input
              type="checkbox"
              className='question__radio-button question__radio-button-cp'
              checked={true}
              readOnly={true}
            />
          </label>
        </div>
        <div className='cp-q9'>
          Incorrect
          <div className="cp-q9-highlight">
            -1 point
          </div>
        </div>
      </div>
      <div className="cp-row-2 cp-row-2-spaced cp-row-2-practice cp-row-2-practice-margin">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q678);
          }}
          // style={{ marginBottom: '100px' }}
        >
          Back
        </button>
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_FINAL);
          }}
          // style={{ marginBottom: '100px' }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ9