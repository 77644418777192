import React, { useState } from 'react';
import './GridTableBody.css';
import OnetWebService from '../../topjobs/onetQuery/OnetWebService';
import { Modal } from 'react-bootstrap';
import ProgressBar from '../../../ProgressBar/ProgressBar';
import '../../topjobs/TopJobs.css';
import Hyphenated from 'react-hyphen';


const brightPic = `/images/resultsImages/occupationGrid/outlook_sun.png`;

const fit1Star = `/images/resultsImages/occupationGrid/fit3_star.png`;
const fit2Stars = `/images/resultsImages/occupationGrid/fit2_stars.png`;
const fit3Stars = `/images/resultsImages/occupationGrid/fit1_stars.png`;

const GridTableBody = ({ visibleData, columns, view }) => {

  const [ riasec, setRiasec ] = useState([]);
  const [ jobZone, setJobZone ] = useState('');
  const [ tasks, setTasks ] = useState([]);
  const [ abilities, setAbilities ] = useState([]);
  const [ workActivities, setWorkActivities ] = useState([]);
  const [ skills, setSkills ] = useState([]);
  const [ relatedOccupations, setRelatedOccupations ] = useState([]);

  const [ showPopup, setShowPopup ] = useState(false);
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);
  const [ currentJob, setCurrentJob ] = useState('');

  const [ OnetURL, setOnetURL ] = useState('');

  // Function to run when an occupation is clicked.
  function passJob(ind) {
    let code = visibleData[ind].onetCode;
    let occupation = visibleData[ind].jobName;
    
    // Query O*Net database, get relevant info, and populate that info into state variables.
    const fetchData = async () => {
      // Reset states.
      setRiasec([]);
      setJobZone([]);
      setTasks([]);
      setAbilities([]);
      setWorkActivities([]);
      setSkills([]);
      setRelatedOccupations([]);

      // Get new data.
      setRiasec(await getRIASEC(code, occupation));
      setJobZone(await getJobZone(code, occupation));
      setTasks(await getTasks(code, occupation));
      setAbilities(await getAbilities(code, occupation));
      setWorkActivities(await getWorkActivities(code, occupation));
      setSkills(await getSkills(code, occupation));
      setRelatedOccupations(await getRelatedOccupations(code, occupation));

      setCurrentJob(occupation);

      handleShowPopup();

      // Set O*Net URL for this job.
      setOnetURL(`https://www.onetonline.org/link/summary/${visibleData[ind].onetCode}`);
    }
    fetchData();
  }

  const ColoredLine = ({ color, height = 0.1, className='' }) => (
    <hr
      style={{
          color: color,
          backgroundColor: color,
          opacity: 1,
          height: height
      }}
      className={className}
    />
  );

  return (
   <tbody className='gridTableBody__tbody'>
    {visibleData.map((data, ind) => {
     return (
      <React.Fragment key={ind}>
        <tr key={ind} className='gridTableBody__tr'>
          {columns.map(({ accessor }, ind2) => {
            const tData = data[accessor] ? data[accessor] : "";
            return (
              (typeof tData === 'number') ? (
                (accessor === 'brightOutlook') ? (
                  (tData === 1) ? (
                    <td
                      key={ind2}
                      className=
                      {`gridTableBody__td gridTableBody__td-centered
                        ${(view === 'mobile') && 'gridTableBody__td-mobileOutlook'}
                      `}
                    >
                      <img src={brightPic} alt="bright-pic" className="gridTableBody__pic" />
                    </td>
                  ) : (
                    <td
                      key={ind2}
                      className=
                      {`gridTableBody__td gridTableBody__td-centered
                        ${(view === 'mobile') && 'gridTableBody__td-mobileOutlook'}
                      `}
                    >
                    </td>
                  ) 
                ) : (
                  (accessor === 'jobZone') ? (
                    <td
                      key={ind2}
                      className=
                      {`gridTableBody__td gridTableBody__td-centered
                        ${(view === 'mobile' || view === 'tablet') && 'gridTableBody__td-mobileJobZone'}
                      `}
                    >
                      {tData}
                    </td>
                  ) : (
                    <td key={ind2} className='gridTableBody__td gridTableBody__td-centered'>
                      {tData}
                    </td>
                  )
                )
              ) : (
                (accessor === 'careerName') ? (
                  <td
                    key={ind2}
                    style={{ color: data.careerColor }}
                    className=
                    {`gridTableBody__td gridTableBody__button-careerName
                      ${(view === 'mobile') && 'gridTableBody__td-mobileCareerName'}
                    `}
                  >
                    <Hyphenated>
                      {tData}
                    </Hyphenated>
                  </td>
                ) : (
                  (accessor === 'jobName') ? (
                    <td key={ind2} className='gridTableBody__td'>
                      <Hyphenated>
                        <button
                          className=
                          {`gridTableBody__button gridTableBody__button-jobName
                            ${(view === 'mobile') && 'gridTableBody__button-mobileJobName'}
                          `}
                          onClick={() => passJob(ind)}
                        >
                          {tData}
                        </button>
                      </Hyphenated>
                    </td>
                  ) : (
                    (accessor === 'fitScore') ? (
                      view === 'pc' ? (
                        <td
                          key={ind2}
                          className='gridTableBody__td gridTableBody__td-fit'
                          style={{ color: 
                            tData === 'Excellent Fit' ? '#A3BAE5' :
                            tData === 'Great Fit' ? '#759BD7' : 
                            tData === 'Good Fit' ? '#3A7BC8' : 'white'
                          }}
                        >
                          {tData}
                        </td>
                      ) : (
                        <td
                          key={ind2}
                          className=
                          {`gridTableBody__td gridTableBody__td-centered
                            ${(view === 'mobile') && 'gridTableBody__td-mobileFit'}
                          `}
                        >
                          {tData === 'Excellent Fit' &&  
                            <img
                              src={fit3Stars}
                              alt="fit-3-stars"
                              className="gridTableBody__pic" 
                            />
                          }
                          {tData === 'Great Fit' &&  
                            <img
                              src={fit2Stars}
                              alt="fit-2-stars"
                              className="gridTableBody__pic" 
                            />
                          }
                          {tData === 'Good Fit' &&  
                            <img
                              src={fit1Star}
                              alt="fit-1-star"
                              className="gridTableBody__pic gridTableBody__pic-1-star" 
                            />
                          }
                        </td>
                      )
                    ) : (
                      (accessor === 'ipMatchScore' ||
                        accessor === 'wipMatchScore' ||
                        accessor === 'apMatchScore') ? (
                        <td key={ind2} className='gridTableBody__td gridTableBody__td-centered gridTableBody__td-single'>
                          {tData}
                        </td>
                      ) : (
                        (accessor === 'trifectaMatchScore' ? (
                          <td
                            key={ind2}
                            className=
                            {`gridTableBody__td gridTableBody__td-centered
                              ${(view === 'mobile') && 'gridTableBody__td-mobileTrifectaScore'}
                            `}
                          >
                            {tData}
                          </td>
                        ) : (
                          // Bright outlook column, but when it's not a bright outlook.
                          <td
                            key={ind2}
                            className=
                            {`gridTableBody__td gridTableBody__td-centered
                              ${(view === 'mobile') && 'gridTableBody__td-mobileOutlook'}
                            `}
                          >
                          </td>
                        ))
                      )
                    )
                  )
                )
              )
            )
          })}
        </tr>
        <tr>
          <td colSpan={columns.length} className='gridTableBody__headerSeparation' />
        </tr>
      </React.Fragment>
     );
    })}

    {/* Code for popup with job name is clicked. */}
    <Modal
      show={showPopup}
      onHide={handleClosePopup}
      centered
      dialogClassName='top-jobs__modal'
      backdropClassName='top-jobs__modal-backdrop'
    >
      <Modal.Header
        closeButton
        className='top-jobs__modal-header'
      >
        <Modal.Title className='top-jobs__modal-title'>
          <h1>
            {currentJob}
          </h1>
          <h3>
            {view === 'mobile' ? (
              <>
                {jobZone.split(':')[0]}: <br />
                {jobZone.split(':')[1]}
              </>
            ) : (
              jobZone
            )}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='top-jobs__modal-body'
      >
        <div>

          <ColoredLine color='#662D91' height='20px' className='top-jobs__modal-hor-line' />

          <div className="top-jobs__modal-main-body">

            <div className='top-jobs__body-column'>
              <div className='top-jobs__section-title'>
                Top 5 Tasks
              </div>
              <ul className='top-jobs__bullets'>
                {tasks !== null ? (
                  tasks.map((task, index) => (
                    <li key={index} className='top-jobs__bullet-item'>
                      {task}
                    </li>
                  ))
                ) : (
                  <div className='top-jobs__bullet-item'>
                    No data available.
                  </div>
                )}
              </ul>
              <div className='top-jobs__section-title'>
                Top 5 Abilities
              </div>
              <ul className='top-jobs__bullets'>
                {abilities !== null ? (
                  abilities.map((ability, index) => (
                    <li key={index} className='top-jobs__bullet-item'>
                      <b>{ability.name}&nbsp;</b>
                      <span>{ability.description}</span>
                    </li>
                  ))
                ) : (
                  <div className='top-jobs__bullet-item'>
                    No data available.
                  </div>
                )}
              </ul>
            </div>

            <div className='top-jobs__body-column'>
              <div className='top-jobs__section-title'>
                Top 5 Skills
              </div>
              <ul className='top-jobs__bullets'>
                {skills !== null ? (
                  skills.map((skill, index) => (
                    <li key={index} className='top-jobs__bullet-item'>
                      <b>{skill.name}:&nbsp;</b>
                      <span>{skill.description}</span>
                    </li>
                  ))
                ) : (
                  <div className='top-jobs__bullet-item'>
                    No data available.
                  </div>
                )}
              </ul>
              <div className='top-jobs__section-title'>
                Top 5 Work Activities
              </div>
              <ul className='top-jobs__bullets'>
                {workActivities !== null ? (
                  workActivities.map((activity, index) => (
                    <li key={index} className='top-jobs__bullet-item'>
                      <b>{activity.name}:&nbsp;</b>
                      <span>{activity.description}</span>
                    </li>
                  ))
                ) : (
                  <div className='top-jobs__bullet-item'>
                    No data available.
                  </div>
                )}
              </ul>
            </div>

            <div className='top-jobs__body-column-line'>
              <div className="top-jobs__body-line">
              </div>
            </div>

            <div className="top-jobs__body-column">
              <div className='top-jobs__section-title'>
                Related Occupations
              </div>
              <ul className="top-jobs__bullets">
                {relatedOccupations !== null ? ( 
                  relatedOccupations.map((related, index) => (
                    <li key={index} className='top-jobs__bullet-item'>
                      {related}
                    </li>
                  ))
                ) : (
                  <div className='top-jobs__bullet-item'>
                    No data available.
                  </div>
                )}
              </ul>
              <div className='top-jobs__riasec-section'>
                {riasec.map((element, index) => (
                    element.score > 49 ? (
                      <div key={index} className='top-jobs__riasec-item'>
                        <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                          {element.score}
                        </div>
                        <div style={{ width: '50%' }}>
                          <ProgressBar bgColor='#001343' completed={element.score}  />
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                          {element.name}
                        </div>
                      </div>

                    ) : (

                      <div key={index} className='top-jobs__riasec-item-gray'>
                        <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                          {element.score}
                        </div>
                        <div style={{ width: '50%' }}>
                          <ProgressBar bgColor='#999999' completed={element.score}  />
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                          {element.name}
                        </div>
                      </div>

                    )
                ))}
              </div>

              {/* <div className="gridTableBody__wages">
                <div className='top-jobs__section-title'>
                  Median Wages
                </div>
                <div className="top-jobs__">
                  ${} hourly, ${} annual
                </div>
              </div> */}

              <div className="top-jobs__link">
                <div className="top-jobs__smallText">
                  Click for more information
                  <span className='top-jobs__arrowText'>→</span>
                </div>
                <a href={OnetURL} target='blank' rel='noreferrer' style={{ color: '#901DB4', width: '100%' }}>
                  Full description on O*Net
                </a>
              </div>
            </div>

          </div>
          
        </div>
      </Modal.Body>
    </Modal>
   </tbody>
  );
 };

 export default GridTableBody;

 // Get RIASEC scores for each occupation.
async function getRIASEC(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/details/interests', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let riasecResults = kwresults.element;
      const riasecs = riasecResults.map((val, ind) => {
        const element = {
          name: val.name,
          score: val.score.value
        };
        return element;
      });
      // Sort RIASEC results based on score, from highest to lowest.
      const sortedRiasecs = riasecs.sort((a, b) => a.score > b.score ? 1 : -1).reverse();
      result(sortedRiasecs);
    });
  });
}

// Get job zone for each occupation.
async function getJobZone(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/job_zone', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let zone = kwresults.title;
      result(zone);
    });
  });
}

// Get top tasks for each occupation.
async function getTasks(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/tasks', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let taskResults = kwresults.task;
      const tasks = taskResults.map((val, ind) => {
        return val.name;
      });
      result(tasks);
    });
  });
}

// Get top abilities for each occupation.
async function getAbilities(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/abilities', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let abilityResults = kwresults.element;
      const abilities = abilityResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(abilities);
    });
  });
}

// Get top work activities for each occupation.
async function getWorkActivities(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/work_activities', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const workResults = kwresults.element;
      const workActivities = workResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(workActivities);
    });
  });
}

// Get top skills for each occupation.
async function getSkills(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/skills', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const skillsResults = kwresults.element;
      const skills = skillsResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(skills);
    });
  });
}

// Get related occupations for each occupation.
async function getRelatedOccupations(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  // let data = null;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/related_occupations', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const relatedResults = kwresults.occupation;
      const relatedOccupations = relatedResults.map((val, ind) => {
        return val.title;
      });
      result(relatedOccupations);
    });
  });
}

// const insertNonBreakingHyphens = (text, maxLength) => {
//   // Insert &shy; after every maxLength characters
//   const regex = new RegExp(`.{1,${maxLength}}`, 'g');
//   const parts = text.match(regex) || [];
//   return parts.join('\u2011');
// };