import React from 'react';
import { WIPDisplayStates } from "./Configs";
import './WIPInstructions.css';

const WIPInstructionsB = ({ onClick }) => {

  return (
    <div className='wipInstructions'>
        <div className="wipInstructions__header">
          Assessment 2: Values Profiler
        </div>
        <div className='wipInstructions__subheader'>
          Instructions
        </div>
        <div className="wipInstructions__sectionheader">
          Repeated Statements & Yes/No
        </div>
        <div className="wipInstructions__body">
          In the first half of this assessment, you will see statements repeated many times. This is not an error, 
          but helps in determining relative significance of items.
        
        <div className="wipInstructions__body">
          There will be 21 of these sorting types of questions.
        </div>
        <div className="wipInstructions__body">
          The second half is comprised of 21 yes/no type questions.
        </div>
        </div>
      <div className="wipInstructions__bottom wipInstructions__bottom-instructions">
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_A);
          }}
        >
          Back
        </button>
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_C);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default WIPInstructionsB
