import React, { useState, useEffect } from 'react';
import { FaLock } from 'react-icons/fa';

const WIPListNoData = () => {
  // Update the screenWidth state when the window is resized.
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const [ chartWidth, setChartWidth ] = useState(800);

  // Update the chartWidth and chartHeight based on screen size.
  const tabletSize = 1025;
  const phoneSize = 640;
  useEffect(() => {
    if (screenWidth <= tabletSize && screenWidth > phoneSize) {
      setChartWidth(500);
    } else if (screenWidth <= phoneSize) {
      setChartWidth(260);
    } else if (screenWidth > tabletSize) {
      setChartWidth(600);
    }
  }, [screenWidth]);

  const chartHeight = 400;
  const xAxisHeight = 55;
  const yAxisWidth = 40;

  try {
    return (
      <div className='wipList'>
        <div className='bar-h1 bar-h1-wip'>
          Values highlights
        </div>
        <div className='bar-h2 bar-h2-wip'>
          {screenWidth <= 640 ? (
            <>
              Your Values Profile -<br />In Order of Importance
            </>
          ) : (
            <>
              Your Values Profile - In Order of Importance
            </>
          )}
          {/* <ColoredLine color='white' /> */}
        </div>

        <svg
          width={chartWidth}
          height={chartHeight + xAxisHeight}
          className='ipChart2__svg'
        >

          {/* Draw filled area. */}
          <g>
            {/* Draw single bar to fill entire space */}
            <rect
              x={13}
              y={20}
              width={chartWidth - yAxisWidth}
              height={chartHeight-xAxisHeight + 34}
              fill={'#163c66'}
            />

            <foreignObject x="2%" y="4.5%" width="94%" height="83.5%">
              {/* Adjust width and height as needed */}
              <div className='chart__rectangle'>
                <div className='chart__icon'>
                  <FaLock />
                </div>
                <div className='chart__body'>
                  Exciting things coming soon!
                </div>
                <div className='chart__subbody'>
                  Finish the values profiler to see results here.
                </div>
              </div>
            </foreignObject>
          </g>
        </svg>
        
      </div>
    )
  } catch (error) {
    console.log('Error: ', error);
    return (
      <div className='wipList'>
        <div className='bar-h1'>
          Values highlights
        </div>
        <div className='bar-h2'>
          Your Values Profile - Summary
        </div>
        <div style={{ height: '450px', paddingBottom: '30px', paddingRight: '30px' }}>
          There is an error, please reload the page.
        </div>
      </div>
    )
  }
  // return (
  //   <div style={{ width: '100%', height: '100%', paddingLeft: '20%', paddingRight: '20%'}}>
  //     <div className='bar-h1'>
  //       Values highlights
  //     </div>
  //     <div className='bar-h2' style={{fontSize: '20px', paddingTop: '5px'}}>
  //       Your Values Profile - In Order of Importance
  //     </div>
  //     <div className='chart__rectangle'>
  //       <div className='chart__icon'>
  //         <FaLock />
  //       </div>
  //       <div className='chart__body'>
  //         Exciting things coming soon!
  //       </div>
  //       <div className='chart__subbody'>
  //         Finish the Values Profiler to see results here.
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default WIPListNoData