import React from 'react';
import './Popup.css'; // Add CSS styles for the popup

const Popup = ({ isOpen, onClose, freeUpgrade, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        {children}
        <div className='popup-buttons'>
          <button className="popup-button" onClick={freeUpgrade}>Yes</button>
          <button className="popup-button popup-button-close" onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;