import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../SA.css';
import variables from '../../../../../../variables.module.scss';

const InstructionsQ1 = ({ setDisplay }) => {

  // Images.
  const webImage = `/images/saImages/web/Spatial Reasoning Ins 1.png`;
  const mobileImage = `/images/saImages/mobile/Spatial Reasoning Ins m1.png`;

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView)+5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='sa-main-box sa-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 3 - Three-Dimensional Space
      </div>
      <div className='sa-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        In this part are some problems in which <b>flat</b> shapes have been folded <i>or</i> rolled
        <i> or</i> both to make <b>three-dimensional</b> objects.
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        Look at Practice Problem 1:
      </div>

      <div className="saInsctructions__question">
        <div className="sa__content sa__content-fixed">
          <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="sa__choices" />
        </div>

        <div className="ap-instructions-body sa-instructions-body">
          At the left is a drawing of a flat shape. The dotted lines show where the shape must
          be folded. At the right are four different drawings of three-dimensional objects. Notice
          that only <b><i>Object D</i></b> can be made by folding the flat shape shown in Figure 1.
        </div>
      </div>
      <div className="sa-row-2 sa-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ1
