import React, { useState } from 'react';
import LoadingPage from '../../../pages/loadingPage/LoadingPage';
import TrifectaCalculate from '../../trifecta/TrifectaCalculate';
import TelosChart2 from './TelosChart2';
import './TelosChart2.css';
import TrifectaNoData from './TrifectaNoData';

const TrifectaGraph = ({ user, apresults, ipresults, wipresults }) => {
  // Track popup height to make sure footer doesn't overlap with it.
  const [extraMargin, setExtraMargin] = useState(null);

  return (
    <div
      className='trifectaGraph'
      style={{ paddingBottom: `${extraMargin}px` }}
    >
      {
        user ? (
          (user[0].apCalculated === true && user[0].apJobsCalculated === true &&
          user[0].ipCalculated === true && user[0].ipJobsCalculated === true &&
          user[0].wipCalculated === true && user[0].wipJobsCalculated === true &&
          user[0].overlapCalculated === true) ? (
            <>
              <TelosChart2
                user={user} apresults={apresults} ipresults={ipresults}
                wipresults={wipresults} extraMargin={extraMargin} setExtraMargin={setExtraMargin}
              />
              
              {user[0].overlapCalculated !== true &&
              user[0].apCalculated === true && user[0].apJobsCalculated === true &&
              user[0].ipCalculated === true && user[0].ipJobsCalculated === true &&
              user[0].wipCalculated === true && user[0].wipJobsCalculated === true &&
                <TrifectaCalculate apresults={apresults} ipresults={ipresults} wipresults={wipresults} />
              }
            </>
          ) : (
            <TrifectaNoData />
          )
        ) : (
          <div>
            <LoadingPage />
          </div>
        )
      }
    </div>
  )
};

export default TrifectaGraph;