import React from 'react';
import './Science.css';

const ikigaiPic = `/images/scienceImages/science-ikigai.png`;

const Ikigai = () => {
  return (
    <div className="ikigai">
      <div className="ikigai__header">
        The Science Behind the Scenes
      </div>
      <div className="ikigai__outerBox">
        <div className="ikigai__triangle" />
        <div className="ikigai__body ikigai__body-white">
          Telos assessments meld the Okinawan concept of Ikigai and the western concept of a hierarchy of needs.
          We harness a set of psychological tests developed by the US Department of Labor using the best in current
          research and statistics to help pinpoint what these concepts mean for you specifically. With these elements
          combined, you will gain an accurate understanding of what types of work will suit you best.
        </div>
      </div>
      <div className="ikigai__main">
        <img src={ikigaiPic} alt="ikigai" className='ikigai__pic' />
        <div className="ikigai__explanation">
          <div className='ikigai__header ikigai__header-left'>
            IKIGAI
          </div>
          <div className='ikigai__body ikigai__body-gray'>
            Ikigai (ee-key-guy) is an Okinawan concept that defines your purpose for living.
            It balances what you love, what you're good at, what you can make a living at
            (i.e., something you can get paid for), and what the world is in need of.<br /><br />

            If you end up in a career that you are good at, but hate doing, it won't last long
            or will make you unhappy. In the same vein, if you love something but have no ability for it,
            you will be both unsuccessful and unhappy. Because people and the world we live in are complex, 
            it can be hard to find a career that is best suited to you. Our combination of tests was designed 
            to help you find your best path, which will have benefits not just for you, but for the world we live in too.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ikigai