import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { siCompleteSubmit } from "../../../store/actions/submitActions";
import './APAssessment.css';

import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useEffect } from 'react';

const GeneralInstructions2 = ({ siCompleteSubmit, user }) => {
  // Store secondary instructions as complete = true in user's Firestore database.
  const onClick = () => {
    // TODO: Check if siComplete is already equal to true. If it is, don't call siCompleteSubmit().
    siCompleteSubmit();   // Sends complete = true boolean to Firestore.
  }

  const [ cmComplete, setCMComplete ] = useState(false);

  useEffect(() => {
    try {
      if (user) {
        if (user[0].cmComplete) {
          setCMComplete(user[0].cmComplete);
        } else {
          setCMComplete(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user]);


  // Route guarding.
  let arComplete = null;
  let vaComplete = null;
  let saComplete = null;
  if (user) {
    // If this assessment has been completed, redirect to '/apassessment'.
    try {
      arComplete = user[0].arComplete;
      vaComplete = user[0].vaComplete;
      saComplete = user[0].saComplete;
      if ((!arComplete) || (!vaComplete) || (!saComplete)) return <Redirect to='/apassessment' />
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  return (
    <div className="generalinstructions">
      <div className='ap-main-box'>
        <div className="ap-instructions-header">
          Assessment 3: Parts 4, 5, and 6
        </div>
        <div className='sa-instructions-header2'>
          Instructions
        </div>
        <div className="ap-instructions-body sa-instructions-body">
          The next three parts are different from the parts you've already taken. On these parts, <b>SPEED </b>
          is <b>VERY IMPORTANT</b>. You won't have time to answer every question. You must work as <b>FAST </b>
          as you can but don't be careless.
        </div>
        <div className="ap-instructions-body sa-instructions-body">
          If you have even the slightest idea of the answer, it is to your advantage to make your
          <b> BEST GUESS</b>. If you can eliminate one or more wrong choices to the question, then make your
          <b> BEST GUESS</b> from the remaining choices. However, if you have no idea of the correct answer, don't
          spend time guessing; go to the next question.
        </div>
        <div className="ap-instructions-body sa-instructions-body">
          You'll receive one point for each correct answer. You'll be penalized for wrong answers. Points will not
          be subtracted for questions you don't answer.
        </div>
        <div className="ap-row-2 ap-row-2-practice">
          {
            (cmComplete) ? (
              <NavLink to ='/apassessment' style={{ marginLeft: 'auto' }}>
                <button onClick={onClick} type='button' className="ap-instructions-button">
                  Continue
                </button>
              </NavLink>
            ) : (
              <NavLink to ='/cmassessment' style={{ marginLeft: 'auto' }}>
                <button onClick={onClick} type='button' className="ap-instructions-button">
                  Next
                </button>
              </NavLink>
            )
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    user: state.firestore.ordered.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    siCompleteSubmit: (result) =>
      dispatch(
        siCompleteSubmit(result)
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid }
    ]
  })
)(GeneralInstructions2)
