import firebase from '../../../config/fbConfig.js';
// import { rescale } from '../Functions.js';
import corrcoeff from '../CalcCorrCoef.js'

export const wipScoreCalculate = (uid, wip, wipResultSubmit, wipCalculatedSubmit) => {
  
  // console.log('WIP CALCULATE');

  let achieveZScore = null;
  let workZScore = null;
  let recogZScore = null;
  let relateZScore = null;
  let supportZScore = null;
  let indepZScore = null;

  try {
    // Map D&D answers from WIP to an array called 'wipValues'.
    let wipData;
    if (wip) { 
      wipData = wip.wipAnswers;
      let wipValues = [];
      let label;
      let count;
      for(let i = 0; i < 21; i ++){
        count = i+1;
        label = `id${count}`;
        wipValues.push({
          id: wipData[label].id,
          order: wipData[label].order
        });
      }

      // Map RB answers from WIP to an array called rbValues'.
      const rbData = wip.rbAnswers;
      let rbValues = [];
      for(let i = 0; i < 21; i ++) {
        count = i + 22;
        label = `id${count}`;
        rbValues.push({
          id: rbData[label].id,
          multiValues: rbData[label].multiValues
        });
      }

      const respOptions = [
        'A',    // A, 1
        'B',    // B, 2
        'C',    // C, 3
        'D',    // D, 4
        'E',    // E, 5
        'F',    // F, 6
        'G',    // G, 7
        'H',    // H, 8
        'I',    // I, 9
        'J',    // J, 10
        'K',    // K, 11
        'L',    // L, 12
        'M',    // M, 13
        'N',    // N, 14
        'O',    // O, 15
        'P',    // P, 16
        'Q',    // Q, 17
        'R',    // R, 18
        'S',    // S, 19
        'T',    // T, 20
        'U'     // U, 21
      ];

      // Cycle through each ranking-type question.
      let respValues = Array.from({length: 21}, () => 0);
      let tempData;
      let matchId;
      let newOrder = [];
      for(let i = 0; i < wipValues.length; i++) {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = wipValues.map(function(item) {
          let searchIndex = i+1;
          let searchText = `WIP-Prompt-${searchIndex}`;
          if (item.id === searchText) {
            return item.order;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);

        // Convert tempData to letters (newOrder).
        let stringParse;
        for (let v = 0; v < tempData.length; v++) {
          stringParse = tempData[v].split('-');
          newOrder[v] = respOptions[stringParse[2]];
        }

        // Based on the selected order, add up the rankings of all 21 question types
        // across all 21 questions.
        let index = 0;
        for(let j = 0; j < newOrder.length; j++) {
          index = index + 1;  // Equals '1' for the first answer to each question, '2' for the second, etc.
          // Add to 'respValues' array based on order of ranked questions.
          for(let k = 0; k < respOptions.length; k++) {
            if(newOrder[j] === respOptions[k]) {
              respValues[k] = respValues[k] + index;      // Score for each response option (A, B, ... , U).
            }
          }
        }
      }

      // Convert the sum of ranks to votes. This allows us to convert rank scores into paired comparison scores (Appendix C).
      let respVotes = Array.from({length: 21}, () => 0);
      for(let i = 0; i < respValues.length; i++) {
        respVotes[i] = 25 - respValues[i];
      }

      // Compute the number of items answered 'No' and add 1 to each item answered 'No' or 'Not Important' in questions 22-42.
      let zeroPointVotes = 0;   // Number of questions answered 'No' in the radio button questions.
      let orderedRBData = [];
      for(let i = 0; i < respValues.length; i++) {
        // Find the correct rbValues object within the array, based on id value. Question 22 corresponds to answer option 'A',
        // question 23 corresponds to answer option 'B', etc.
        matchId = rbValues.map(function(item) {
          let searchIndex = i+22;
          let searchText = `WIP-Prompt-${searchIndex}`;
          if (item.id === searchText) {
            // Return the state of this option ('Yes' or 'No').
            if(item.multiValues[0].isSelected === true) {
              return 'Yes';
            } else {
              return 'No';
            }
          }
          else {
            return null;
          }
        });
        orderedRBData[i] = matchId.find(el => el !== null);

        if (orderedRBData[i] === 'No') {
          zeroPointVotes = zeroPointVotes + 1;
        }
      }

      // Add 0.5 votes to each item to account for self-correlation (an item compared to itself will be picked half the time).
      for(let i = 0; i < respValues.length; i++) {
        respVotes[i] = respVotes[i] + 0.5;
      }

      // Add 1 vote to each item rated 'Important' (answered 'Yes') in questions 22-42.
      const rbOrder = [ 'Q', 'I', 'K', 'J', 'H', 'C', 'B', 'A', 'G', 'S', 'E', 'O', 'M', 'T', 'L', 'D', 'U', 'N', 'F', 'R', 'P' ];  // This is the order of questions 22-42, based on the alphabetic naming convention.
      for(let i = 0; i < respValues.length; i++) {
        if (orderedRBData[i] === 'Yes') {
          for(let j = 0; j < respOptions.length; j++) {
            if (rbOrder[i] === respOptions[j]) {
            respVotes[j] = respVotes[j] + 1;
            }
          }
        }
      }

      // Calculate z-score from table on page C-5 in "Development of the O*NET Computerized Work Importance Profiler."
      const zValues = [-2.000, -1.489, -1.207, -0.998, -0.825, -0.674, -0.538, -0.410, -0.289, -0.172, -0.057,
        0.057, 0.172, 0.289, 0.410, 0.538, 0.674, 0.825, 0.998, 1.207, 1.489, 2.000];
      const possibleScores = Array.from({length: 22}, (v, i) => 0.5+i);
      const respZScore = Array.from({length: respVotes.length}, (v, i) => 0);
      for(let i = 0; i < respVotes.length; i++) {
        for(let j = 0; j < possibleScores.length; j++) {
          if (respVotes[i] === possibleScores[j]) {
            respZScore[i] = zValues[j]; // This is the unadjusted z-score.
          }
        }
      }

      // Calculate final z-score by subtracting the z-score of the zero-point item from each item's z-score.
      zeroPointVotes = zeroPointVotes + 0.5;    // Zero-point vote with the 0.5 added to account for self-correlation (or whatever it's called).
      let zeroPointZScore = [];
      for(let i = 0; i < possibleScores.length; i++) {
        if (zeroPointVotes === possibleScores[i] ) {
          zeroPointZScore = zValues[i];
        }
      }

      // Calculate final, adjusted z-score of each item A-U.
      for (let i = 0; i < respVotes.length; i++) {
        respZScore[i] = Math.round((respZScore[i] - zeroPointZScore)*1000)/1000;
      }

      // IMPORTANT NOTE: Based on these calculations, it IS possible to calculate a z-score as high as 4. Why? Because the maximum unadjusted
      // z-score is 2. If a user says that every metric is important (answering 'Yes' to questions 22-42), then the zeroPointZScore will end
      // up being -2. 2 minus negative 2 equals 4.

      /* Calculate the mean of the item scores in each subscale. */
      // 1. Achievement: A + F (0, 5)
      achieveZScore = Math.round((respZScore[0] + respZScore[5])/2*1000)/1000;

      // 2. Working Conditions: C + G + J + N + R + S (2, 6, 8, 13, 17, 18)
      workZScore = Math.round((respZScore[2] + respZScore[6] + respZScore[8] + 
                        respZScore[13] + respZScore[17] + respZScore[18])/6*1000)/1000;

      // 3. Recognition: D + E + L + U (3, 4, 11, 20)
      recogZScore = Math.round((respZScore[3] + respZScore[4] + respZScore[11] + 
        respZScore[20])/4*1000)/1000;
      
      // 4. Relationships: H + K + O (7, 10, 14)
      relateZScore = Math.round((respZScore[7] + respZScore[10] + respZScore[14])/3*1000)/1000;

      // 5. Support: B + P + Q (1, 15, 16)
      supportZScore = Math.round((respZScore[1] + respZScore[15] + respZScore[16])/3*1000)/1000;

      // 6. Independence: I + M + T (8, 12, 19)
      indepZScore = Math.round((respZScore[8] + respZScore[12] + respZScore[19])/3*1000)/1000;

    }

    if (achieveZScore !== null && workZScore !== null && recogZScore !== null &&
        relateZScore !== null && supportZScore !== null && indepZScore !== null) {
          // Submit data to Firebase.
          wipResultSubmit({
            achieveZScore, workZScore, recogZScore,
            relateZScore, supportZScore, indepZScore, uid
          });
    }

    // Reorganize data in this order: Achievement, Working Conditions, Recognition,
    // Relationships, Support, Independence. This matches the order of elements listed
    // in O*Net's 'Work Values' database file.
    // const wipAnswers = [achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore];

    // Set 'wipCalculated' to true in user's document.
    wipCalculatedSubmit(uid);  // Sends calculated = true boolean to Firestore.

    return [achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore];

  } catch (error) {
    console.log('Error: ', error);
  }
}

// Function to calculate the user's WIP jobs.
export const wipJobOverlapCalculate = async (
  uid, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore
) => {
  // Function contents go here.
  try {
    if (achieveZScore !== null && workZScore !== null && recogZScore !== null && relateZScore !== null && supportZScore !== null && indepZScore !== null) {
      // Only call functions and pass data.

      // Generate an array of O*Net doc IDs.
      const docIDs = await generateOnetDocIds();
      const filledDocs = await fetchAllOnetDocs(docIDs, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore);
      
      const sortedResults = filledDocs.sort((a, b) => a.calcOutput > b.calcOutput ? 1 : -1);

      sortedResults.reverse().map((element, index) => {
        element.rank = index;   // Add 'rank' element to the object.
        return element;
      });

      await submitSortedResults(sortedResults, uid);

      return sortedResults;

      // // Generate an array of O*Net doc IDs.
      // generateOnetDocIds()
      //   .then(async (docIDs) => {
      //     // Fulfilled O*Net docs.
      //     fetchAllOnetDocs(docIDs, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore)
      //       .then(async (filledDocs) => {
      //         // console.log('Filled docs: ', filledDocs);
      //         // Rank jobs based on correlation coefficient.
      //         const sortedResults = filledDocs.sort((a, b) => a.calcOutput > b.calcOutput ? 1 : -1)
      //           .reverse().map((element, index) => {
      //             element.rank = index;   // Add 'rank' element to the object.
      //             return element;
      //         });
      //         // console.log('Sorted results: ', sortedResults);
      //         // Send array to user's Firestore.
      //         // console.log('IP sorted data being sent to Firebase.');
      //         // wipResultSubmit({ sortedResults });  // Sends results to Firestore.
      //         // console.log('WIP sorted data sent to Firebase.');

      //         // Take the first 'resultCount' number of results.
      //         // const shortResults = await grabTopResults(sortedResults, wipThreshold);

      //         // Submit data to Firebase.
      //         // submitSortedResults(shortResults, auth);
      //         submitSortedResults(sortedResults, uid);

      //         return [sortedResults];
      //     });
      //   });
    }
  } catch (error) {
      console.error(error);
  }
}

// Fetch a single O*Net doc from the Firebase database.
async function fetchOnetDoc(docId) {
  const onetDoc = await firebase.firestore().collection('onetData').doc(docId).get();
  const onetDocData = await onetDoc.data();
  return onetDocData;
}

// Submit the top 'resultCount' number of results to Firebase.
async function submitSortedResults(sortedResults, uid) {
  // const connection = await firebase.firestore().collection('apresults').doc(auth.uid).set({
  await firebase.firestore().collection('wipresults').doc(uid).set({
      wipSortedResults: sortedResults
  }, { merge: true });

  // Add a boolean to the user's document called 'wipJobsCalculated' and set it to true. This is used
  // in AllResults.js to determine whether to run the overlap calculations.
  await firebase.firestore().collection('users').doc(uid).set({
      wipJobsCalculated: true
    }, { merge: true });
}

// Generate O*Net doc IDs.
async function generateOnetDocIds() {
  //E000 - E872
  const onetDocIds = [];
  const maxDocCount = 872;
  for (let i = 0; i < maxDocCount; i++) {
      const docId = `E${String(i).padStart(3, '0')}`;
      onetDocIds.push(docId);
  }
  const data = await onetDocIds;

  return data;
}

// Calculate the correlation coefficient for each occupation.
async function calcDoc(onetDoc, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore) {
  // Define a map (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Addition_assignment).
  // This maintains its order, unlike an array and unlike an object.
  const wipDataMap = new Map();

  wipDataMap.set("wipAchieve",{
      onetVal: onetDoc.wipAchievement,
      userVal: achieveZScore
  })
  wipDataMap.set("wipWork",{
      onetVal: onetDoc.wipWorkingConditions,
      userVal: workZScore
  })
  wipDataMap.set("wipRecog",{
      onetVal: onetDoc.wipRecognition,
      userVal: recogZScore
  })
  wipDataMap.set("wipRelate",{
      onetVal: onetDoc.wipRelationships,
      userVal: relateZScore
  })
  wipDataMap.set("wipSupport",{
      onetVal: onetDoc.wipSupport,
      userVal: supportZScore
  })
  wipDataMap.set("wipIndep",{
      onetVal: onetDoc.wipIndependence,
      userVal: indepZScore
  });

  // console.log(ipDataMap);

  // Calculate correlation coefficient.
  const wipCorrCoeff = corrcoeff(wipDataMap);

  // Return correlation coefficient.
  return wipCorrCoeff;
}

// Calculate the matching score.
async function matchCalc(calcOutput) {
  // // 'calcOutput' is the correlation coefficient on [-1, 1] and
  // // needs to be rescaled to [0, 1].
  // const originalRange = [-1, 1];
  // const desiredRange = [0, 100];
  // const matchScore = rescale(originalRange, desiredRange, calcOutput);

  // Convert wipArrayCalcOutput to z-score.
  const wipMean = 0.016794;   // Mean WIP correlation coefficient based on beta tester results.
  const wipDev = 0.439068;    // Standard deviation of the WIP correlation coefficient based on beta tester results.
  const wipZScore = (calcOutput - wipMean)/wipDev;  // Calculate WIP Z-score.
  const wipPercentile = GetZPercent(wipZScore);             // Calculate WIP percentile.

  const matchScore = wipPercentile;

  return matchScore;
}

// Fetch all O*Net docs.
async function fetchAllOnetDocs(docIdArray, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore) {
  try {
      let onetDoc = null;
      const returnVal = await Promise.all(docIdArray.map(async function stuff (id) {
          // console.log("Running calculation for document, ID: ", id);
          onetDoc = await fetchOnetDoc(id);
          // console.log('ONet Doc: ', onetDoc);
          if (!onetDoc) {
              console.error("Encountered error fetching doc, ID: ", id);
              return null;
          }
          if (!onetDoc.UID) {
              console.error("Encountered error: Doc does not have UID, Doc: ", onetDoc);
              return null;
          }
          if (onetDoc.UID !== id) {
              console.error({
                  message: "Encountered error: the fetched doc id does not match the input id, Doc: ", 
                  onetDoc,
                  id
              });
              return null;
          }

          // Do calculation.
          const calcOutput = await calcDoc(onetDoc, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore);

          // Run match score calculation.
          const matchScore = await matchCalc(calcOutput);
          
          return {
              UID: onetDoc.UID,
              calcOutput,
              matchScore,
              occupation: onetDoc.occupation,
              onetCode: onetDoc.code,
              jobZone: onetDoc.jobZone,
              brightOutlook: onetDoc.brightOutlook
          }            
      }));

      const filteredVals = returnVal.filter((element)=>element!==null);

      return filteredVals;
  }
  catch(error) {
      console.error("Encountered error processing onet docs: ", error);
  }
  return []
}

// Function to convert Z-score to percentile.
function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);

  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
}