import React, { useState, useEffect } from 'react';
import { AdminDisplayStates } from './Configs';
import LoadingPage from '../loadingPage/LoadingPage';
import Admin from './Admin';
import Admin2 from './Admin2';
import './Admin.css';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

const AdminFrontPage = ({ user, auth }) => {
  const [ display, setDisplay ] = useState(AdminDisplayStates.LOADING);

  useEffect(() => {
    try {
      // Check if user is signed in.
      if (auth.isLoaded) {
        if (auth.isEmpty) {
          // User isn't signed in.
          // setDisplay(AdminDisplayStates.SIGNED_OUT);
          setDisplay(AdminDisplayStates.LOADING);
        } else {
          if (user) {
            if (user[0]) {
              if (user[0].admin) {
                // User is an admin.
                // setDisplay(AdminDisplayStates.ADMIN);
                setDisplay(AdminDisplayStates.ADMIN_V2);
              }
            }
          }
        }
      } else {
        setDisplay(AdminDisplayStates.LOADING);
      }

    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, auth]);

  return (
    <div className='adminFrontPage'>
      {display === AdminDisplayStates.LOADING && (
        <LoadingPage />
        )
      }
      {display === AdminDisplayStates.ADMIN && (
        <Admin />
        )
      }
      {display === AdminDisplayStates.ADMIN_V2 && (
        <Admin2 auth={auth} />
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    user: state.firestore.ordered.users,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
    ]
  })
)(AdminFrontPage)