export default function getCorrCoef(dataMap) {
    // Calculate correlation coefficient. Accepts a data map.
  
    const n = dataMap.size;
    let sumSqOnet = 0;
    let sumSqUser = 0;
    let sumFull = 0;

    // Calculate mean.
    let sumOnetVals = 0;
    let sumUserVals = 0;
    dataMap.forEach(({onetVal, userVal}, key) => {
      sumOnetVals += onetVal;
      sumUserVals += userVal;
    })
    const onetMean = sumOnetVals/n
    const userMean = sumUserVals/n

    // Calculate summations.
    dataMap.forEach(({onetVal, userVal}, key)=>{

      sumSqOnet += Math.pow((onetVal - onetMean), 2)
      sumSqUser += Math.pow((userVal - userMean), 2)

      sumFull += (onetVal - onetMean)*(userVal - userMean)
    })

    // Calculate standard deviations.
    const onetStdDev = Math.sqrt(sumSqOnet/n)
    const userStdDev = Math.sqrt(sumSqUser/n)

    // Calculation correlation coefficient.
    const corr = sumFull/(n*onetStdDev*userStdDev);


    //   // TODO: Finish calculating summations needed for correlation coefficient calculation!!!


    //   const multiOnetUser = onetVal*userVal;
    //   const multiOnet = onetVal*onetVal;
    //   const multiUser = userVal*userVal;

    //   sumOnetVals += onetVal;
    //   sumUserVals += userVal;
    //   sumMultiOnetUser += multiOnetUser;
    //   sumMultiOnet += multiOnet;
    //   sumMultiUser += multiUser;
    // })

    // // Calculations.

    // const onetStdDev = Math.sqrt()

    // // Use formula for calculating correlation coefficient.
    // const corr = (n * sumMultiOnetUser - sumOnetVals*sumUserVals)/Math.sqrt((n*sumMultiOnet - sumOnetVals*sumOnetVals)*(n*sumMultiUser - sumUserVals*sumUserVals));

    // n = 6






    // // let n = A.length;
    // let sumA = 0;
    // let sumB = 0;
    // let sumAB = 0;
    // let squareSumA = 0;
    // let squareSumB = 0;
  
    // for (let i = 0; i < n; i++) {
    //   // Sum of elements of array A.
    //   sumA = sumA + A[i];
  
    //   // Sum of elements of array B.
    //   sumB = sumB + B[i];
  
    //   // Sum of A and B.
    //   sumAB = sumAB + A[i]*B[i];
  
    //   // Sum of square of array elements.
    //   squareSumA = squareSumA + A[i]*A[i];
    //   squareSumB = squareSumB + B[i]*B[i];
    // }
  
    // // Use formula for calculating correlation coefficient.
    // const corr = (n * sumAB - sumA*sumB)/Math.sqrt((n*squareSumA - sumA*sumA)*(n*squareSumB - sumB*sumB));
  
    // Return the correlation coefficient.
    return corr;
  }