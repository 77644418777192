import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../VA.css';

const InstructionsQ1 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');
  const [ currentQuestion ] = useState(0);

  useEffect(() => {
    if (practiceAnswers[currentQuestion].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[currentQuestion].values === 'A-B') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers, currentQuestion]);

  // Answer options.
  const answerOptions = [
    'A-B',
    'A-C',
    'A-D',
    'B-C',
    'B-D',
    'C-D'
  ];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[currentQuestion].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: currentQuestion,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === currentQuestion) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === 'A-B') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  return (
    <div className='va-main-box va-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 2 - Vocabulary
      </div>
      <div className='va-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body va-instructions-body">
        In this part are some questions in which you are asked to pick the two words that are either
        <b><i> most nearly the same</i></b> in meaning or <b><i>most nearly the opposite</i></b> in meaning.
      </div>
      <div className="ap-instructions-body va-instructions-body">
        Read and answer Practice Question 1:
      </div>
      <div className="question-va">
        <div className="question__number-va">
          1.
        </div>
        <div className="question__body-va">
          <div className="question__text-va">
            A. big
          </div>
          <div className="question__text-va">
            B. large
          </div>
          <div className="question__text-va">
            C. dry
          </div>
          <div className="question__text-va">
            D. slow
          </div>
          <div className="question__answers-horizontal-va">
            {
              answerOptions.map((element, index) => (
                <label className="question__answer-option-horizontal-va" key={index}>
                  <div className="question__answer-text-va">
                    {element}
                  </div>
                  <input
                    type="checkbox"
                    className='question__radio-button question__radio-button-va'
                    checked={practiceAnswers[currentQuestion].values === element}
                    name={currentQuestion}
                    value={element}
                    onChange={e => handleAnswer(e)}
                  />
                </label>
              ))
            }
          </div>
        </div>
        { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
          correct ? (
            <div className="display-answer-va-flex">
              Correct
              <div className="display-answer__body">
                That's right, BIG and LARGE have the same meaning. The letter for BIG is A and the letter for
                LARGE is B, therefore A-B is the correct answer.
              </div>
            </div>
          ) : (
            <div className="display-answer-va-flex">
              Incorrect
              <div className="display-answer__body">
                BIG and LARGE have the same meaning. The letter for BIG is A and the letter for
                LARGE is B, therefore A-B is the correct answer.
              </div>
            </div>
          )
        ) : (
          <div className="display-answer-va-flex">&nbsp;</div>
        )
      }
      </div>
      <div className="va-row-2 va-row-2-practice">
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Next
        </button>
      </div>
      {/* { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
          correct ? (
            <div className="display-answer-va display-answer-va-q1">
              Correct
              <div className="display-answer__body">
                That's right, BIG and LARGE have the same meaning. The letter for BIG is A and the letter for
                LARGE is B, therefore A-B is the correct answer.
              </div>
            </div>
          ) : (
            <div className="display-answer-va display-answer-va-q1">
              Incorrect
              <div className="display-answer__body">
                BIG and LARGE have the same meaning. The letter for BIG is A and the letter for
                LARGE is B, therefore A-B is the correct answer.
              </div>
            </div>
          )
      ) : (
        <div className="display-answer-va"></div>
      )
      } */}
    </div>
  )
}

export default InstructionsQ1
