import React, { Fragment, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// import Splash from './components/pages/splash/Splash';
import HomeFrontPage from "./components/pages/home/HomeFrontPage";
import Assessments from "./components/pages/assessments/Assessments";
import SignedInAssessments from "./components/pages/assessments/AssessmentChoose";
import AboutUs from "./components/pages/about/AboutUs";
// import Results from "./components/results/Results";
import ResultFrontPage from "./components/results/ResultFrontPage";
import TopJobs from './components/results/topjobs/TopJobs';
import Science from "./components/pages/science/Science";
import SignIn from "./components/auth/SignIn2";
import SignUp from "./components/auth/SignUp2";
import { connect } from "react-redux";
import WIPFrontPage from "./components/assessments/wip/WIPFrontPage";
import IPFrontPage from "./components/assessments/ip/IPFrontPage";
import APFrontPage from "./components/assessments/ap/APFrontPage";
import ARFrontPage from "./components/assessments/ap/Assessments/ar/ARFrontPage";
import VAFrontPage from "./components/assessments/ap/Assessments/va/VAFrontPage";
import SAFrontPage from "./components/assessments/ap/Assessments/sa/SAFrontPage";
import CMFrontPage from "./components/assessments/ap/Assessments/cm/CMFrontPage";
import CPFrontPage from "./components/assessments/ap/Assessments/cp/CPFrontPage";
import FPFrontPage from "./components/assessments/ap/Assessments/fp/FPFrontPage";
import APGeneralInstructions from "./components/assessments/ap/GeneralInstructions";
import APSecondaryInstructions from "./components/assessments/ap/GeneralInstructions2";
// import Payment from "./components/pages/payments/Payment";
import Payment from "./components/pages/payments/PaymentFrontPage";
// import Payment from './components/pages/payments/payments2/Payment';
import Footer from './components/footer/Footer';
import Error from './components/pages/error/Error';
import Profile from './components/pages/profile/Profile';
import Admin from './components/pages/admin/AdminFrontPage';
import Privacy from './components/pages/privacy/Privacy';
import Terms from './components/pages/terms/Terms';
import { LastLocationProvider } from 'react-router-last-location';
// import './styles/Home.css';
import './App.css';
import NavbarCheck from "./components/Navbar/NavbarCheck";
import { useEffect } from "react";
import LoadingPage from "./components/pages/loadingPage/LoadingPage";
import ForgotPassword from "./components/auth/ForgotPassword";
import ScrollToTop from './Utils/ScrollToTop';
import LandingPage from "./components/pages/landingPage/LandingPage";

function App({ auth, user }) {
  const [ signedIn, setSignedIn ] = useState(false);
  const [ authLoaded, setAuthLoaded ] = useState(false);
  const [ resultsIndex, setResultsIndex ] = useState(0);

  useEffect(() => {
    setAuthLoaded(auth.isLoaded);
    setSignedIn(auth.uid ? true : false);
  }, [auth]);

  return (
    <Router>
      <ScrollToTop>
        <LastLocationProvider>
          <div className='app'>
            <Switch>
              {/* <Route exact path="/" component={Splash} /> */}
              <Fragment>
                <div className="app__main">
                  <Route
                    path="/"
                    render={(props) => <NavbarCheck {...props} signedIn={signedIn} resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} />}
                  />

                  {/* I have to put all this inside a switch to prevent the catch-all '*' path
                  component from displaying on every page. */}
                  <Switch>
                    <Route
                      exact path="/home"
                      // render={(props) => <Home {...props} appAuth={auth} />}
                      render={(props) => <HomeFrontPage {...props} appAuth={auth} />}
                    />
                    <Route
                      exact path="/"
                      // render={(props) => <Home {...props} appAuth={auth} />}
                      render={() => <Redirect to="/home" />}
                    />
                    { authLoaded ? (
                      signedIn ? (
                        <Route exact path="/assessments" component={SignedInAssessments} />
                      ) : (
                        <Route exact path="/assessments" component={Assessments} />
                      )
                    ) : (
                      <Route exact path='/assessments' component={LoadingPage} />
                    )}
                    <Route exact path="/aboutus" component={AboutUs} />
                    {/* <Route exact path="/results" component={Results} /> */}
                    <Route
                      exact path="/results"
                      render={(props) => <ResultFrontPage {...props} resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} />}
                      // component={ResultFrontPage} resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} />
                    />

                    <Route exact path="/topjobs" component={TopJobs} />

                    <Route exact path="/science" component={Science} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/vpassessment" component={WIPFrontPage} />
                    <Route
                      exact path="/ipassessment"
                      render={(props) => <IPFrontPage {...props} />}
                    />
                    <Route exact path="/apassessment" component={APFrontPage} />
                    <Route exact path="/apassessment-general-instructions" component={APGeneralInstructions} />
                    <Route exact path="/arassessment" component={ARFrontPage} />
                    <Route exact path="/vaassessment" component={VAFrontPage} />
                    <Route exact path="/saassessment" component={SAFrontPage} />
                    <Route exact path="/apassessment-secondary-instructions" component={APSecondaryInstructions} />
                    <Route exact path="/cmassessment" component={CMFrontPage} />
                    <Route exact path="/cpassessment" component={CPFrontPage} />
                    <Route exact path="/fpassessment" component={FPFrontPage} />
                    <Route exact path="/payment" component={Payment} />
                    <Route exact path="/error" component={Error} />
                    <Route exact path='/profile' component={Profile} />
                    <Route exact path='/admin' component={Admin} />
                    <Route exact path='/termsandconditions' component={Terms} />
                    <Route exact path='/privacypolicy' component={Privacy} />
                    <Route exact path='/info' component={LandingPage} />
                    <Route path='*' component={Error} status={404} />

                  </Switch>

                </div>
                <Footer />
              </Fragment>
            </Switch>
          </div>
        </LastLocationProvider>
      </ScrollToTop>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,  // Get auth status.
    user: state.firestore.ordered.users
  };
};

export default connect(mapStateToProps)(App);
