import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';

const TelosLogo = `/images/Telos_Logo_Light.png`;

const SignedOutLinks2Dark = () => {
    const [ expanded, setExpanded ] = useState(false);

		const timerRef = useRef(null);

		const thisLocation = useLocation();
		let currentPath = thisLocation.pathname;
		const weight = {
			fontWeight: '800'
		}

		useEffect(() => {
			// Clean up async issues with setTimeout.
			return () => clearTimeout(timerRef.current);
		}, []);

		// Close hamburger menu (if its open).
    const onClick2 = (e) => {
			timerRef.current = setTimeout(() => {setExpanded(false)}, 0);
		}

    return (
			<Navbar className='navbar__wrapper' collapseOnSelect expand="lg" bg="transparent" expanded={expanded}>
				<Container fluid className='navbar'>
					<NavLink to="/home">
						<img
							src={TelosLogo}
							alt=''
							width='50px'
							height="auto"
							className="navbar__logo"
						/>
					</NavLink>
					<Navbar.Toggle
							aria-controls="responsive-navbar-nav"
							onClick={() => setExpanded(expanded ? false : true)}
					/>
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto">
							<div className='navbar__left'>
								<NavLink to='/assessments' className="navbar__link navbar__link-dark navbar__link-signedOut" onClick={onClick2}>
									{
										(
											currentPath === '/assessments'
										) ? (
											<div style={weight}>
												Assessments
											</div>
										) : (
											<div>
												Assessments
											</div>
										)
									}
								</NavLink>
								<NavLink to='/results' className="navbar__link navbar__link-dark navbar__link-signedOut navbar__link-signedOutBold" onClick={onClick2}>
									{
										(
											currentPath === '/results'
										) ? (
											<div style={weight}>
												Your Results
											</div>
										) : (
											<div>
												Your Results
											</div>
										)
									}
								</NavLink>
								<NavLink to='/science' className="navbar__link navbar__link-dark navbar__link-signedOut" onClick={onClick2}>
									{
										(
											currentPath === '/science'
										) ? (
											<div style={weight}>
												The Science
											</div>
										) : (
											<div>
												The Science
											</div>
										)
									}
								</NavLink>
								<NavLink to='/aboutus' className="navbar__link navbar__link-dark navbar__link-signedOut" onClick={onClick2}>
									{
										(
											currentPath === '/aboutus'
										) ? (
											<div style={weight}>
												About Us
											</div>
										) : (
											<div>
												About Us
											</div>
										)
									}
								</NavLink>
                <NavLink to='/payment' className="navbar__link navbar__link-dark navbar__link-signedOut" onClick={onClick2}>
                  {
                    (
                      currentPath === '/payment'
                    ) ? (
                      <div style={weight}>
                        Purchase
                      </div>
                    ) : (
                      <div>
                        Purchase
                      </div>
                    )
                  }
							  </NavLink>
							</div>
							<div className='navbar__right'>
								<NavLink to='/signin' className="navbar__link navbar__link-right navbar__link-dark" onClick={onClick2}>
									{
										(
											currentPath === '/signin'
										) ? (
											<div style={weight}>
												Sign In
											</div>
										) : (
											<div>
												Sign In
											</div>
										)
									}
								</NavLink>
								<NavLink to='/signup' className="navbar__link navbar__link-right navbar__link-dark" onClick={onClick2}>
									<button className="navbar__button navbar__button-dark">
										SIGN UP
									</button>
								</NavLink>
							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
    )
}

export default SignedOutLinks2Dark