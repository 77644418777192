import React from 'react';
import { WIPDisplayStates } from "./Configs";
import './WIPInstructions.css';

const WIPInstructionsC = ({ onClick }) => {

  return (
    <div className='wipInstructions'>
        <div className="wipInstructions__header">
          Assessment 2: Values Profiler
        </div>
        <div className='wipInstructions__subheader'>
          Instructions
        </div>
        <div className="wipInstructions__body2">
          You may feel like you want to "get everything right" but please do not worry or stress
          about that. You do not need to remember what you put in the last question. Just treat each
          new question as its own and answer accordingly.
        </div>
      <div className="wipInstructions__bottom wipInstructions__bottom-instructions">
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_B);
          }}
        >
          Back
        </button>
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_D);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default WIPInstructionsC
