import React, { useState } from 'react';
import { WIPDisplayStates } from '../Configs';
import { ReadyPopup } from '../../../../../../Utils/ReadyPopup'

const InstructionsFinal = ({ setDisplay }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='ar-main-box'>
      <ReadyPopup
        onClick = {setDisplay}
        open={open}
        setOpen={setOpen}
        readyDestination = {WIPDisplayStates.ASSESSMENT}
      />
      <div className="ap-instructions-header">
        Assessments 3: Part 1 - Arithmetic Reasoning
      </div>
      <div className='ar-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        On the next pages are more questions like the ones you've just answered.
        For each question, click on the circle next to your answer.
        <br /><br />
        Work <b>CAREFULLY</b>. You should have enough time to answer each question.
        It is to your advantage to <b>ANSWER EACH QUESTION</b>. Even if you're not sure of
        an answer, make your <b>BEST GUESS</b>. You'll receive one point for each correct answer.
        Points will not be subtracted for questions you answer incorrectly.
        <br /><br />
        If you finish before the time is up, you may go back and check your work.
        <br /><br />
        <b style={{ fontWeight: '800' }}>You will have 20 minutes to complete this part.</b>
      </div>
      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q34);
            }}  
          >
            Back
          </button>
          {/* <button type='button' className="ap-instructions-button" onClick={onClick}> */}
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => setOpen(true)}
          >
            Begin
          </button>
      </div>
    </div>
  )
}

export default InstructionsFinal
