import React from 'react';
// import ProgressBar from '../../../../ProgressBar/ProgressBar';
import { questions, labels, values } from './Configs';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const VAMultiChoice2 = ({count, answer, setAnswer, last, setLast}) => {

  // If count is null, set it to 0.
  if (count === null) {
    count = 0;
  }

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // If already selected, clear it.
    const previousAnswer = answer[count].values;
    const currentAnswer = e.target.value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  const options = questions[count].answerOptions.map((key, index) => {
    return key.answerText;
  });

  const numTotalQuestions = 19;
  // const progress = Math.round((count+1)/numTotalQuestions*100*10)/10;

  // Figure out
  let currentAnswer1 = null;
  let currentAnswer2 = null;
  try {
    if (answer[count].values.length === 2) { // Old method.
      currentAnswer1 = answer[count].values[0] + '-' + answer[count].values[1];
      currentAnswer2 = answer[count].values[1] + '-' + answer[count].values[0];
    } else {  // New method.
      currentAnswer1 = answer[count].values;
      currentAnswer2 = answer[count].values;
    }
  } catch (error) {
    console.log('Error: ', error);
  }


  return (
    <div className='va-main-box'>
      <div className="question-va">
        <div className="question__number-va">
          {count+1}.
        </div>
        <div className="question__body-va">
          { labels.map((key, index) => (
            <div className="question__text-va" key={index}>
              {key}. {options[index]}
            </div>
          )) }
          <div className="question__answers-horizontal-va">
            { values.map((key, index) => (
              <label className="question__answer-option-horizontal-va" key={index}>
                <div className="question__answer-text-va">
                  {key}
                </div>
                <input
                  type="checkbox"
                  className="question__radio-button question__radio-button-va"
                  checked={currentAnswer1 === key || currentAnswer2 === key}
                  value={key}
                  name={count}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            )) }
          </div>
        </div>
      </div>
      <div className="apMultiChoice__bottom">
        {/* <div className="apMultiChoice__progressBar">
          <ProgressBar completed={progress} bgColor='#008375' />
        </div> */}
        <div className="vaMultiChoice__question-count" style={{ width: '100%' }}>
          {count+1} of {numTotalQuestions} questions
        </div>
      </div>
    </div>
  )
}

export default VAMultiChoice2
