import React, { useState, useEffect } from 'react';
// import { BarChart, XAxis, YAxis, ResponsiveContainer, Text } from 'recharts';
import { FaLock } from 'react-icons/fa';

const APChartNoData = () => {
  const [ apData ] = useState([
    {name: 'Verbal Ability', color: '#F5EA61', value: 0, id: 0},
    {name: 'Arithmetic Reasoning', color: '#93D500', value: 0, id: 1},
    {name: 'Computation', color: '#007960', value: 0, id: 2},
    {name: 'Spatial Ability', color: '#009ADE', value: 0, id: 3},
    {name: 'Form Perception', color: '#8CB7C9', value: 0, id: 4},
    {name: 'Clerical Perception', color: '#C2A6E1', value: 0, id: 5}
  ]);

  // Check screen size.
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  const [ chartWidth, setChartWidth ] = useState(800);
  const [ barSpacing, setBarSpacing ] = useState(30);
  const [ yOffset, setYOffset ] = useState(-30);

  // Update the screenWidth state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update the chartWidth and chartHeight based on screen size.
  const tabletSize = 1025;
  const phoneSize = 640;
  useEffect(() => {
    if (screenWidth <= tabletSize && screenWidth > phoneSize) {
      setChartWidth(500);
      setBarSpacing(30);
      setYOffset(-32);
    } else if (screenWidth <= phoneSize) {
      setChartWidth(260);
      setBarSpacing(8);
      setYOffset(-35);
    } else if (screenWidth > tabletSize) {
      setChartWidth(600);
      setBarSpacing(30);
      setYOffset(-30);
    }
  }, [screenWidth]);

  const chartHeight = 400;
  const chartBuffer = 0.05;   // Fraction offset to make sure labels don't get cut off.
  const xAxisHeight = 55;
  const yAxisWidth = 40;
  // const barColor = apData.map(obj => obj.color);
  const data = apData.map(obj => obj.value);
  const barLabel = apData.map(obj => obj.name);
  const maxValue = 100;
  const barWidth = (chartWidth - 1 - yAxisWidth) / data.length - barSpacing;

  // Determine the number of y-axis ticks
  const numYAxisTicks = 9;

  // Calculate tick values based on data range and number of ticks.
  const tickInterval = 20;
  const tickValues = Array.from({ length: numYAxisTicks }, (_, i) => tickInterval * i);

  return (
    <div className='apChart2'>
      <div className='ipChart2'>
        <h1>Abilities highlights</h1>
        <h2>Your Ability Profiler - Percentile Scores</h2>

        <svg
          width={chartWidth}
          height={chartHeight + xAxisHeight}
          className='ipChart2__svg'
        >

          {/* Draw filled area. */}
          <g>
            {/* Draw single bar to fill entire space */}
            <rect
              x={40}
              y={20}
              width={chartWidth - yAxisWidth}
              height={chartHeight-xAxisHeight + 34}
              fill={'#163c66'}
            />

            <foreignObject x="6.5%" y="4.5%" width="94%" height="83.5%">
              {/* Adjust width and height as needed */}
              <div className='chart__rectangle'>
                <div className='chart__icon'>
                  <FaLock />
                </div>
                <div className='chart__body'>
                  Exciting things coming soon!
                </div>
                <div className='chart__subbody'>
                  Finish the abilities profiler to see results here.
                </div>
              </div>
            </foreignObject>
          </g>

          {/* Draw y-axis */}
          <line
            x1={yAxisWidth}
            y1={chartHeight*chartBuffer}
            x2={yAxisWidth}
            y2={chartHeight}
            className='ipChart2__axis'
          />

          {/* Render y-axis ticks and tick labels */}
          {tickValues.map((tickValue, index) => {
            const yTick = chartHeight - (tickValue / maxValue) * (chartHeight*(1-chartBuffer));
            const xTickLabel = yAxisWidth - 5; // Adjust the x position of the tick labels

            return (
              <g key={index}>
                {/* Tick label */}
                <text
                  x={xTickLabel - 5}
                  y={yTick}
                  alignmentBaseline="middle"
                  textAnchor="end"
                  className="ipChart2__ylabel"
                >
                  {tickValue}
                </text>
              </g>
            );
          })}

          {/* Draw x-axis */}
          <line
            x1={yAxisWidth}
            y1={chartHeight}
            x2={chartWidth}
            y2={chartHeight}
            className='ipChart2__axis'
          />

          {/* Add x-axis labels */}
          {data.map((_, index) => {
            const x = yAxisWidth + barSpacing/2 + index * (barWidth + barSpacing) + barWidth / 2;
            const y = chartHeight + xAxisHeight + yOffset;

            return (
              <g key={index}>
                {/* First line, only appears on mobile. */}
                <text
                  x={x}
                  y={y}
                  textAnchor="middle"
                  className='ipChart2__xlabel ipChart2__xlabel-firstMobile'
                >
                  {barLabel[index][0]}
                </text>
                {/* X-axis labels */}
                <text x={x} y={y - 3} textAnchor="middle" className='ipChart2__xlabel ipChart2__xlabel-second'>
                  {/* Split the label into words */}
                  {barLabel[index].split(' ').map((word, wordIndex) => (
                    <tspan key={wordIndex} x={x} dy={wordIndex > 0 ? '1.375em' : 0}>
                      {word}
                    </tspan>
                  ))}
                </text>
              </g>
            );
          })}
        </svg>
      </div>
    </div>
  )
}

export default APChartNoData