import React from 'react';
import { WIPDisplayStates } from '../Configs';
import '../AR.css';

const Instructions = ({ setDisplay }) => {
  return (
    <div className='ar-main-box'>
        <div className="ap-instructions-header">
          Assessments 3: Part 1 - Arithmetic Reasoning
        </div>
        <div className='ar-instructions-header2'>
          Instructions
        </div>
        <div className="ap-instructions-body ar-instructions-body">
          On this part work <b>CAREFULLY</b>.
          <br /><br />
          You should have enough time to answer each question. It is to your advantage to
          <b> ANSWER EVERY QUESTION</b>. Even if you're not sure of an answer, make your <b>BEST GUESS</b>,
          fill in your answer, then go to the next question.
          <br /><br />
          Your score for each part will be the number of questions you answer correctly. There is no penalty
          for answering incorrectly.
        </div>
      <div className="ap-row-2 ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONSB);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Instructions
