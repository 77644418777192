import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../FP.css';
import variables from '../../../../../../variables.module.scss';

const InstructionsQ3 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');
  const [ currentQuestion ] = useState(2);

  useEffect(() => {
    if (practiceAnswers[currentQuestion].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[currentQuestion].values === 'C') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers, currentQuestion]);

  // Images.
  const webImage = `/images/fpImages/web/Object Matching Ins 3.png`;
  const mobileImage = `/images/fpImages/mobile/Object Matching Ins m3.png`;

  // Answer options.
  const answerOptions = [
    'A',
    'B',
    'C',
    'D'
  ];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[currentQuestion].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: currentQuestion,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === currentQuestion) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === 'A-B') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView) + 5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='fp-main-box fp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 6 - Object Matching
      </div>
      <div className='fp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        Now do the next two practice problems in the same way. In each one, find the lettered object that is
        exactly the same as the numbered object. Then select the circle under the letter of your answer.
      </div>

      <div className="fp__content">
        <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="fp__choices" />

        <div className="fp__options">
          {
            answerOptions.map((element, index) => (
              <div className="fp__option" key={index}>
                <input
                  type="checkbox"
                  className='fp__optionButton'
                  checked={practiceAnswers[currentQuestion].values === element}
                  name={currentQuestion}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </div>
            ))
          }
        </div>
        <span className='fp__span'></span>
      </div>

      <div className="display-answer-fp">
        { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
          correct ? (
            <div className="display-answer-fp__content">
              Correct
            </div>
          ) : (
            <div className="display-answer-fp__content">
              Incorrect
            </div>
          )
        ) : (
          <div className="display-answer-fp__content">&nbsp;</div>
        )
        }
      </div>

      <div className="fp-row-2 fp-row-2-spaced fp-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Back
        </button>
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q4);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ3