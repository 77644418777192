import React, { useState, useEffect } from 'react';
import {
  pullAllUsers, getAPCalcOutputs, getAPCorrelationCoefficients,
  getIPCalcOutputs, getWIPCalcOutputs, getTrifectaCalcOutputs, getAPScores,
  getAPAverages
} from '../../../../store/actions/orderActions';
import { connect } from 'react-redux';
import TableHead from './TableHead';
import TableBody from './TableBody';
import Popup from './Popup';
import { saveAs } from 'file-saver';
import { getTestResults } from '../../../../store/actions/orderActions';
import { apScoreCalculate, apJobOverlapCalculate } from '../../../results/functionalizedCalculations/APCalculations';
import {
  apResultSubmit, apCalculatedSubmit, ipResultSubmit, ipCalculatedSubmit,
  wipResultSubmit, wipCalculatedSubmit, trifectaResultSubmit, overlapCalculatedSubmit
} from '../../../../store/actions/submitActions';
import { ipScoreCalculate, ipJobOverlapCalculate } from '../../../results/functionalizedCalculations/ipCalculations';
import { wipScoreCalculate, wipJobOverlapCalculate } from '../../../results/functionalizedCalculations/wipCalculations';
import { trifectaJobOverlapCalculate } from '../../../results/functionalizedCalculations/trifectaCalculations';

const DataTable = ({
  pullAllUsers, getAPCalcOutputs, getIPCalcOutputs, getWIPCalcOutputs,
  getTrifectaCalcOutputs, getTestResults, apResultSubmit, apCalculatedSubmit,
  ipResultSubmit, ipCalculatedSubmit, wipResultSubmit, wipCalculatedSubmit,
  trifectaResultSubmit, overlapCalculatedSubmit, getAPScores, getAPCorrelationCoefficients,
  getAPAverages
}) => {

  // Get all users.
  const [ users, setUsers ] = useState([]);
  useEffect(() => {
    try {
      // Call the action to pull the codes when the component mounts
      pullAllUsers().then(usersData => {
        // Here, you have access to the codesData retrieved from Firestore.
        setUsers(usersData);
      });
    } catch (error) {
      console.log('Error getting codes: ', error);
    }
  }, [pullAllUsers]);

  const columns = [
    { label: 'First Name', accessor: 'firstName', sortable: true },
    { label: 'Last Name', accessor: 'lastName', sortable: true },
    { label: 'UID', accessor: 'userID', sortable: true },
    { label: 'Results Calculated?', accessor: 'resultsCalculated', sortable: true }
  ];

  const handleSorting = (sortField, sortOrder) => {
    try {
      if (sortField !== undefined) {
        const sorted = [...users].sort((a, b) => {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          return (
            a[sortField]?.toString().localeCompare(b[sortField]?.toString(), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        });
        setUsers(sorted);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // User info of user clicked for popup.
  const [ userID, setUserID ] = useState();
  const [ userFirstName, setUserFirstName ] = useState();
  const [ userLastName, setUserLastName ] = useState();

  // For popup.
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = (ind) => {
    try {
      setIsPopupOpen(true);
      setUserID(users[ind].userID);
      setUserFirstName(users[ind].firstName);
      setUserLastName(users[ind].lastName);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Loading bar for recalculations.
  const [ loadingProgress, setLoadingProgress ] = useState(100);


  // Recalculate the selected user's results.
  const recalculateResults = async () => {
    try {
      // Update progress.
      setLoadingProgress(0);

      // Function to recalculate results in the following order,
      // waiting on each before running the next:
      // 1. AP results.
      // 2. IP results.
      // 3. WIP results.
      // 4. Trifecta results.

      // Get user's ar, va, sa, cm, cp, and fp test results.
      const ar = await getTestResults(userID, 'ar');
      const va = await getTestResults(userID, 'va');
      const sa = await getTestResults(userID, 'sa');
      const cm = await getTestResults(userID, 'cm');
      const cp = await getTestResults(userID, 'cp');
      const fp = await getTestResults(userID, 'fp');

      // Wait for the ap results before calling apScoreCalculate.
      const [
        arPercentile, vaPercentile, saPercentile,
        cmPercentile, cpPercentile, fpPercentile
      ] =
      await apScoreCalculate(
        userID, ar, va, sa, cm, cp, fp,
        apResultSubmit, apCalculatedSubmit
      );

      // Calculate AP job overlap.
      const apResults = await apJobOverlapCalculate(
        userID,
        arPercentile, vaPercentile, saPercentile,
        cmPercentile, cpPercentile, fpPercentile
      );

      // Update progress.
      setLoadingProgress(25);

      // Get user's IP test results.
      const ip = await getTestResults(userID, 'ip');

      // Wait for the IP results before calling ipScoreCalculate.
      const [
        rAnswers, iAnswers, aAnswers,
        sAnswers, eAnswers, cAnswers
      ] =
      await ipScoreCalculate(userID, ip, ipResultSubmit, ipCalculatedSubmit);

      // Calculate IP job overlap.
      const ipResults = await ipJobOverlapCalculate(
        userID,
        rAnswers, iAnswers, aAnswers,
        sAnswers, eAnswers, cAnswers
      );

      // Update progress.
      setLoadingProgress(50);

      // Get user's WIP test results.
      const wip = await getTestResults(userID, 'wip');

      // Wait for the WIP results before calling wipScoreCalculate.
      const [
        achieveZScore, workZScore, recogZScore,
        relateZScore, supportZScore, indepZScore
      ] = 
      await wipScoreCalculate(userID, wip, wipResultSubmit, wipCalculatedSubmit);

      // Calculate WIP job overlap.
      const wipResults = await wipJobOverlapCalculate(
        userID,
        achieveZScore, workZScore, recogZScore,
        relateZScore, supportZScore, indepZScore
      );

      // Update progress.
      setLoadingProgress(75);

      // Calculate trifecta job overlap.
      if (apResults && ipResults && wipResults) {
        await trifectaJobOverlapCalculate(
          userID, apResults, ipResults, wipResults,
          trifectaResultSubmit, overlapCalculatedSubmit
        )
      }

      // Update progress.
      setLoadingProgress(100);

    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Download the selected user's IP results.
  const downloadIP = async () => {
    try {
      // Function to get IP results for this UID,
      // grab correlation coefficient data for all occupations,
      // return that data to a variable, and save it
      // to the computer.
      const calcOutputs = await getIPCalcOutputs(userID);
      const filename = 'IP_' + userID;

      // Save as column text file.
      const headerStrings = [userFirstName, userLastName, userID]
      const combinedData = headerStrings.concat(calcOutputs);
      generateAndDownloadFile(combinedData.join('\n'), filename);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Download the selected user's WIP results.
  const downloadWIP = async () => {
    try {
      // Function to get WIP results for this UID,
      // grab correlation coefficient data for all occupations,
      // return that data to a variable, and save it
      // to the computer.
      const calcOutputs = await getWIPCalcOutputs(userID);
      const filename = 'WIP_' + userID;

      // Save as column text file.
      const headerStrings = [userFirstName, userLastName, userID]
      const combinedData = headerStrings.concat(calcOutputs);
      generateAndDownloadFile(combinedData.join('\n'), filename);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Download the selected user's AP results.
  const downloadAP = async () => {
    try {
      // Run function to get AP results for this UID,
      // grab Euclidean distance data for all occuptions,
      // return that data to a variable, and save it
      // to the computer.
      // const calcOutputs = await getAPCalcOutputs(userID);
      // const calcOutputs = await getAPCorrelationCoefficients(userID);
      const calcOutputs = await getAPAverages(userID);
      const filename = 'AP_' + userID;

      // Save as column text file.
      const headerStrings = [userFirstName, userLastName, userID]
      const combinedData = headerStrings.concat(calcOutputs);
      generateAndDownloadFile(combinedData.join('\n'), filename);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Download the selected user's trifecta results.
  const downloadTrifecta = async () => {
    try {
      // Run function to get trifecta results for this UID,
      // grab final score data for all occuptions,
      // return that data to a variable, and save it
      // to the computer.
      const calcOutputs = await getTrifectaCalcOutputs(userID);
      const filename = 'TRIFECTA_' + userID;

      // Save as column text file.
      const headerStrings = [userFirstName, userLastName, userID]
      const combinedData = headerStrings.concat(calcOutputs);
      generateAndDownloadFile(combinedData.join('\n'), filename);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Function to generate and save the file when a user takes a specific
  // action, like clicking a button.
  const generateAndDownloadFile = (content, filename) => {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, filename);
  };  

  // Download all AP test results.
  const apDownload = async () => {
    try {
      getAPScores()
        .then(data => {
          const filename = 'APScores.txt';

          // Turn array of objects into an array of arrays.
          // const arrayData = data.map(obj => Object.values(obj));

          const arrayData = data.map(obj => [
            obj.id,
            obj.arScore,
            obj.vaScore,
            obj.saScore,
            obj.cmScore,
            obj.cpScore,
            obj.fpScore
          ]);

          // Replace commas with tabs.
          const tabSeparatedData = arrayData.map(
            row => row.join('\t')
          ).join('\n');

          // Get header names.
          const headerStrings = [
            'UID', 'arScore', 'vaScore', 'saScore',
            'cmScore', 'cpScore', 'fpScore'
          ];

          // Save as column text file.
          const combinedData = [headerStrings.join('\t') + '\n'].concat(tabSeparatedData);

          // Save as column text file.
          generateAndDownloadFile(combinedData, filename);
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  return (
    <div>
      <button onClick={apDownload}>
        Download All AP Results
      </button>
      {/* <button onClick={handleClick}>Download Text File</button> */}
      <table className='table'>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, users, setUserID, openPopup }} />
      </table>

      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <div className="dataPopup__header">
          {userFirstName}'s Results
        </div>
        <div className="dataPopup__form">
          <div className="dataPopup__row">
            {/* <button
              className='dataPopup__button' style={{'width': '100%'}}
              onClick={recalculateResults}
            >
              Recalculate Results
            </button> */}
            {loadingProgress < 100 ? (
              <div style={{ width: '100%', border: '1px solid #ccc', borderRadius: '5px', height: '20px' }}>
                <div
                  style={{
                    width: `${loadingProgress}%`,
                    height: '100%',
                    backgroundColor: '#4caf50',
                    borderRadius: '5px',
                  }}
                />
              </div>
            ) : (
              <button
                className='dataPopup__button' style={{'width': '100%'}}
                onClick={recalculateResults}
              >
                Recalculate Results
              </button>
            )}
          </div>
          <div className="dataPopup__row">
            <div className="dataPopup__col">
              <button
                className='dataPopup__button'
                onClick={downloadIP}
              >
                Download IP Results
              </button>
              <button
                className='dataPopup__button'
                onClick={downloadWIP}
              >
                Download VP Results
              </button>
            </div>
            <div className="dataPopup__col">
              <button
                className='dataPopup__button'
                onClick={downloadAP}
              >
                Download AP Results
              </button>
              <button
                className='dataPopup__button'
                onClick={downloadTrifecta}
              >
                Download Trifecta Results
              </button>
            </div>
          </div>
        </div>
      </Popup>

    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
    user: state.firestore.ordered.users
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
    pullAllUsers: () => dispatch(pullAllUsers()),
    getAPCalcOutputs: (uid) => dispatch(getAPCalcOutputs(uid)),
    getIPCalcOutputs: (uid) => dispatch(getIPCalcOutputs(uid)),
    getWIPCalcOutputs: (uid) => dispatch(getWIPCalcOutputs(uid)),
    getTrifectaCalcOutputs: (uid) => dispatch(getTrifectaCalcOutputs(uid)),
    getTestResults: (uid, collectionName) => dispatch(getTestResults(uid, collectionName)),
    apResultSubmit: (result) => dispatch(apResultSubmit(result)),
    apCalculatedSubmit: (result) => dispatch(apCalculatedSubmit(result)),
    ipResultSubmit: (result) => dispatch(ipResultSubmit(result)),
    ipCalculatedSubmit: (result) => dispatch(ipCalculatedSubmit(result)),
    wipResultSubmit: (result) => dispatch(wipResultSubmit(result)),
    wipCalculatedSubmit: (result) => dispatch(wipCalculatedSubmit(result)),
    trifectaResultSubmit: (result) => dispatch(trifectaResultSubmit(result)),
    overlapCalculatedSubmit: (result) => dispatch(overlapCalculatedSubmit(result)),
    getAPScores: (result) => dispatch(getAPScores(result)),
    getAPCorrelationCoefficients: (result) => dispatch(getAPCorrelationCoefficients(result)),
    getAPAverages: (result) => dispatch(getAPAverages(result))
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);