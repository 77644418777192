import React, { useState } from 'react';
import Table from './adminComponents/Table';
import DataTable from './adminData/DataTable';
import CodeTable from './adminCodes/CodeTable';

const Admin_v2 = ({ auth }) => {
  const [ users, setUsers ] = useState(true);
  const [ data, setData ] = useState(false);
  const [ codes, setCodes ] = useState(false);

  const usersClick = (e) => {
    setUsers(true);
    setData(false);
    setCodes(false);
  }

  const codesClick = (e) => {
    setUsers(false);
    setData(false);
    setCodes(true);
  }

  // Go to tab with user data.
  const userDataClick = (e) => {
    setData(true);
    setUsers(false);
    setCodes(false);
  }
  
  return (
    <div className='admin'>
      <div className="admin__topRow">
        <button
          onClick={usersClick}
          className={`admin__button ${users ? 'admin__button-on' : 'admin__button-off'}`}
        >
          Users
        </button>
        <button
          onClick={userDataClick}
          className={`admin__button ${data ? 'admin__button-on' : 'admin__button-off'}`}
        >
          User Data
        </button>
        <button
          onClick={codesClick}
          className={`admin__button ${codes ? 'admin__button-on' : 'admin__button-off'}`}
        >
          Codes
        </button>
      </div>
      { users === true &&
        <Table auth={auth} />
      }
      { data === true &&
        <DataTable auth={auth} />
      }
      { codes === true &&
        <CodeTable />
      }
    </div>
  )
}

export default Admin_v2