export const WIPDisplayStates = {
  INSTRUCTIONS: "INSTRUCTIONS",
  VIDEO: "VIDEO",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "WIPCOMPLETE",
  DEMOGRAPHIC_INFORMATION: "DEMOGRAPHIC_INFORMATION"
};

export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''},
  {id: 19, values: ''},
  {id: 20, values: ''},
  {id: 21, values: ''},
  {id: 22, values: ''},
  {id: 23, values: ''},
  {id: 24, values: ''},
  {id: 25, values: ''},
  {id: 26, values: ''},
  {id: 27, values: ''},
  {id: 28, values: ''},
  {id: 29, values: ''},
  {id: 30, values: ''},
  {id: 31, values: ''},
  {id: 32, values: ''},
  {id: 33, values: ''},
  {id: 34, values: ''},
  {id: 35, values: ''},
  {id: 36, values: ''},
  {id: 37, values: ''},
  {id: 38, values: ''},
  {id: 39, values: ''},
  {id: 40, values: ''},
  {id: 41, values: ''},
  {id: 42, values: ''},
  {id: 43, values: ''},
  {id: 44, values: ''},
  {id: 45, values: ''},
  {id: 46, values: ''},
  {id: 47, values: ''},
  {id: 48, values: ''},
  {id: 49, values: ''},
  {id: 50, values: ''},
  {id: 51, values: ''},
  {id: 52, values: ''},
  {id: 53, values: ''},
  {id: 54, values: ''},
  {id: 55, values: ''},
  {id: 56, values: ''},
  {id: 57, values: ''},
  {id: 58, values: ''},
  {id: 59, values: ''},
];

// List of all the questions and their answer choices.
export const questions = [
  'Building kitchen cabinets.',
  'Laying brick or tile.',
  'Developing a new medicine.',
  'Studying ways to reduce water pollution.',
  'Writing books or plays.',
  'Playing a musical instrument.',
  'Teaching an individual an exercise routine.',
  'Helping people with personal or emotional problems.',
  'Buying and selling stocks and bonds.',
  'Managing a retail store.',
  'Developing a spreadsheet using computer software.',
  'Proofreading records or forms.',
  'Repairing household appliances.',
  'Raising fish in a fish hatchery.',
  'Conducting chemical experiments.',
  'Studying the movement of planets.',
  'Composing or arranging music.',
  'Drawing pictures.',
  'Giving career guidance to people.',
  'Performing rehabilitation therapy.',
  'Operating a beauty salon or barber shop.',
  'Managing a department within a large company.',
  'Installing software across computers on a large network.',
  'Operating a calculator.',
  'Assembling electronic parts.',
  'Driving a truck to deliver packages to offices and homes.',
  'Examining blood samples using a microscope.',
  'Investigating the cause of a fire.',
  'Creating special effects for movies.',
  'Painting sets for plays.',
  'Doing volunteer work at a non-profit organization.',
  'Teaching children how to play sports.',
  'Starting your own business.',
  'Negotiating business contracts.',
  'Keeping shipping and receiving records.',
  'Calculating the wages of employees.',
  'Testing the quality of parts before shipment.',
  'Repairing and installing locks.',
  'Developing a way to better predict the weather.',
  'Working in a biology lab.',
  'Writing scripts for movies or television shows.',
  'Performing jazz or tap dance.',
  'Teaching sign language to people who are deaf or hard of hearing.',
  'Helping conduct a group therapy session.',
  'Representing a client in a lawsuit.',
  'Marketing a new line of clothing.',
  'Inventorying supplies using a hand-held computer.',
  'Recording rent payments.',
  'Setting up and operating machines to make products.',
  'Putting out forest fires.',
  'Inventing a replacement for sugar.',
  'Doing laboratory tests to identify diseases.',
  'Singing in a band.',
  'Editing movies.',
  'Taking care of children at a day-care center.',
  'Teaching a high school class.',
  'Selling merchandise at a department store.',
  'Managing a clothing store.',
  'Keeping inventory records.',
  'Stamping, sorting, and distributing mail for an organization.'
];

// Answer options.
export const answerOptions = [
  'Strongly Dislike',
  'Dislike',
  'Unsure',
  'Like',
  'Strongly Like'
]