import { useState } from 'react';
import './GridTableHead.css';

const brightPic = `/images/resultsImages/occupationGrid/outlook_sun.png`;

const GridTableHead = ({ columns, handleSorting, view }) => {

  const [ sortField, setSortField ] = useState('');
  const [ order, setOrder ] = useState('asc');

  const handleSortingChange = (accessor) => {
    const sortOrder = 
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  }

  return (
    <>
      <thead className='gridTableHead__thead'>
        <tr className='gridTableHead__tr'>
        {columns.map(({ label, accessor, sortable }) => {
            return (
              (label === 'S') ? (
                <th
                  key={accessor}
                  onClick={sortable ? () => handleSortingChange(accessor) : null}
                  className=
                  {`gridTableHead__th
                    ${(view === 'mobile' && accessor === 'brightOutlook') && 'gridTableHead__th-mobileOutlook'}
                  `}
                >
                  <img src={brightPic} alt="bright-pic" className="gridTableBody__pic" />
                </th>
              ) : (
                <th
                  key={accessor}
                  onClick={sortable ? () => handleSortingChange(accessor) : null}
                  className=
                  {`gridTableHead__th
                    ${(accessor === 'jobName') && 'gridTableHead__th-jobName'}
                    ${(accessor === 'careerName') && 'gridTableHead__th-careerName'}
                    ${((view === 'mobile' || view === 'tablet') && accessor === 'jobZone') && 'gridTableHead__th-mobileJobZone '}
                    ${(view === 'mobile' && accessor === 'fitScore') && 'gridTableHead__th-mobileFit '}
                    ${(view === 'mobile' && accessor === 'trifectaMatchScore') && 'gridTableHead__th-mobileTrifectaScore '}
                    ${(view === 'mobile' && accessor === 'jobName') && 'gridTableHead__th-mobileJobName '}
                    ${(view === 'mobile' && accessor === 'careerName') && 'gridTableHead__th-mobileCareerName '}
                  `}
                >
                  {label}
                </th>
              )
            );
          })}
        </tr>
        <tr>
          <td colSpan={columns.length} className='gridTableHead__headerSeparation' />
        </tr>
      </thead>
    </>
  );
 };
 
 export default GridTableHead;