import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { LeftArrow, RightArrow } from "./scroll_items/Arrows";
import usePreventBodyScroll from "./scroll_items/UsePreventBodyScroll";

const TelosLogo = `/images/Telos_Logo_Light.png`;
const infoPic = `/images/resultsImages/navBar/info_nav.png`;
const interestsPic = `/images/resultsImages/navBar/interests_nav.png`;
const valuesPic = `/images/resultsImages/navBar/importance_nav.png`;
const abilitiesPic = `/images/resultsImages/navBar/abilities_nav.png`;
const trifectaPic = `/images/resultsImages/navBar/trifectaGraph_nav.png`;
const zonesPic = `/images/resultsImages/navBar/topJobs_nav.png`;
const gridPic = `/images/resultsImages/navBar/grid_nav.png`;

const infoPic_hover = `/images/resultsImages/navBar/info_nav_hov.png`;
const interestsPic_hover = `/images/resultsImages/navBar/interests_nav_hov.png`;
const valuesPic_hover = `/images/resultsImages/navBar/importance_nav_hov.png`;
const abilitiesPic_hover = `/images/resultsImages/navBar/abilities_nav_hov.png`;
const trifectaPic_hover = `/images/resultsImages/navBar/trifectaGraph_nav_hov.png`;
const zonesPic_hover = `/images/resultsImages/navBar/topJobs_nav_hov.png`;
const gridPic_hover = `/images/resultsImages/navBar/grid_nav_hov.png`;

// Scrollbar stuff.
function onWheel(apiObj, ev) {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

// Main function.
const SignedInLinks2Dark = ({ user, signOut, resultsIndex, setResultsIndex }) => {
    const [ expanded, setExpanded ] = useState(false);
		const [ firstName, setFirstName ] = useState('');
		const [ openDD, setOpenDD ] = useState(false);		// To open or close the dropdown.
		const [ dropdown, setDropdown ] = useState(false);
		const [ hoverID, setHoverID ] = useState(-1);
		const [ admin, setAdmin ] = useState(false);

		// Check if user is an admin.
		useEffect(() => {
			try {
				if (user) {
					if (user[0]) {
						if (user[0].admin) {
							if (user[0].admin === true) {
								setAdmin(true);
							} else {
								setAdmin(false);
							}
						}
					}
				}
			} catch (error) {
				console.log('Error: ', error);
			}
		}, [user])
		
		// For horizontal scrolling menu.
  	const { disableScroll, enableScroll } = usePreventBodyScroll();

		const timerRef = useRef(null);

		const thisLocation = useLocation();
		let currentPath = thisLocation.pathname;
		const weight = {
			fontWeight: '800'
		}

		// let firstName = '';
		useEffect (() => {
			if(user) {
				setFirstName(user[0].firstName);

				// Open dropdown.
				setOpenDD(true);
			}

			// Clean up async issues with setTimeout.
			return () => clearTimeout(timerRef.current);
		}, [user]);

    // Sign out and close hamburger menu (if its open).
    const onClick = (e) => {
        // Close hamburger menu.
        timerRef.current = setTimeout(() => {setExpanded(false)}, 0);

        // Sign out.
        signOut();

    }

    // Close hamburger menu (if its open).
    const onClick2 = (e) => {
			timerRef.current = setTimeout(() => {setExpanded(false)}, 0);
    }

		// Open results dropdown menu.
		const onClick3 = (e) => {
			setOpenDD(!openDD);
		}

		// Change results index.
		const resultsNavigate = (e) => {
			setResultsIndex(+e.target.id);
		}

		// Flip the dropdown state.
		const dropdownClick = (e) => {
			setDropdown(!dropdown);
		}

		// Mouse hover.
		const mouseHover = (e) => {
			setHoverID(Number(e.target.id));
		}

		// Mouse not hover.
		const mouseLeave = (e) => {
			setHoverID(-1);
		}

    return (
			<Navbar className='navbar__wrapper' style={{ paddingTop: '25px' }} collapseOnSelect expand="lg" bg="transparent" expanded={expanded}>
				<Container fluid className='navbar navbar__dark'>
					<div className='navbar__dark-topRow'>
						<NavLink to="/home">
							<img
								src={TelosLogo}
								alt=''
								width='50px'
								height="auto"
								className="navbar__logo"
							/>
						</NavLink>
						<Navbar.Toggle
								aria-controls="responsive-navbar-nav"
								onClick={() => setExpanded(expanded ? false : true)}
						/>
					</div>
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto">
							<div className='navbar__left'>
								<NavLink to='/assessments' className="navbar__link navbar__link-dark" onClick={onClick2}>
									Assessments
								</NavLink>
								<NavLink
									to='/results'
									className="navbar__link navbar__link-dark"
									onClick={() => { onClick2(); onClick3() }}
								>
									Your Results
								</NavLink>

								<NavLink to='/science' onClick={onClick2} className="navbar__link navbar__link-dark">
									{
										(
											currentPath === '/science'
										) ? (
											<div style={weight}>
												The Science
											</div>
										) : (
											<div>
												The Science
											</div>
										)
									}
								</NavLink>
								<NavLink to='/aboutus' onClick={onClick2} className="navbar__link navbar__link-dark">
									{
										(
											currentPath === '/aboutus'
										) ? (
											<div style={weight}>
												About Us
											</div>
										) : (
											<div>
												About Us
											</div>
										)
									}
								</NavLink>
								<NavLink to='/payment' onClick={onClick2} className="navbar__link navbar__link-dark">
									{
										(
											currentPath === '/payment'
										) ? (
											<div style={weight}>
												Purchase
											</div>
										) : (
											<div>
												Purchase
											</div>
										)
									}
								</NavLink>
							</div>
							<div className='navbar__right'>
								{/* <NavLink to='/signin' onClick={onClick} className="navbar__link navbar__link-dark">
									Sign Out
								</NavLink> */}
								{/* <div className='navbar__name'>
									<NavLink to='/profile'>
										{firstName}
									</NavLink>
								</div> */}

								<div className='navbar__user'>
									<div className='navbar__name' onClick={dropdownClick}>
											{firstName} {dropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}
									</div>
									{
										dropdown &&
										<div className='navbar__userDropdown'>
											<NavLink className='navbar__userElement navbar__userElement-dark' to='profile' onClick={dropdownClick}>
												Account
											</NavLink>
											{admin &&
												<NavLink className='navbar__userElement navbar__userElement-dark' to='admin' onClick={dropdownClick}>
													Admin
												</NavLink>
											}
											<NavLink className='navbar__userElement navbar__userElement-dark' to='/signin' onClick={() => { onClick(); dropdownClick() }}>
												Sign Out
											</NavLink>
										</div>
									}
								</div>

							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
				<div style={{ marginTop: dropdown && '35px' }} className={"navbar__dropdown " + (openDD === true ? 'navbar__dropdown-open' : 'navbar__dropdown-close')}>
					<div className="navbar__scroll">
						<div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
							<ScrollMenu
								LeftArrow={LeftArrow}
								RightArrow={RightArrow}
								onWheel={onWheel}
								className='navbar__scrollMenu'
							>
								<button id='0' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 0 ? true : false}>
									<img id='0' src={(hoverID === 0 || resultsIndex === 0) ? infoPic_hover : infoPic} alt="info-pic" className="navbar__image" />
									<div id='0' className={(hoverID === 0 || resultsIndex === 0) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
									{/* <div id='0' className="navbar__dropdownText navbar__dropdownText-white"> */}
									
										Info
									</div>
								</button>
								<button id='1' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 1 ? true : false}>
									<img id='1' src={(hoverID === 1 || resultsIndex === 1) ? interestsPic_hover : interestsPic} alt="interests-pic" className="navbar__image" />
									<div id='1' className={(hoverID === 1 || resultsIndex === 1) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
									{/* <div id='0' className="navbar__dropdownText navbar__dropdownText-white"> */}
									
										Interests
									</div>
								</button>
								<button id='2' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 2 ? true : false}>
									<img id='2' src={(hoverID === 2 || resultsIndex === 2) ? valuesPic_hover : valuesPic} alt="values-pic" className="navbar__image" />
									<div id='2' className={(hoverID === 2 || resultsIndex === 2) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
										Values
									</div>
								</button>
								<button id='3' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 3 ? true : false}>
									<img id='3' src={(hoverID === 3 || resultsIndex === 3) ? abilitiesPic_hover : abilitiesPic} alt="abilities-pic" className="navbar__image" />
									<div id='3' className={(hoverID === 3 || resultsIndex === 3) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
										Abilities
									</div>
								</button>
								<button id='4' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 4 ? true : false}>
									<img id='4' src={(hoverID === 4 || resultsIndex === 4) ? trifectaPic_hover : trifectaPic} alt="trifecta-pic" className="navbar__image" />
									<div id='4' className={(hoverID === 4 || resultsIndex === 4) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
										Trifecta Graph
									</div>
								</button>
                <button id='5' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 5 ? true : false}>
                  <img id='5' src={(hoverID === 5 || resultsIndex === 5) ? zonesPic_hover : zonesPic} alt="job-zones-pic" className="navbar__image" />
                  <div id='5' className={(hoverID === 5 || resultsIndex === 5) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
                    Job Zones
                  </div>
                </button>
								<button id='6' className="navbar__dropdownItem" onClick={resultsNavigate} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} disabled={resultsIndex === 6 ? true : false}>
									<img id='6' src={(hoverID === 6 || resultsIndex === 6) ? gridPic_hover : gridPic} alt="occupation-grid-pic" className="navbar__image" />
									<div id='6' className={(hoverID === 6 || resultsIndex === 6) ? "navbar__dropdownText navbar__dropdownText-white" : "navbar__dropdownText"}>
										Occupation Grid
									</div>
								</button>
							</ScrollMenu>
						</div>
					</div>
					{/* <button id='0' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='0' src={interestsPic} alt="interests-pic" className="navbar__image" />
						<div id='0' className="navbar__dropdownText">
							Interests
						</div>
					</button>
					<button id='1' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='1' src={importancePic} alt="importance-pic" className="navbar__image" />
						<div id='1' className="navbar__dropdownText">
							Importance
						</div>
					</button>
					<button id='2' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='2' src={abilitiesPic} alt="abilities-pic" className="navbar__image" />
						<div id='2' className="navbar__dropdownText">
							Abilities
						</div>
					</button>
					<button id='3' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='3' src={trifectaPic} alt="trifecta-pic" className="navbar__image" />
						<div id='3' className="navbar__dropdownText">
							Trifecta Graph
						</div>
					</button>
					<button id='4' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='4' src={occupationPic} alt="occupation-pic" className="navbar__image" />
						<div id='4' className="navbar__dropdownText">
							Occupation Types
						</div>
					</button>
					<button id='5' className="navbar__dropdownItem" onClick={resultsNavigate}>
						<img id='5' src={topjobsPic} alt="topjobs-pic" className="navbar__image" />
						<div id='5' className="navbar__dropdownText">
							Top Jobs
						</div>
					</button> */}
				</div>
			</Navbar>
    )
}

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut())
	};
};

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		user: state.firestore.ordered.users
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect((props) => {
		return [
			{ collection: 'users', doc: props.auth.uid }
		]
	})
)(SignedInLinks2Dark)