import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Row, Col } from 'react-bootstrap';
import HomeBottom from './HomeBottom';


const HomeSignedOut = ({ appAuth, user }) => {
  const [ randomNumber, setRandomNumber ] = useState(undefined);

  // Select a random number between 0 and 5, inclusive.
  const min = 0;
  const max = 9;    // Number of home page images, minus one.
  useEffect(() => {
    setRandomNumber(Math.floor(Math.random()*(max - min + 1)) + min);
  }, []);

  // Select image based on random number.
  const imagePath = `/images/homeImages/home-0${randomNumber + 1}.jpg`;

  return (
    <div className="homeWrapper">
      <div className='home'>
        <div className='home__text'>
          <p className='home__title'>
            The path to personal and professional fulfillment has already been mapped. We provide the compass.
          </p>
          <p className="home__subtitle">
            The Telos assessments measure your values and abilities and orient you toward meaningful career options. Interested?
          </p>

          <NavLink to='signin'>
            <button className="home__button">
              LET'S GET STARTED
            </button>
          </NavLink>

        </div>
        <div className='home__image-container'>
          <img className='home__image' src={process.env.PUBLIC_URL + imagePath} alt="" />
        </div>
      </div>
      <HomeBottom />
    </div>
  )
}

export default HomeSignedOut;