import React, { useState, useEffect } from 'react';
import SignedInAssessments2 from './SignedInAssessments2'
import PurchasedAssessments from './PurchasedAssessments';
import { connect } from "react-redux";
import firebase from '../../../config/fbConfig';
import LoadingPage from '../loadingPage/LoadingPage';
import { useHistory } from 'react-router-dom';
import { AssessmentDisplayStates } from './Config';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const AssessmentChoose = ({ auth, user, order }) => {
  // const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);
  const [display, setDisplay] = useState(AssessmentDisplayStates.LOADING);

  const history = useHistory();

  useEffect(() => {
    try {
      // Check if user is signed in.
      if (auth.isLoaded) {
        if (auth.isEmpty) {
          // User isn't signed in, so go to signup page.
          history.push('/signup');
        } else {
          if (user) {
            // Check if single assessment was purchased.
            if (order && order.length > 0) {
              if (order[0].order) {
                if (order[0].order.orderItems === 'Base Package' || order[0].order.orderItems === 'basePackage') { // Single assessment was purchased.
                  setPurchasedOrder(true);
                  setDisplay(AssessmentDisplayStates.PURCHASED);
                } else {
                  setPurchasedOrder(false);
                }
              }
            } else if (order !== undefined) {
              // User is signed in and exists in the database,
              // but hasn't paid in the pre-Stripe system.
              setPurchasedOrder(false);
            }

            // Check if the user used Stripe to purchase a 'Deluxe Package'.
            if (auth.uid !== undefined) {
              firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
                // Set stripePurchase to false if the user has no payments.
                if (snapshot.empty) {
                  setStripePurchase(false);
                } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
                  snapshot.forEach(paymentDoc => {
                    let status = paymentDoc.data().status;
                    const items = paymentDoc.data().items;
                    if (items && status === 'succeeded') {
                      items.forEach(item => {
                        if (item.description === 'Deluxe Package') {
                          // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                          // product in Stripe.
                          setStripePurchase(true);
                          setDisplay(AssessmentDisplayStates.PURCHASED);
                        } else {
                          setStripePurchase(false);
                        }
                      })
                    } else {
                      setStripePurchase(false);
                    }
                  })
                }
              });
            }

            // If no assessment was purchased, go to signed in assessments page.
            if (purchasedOrder === false && stripePurchase === false) {
              setDisplay(AssessmentDisplayStates.NOT_PURCHASED);
            }
          } else {
            setDisplay(AssessmentDisplayStates.LOADING);
          }
        }
      } else {
        setDisplay(AssessmentDisplayStates.LOADING);
      }

    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, auth, order, history, purchasedOrder, stripePurchase]);

  return (
    <div>
      {user &&
        <div>
          {display === AssessmentDisplayStates.LOADING && (
            <LoadingPage />
          )}
          {display === AssessmentDisplayStates.PURCHASED && (
            <PurchasedAssessments />
          )}
          {display === AssessmentDisplayStates.NOT_PURCHASED && (
            <SignedInAssessments2 />
          )}
        </div>
      }
    </div>
  )

  // return (
  //   <div>
  //     {(isMounted) ? (
  //       <div>
  //         {
  //           purchasedOrder === true ? (
  //               <PurchasedAssessments />
  //             ) : (
  //               <SignedInAssessments2 />
  //           )
  //         }
  //       </div>
  //     ):(
  //       <div>
  //         <LoadingPage />
  //       </div>
  //     )}
  //   </div>
  // )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    order: state.firestore.ordered.orders,
    user: state.firestore.ordered.users,
    customer: state.firestore.ordered.customers
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'orders', doc: props.auth.uid },
      { collection: 'users', doc: props.auth.uid },
      { collection: 'customers', doc: props.auth.uid }
    ]
  })
)(AssessmentChoose)

// const mapStateToProps = (state) => {
// 	return {
// 		auth: state.firebase.auth,
// 		authError: state.auth.authError
// 	};
// };

// export default connect(mapStateToProps)(AssessmentChoose)
