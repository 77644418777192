import React from 'react';
import { FaLock } from 'react-icons/fa';

const TrifectaNoData = () => {
  return (
    <div className='telosChart2'>
      <div className="telosChart2__h1">
        The Trifecta Graph - Overlapping Results
      </div>
      <div className="telosChart2__h2">
        Please click on the areas below to see your results. Each job is also clickable and
        will take you to the O*Net description for that job.
      </div>
      <svg
        className='telosChart2__svg'
        viewBox="0 0 300 250"
        preserveAspectRatio="xMidYMid meet"
      >

        {/* WIP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 483.00,318.60
              C 483.00,280.10 398.30,248.90 293.90,248.90
                189.50,248.90 104.80,280.10 104.80,318.60
                104.80,357.10 189.50,388.30 293.90,388.30
                398.30,388.30 483.00,357.10 483.00,318.60
              " 
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-trifecta'
          />
          <text
            x="50%"
            y="61%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className='telosChart2__ellipseText'
          >
            Values
          </text>
        </g>

        {/* IP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 468.50,343.80
              C 501.70,324.70 486.20,235.80 433.90,145.30
                381.60,54.80 312.20,-3.10 278.90,16.10
                245.70,35.20 261.20,124.10 313.50,214.60
                365.90,305.10 435.30,362.90 468.50,343.80
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-trifecta'
          />
          <text
            x="50%"
            y="-19%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(60 50 50)"
            className='telosChart2__ellipseText'
          >
            Interests
          </text>
        </g>

        {/* AP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 119.40,343.80
              C 86.20,324.70 101.70,235.80 154.00,145.30
                206.30,54.80 275.70,-3.10 308.90,16.10
                342.10,35.20 326.60,124.10 274.30,214.60
                221.90,305.10 152.60,362.90 119.40,343.80
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-trifecta'
          />
          <text
            x="16.5%"
            y="49%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(-60 50 50)"
            className='telosChart2__ellipseText'
          >
            Abilities
          </text>
        </g>

        {/* APIP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            fill="transparent" stroke="#6999A9" strokeWidth="1"
            d="
              M 293.90,177.10
              C 330.00,100.70 337.50,32.50 308.90,16.00
                306.80,14.80 304.60,13.90 302.20,13.30
                299.30,12.70 296.50,12.40 293.90,12.40
                291.20,12.40 288.50,12.70 285.60,13.30
                283.20,13.90 281.00,14.80 278.90,16.00
                250.30,32.60 257.90,100.70 293.90,177.10 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipseOverlapPath'
          />

          <text
            y="13%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className='telosChart2__ellipseOverlapText'
          >
            <tspan x='50%' dy='1.2em' style={{ fill: 'black' }}>Interests</tspan>
            <tspan x='50%' dy='1.2em' style={{ fill: 'black' }}>&</tspan>
            <tspan x='50%' dy='1.2em' style={{ fill: 'black' }}>Abilities</tspan>
          </text>

        </g>

        {/* IPWIP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            fill="transparent" stroke="#6788A8" strokeWidth="1"
            d="
              M 336.50,250.70
              C 384.70,319.80 439.90,360.20 468.40,343.80
                471.90,341.80 474.90,338.90 477.40,335.40
                477.60,335.10 477.80,334.80 478.00,334.50
                478.10,334.40 478.10,334.30 478.20,334.30
                481.30,329.30 483.00,324.00 483.00,318.70
                483.00,285.50 420.40,257.90 336.50,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipseOverlapPath'
          />
          <text
            y="54.25%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className='telosChart2__ellipseOverlapText'
          >
            <tspan x='72.5%' dy='1.2em' style={{ fill: 'black' }}>Values</tspan>
            <tspan x='72.5%' dy='1.2em' style={{ fill: 'black' }}>&</tspan>
            <tspan x='72.5%' dy='1.2em' style={{ fill: 'black' }}>Interests</tspan>
          </text>

        </g>

        {/* APWIP */}
        <g
          className='telosChart2__ellipseButton'
        >
          <path
            fill="transparent" stroke="#7C8FA9" strokeWidth="1"
            d="
              M 251.30,250.70
              C 167.30,257.80 104.70,285.50 104.70,318.60
                104.70,321.40 105.20,324.20 106.00,326.90
                108.90,334.50 113.30,340.30 119.30,343.80
                147.90,360.20 203.10,319.80 251.30,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipseOverlapPath'
          />
          <text
            y="54.25%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className='telosChart2__ellipseOverlapText'
          >
            <tspan x='27.5%' dy='1.2em' style={{ fill: 'black' }}>Abilities</tspan>
            <tspan x='27.5%' dy='1.2em' style={{ fill: 'black' }}>&</tspan>
            <tspan x='27.5%' dy='1.2em' style={{ fill: 'black' }}>Values</tspan>
          </text>

        </g>

        {/* Trifecta */}
        <g
          className='telosChart2__ellipseButton'
        >
          {/* Define the linear gradient for the trifecta on hover. */}
          <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="20%" style={{ stopColor: '#910DB4' }} />
            <stop offset="50%" style={{ stopColor: '#006DB2' }} />
            <stop offset="80%" style={{ stopColor: '#008375' }} />
          </linearGradient>
          <path
            fill="transparent" stroke="white" strokeWidth="1"
            d="
              M 251.30,250.70
              C 265.00,249.50 279.20,248.90 293.80,248.90
                308.50,248.90 322.80,249.50 336.50,250.70
                328.60,239.40 320.90,227.30 313.50,214.60
                306.20,202.00 299.70,189.50 293.90,177.20
                288.10,189.50 281.50,202.10 274.30,214.60
                266.90,227.30 259.20,239.40 251.30,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipseOverlapPath'
          />
          <text
            y="39.5%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className='telosChart2__ellipseOverlapText'
          >
            <tspan x='50%' dy='1.2em' style={{ fill: 'black' }}>THE</tspan>
            <tspan x='50%' dy='1.2em' style={{ fill: 'black' }}>TRIFECTA</tspan>
          </text>

        </g>

        <foreignObject x="3%" y="2%" width="94%" height="83.5%">
          {/* Adjust width and height as needed */}
          <div className='chart__rectangle chart__rectangle-trifecta'>
            <div className='chart__icon chart__icon-trifecta'>
              <FaLock />
            </div>
            <div className='chart__body chart__body-trifecta'>
              Exciting things coming soon!
            </div>
            <div className='chart__subbody chart__subbody-trifecta'>
              Finish all profilers to see results here.
            </div>
          </div>
        </foreignObject>
      </svg>
    </div>
  )
}

export default TrifectaNoData