import React from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CP.css';

const InstructionsQ2 = ({ setDisplay }) => {
  return (
    <div className='cp-main-box cp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 5 - Name Comparison
      </div>
      <div className='cp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Read Practice Question 2:
      </div>
      <div className="cp-question">
        <div className="cp-question__number">
          2.
        </div>
        <div className="cp-question__content">
          Debbie Bailey &ndash; Debbie Baily
        </div>

          <div className="cp-question__answers">
            <label className="cp-question__answer-option">
              <div className="cp-question__answer-text">
                S
              </div>
              <input
                type="checkbox"
                className='question__radio-button question__radio-button-cp'
                checked={false}
                readOnly={true}
              />
            </label>
            <label className="cp-question__answer-option">
              <div className="cp-question__answer-text">
                D
              </div>
              <input
                type="checkbox"
                className='question__radio-button question__radio-button-cp'
                checked={true}
                readOnly={true}
              />
            </label>
          </div>
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        The two names are <i>different</i>.
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        So, for Practice Question 2, the circle under <b><i>D</i></b>, for <i>different</i>, has been filled in.
      </div>
      <div className="cp-row-2 cp-row-2-spaced cp-row-2-practice cp-row-2-practice-margin">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}
          // style={{ marginBottom: '100px' }}
        >
          Back
        </button>
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q345);
          }}
          // style={{ marginBottom: '100px' }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ2