import React, { useState, useEffect } from 'react';
import { WIPDisplayStates, practiceInitialState } from "./Configs";
import LoadingPage from '../../../../pages/loadingPage/LoadingPage';
import CPInstructionsQ1 from './CP_Instructions/InstructionsQ1';
import CPInstructionsQ2 from './CP_Instructions/InstructionsQ2';
import CPInstructionsQ345 from './CP_Instructions/InstructionsQ345';
import CPInstructionsQ678 from './CP_Instructions/InstructionsQ678';
import CPInstructionsQ9 from './CP_Instructions/InstructionsQ9';
import CPInstructionsFinal from './CP_Instructions/InstructionsFinal';
import CPAssessment from './CPAssessment2';
import CPComplete from './CPCompleted';
import DemoInfo from '../../../demoInfo/DemoInfo';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import firebase from '../../../../../config/fbConfig';
import { Redirect } from 'react-router-dom';

const CPFrontPage = ({ cp, user, auth }) => {
  const [display, setDisplay] = useState(WIPDisplayStates.LOADING);
  // const [display, setDisplay] = useState(WIPDisplayStates.INSTRUCTIONS_Q1);
  const [ practiceAnswers, setPracticeAnswers ] = useState([...practiceInitialState]);

  const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);

  // Check if user has paid.
  useEffect(() => {
    try {
      async function checkPurchases(docId) {
        const order = await firebase.firestore().collection('orders').doc(docId).get();
        
        let purchased = false;
        let orderData = await order.data();
        if (orderData) {
          purchased = true;
        } else {
          purchased = false;
        }
        setPurchasedOrder(purchased);
        
        // Component is mounted after this check is complete.
        setIsMounted(true);
        
        return purchased;
      }
      const uid = auth.uid;
      checkPurchases(uid);
    } catch (error) {
      console.log('Error: ', error);
      setIsMounted(true);
    }

  }, [auth.uid]);

  // Check if user purchased through Stripe.
  useEffect(() => {
    try {
      // Check if the user used Stripe to purchase a 'Deluxe Package'.
      if (auth.uid !== undefined) {
        firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
          // Set stripePurchase to false if the user has no payments.
          if (snapshot.empty) {
            setStripePurchase(false);
          } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
            snapshot.forEach(paymentDoc => {
              let status = paymentDoc.data().status;
              const items = paymentDoc.data().items;
              if (items && status === 'succeeded') {
                items.forEach(item => {
                  if (item.description === 'Deluxe Package') {
                    // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                    // product in Stripe.
                    setStripePurchase(true);
                  } else {
                    setStripePurchase(false);
                  }
                })
              }
            })
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth.uid]);

  useEffect(() => {
    try {
      // Go directly to the assessment page if the user has already started the assessment.
      if (cp && cp.length > 0) {
        setDisplay(WIPDisplayStates.ASSESSMENT);
      } else {
        // Otherwise, go to the instructions page.
        setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
      }

      // Go directly to the complete page if the user has finished the assessment.
      if (user) {
        let cpStatus = user[0].cpComplete;
        if (cpStatus === true) {
          setDisplay(WIPDisplayStates.COMPLETE);
        }
      }

      // Go directly to the demographic page if the user hasn't completed it yet.
      if (user) {
        let demoComplete = user[0].demoComplete;
        if (demoComplete !== true) {
          setDisplay(WIPDisplayStates.DEMOGRAPHIC_INFORMATION);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }    
  }, [cp, user]);

  return (
    <div className='cpFrontPage'>
      { isMounted === true && purchasedOrder === false && stripePurchase === false &&  // If the loading page is meant to check whether the user has paid, the user's profile is mounted, and the user didn't pay yet, redirect to the payment page.
        <Redirect
          to={{
            pathname: `/payment`,
          }}
        />
      }
      {user &&
          <div>
            {display === WIPDisplayStates.LOADING && (
              <LoadingPage checkPaid={true} />
              )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_Q1 && (
              <CPInstructionsQ1 setDisplay={setDisplay} />
              )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_Q2 && (
              <CPInstructionsQ2 setDisplay={setDisplay} />
            )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_Q345 && (
              <CPInstructionsQ345 setDisplay={setDisplay} practiceAnswers={practiceAnswers} setPracticeAnswers={setPracticeAnswers} />
              )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_Q678 && (
              <CPInstructionsQ678 setDisplay={setDisplay} practiceAnswers={practiceAnswers} setPracticeAnswers={setPracticeAnswers} />
              )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_Q9 && (
              <CPInstructionsQ9 setDisplay={setDisplay} />
            )
            }
            {display === WIPDisplayStates.INSTRUCTIONS_FINAL && (
              <CPInstructionsFinal setDisplay={setDisplay} />
              )
            }
            {display === WIPDisplayStates.ASSESSMENT && (
              <CPAssessment setDisplay={setDisplay} />
              )
            }
            {display === WIPDisplayStates.COMPLETE && (
              <CPComplete />
              )
            }
            {display === WIPDisplayStates.DEMOGRAPHIC_INFORMATION && (
              <DemoInfo setDisplay={setDisplay} user={user} />
              )
            }
          </div>
        }
      {!user && <div>
        <LoadingPage />
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    user: state.firestore.ordered.users,
    cp: state.firestore.ordered.cp
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
      { collection: 'cp', doc: props.auth.uid }
    ]
  })
)(CPFrontPage)