import React from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CM.css';

const calcPic = `/images/cmImages/NoCaluculator-OnlyPaper - instructions.png`;

const Instructions = ({ setDisplay }) => {
  return (
    <div className='cm-main-box cm-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 4 - Computation
      </div>
      <div className='cm-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        While calculators aren't allowed as they would invalidate results,
        you should get some paper and pencil handy for calculations.
      </div>
      <img src={calcPic} alt="" className='instructionsB__image' />
      <div className="ap-row-2 ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Instructions
