import React, { useState, useEffect } from 'react';
import firebase from '../../../config/fbConfig';

import { prepareDate } from '../../results/Functions';

// Function to create Javascript date to a MM/DD/yyyy string.
// Source: https://stackoverflow.com/questions/11591854/format-date-to-mm-dd-yyyy-in-javascript
function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return month + '/' + day + '/' + year;
}

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [ ipUsers, setIPUsers ] = useState();
  const [ wipUsers, setWIPUsers ] = useState();
  const [ apUsers, setAPUsers ] = useState();
  const [ allData, setAllData ] = useState([]);
  const [ filteredAllData, setFilteredAllData ] = useState([]);

  const [ resultsToggle, setResultsToggle ] = useState(false);
  const [ filteredDataToggle, setFilteredDataToggle ] = useState(false);

  useEffect(() => {
    try {
      const db = firebase.firestore();
      return db.collection('users').onSnapshot((snapshot) => {
        const userData = [];
        snapshot.forEach((doc) => userData.push({ ...doc.data(), id: doc.id }));
        setUsers(userData);

        // Set all data.
        setAllData(
          userData.map((element) => {
            let currentUID = null;
            let currentFirstName = null;
            let currentLastName = null;
            let currentSignUpDate = null;
            let currentIPDate = null;
            let currentWIPDate = null;
            let currentARDate = null;
            let currentSADate = null;
            let currentVADate = null;
            let currentCMDate = null;
            let currentCPDate = null;
            let currentFPDate = null;
            let currentResultsCalculated = null;

            // Get user's UID.
            if (element.userId) {
              currentUID = element.userId;
            } else {
              currentUID = 'N/A';
            }

            // Get user's first name.
            if (element.firstName) {
              currentFirstName = element.firstName;
            } else {
              currentFirstName = 'N/A';
            }

            // Get user's last name.
            if (element.lastName) {
              currentLastName = element.lastName;
            } else {
              currentLastName = 'N/A';
            }

            // Get user's sign-up date.
            if (element.signupDate) {
              const output = new Date(element.signupDate.seconds * 1000);
              currentSignUpDate = getFormattedDate(output);
            } else {
              currentSignUpDate = 'N/A';
            }

            // Get user's IP complete date.
            if (element.ipDate) {
              const output = new Date(element.ipDate.seconds * 1000);
              currentIPDate =  getFormattedDate(output);
            } else {
              currentIPDate = 'Incomplete';
            }

            // Get user's WIP complete date.
            if (element.wipDate) {
              const output = new Date(element.wipDate.seconds * 1000);
              currentWIPDate =  getFormattedDate(output);
            } else {
              currentWIPDate = 'Incomplete';
            }

            // Get user's AR complete date.
            if (element.arDate) {
              const output = new Date(element.arDate.seconds * 1000);
              currentARDate =  getFormattedDate(output);
            } else {
              currentARDate = 'Incomplete';
            }

            // Get user's SA complete date.
            if (element.saDate) {
              const output = new Date(element.saDate.seconds * 1000);
              currentSADate =  getFormattedDate(output);
            } else {
              currentSADate = 'Incomplete';
            }

            // Get user's VA complete date.
            if (element.vaDate) {
              const output = new Date(element.vaDate.seconds * 1000);
              currentVADate =  getFormattedDate(output);
            } else {
              currentVADate = 'Incomplete';
            }

            // Get user's CM complete date.
            if (element.cmDate) {
              const output = new Date(element.cmDate.seconds * 1000);
              currentCMDate =  getFormattedDate(output);
            } else {
              currentCMDate = 'Incomplete';
            }

            // Get user's CP complete date.
            if (element.cpDate) {
              const output = new Date(element.cpDate.seconds * 1000);
              currentCPDate =  getFormattedDate(output);
            } else {
              currentCPDate = 'Incomplete';
            }

            // Get user's FP complete date.
            if (element.fpDate) {
              const output = new Date(element.fpDate.seconds * 1000);
              currentFPDate =  getFormattedDate(output);
            } else {
              currentFPDate = 'Incomplete';
            }

            // Get user's results calculated.
            if (element.apCalculated === true && element.ipCalculated === true && element.wipCalculated === true) {
              currentResultsCalculated = 'Yes';
            } else {
              currentResultsCalculated = 'No';
            }

            return {
              UID: currentUID,
              firstName: currentFirstName,
              lastName: currentLastName,
              signupDate: currentSignUpDate,
              ipDate: currentIPDate,
              wipDate: currentWIPDate,
              arDate: currentARDate,
              saDate: currentSADate,
              vaDate: currentVADate,
              cmDate: currentCMDate,
              cpDate: currentCPDate,
              fpDate: currentFPDate,
              resultsCalculated: currentResultsCalculated
            }
          })
        );


        // Get number of users who have completed the IP assessments.
        let someIPUsers = userData.filter(e => e.ipComplete);
        let someIPCompleteUsers = null
        if (someIPUsers) {
          someIPCompleteUsers = someIPUsers.filter(e => e.ipComplete === true);
        }
        setIPUsers(someIPCompleteUsers.length);

        // Get number of users who have completed the WIP assessments.
        let someWIPUsers = userData.filter(e => e.wipComplete);
        let someWIPCompleteUsers = null
        if (someWIPUsers) {
          someWIPCompleteUsers = someWIPUsers.filter(e => e.wipComplete === true);
        }
        setWIPUsers(someWIPCompleteUsers.length);

        // Get number of users who have completed the AP assessments.
        let someAPUsers = userData.filter(e => e.fpComplete);
        let someAPCompleteUsers = null
        if (someAPUsers) {
          someAPCompleteUsers = someAPUsers.filter(e => e.fpComplete === true);
        }
        setAPUsers(someAPCompleteUsers.length);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Function to run when 'Results Viewed' is clicked.
  const onClickResultsViewed = (e) => {
    const strAscending = [...allData].sort((a, b) =>
      a.resultsCalculated > b.resultsCalculated ? 1 : -1,
    );
    
    const strDescending = [...allData].sort((a, b) =>
      a.resultsCalculated > b.resultsCalculated ? -1 : 1,
    );

    if (resultsToggle === false) {
      setAllData(strDescending);
    } else {
      setAllData(strAscending);
    }
      
    setResultsToggle(!resultsToggle);
  }

  // Click function to run when the user clicks the 'Beta Only' button.
  const betaClick = (e) => {
    // Filter allData by date. Beta started on 04/01/23.
    let betaDate = new Date(...prepareDate("04-01-2023")).getTime();
    const filtered = allData.filter(user => {
      let userDate = null;
      if (user.ipDate !== 'Incomplete') {
        const input = user.ipDate;
        const [ month, day, year ] = input.split('/');
        userDate = `${month}-${day}-${year}`;
      } else {
        userDate = '01-01-2000';
      }
      let finalUserDate = new Date(...prepareDate(userDate)).getTime();
      return finalUserDate > betaDate;
    });
    setFilteredAllData(filtered);

    setFilteredDataToggle(!filteredDataToggle);

  }

  try {
    return (
      <div className='admin'>
        <div className="admin__header">
          <h1>
            Admin Page
          </h1>
        </div>

        <div className="admin__summary">
          <p>Total number of users: {users.length}</p>
          <p>Total number of users who completed the IP assessment: {ipUsers}</p>
          <p>Total number of users who completed the WIP assessment: {wipUsers}</p>
          <p>Total number of users who completed the AP assessment: {apUsers}</p>
        </div>

        <div className="admin__header">
          <h2>
            User Stats
          </h2>
        </div>

        <div className="admin__filters">
            {
              filteredDataToggle ? (
                <button className="admin__filter" onClick={betaClick}>
                  All Data
                </button>
              ) : (
                <button className="admin__filter" onClick={betaClick}>
                  School Beta Only
                </button>
              )
            }
        </div>

        <table className='admin__table'>
          <thead>
            <tr>
              <th>No.</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>UID</th>
              <th>Sign-Up Date</th>
              <th>IP Date</th>
              <th>VP Date</th>
              {/* <th>AR Date</th>
              <th>VA Date</th>
              <th>SA Date</th>
              <th>CM Date</th>
              <th>CP Date</th> */}
              <th>FP Date</th>
              <th>
                <button onClick={onClickResultsViewed}>
                  Results Viewed
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              (allData.length > 1 && allData[0].firstName) && (
                filteredDataToggle ? (
                  filteredAllData.map((element, index) => (
                    <tr key={index} style={{ backgroundColor: index % 2 ? '#bbe1f5' : 'white' }}>
                      <td>{index + 1}</td>
                      <td>{filteredAllData[index].firstName}</td>
                      <td>{filteredAllData[index].lastName}</td>
                      <td>{filteredAllData[index].UID}</td>
                      <td>{filteredAllData[index].signupDate}</td>
                      <td>{filteredAllData[index].ipDate}</td>
                      <td>{filteredAllData[index].wipDate}</td>
                      {/* <td>{filteredAllData[index].arDate}</td>
                      <td>{filteredAllData[index].vaDate}</td>
                      <td>{filteredAllData[index].saDate}</td>
                      <td>{filteredAllData[index].cmDate}</td>
                      <td>{filteredAllData[index].cpDate}</td> */}
                      <td>{filteredAllData[index].fpDate}</td>
                      <td>{filteredAllData[index].resultsCalculated}</td>
                    </tr>
                  ))
                ) : (
                  users.map((element, index) => (
                    <tr key={index} style={{ backgroundColor: index % 2 ? '#bbe1f5' : 'white' }}>
                      <td>{index + 1}</td>
                      <td>{allData[index].firstName}</td>
                      <td>{allData[index].lastName}</td>
                      <td>{allData[index].UID}</td>
                      <td>{allData[index].signupDate}</td>
                      <td>{allData[index].ipDate}</td>
                      <td>{allData[index].wipDate}</td>
                      {/* <td>{allData[index].arDate}</td>
                      <td>{allData[index].vaDate}</td>
                      <td>{allData[index].saDate}</td>
                      <td>{allData[index].cmDate}</td>
                      <td>{allData[index].cpDate}</td> */}
                      <td>{allData[index].fpDate}</td>
                      <td>{allData[index].resultsCalculated}</td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
        
      </div>
    )
  } catch (error) {
    console.log('Error: ', error);
    return (
      <div>
        Error, please contact a system administrator for assistance.
      </div>
    )
  }
}

export default Admin