import React from "react";
import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";
import { WIPDNDOptions } from "./Configs";

const WIPDragDrop = ({ droppableId, currentPrompt, order }) => {

  return (
    <div className='wipDragDrop'>
      <div className="wipDragDrop__numbers">
        <div className="wipDragDrop__singleNumber">
          1
        </div>
        <div className="wipDragDrop__singleNumber">
          2
        </div>
        <div className="wipDragDrop__singleNumber">
          3
        </div>
        <div className="wipDragDrop__singleNumber">
          4
        </div>
        <div className="wipDragDrop__singleNumber">
          5
        </div>
      </div>
      {currentPrompt && (
        <Droppable droppableId="column-1">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="wipDragDrop__body"
            >
              {order.map((optionId, index) => {
                const option = WIPDNDOptions.find(
                  (option) => option.id === optionId
                );
                return (
                  <Task
                    id={option.id}
                    key={option.id}
                    index={index}
                    content={option.text}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};

export default WIPDragDrop;
