import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { resetResults, sendWelcomeEmail } from '../../../store/actions/userActions';
// import { functions } from '../../../config/fbConfig';
import HomeBottom from './HomeBottom';


const HomeSignedIn = ({ appAuth, user, resetResults, sendWelcomeEmail }) => {
  const [ complete, setComplete ] = useState(undefined);
  const [ randomNumber, setRandomNumber ] = useState(undefined);

  // Select a random number between 0 and 5, inclusive.
  const min = 0;
  const max = 9;    // Number of home page images, minus one.
  useEffect(() => {
    setRandomNumber(Math.floor(Math.random()*(max - min + 1)) + min);
  }, []);

  // Select image based on random number.
  const imagePath = `/images/homeImages/home-0${randomNumber + 1}.jpg`;


  useEffect(() => {
    try {
      if (user) {
        let ipStatus = user[0].ipComplete;
        let wipStatus = user[0].wipComplete;
        let arStatus = user[0].arComplete;
        let cmStatus = user[0].cmComplete;
        let cpStatus = user[0].cpComplete;
        let fpStatus = user[0].fpComplete;
        let saStatus = user[0].saComplete;
        let vaStatus = user[0].vaComplete;
        if (ipStatus && wipStatus && arStatus && cmStatus && cpStatus && fpStatus && saStatus && vaStatus){
          setComplete(true);
        } else {
          setComplete(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user]);

  // // Resets this user's result booleans so they'll get recalculated when the user next goes
  // // to the 'Results' page.
  // const resultsReset = () => {
  //   resetResults();
  // }

  // // Sends an email to the user through SendGrid.
  // const sendEmail = () => {
  //   const callable = functions.httpsCallable('sendEmailTemplateModule');

  //   callable({ 
  //     to: user[0].email,
  //     templateId: process.env.REACT_APP_WELCOME_EMAIL_TEMPLATE_ID
  //    });
  // }


  return (
    <div className="homeWrapper">
      <div className='home'>
        <div className='home__text'>
          <p className='home__title'>
            The path to personal and professional fulfillment has already been mapped. We provide the compass.
          </p>
          <p className="home__subtitle">
            The Telos assessments measure your values and abilities and orient you toward meaningful career options. Interested?
          </p>
          { (typeof(complete) === 'undefined') ? (
            <div>
              <div>
                <div className="home__button">
                  &nbsp;
                </div>
              </div>
            </div>
          ) : (
            complete ? (
              <div>
                <NavLink to='results'>
                  <button className="home__button">
                    CLICK HERE TO SEE YOUR RESULTS!
                  </button>
                </NavLink>
              </div>
            ) : (
              <NavLink to='assessments'>
                <button className="home__button">
                  CLICK HERE TO START YOUR ASSESSMENTS!
                </button>
              </NavLink>
            )
          ) }
          {/* <button className="home__resetResults" onClick={resultsReset}>
            Reset Your Results
          </button> */}
          {/* <button className="home__resetResults" onClick={sendEmail}>
            Send Email
          </button> */}
        </div>
        <div className='home__image-container'>
          <img className='home__image' src={process.env.PUBLIC_URL + imagePath} alt="pic" />
        </div>
      </div>
      <HomeBottom />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    user: state.firestore.ordered.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetResults: () => dispatch(resetResults()),
    sendWelcomeEmail: () => dispatch(sendWelcomeEmail())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
    ]
  })
)(HomeSignedIn);