import React, { useState, useEffect } from 'react';
import Timer from "../../../../timer/Timer";
import VAMultiChoice2 from './VAMultiChoice2';
import { connect } from "react-redux";
import { vaSubmit, vaTime, vaCompleteSubmit } from "../../../../../store/actions/submitActions";
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { WIPDisplayStates, initialState } from './Configs';
import firebase from '../../../../../config/fbConfig';

const VAAssessment2 = ({ vaSubmit, vaTime, setDisplay, vaCompleteSubmit, auth }) => {
  const timeLimit = 8 * 60; // Time limit for this section, in seconds.
  const [counter, setCounter] = useState(timeLimit);
  // Keep track of the question number within this component.
  const [count, setCount] = useState(null);

  // Total number of questions.
  const numTotalQuestions = 19;

  // Create a state array for the answers.
  const [vaAnswers, setVAAnswers] = useState([...initialState]); // Store VA answers.

  // Most recent question answered.
  const [ last, setLast ] = useState(0);

  // Watch currentPromptId for changes, update initial display order upon change.
  useEffect(() => {
    let isMounted = true;   // Note mutable flag.
    // Get data from this user's 'va' document, if it exists.
    const getData = firebase.firestore().collection('va').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let fbVAAnswers = null; // Firebase answers.
        let newCounter = 0;
        if (doc.exists) {
          fbVAAnswers = doc.data().vaAnswers;
          if (doc.data().timeLeft) {
            if (doc.data().timeLeft.counter) {
              newCounter = doc.data().timeLeft.counter;
            } else {
              newCounter = timeLimit;
            }
          } else {
            newCounter = timeLimit;
          }

          if (count === null && isMounted) {
            // If the doc exists, that means the user has answered at least one question at some point.
            // If count is null, that means the user either just started for the first time or reloaded the assessment.
            setCounter(newCounter);
          }
        }
        let filledVAAnswers = null;
        let countValue = 0;
        try {
          if (fbVAAnswers) {
            filledVAAnswers = fbVAAnswers.filter(e => e.values !== "");
            // Get maximum id value from filledVAAnswers.
            if (filledVAAnswers.length > 0) {
              const maxValue = filledVAAnswers.reduce((p, c) => p.id > c.id ? p: c);
              countValue = maxValue.id;
            } else {
              countValue = 0;
            }
          } else {
            filledVAAnswers = null;
            countValue = 0;
          }
          if (countValue < 0) {
            countValue = 0;
          }
          // Only update the count if the page has been reloaded, otherwise, allow count to get updated
          // when the user clicks the forward and back buttons.
          if (count === null && isMounted) {
            setCount(countValue);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
        return [
          {
            id: 'va',
            data: fbVAAnswers
          }
        ];
      }
    });

    const output = () => {
      getData.then((result) => {
        if (isMounted) {
          // User's stored data in Firebase.
          let storedResults = result[0].data;

          // Filled data.
          let filledVAAnswers = null;

          try {
            if (storedResults){
              filledVAAnswers = result[0].data.filter(e => e.values !== "");
            } else {
              filledVAAnswers = null;
            }
          } catch (error) {
            console.log('Error: ', error);
          }

          // Only set 'last' if the page has been reloaded, otherwise 'last' will be set when the user
          // selects an answer to a question.
          if (count === null) {
            try {
              if (storedResults) {
                setLast(filledVAAnswers.length);
              } else {
                setLast(0); // If no results are stored in Firebase.
              }
            } catch (error) {
              console.log('Error: ', error);
            }
          }

          // Combine Firebase stored answers with vaAnswers state array.
          let currentVAAnswers = null;
          try {
            if (filledVAAnswers) {
              currentVAAnswers = Object.values([...initialState, ...filledVAAnswers].reduce((result, {
                id,
                ...rest
              }) => {
                result[id] = {
                  ...(result[id] || {}),
                  id,
                  ...rest
                };
                return result;
              }, {}));
            } else {
              currentVAAnswers = initialState; // If filledVAAnswers is null (can't be loaded from Firebase, or Firebase is empty).
            }
          } catch (error) {
            console.log('Error: ', error);
          }
          
          // Store Firebase data in vaAnswers state array.
          setVAAnswers(currentVAAnswers);
        }
      })
    }
    output();

    return () => { isMounted = false }; // Cleanup toggles value, if unmounted.
  }, [auth, count, timeLimit]);

  const nextClick = (e) => {
    // Increment 'count'.
    setCount(count + 1);

    // Send all results up to this page to Firestore.
    vaSubmit({ vaAnswers });

    // Submit current time left.
    vaTime({ counter });
  }

  const backClick = (e) => {
    // Decrement 'count'.
    setCount(count - 1);

    // Send all results up to this page to Firestore.
    vaSubmit({ vaAnswers });

    // Submit current time left.
    vaTime({ counter });
  }

  const submitClick = (e) => {
    vaSubmit({ vaAnswers });    // Sends results to Firestore.

    // Submit current time left.
    vaTime({ counter });

    // Set 'vaComplete' to 'true' in user document.
    vaCompleteSubmit(); // Sends complete = true boolean to Firestore.

    // Updates display state.
    setDisplay(WIPDisplayStates.COMPLETE);
  }

  // Submit assessment results if counter goes to zero.
  if (counter === 0) {
    submitClick();
  }

  return (
    <div className="va-main-box">
      <div className="timer">
        <Timer counter={counter} setCounter={setCounter} />
      </div>
      <VAMultiChoice2 count={count} answer={vaAnswers} setAnswer={setVAAnswers} last={last} setLast={setLast} />
      
      <div className="va-row-2 va-row-2-spaced va-row-2-nomargin">
        {count > 0 ? (
          <div className="va-row-2 va-row-2-spaced va-row-2-nomargin">
            <button className='ap-button' onClick={backClick}>
              <MdNavigateBefore className='ar-nav-button' />
            </button>
            {count === numTotalQuestions-1 ? (
              <button className='ap-button ap-button-submit' onClick={submitClick}>Submit</button>
            ) : (
              <button className='ap-button' onClick={nextClick}>
                    <MdNavigateNext className='ar-nav-button' />
              </button>
            )}
          </div>
        ) : (
          <div className="va-row-2 va-row-2-nomargin">
            <button className='ap-button' onClick={nextClick}>
              <MdNavigateNext className='ar-nav-button' />
            </button>
          </div>
        )}
      </div>

      {/* <div className="vaass__questionBoxes">
        <div className="vaass__questionBoxesRow">
          { vaOnline.map((val, ind) => (            
              <input
                type="radio"
                checked={ind === count}
                className="vaass__questionBox"
                readOnly={true}
                key={ind}
              />
            ))
          }
        </div>
      </div> */}

      <div className="vaass__questionBoxes">
        <div className="vaass__questionBoxesRow">
          { vaAnswers.map((val, ind) => (
            val.values !== '' ? (
              <input
                type="radio"
                checked={ind === count}
                className="vaass__questionBox vaass__questionBox-answered"
                readOnly={true}
                key={ind}
              />
            ) : (
              <input
                type="radio"
                checked={ind === count}
                className="vaass__questionBox"
                readOnly={true}
                key={ind}
              />
            )
            ))
          }
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vaSubmit: (scores) => dispatch(vaSubmit(scores)),
    vaTime: (counter) => dispatch(vaTime(counter)),
    vaCompleteSubmit: (result) =>
      dispatch(
        vaCompleteSubmit(result)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VAAssessment2)
