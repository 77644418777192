import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CM.css';
import Multiplication from '../Multiplication';
import Division from '../Division';

const InstructionsQ34 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled3, setDisabled3 ] = useState(true);
  const [ disabled4, setDisabled4 ] = useState(true);
  const [ correct3, setCorrect3 ] = useState(false);
  const [ correct4, setCorrect4 ] = useState(false);
  const [ currentQuestion3 ] = useState(2);
  const [ currentQuestion4 ] = useState(3);

  useEffect(() => {
    if (practiceAnswers[currentQuestion3].values !== '') {
      setDisabled3(false);
    } else {
      setDisabled3(true);
    }

    if (practiceAnswers[currentQuestion4].values !== '') {
      setDisabled4(false);
    } else {
      setDisabled4(true);
    }

    if (practiceAnswers[currentQuestion3].values === '12') {
      setCorrect3(true);
    } else {
      setCorrect3(false);
    }

    if (practiceAnswers[currentQuestion4].values === '3') {
      setCorrect4(true);
    } else {
      setCorrect4(false);
    }
  }, [practiceAnswers, currentQuestion3, currentQuestion4]);

  // Answer options.
  const answerOptions3 = [
    '8',
    '9',
    '12',
    '14',
    'none of these'
  ];

  const answerOptions4 = [
    '3',
    '4',
    '5',
    '6',
    'none of these'
  ];

  const elements3 = [3, 4];
  const elements4 = [6, 2];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // Question 3
    if (name === String(currentQuestion3)) {
      const previousAnswer = practiceAnswers[currentQuestion3].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion3,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion3) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }


    // Question 4
    if (name === String(currentQuestion4)) {
      const previousAnswer = practiceAnswers[currentQuestion4].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion4,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion4) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }
  }

  return (
    <div className='cm-main-box cm-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 4 - Computation
      </div>
      <div className='cm-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Now do Practice Questions 3 and 4 in the same way.
      </div>
      
      <div className="cm-questions">
        <div className="cm-question cm-question-q34">
          <div className="cm-question__top">
            <div className="cm-question__number">
              3.
            </div>
            <div className="cm-question__content">
              <Multiplication elements={elements3} />
            </div>

            <div className="cm-question__answers">
              {
                answerOptions3.map((element, index) => (
                  <label className="cm-question__answer-option" key={index}>
                    <input
                      type="checkbox"
                      className='question__radio-button question__radio-button-cm'
                      checked={practiceAnswers[currentQuestion3].values === element}
                      name={currentQuestion3}
                      value={element}
                      onChange={e => handleAnswer(e)}
                    />
                    <div className="cm-question__answer-text">
                      {element}
                    </div>
                  </label>
                ))
              }
            </div>
          </div>
          { practiceAnswers[currentQuestion3].values !== '' || practiceAnswers[currentQuestion3].values.length > 0 ? (
            correct3 ? (
              <div className="cm-display-answer-flex cm-display-answer-flex-bottom">
                Correct
              </div>
            ) : (
              <div className="cm-display-answer-flex cm-display-answer-flex-bottom">
                Incorrect
              </div>
            )
          ) : (
            <div className="cm-display-answer-flex cm-display-answer-flex-bottom"></div>
          )
          }
        </div>

        <div className="cm-question cm-question-q34">
          <div className="cm-question__top">
            <div className="cm-question__number">
              4.
            </div>
            
            <div className="cm-question__content">
              <Division elements={elements4} />
            </div>

            <div className="cm-question__answers">
              {
                answerOptions4.map((element, index) => (
                  <label className="cm-question__answer-option" key={index}>
                    <input
                      type="checkbox"
                      className='question__radio-button question__radio-button-cm'
                      checked={practiceAnswers[currentQuestion4].values === element}
                      name={currentQuestion4}
                      value={element}
                      onChange={e => handleAnswer(e)}
                    />
                    <div className="cm-question__answer-text">
                      {element}
                    </div>
                  </label>
                ))
              }
            </div>
          </div>
            { practiceAnswers[currentQuestion4].values !== '' || practiceAnswers[currentQuestion4].values.length > 0 ? (
                correct4 ? (
                  <div className="cm-display-answer-flex cm-display-answer-flex-bottom">
                    Correct
                  </div>
                ) : (
                  <div className="cm-display-answer-flex cm-display-answer-flex-bottom">
                    Incorrect
                  </div>
                )
            ) : (
              <div className="cm-display-answer-flex cm-display-answer-flex-bottom"></div>
            )
            }
        </div>
      </div>

      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}  
        >
          Back
        </button>
        { disabled3 === true || disabled4 === true ? (
          <button
            type='button'
            disabled={disabled3 === true || disabled4 === true}
            className="ap-instructions-button"
            style={{ backgroundColor: 'grey' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q5);
            }}
          >
            Next
          </button>
        ) : (
          <button
            type='button'
            className="ap-instructions-button"
            style={{ backgroundColor: '#008375' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q5);
            }}
          >
            Next
          </button>
        ) }
      </div>
      
      {/* { practiceAnswers[currentQuestion3].values !== '' || practiceAnswers[currentQuestion3].values.length > 0 ? (
        correct3 ? (
          <div className="cm-display-answer cm-display-answer-q3">
            Correct
          </div>
        ) : (
          <div className="cm-display-answer cm-display-answer-q3">
            Incorrect
          </div>
        )
      ) : (
        <div className="cm-display-answer cm-display-answer-q3"></div>
      )
      } */}

        {/* { practiceAnswers[currentQuestion4].values !== '' || practiceAnswers[currentQuestion4].values.length > 0 ? (
            correct4 ? (
              <div className="cm-display-answer cm-display-answer-q4">
                Correct
              </div>
            ) : (
              <div className="cm-display-answer cm-display-answer-q4">
                Incorrect
              </div>
            )
        ) : (
          <div className="cm-display-answer cm-display-answer-q4"></div>
        )
        } */}



    </div>
  )
}

export default InstructionsQ34