export const WIPDisplayStates = {
  INSTRUCTIONS: "INSTRUCTIONS",
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q345: "INSTRUCTIONS_Q345",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: 'DEMOGRAPHIC_INFORMATION'
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''}
]

export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''}
];

// List of all the questions and their answer choices.
export const questions = [
  { questionText: '',
    answerOptions: [{ answerText: 'push' },{ answerText: 'dine' },{ answerText: 'nap' },{ answerText: 'eat' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'easy' },{ answerText: 'fast' },{ answerText: 'difficult' },{ answerText: 'free' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'prompt' },{ answerText: 'plush' },{ answerText: 'happy' },{ answerText: 'tardy' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'junction' },{ answerText: 'induction' },{ answerText: 'intersection' },{ answerText: 'attention' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'ripe' },{ answerText: 'humble' },{ answerText: 'arrogant' },{ answerText: 'autumnal' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'decompose' },{ answerText: 'adjust' },{ answerText: 'decay' },{ answerText: 'replenish' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'digestive' },{ answerText: 'conclusive' },{ answerText: 'decisive' },{ answerText: 'heroic' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'thirst' },{ answerText: 'turmoil' },{ answerText: 'petroleum' },{ answerText: 'chaos' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'scrub' },{ answerText: 'scorch' },{ answerText: 'scald' },{ answerText: 'sprung' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'wallow' },{ answerText: 'darken' },{ answerText: 'produce' },{ answerText: 'illuminate' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'reasonable' },{ answerText: 'irrational' },{ answerText: 'shifty' },{ answerText: 'humorous' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'animosity' },{ answerText: 'restitution' },{ answerText: 'resentment' },{ answerText: 'intuition' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'recognition' },{ answerText: 'descendant' },{ answerText: 'opponent' },{ answerText: 'antagonist' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'prudent' },{ answerText: 'mirthful' },{ answerText: 'helpless' },{ answerText: 'sullen' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'oviparous' },{ answerText: 'eulogistic' },{ answerText: 'carnivorous' },{ answerText: 'laudatory' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'commodious' },{ answerText: 'unwavering' },{ answerText: 'cowardly' },{ answerText: 'oscillatory' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'regret' },{ answerText: 'respect' },{ answerText: 'deference' },{ answerText: 'poverty' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'sequestrate' },{ answerText: 'segregate' },{ answerText: 'delegate' },{ answerText: 'dehydrate' }] },
  { questionText: '',
    answerOptions: [{ answerText: 'impenitent' },{ answerText: 'compendious' },{ answerText: 'capable' },{ answerText: 'sorry' }] },
];

export const labels = [
  'A',
  'B',
  'C',
  'D'
]

export const values = [
  'A-B',
  'A-C',
  'A-D',
  'B-C',
  'B-D',
  'C-D'
];