import React, { useState, useEffect } from 'react';
import HomeSignedIn from './HomeSignedIn';
import HomeSignedOut from './HomeSignedOut';
import HomeUnverified from './HomeUnverified';
import LoadingPage from '../loadingPage/LoadingPage';
import { DisplayStates } from './Configs';
// import { Redirect } from 'react-router-dom';
import firebase from '../../../config/fbConfig';
import { prepareDate } from '../../results/Functions';

const HomeFrontPage = ({ appAuth }) => {
  const [display, setDisplay] = useState(DisplayStates.LOADING);
  // const [display, setDisplay] = useState(DisplayStates.UNVERIFIED);

  // State variables to check if the user's email has been verified.
  const [ createTime, setCreateTime ] = useState(null);
  const [ emailVerified, setEmailVerified ] = useState(null);

  let verificationStartDate = new Date(...prepareDate(process.env.REACT_APP_VERIFICATION_START_DATE)).getTime();  // In milliseconds.

  // useEffect() to check if user's account is created.
  useEffect(() => {
    try {
      // Get user's account creation time.
      if (firebase.auth().currentUser) {
        setCreateTime(firebase.auth().currentUser.metadata.creationTime);
      }

      // Get user's email verification status.
      if (appAuth) {
        setEmailVerified(appAuth.emailVerified);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [appAuth, createTime, emailVerified, verificationStartDate]);

  useEffect(() => {
    try {
      if (appAuth.isLoaded) {
        if (appAuth.isEmpty) {
          setDisplay(DisplayStates.SIGNEDOUT);
        } else {  // User is signed in.
          if (emailVerified === true || Date.parse(createTime) < verificationStartDate) {
            setDisplay(DisplayStates.SIGNEDIN);
          } else if (emailVerified === false && Date.parse(createTime) > verificationStartDate) {
            setDisplay(DisplayStates.UNVERIFIED);
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [appAuth, createTime, emailVerified, verificationStartDate]);

  return (
    <div className='homeFrontPage'>
      {display === DisplayStates.LOADING && (
        <LoadingPage />
        )
      }
      {display === DisplayStates.SIGNEDIN && (
        <HomeSignedIn appAuth={appAuth} />
        )
      }
      {display === DisplayStates.SIGNEDOUT && (
        <HomeSignedOut appAuth={appAuth} />
        )
      }
      {display === DisplayStates.UNVERIFIED && (
        <HomeUnverified appAuth={appAuth} />
        )
      }
    </div>
  )
}

export default HomeFrontPage