import React from 'react';
// import firebase from '../../../config/fbConfig';
// import { wipResultSubmit } from '../../../store/actions/submitActions';
// import corrcoeff from '../CalcCorrCoef';
import { connect } from 'react-redux';
// import { rescale } from '../Functions';
import { wipJobOverlapCalculate } from '../functionalizedCalculations/wipCalculations';

const WIPJobOverlap = ({ uid, achieveZScore, workZScore, recogZScore, relateZScore, supportZScore, indepZScore }) => {
//   const resultCount = 500;     // Number of results to store.
  // const wipThreshold = 0.291;     // Minimum correlation coefficient for jobs to be included.

  // console.log('WIP JOBS CALCULATE');

  try {
    // Calculate IP job overlap.
    wipJobOverlapCalculate(
      uid,
      achieveZScore, workZScore, recogZScore,
      relateZScore, supportZScore, indepZScore
    );
  } catch (error) {
    console.log('Error: ', error);
  }


return (
    <div>
    </div>
)
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(WIPJobOverlap)