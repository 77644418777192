import { useState } from 'react';
import './TableHead.css';
import { FaArrowsAltV, FaArrowDown, FaArrowUp } from 'react-icons/fa';

const TableHead = ({ columns, handleSorting }) => {

  const [ sortField, setSortField ] = useState('');
  const [ order, setOrder ] = useState('asc');

  const handleSortingChange = (accessor) => {
    const sortOrder = 
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  }

  return (
   <thead>
    <tr>
      <th className='tableHead__th'></th>
     {columns.map(({ label, accessor, sortable }) => {
      const cl = sortable
        ? sortField === accessor && order === 'asc'
          ? 'up'
          : sortField === accessor && order === 'desc'
          ? 'down'
          : 'default'
        : '';
        return (
          <th
            key={accessor}
            onClick={sortable ? () => handleSortingChange(accessor) : null}
            className='tableHead__th'
          >
            <>
              {label}
            </>
            <span className='tableHead__icon'>
              {
                (cl === 'up') && <FaArrowUp />
              }
              {
                (cl === 'down') && <FaArrowDown />
              }
              {
                (cl === 'default') && <FaArrowsAltV />
              }
            </span>
          </th>
        );
      })}
    </tr>
   </thead>
  );
 };
 
 export default TableHead;