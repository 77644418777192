import React, { useState, useEffect } from 'react';
import './GridTablePagination.css';

const GridTablePagination = ({ currentPage, setCurrentPage, totalPages, view }) => {
  
  // Function to change the current page based on user input.
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  let pagesToShow = 9;  // Maximum number of pages to show.
  if (view === 'mobile') {
    pagesToShow = 4;
  }
  const [ pageNumbers, setPageNumbers ] = useState(Array.from({ length: totalPages }, (_, i) => i + 1).slice(0, pagesToShow));
  useEffect(() => {
    if (currentPage - 1 < totalPages - pagesToShow) {
      setPageNumbers(
        Array.from({ length: totalPages }, (_, i) => i + 1).slice(currentPage - 1, currentPage - 1 + pagesToShow)
      );
    } else {
      setPageNumbers(
        Array.from({ length: totalPages }, (_, i) => i + 1).slice(totalPages - pagesToShow, totalPages)
      );
    }
  }, [currentPage, setPageNumbers, totalPages, pagesToShow]);

  // // Collect user input value.
  // const [ pageInput, setPageInput ] = useState('');
  // // Event handler function to update the state when the input changes.
  // const handleInputChange = (event) => {
  //   let value = event.target.value;

  //   // Check if the value is a valid number between 0 and maxValue
  //   if (!isNaN(value) && value >= 0 && value <= totalPages) {
  //     setPageInput(event.target.value);
  //   }
  // };

  // // Go to page when user clicks the 'Go' button.
  // const goClick = () => {
  //   goToPage(Number(pageInput));
  //   setPageInput('');
  // }

  return (
    <div className='gridTablePagination'>

      {/* Previous button. */}
      <button
        onClick={() => goToPage(currentPage - 1)}
        disabled={currentPage === 1}
        className={ `gridTablePagination__button ${currentPage === 1 ? 'gridTablePagination__button-disabled' : ''}` }
      >
        {(view === 'mobile') ? (
          <span>
            <BackArrow
              className={ `gridTablePagination__arrow ${currentPage === 1 ? 'gridTablePagination__arrow-disabled' : ''}`}
            />
          </span>
        ) : (
          <span>
            {/* <BackArrow
              className={ `gridTablePagination__arrow ${currentPage === 1 ? 'gridTablePagination__arrow-disabled' : ''}`}
            /> */}
            Previous
          </span>
        )}
      </button>

      {/* First page always available to select. */}
      { (currentPage > 1) &&
        <span className="gridTablePagination__span" onClick={() => goToPage(1)}>
          1
        </span>
      }
      { (currentPage > 2) &&
        <span className="gridTablePagination__span gridTablePagination__span-disabled">
          ...
        </span>
      }

      {/* Loop through all available pages to show. */}
      {pageNumbers.map((page) => (
        <span
          key={page}
          className={`gridTablePagination__span ${currentPage === page ? 'gridTablePagination__span-active' : ''}`}
          onClick={() => goToPage(page)}
        >
          {page}
        </span>
      ))}

      { (currentPage < totalPages - (pagesToShow)) &&
        <span className="gridTablePagination__span gridTablePagination__span-disabled">
          ...
        </span>
      }

      {/* Last page always available to select. */}
      { (currentPage < totalPages - (pagesToShow - 1)) &&
        <span className="gridTablePagination__span" onClick={() => goToPage(totalPages)}>
          {totalPages}
        </span>
      }

      {/* Next button. */}
      <button
        onClick={() => goToPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={ `gridTablePagination__button ${currentPage === totalPages ? 'gridTablePagination__button-disabled' : ''}` }
      >
        {(view === 'mobile') ? (
          <span>
            <NextArrow
              className={ `gridTablePagination__arrow ${currentPage === 1 ? 'gridTablePagination__arrow-disabled' : ''}`}
            />
          </span>
        ) : (
          <span>
            Next
            {/* <NextArrow
              className={ `gridTablePagination__arrow ${currentPage === 1 ? 'gridTablePagination__arrow-disabled' : ''}`}
            /> */}
          </span>
        )}
      </button>

      {/* Go to page option. */}
      {/* <span className="gridTablePagination__text">Go to page</span>
      <input
        type="text"
        className="gridTablePagination__input"
        onChange={handleInputChange}
        value={pageInput}
      />
      <button
        className="gridTablePagination__goButton"
        onClick={goClick}
      >
        Go
      </button> */}

    </div>
  )
}

export default GridTablePagination

const BackArrow = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
  </svg>
);

const NextArrow = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    style={{ transform: 'rotate(180deg)' }}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
  </svg>
);