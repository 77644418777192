import React from 'react';
// import ProgressBar from '../../../../ProgressBar/ProgressBar';
import Addition from './Addition';
import Subtraction from './Subtraction';
import Division from './Division';
import Multiplication from './Multiplication';
import { questions, values } from './Configs';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const CMMultiChoice2 = ({count, answer, setAnswer, last, setLast}) => {

  // If count is null, set it to 0.
  if (count === null) {
    count = 0;
  }

  const questionType = questions[count].questionType;
  const elements = questions[count].elements;

  const options = questions[count].answerOptions.map((key, index) => {
    return key.answerText;
  });

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // If already selected, clear it.
    const previousAnswer = answer[count].values;
    const currentAnswer = e.target.value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  const numTotalQuestions = 40;
  // const progress = Math.round((count+1)/numTotalQuestions*100*10)/10;

  return (
    <div className='cm-main-box'>
      <div className="cm-question cm-question-center cm-question-main">
        <div className="cm-question__number">
          {count+1}.
        </div>
        <div className="cm-question__content">
          {questionType === 'ADD'
            ? <Addition elements={elements} />
            : questionType === 'SUBTRACT'
              ? <Subtraction elements={elements} />
              : questionType ==='MULTIPLY'
                ? <Multiplication elements={elements} />
                : <Division elements={elements} />
          }
        </div>
        <div className="cm-question__answers">
          { options.map((key, index) => (
            <label className="cm-question__answer-option" key={index}>
              <input
                type='checkbox'
                className='question__radio-button question__radio-button-cm'
                checked={answer[count].values === values[index]}
                value={values[index]}
                onChange={e => handleAnswer(e)}
                name={count}
              />
              <div className="cm-question__answer-text">
                {key}
              </div>
            </label>
          )) }
        </div>
      </div>
      <div className="apMultiChoice__bottom">
        {/* <div className="apMultiChoice__progressBar">
          <ProgressBar completed={progress} bgColor='#008375' />
        </div> */}
        <div className="cmMultiChoice__question-count" style={{ width: '100%' }}>
          {count+1} of {numTotalQuestions} questions
        </div>
      </div>
    </div>
  )
}

export default CMMultiChoice2
