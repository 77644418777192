import React, { useState, useEffect } from 'react';
import './PaymentBottom.css';
import { NavLink } from 'react-router-dom';

const adPic = `images/purchaseImages/Telos_PurchasePage_Bottom_1-16-24_72dpi.png`;
const adPicMobile = `images/purchaseImages/TelosPurchasePage_Bottom_96dpi.png`;

const PaymentBottom = () => {
  // Check page width.
  const [view, setView] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 640) {
        setView('mobile');
      } else if (screenWidth > 640 && screenWidth < 1024) {
        setView('tablet');
      } else {
        setView('pc');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set pic types.
  let currentPic = adPic;
  if (view === 'mobile') {
    currentPic = adPicMobile;
  } else {
    currentPic = adPic;
  }

  return (
    <div className='paymentBottom'>
      <img
        src={currentPic}
        alt='benefits-pic'
        className='paymentBottom__pic'
      />
      { view === 'mobile' &&
        <NavLink to='/signup'>
          <button
            className="paymentBottom__button paymentBottom__button-button1"
          />
        </NavLink>
      }
    </div>
  )
}

export default PaymentBottom