import React, { useState } from 'react';
import './IPInstructions.css';
import { WIPDisplayStates } from "./Configs";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { ReadyPopup } from '../../../Utils/ReadyPopup';

const IPInstructions = ({ setDisplay, ip }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='ipInstructions'>
      <div className="ipInstructions__header">
        Assessment 1: Interest Profiler
      </div>
      <div className="ipInstructions__subheader">
        Instructions
      </div>
      <div className="ipInstructions__body">
        Welcome to the Interest Profiler assessment. This module will explore how you
        react to a handful of different workplace scenarios in order to gauge what you
        personally find valuable.
        <br /><br />
        <b>*Please note that there are no wrong answers to these questions.*</b>
        <br /><br />
        We find that the best answer is an honest answer. This will help us match you more accurately
        to career fields that will bring your life value.
        <br /><br />
        <b>Please make sure you are in a quiet space and have 30 minutes to complete the assessment
          without interruption.</b> There will be a short video explanation before the assessment.
          Click on the start button below to begin. 
      </div>
      <button type='button' className="ipInstructions__button" onClick={() => setOpen(o => !o)}>
        Begin
      </button>
      <ReadyPopup
        onClick = {setDisplay}
        readyDestination = {WIPDisplayStates.VIDEO}
        open = {open}
        setOpen = {setOpen}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    ip: state.firestore.ordered.ip
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'ip', doc: props.auth.uid }
    ]
  })
)(IPInstructions)