export const WIPDisplayStates = {
  INSTRUCTIONS: "INSTRUCTIONS",
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q34: "INSTRUCTIONS_Q34",
  INSTRUCTIONS_Q5: "INSTRUCTIONS_Q5",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: "DEMOGRAPHIC_INFORMATION"
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: '42'}
]

// Initial state.
export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''},
  {id: 19, values: ''},
  {id: 20, values: ''},
  {id: 21, values: ''},
  {id: 22, values: ''},
  {id: 23, values: ''},
  {id: 24, values: ''},
  {id: 25, values: ''},
  {id: 26, values: ''},
  {id: 27, values: ''},
  {id: 28, values: ''},
  {id: 29, values: ''},
  {id: 30, values: ''},
  {id: 31, values: ''},
  {id: 32, values: ''},
  {id: 33, values: ''},
  {id: 34, values: ''},
  {id: 35, values: ''},
  {id: 36, values: ''},
  {id: 37, values: ''},
  {id: 38, values: ''},
  {id: 39, values: ''}
];

export const questions = [
  { questionType: 'ADD',
    elements: [4, 3],
    answerOptions: [{answerText: '1'}, {answerText: '6'}, {answerText: '7'}, {answerText: '12'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [19, 5],
    answerOptions: [{answerText: '14'}, {answerText: '24'}, {answerText: '34'}, {answerText: '95'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [13, 3],
    answerOptions: [{answerText: '16'}, {answerText: '36'}, {answerText: '37'}, {answerText: '39'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [63, 9],
    answerOptions: [{answerText: '5'}, {answerText: '6'}, {answerText: '7'}, {answerText: '8'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [25, 18, 13],
    answerOptions: [{answerText: '46'}, {answerText: '56'}, {answerText: '63'}, {answerText: '66'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [33, 14],
    answerOptions: [{answerText: '17'}, {answerText: '19'}, {answerText: '21'}, {answerText: '27'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [4, 4],
    answerOptions: [{answerText: '0'}, {answerText: '8'}, {answerText: '14'}, {answerText: '15'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [182, 7],
    answerOptions: [{answerText: '24'}, {answerText: '25'}, {answerText: '26'}, {answerText: '28'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [3, 8, 4],
    answerOptions: [{answerText: '12'}, {answerText: '15'}, {answerText: '16'}, {answerText: '17'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [377, 42],
    answerOptions: [{answerText: '335'}, {answerText: '336'}, {answerText: '345'}, {answerText: '419'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [17, 3],
    answerOptions: [{answerText: '20'}, {answerText: '54'}, {answerText: '55'}, {answerText: '61'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [495, 15],
    answerOptions: [{answerText: '27'}, {answerText: '29'}, {answerText: '30'}, {answerText: '31'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [963, 12],
    answerOptions: [{answerText: '951'}, {answerText: '965'}, {answerText: '975'}, {answerText: '985'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [7384, 285],
    answerOptions: [{answerText: '7,099'}, {answerText: '7,107'}, {answerText: '7,109'}, {answerText: '7,669'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [123, 4],
    answerOptions: [{answerText: '127'}, {answerText: '492'}, {answerText: '582'}, {answerText: '592'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [64, 8],
    answerOptions: [{answerText: '4'}, {answerText: '6'}, {answerText: '7'}, {answerText: '9'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [1049, 83, 435],
    answerOptions: [{answerText: '1,567'}, {answerText: '1,568'}, {answerText: '1,577'}, {answerText: '1,578'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [150, 67],
    answerOptions: [{answerText: '83'}, {answerText: '84'}, {answerText: '93'}, {answerText: '217'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [66, 7],
    answerOptions: [{answerText: '442'}, {answerText: '452'}, {answerText: '462'}, {answerText: '667'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [378, 7],
    answerOptions: [{answerText: '44'}, {answerText: '45'}, {answerText: '49'}, {answerText: '54'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [465, 96, 954],
    answerOptions: [{answerText: '1,305'}, {answerText: '1,315'}, {answerText: '1,515'}, {answerText: '1,615'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [147, 59],
    answerOptions: [{answerText: '98'}, {answerText: '108'}, {answerText: '112'}, {answerText: '206'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [8733, 4],
    answerOptions: [{answerText: '32,822'}, {answerText: '32,932'}, {answerText: '34,932'}, {answerText: '35,932'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [29554, 14],
    answerOptions: [{answerText: '2,116'}, {answerText: '2,121'}, {answerText: '2,131'}, {answerText: '2,146'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [6234, 549, 473],
    answerOptions: [{answerText: '6,256'}, {answerText: '7,256'}, {answerText: '7,356'}, {answerText: '7,526'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [42726, 6175],
    answerOptions: [{answerText: '36,551'}, {answerText: '36,651'}, {answerText: '37,241'}, {answerText: '48,901'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [246, 6],
    answerOptions: [{answerText: '1,446'}, {answerText: '1,476'}, {answerText: '1,486'}, {answerText: '1,576'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [43764, 6],
    answerOptions: [{answerText: '7,094'}, {answerText: '7,189'}, {answerText: '7,199'}, {answerText: '7,294'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [36905, 9672, 10193, 230, 325],
    answerOptions: [{answerText: '55,115'}, {answerText: '57,325'}, {answerText: '58,315'}, {answerText: '59,425'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [239, 75],
    answerOptions: [{answerText: '154'}, {answerText: '264'}, {answerText: '304'}, {answerText: '314'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [97132, 9],
    answerOptions: [{answerText: '97,123'}, {answerText: '97,141'}, {answerText: '874,128'}, {answerText: '874,188'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [597065, 7],
    answerOptions: [{answerText: '85,205'}, {answerText: '85,285'}, {answerText: '85,295'}, {answerText: '85,305'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [12125, 8971, 56660, 513, 2550],
    answerOptions: [{answerText: '80,819'}, {answerText: '80,919'}, {answerText: '81,819'}, {answerText: '90,819'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [154321, 5492],
    answerOptions: [{answerText: '148,829'}, {answerText: '148,929'}, {answerText: '149,939'}, {answerText: '159,813'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [273, 54],
    answerOptions: [{answerText: '13,642'}, {answerText: '14,642'}, {answerText: '14,728'}, {answerText: '14,742'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [114363, 9],
    answerOptions: [{answerText: '1,277'}, {answerText: '12,707'}, {answerText: '12,807'}, {answerText: '12,907'}, {answerText: 'none of these'}] },
  { questionType: 'ADD',
    elements: [242, 853, 36, 42, 396, 9, 10, 81, 4, 78, 765, 4, 9],
    answerOptions: [{answerText: '2,029'}, {answerText: '2,479'}, {answerText: '2,529'}, {answerText: '2,629'}, {answerText: 'none of these'}] },
  { questionType: 'SUBTRACT',
    elements: [3871532, 597633],
    answerOptions: [{answerText: '2,373,999'}, {answerText: '2,383,899'}, {answerText: '3,272,999'}, {answerText: '3,273,899'}, {answerText: 'none of these'}] },
  { questionType: 'MULTIPLY',
    elements: [47094, 413],
    answerOptions: [{answerText: '47,507'}, {answerText: '376,742'}, {answerText: '18,649,812'}, {answerText: '19,449,822'}, {answerText: 'none of these'}] },
  { questionType: 'DIVIDE',
    elements: [476034, 34],
    answerOptions: [{answerText: '1,401'}, {answerText: '14,001'}, {answerText: '14,071'}, {answerText: '14,072'}, {answerText: 'none of these'}] },
];

export const values = [
  'A',
  'B',
  'C',
  'D',
  'E'
];