import React, { useState, useEffect } from 'react';
import { FaSpinner } from "react-icons/fa";
import './LoadingPage.css';
import { Redirect } from 'react-router-dom';
import { useInterval } from '../../../Utils/useInterval';
import firebase from '../../../config/fbConfig';
import { connect } from "react-redux";

const LoadingPage = ({ redirectCheck, light, checkPaid, auth }) => {
  // redirectCheck = true means the loading page won't redirect after 5 seconds, but will
  // instead reveal an error message and prompt the user to refresh the page.
  // light = true will set the text font color of the error message to be white instead of black.
  // checkPaid: true will redirect the user to the payment page if the user hasn't purchased his assessments yet.
  const [ over, setOver ] = useState(false);

  const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(false);

  // Check if user has paid.
  useEffect(() => {
    try {
      async function checkPurchases(docId) {
        const order = await firebase.firestore().collection('orders').doc(docId).get();
        
        let purchased = false;
        let orderData = await order.data();
        if (orderData) {
          purchased = true;
        } else {
          purchased = false;
        }
        setPurchasedOrder(purchased);
        
        // Component is mounted after this check is complete.
        setIsMounted(true);
        
        return purchased;
      }
      const uid = auth.uid;
      checkPurchases(uid);
    } catch (error) {
      console.log('Error: ', error);
      setIsMounted(true);
    }

  }, [auth.uid]);

  // Redirect to 'Error' page after 5 seconds if 'redirectCheck' is not true.
    useInterval(() => {
      setOver(true);
    }, 1000 * 5);
  
  try {
    if (over === true && redirectCheck !== true) {
      return <Redirect to='/error' />
    }
  } catch (error) {
    console.log('Error: ', error);
  }

  return (
    <div className='spinner_box'>
      {over !== true ? (
        <FaSpinner
          className='spinner'
        />
      ) : (
        <div className={"loadingPage__error " + ((light === true) && "loadingPage__error-light")}>
          Hmm, something's not right. Have you tried refreshing the page?
        </div>
      )}
      { checkPaid === true && isMounted === true && purchasedOrder !== true &&  // If the loading page is meant to check whether the user has paid, the user's profile is mounted, and the user didn't pay yet, redirect to the payment page.
        <Redirect
          to={{
            pathname: `/payment`,
          }}
        />
      }

    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	};
};

export default connect(mapStateToProps)(LoadingPage)