import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import './TelosChart2.css';
import WIPPopup from './Popups/WIPPopup';
import IPPopup from './Popups/IPPopup';
import APPopup from './Popups/APPopup';
import IPAPPopup from './Popups/IPAPPopup';
import IPWIPPopup from './Popups/IPWIPPopup';
import APWIPPopup from './Popups/APWIPPopup';
import TrifectaPopup from './Popups/TrifectaPopup';
import IntroPopup from './Popups/IntroPopup';
import { updateTrifectaViewed } from '../../../../store/actions/userActions';

const infoButtonRolloverPic = `images/resultsImages/trifecta/InfoButtonTrifectaRolloverState.png`;
const infoButtonPic = `images/resultsImages/trifecta/InfoButtonTrifecta.png`;

const TelosChart2 = ({
  user, apresults, ipresults, wipresults, trifectaresults,
  updateTrifectaViewed, extraMargin, setExtraMargin }) => {

  // Check if user has already seen this page.
  const [showIntro, setShowIntro] = useState(false);
  const [firstView, setFirstView] = useState(false);

  // If this is the user's first time on this page, show them the info popup.
  useEffect(() => {
    try {
      if (user) {
        if (user[0]) {
          if (user[0].trifectaViewed) {
            if (user[0].trifectaViewed === true) {
              setShowIntro(false);
            } else {
              setShowIntro(true);
              setFirstView(true);
            }
          } else {
            setShowIntro(true);
            setFirstView(true);
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user]);

  const [activeElement, setActiveElement] = useState(null);


  const [showIP, setShowIP] = useState(false);
  const [ipGroupedResults, setIPGroupedResults] = useState([]);
  
  const [showWIP, setShowWIP] = useState(false);
  const [wipGroupedResults, setWIPGroupedResults] = useState([]);
  
  const [showAP, setShowAP] = useState(false);
  const [apGroupedResults, setAPGroupedResults] = useState([]);
  
  const [showAPWIP, setShowAPWIP] = useState(false);
  const [APWIPGroupedResults, setAPWIPGroupedResults] = useState([]);
  
  const [showIPAP, setShowIPAP] = useState(false);
  const [IPAPGroupedResults, setIPAPGroupedResults] = useState([]);
  
  const [showIPWIP, setShowIPWIP] = useState(false);
  const [IPWIPGroupedResults, setIPWIPGroupedResults] = useState([]);
  
  const [showTrifecta, setShowTrifecta] = useState(false);
  const [trifectaGroupedResults, setTrifectaGroupedResults] = useState([]);

  // // Track whether a button has already been clicked.
  // const [ ipClicked, setIPClicked ] = useState(false);
  // const [ wipClicked, setWIPClicked ] = useState(false);
  // const [ apClicked, setAPClicked ] = useState(false);
  // const [ ipapClicked, setIPAPClicked ] = useState(false);
  // const [ ipwipClicked, setIPWIPClicked ] = useState(false);
  // const [ apwipClicked, setAPWIPClicked ] = useState(false);
  // const [ trifectaClicked, setTrifectaClicked ] = useState(false);

  // Show info popup when clicked.
  const infoClick = (e) => {
    setShowIntro(true);
  }

  const handleEllipseClick = (elementId) => {
    if (elementId === 0) {
      // Trigger WIP popup.
      setShowWIP(true);
      // setWIPClicked(true);
    } else if (elementId === 1) {
      // Trigger IP popup.
      setShowIP(true);
      // setIPClicked(true);
    } else if (elementId === 2) {
      // Trigger AP popup.
      setShowAP(true);
      // setAPClicked(true);
    } else if (elementId === 3) {
      // Trigger APIP popup.
      setShowIPAP(true);
      // setIPAPClicked(true);
    } else if (elementId === 4) {
      // Trigger IPWIP popup.
      setShowIPWIP(true);
      // setIPWIPClicked(true);
    } else if (elementId === 5) {
      // Trigger WIPAP popup.
      setShowAPWIP(true);
      // setAPWIPClicked(true);
    } else if (elementId === 6) {
      // Trigger trifecta popup.
      setShowTrifecta(true);
      // setTrifectaClicked(true);
    }
  }

  // Close any popup that is open when the 'X' is clicked on the popup.
  const closePopup = () => {
    setShowIntro(false);
    setShowAP(false);
    setShowIP(false);
    setShowWIP(false);
    setShowIPAP(false);
    setShowIPWIP(false);
    setShowAPWIP(false);
    setShowTrifecta(false);

    // Make a note in the user's document that he has successfully
    // viewed the intro popup for the first time.
    if (firstView === true) {
      // Update user document; trifectaViewed should be set to true.
      updateTrifectaViewed();
      // Set firstView state to false to avoid triggering the updateTrifectaViewed()
      // function more than once.
      setFirstView(false);
    }
  };

  // Close any popup that is open when the user clicks outside the popup.
  useEffect(() => {
    const handleClickOutside = (event) => {
      const wipPopup = document.getElementById('wipPopup');
      const ipPopup = document.getElementById('ipPopup');
      const apPopup = document.getElementById('apPopup');
      const ipapPopup = document.getElementById('ipapPopup');
      const ipwipPopup = document.getElementById('ipwipPopup');
      const apwipPopup = document.getElementById('apwipPopup');
      const trifectaPopup = document.getElementById('trifectaPopup');
      const introPopup = document.getElementById('introPopup');

      if (wipPopup && !wipPopup.contains(event.target)) {
        setShowWIP(false);
      } else if (ipPopup && !ipPopup.contains(event.target)) {
        setShowIP(false);
      } else if (apPopup && !apPopup.contains(event.target)) {
        setShowAP(false);
      } else if (ipapPopup && !ipapPopup.contains(event.target)) {
        setShowIPAP(false);
      } else if (ipwipPopup && !ipwipPopup.contains(event.target)) {
        setShowIPWIP(false);
      } else if (apwipPopup && !apwipPopup.contains(event.target)) {
        setShowAPWIP(false);
      } else if (trifectaPopup && !trifectaPopup.contains(event.target)) {
        setShowTrifecta(false);
      } else if (introPopup && !introPopup.contains(event.target)) {
        setShowIntro(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleHover = (elementId) => {
    setActiveElement(elementId);
  }

  const handleMouseOut = () => {
    setActiveElement(null);
  }

  // Number of top jobs to show.
  const individualN = 50; // Maximum number of jobs to show in each individual category.
  const overlapN = 30;     // Maximum number of jobs to show in each overlap category.

  useEffect(() => {
    try {
      if (typeof apresults !== 'undefined' && apresults.length > 0) {
        if (typeof apresults[0].apSortedResults !== 'undefined' && apresults[0].apSortedResults.length > 0) {
          let topAPResults = apresults[0].apSortedResults.slice(0, individualN);
          // Group occupations.
          setAPGroupedResults(groupJobs(topAPResults));
        }
      }
      if (typeof ipresults !== 'undefined' && ipresults.length > 0) {
        if (typeof ipresults[0].ipSortedResults !== 'undefined' && ipresults[0].ipSortedResults.length > 0) {
          let topIPResults = ipresults[0].ipSortedResults.slice(0, individualN);
          // Group occupations.
          setIPGroupedResults(groupJobs(topIPResults));
        }
      }
      if (typeof wipresults !== 'undefined' && wipresults.length > 0) {
        if (typeof wipresults[0].wipSortedResults !== 'undefined' && wipresults[0].wipSortedResults.length > 0) {
          let topWIPResults = wipresults[0].wipSortedResults.slice(0, individualN);
          // Group occupations.
          setWIPGroupedResults(groupJobs(topWIPResults));
        }
      }
      if (typeof trifectaresults !== 'undefined' && trifectaresults.length > 0) {
        if (typeof trifectaresults[0].apwipSortedResults !== 'undefined' && trifectaresults[0].apwipSortedResults.length > 0) {
          let apwipResults = trifectaresults[0].apwipSortedResults.slice(0, overlapN);
          // Group occupations.
          setAPWIPGroupedResults(groupJobs(apwipResults));
        }

        if (typeof trifectaresults[0].ipapSortedResults !== 'undefined' && trifectaresults[0].ipapSortedResults.length > 0) {
          let ipapResults = trifectaresults[0].ipapSortedResults.slice(0, overlapN);
          // Group occupations.
          setIPAPGroupedResults(groupJobs(ipapResults));
        }

        if (typeof trifectaresults[0].ipwipSortedResults !== 'undefined' && trifectaresults[0].ipwipSortedResults.length > 0) {
          let ipwipResults = trifectaresults[0].ipwipSortedResults.slice(0, overlapN);
          // Group occupations.
          setIPWIPGroupedResults(groupJobs(ipwipResults));
        }

        if (typeof trifectaresults[0].trifectaResults !== 'undefined' && trifectaresults[0].trifectaResults.length > 0) {
          let trifectaResults = trifectaresults[0].trifectaResults.slice(0, overlapN);
          // Group occupations.
          setTrifectaGroupedResults(groupJobs(trifectaResults));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apresults, ipresults, wipresults, trifectaresults]);


  // Make sure the footer doesn't overlap with the popup content for any
  // screen size.
  useEffect(() => {
    // Array containing IDs of the popups
    const popupIds = [
      'wipPopup',
      'ipPopup',
      'apPopup',
      'ipapPopup',
      'ipwipPopup',
      'apwipPopup',
      'trifectaPopup',
      'introPopup'
    ];
  
    // Initialize variables for rectangle dimensions
    let rect = { bottom: 0, top: 0 };
    let foundPopup = false; // Flag to track if a valid popup was found
  
    // Loop through each popup ID
    for (const popupId of popupIds) {
      const popup = document.getElementById(popupId); // Get popup element by ID
  
      if (popup) { // If a valid popup element is found
        rect = popup.getBoundingClientRect(); // Get dimensions of the popup
        if (popup === 'trifectaPopup') {
          setExtraMargin(rect.bottom - rect.top + 100); // Calculate extra margin and update state
        } else {
          setExtraMargin(rect.bottom - rect.top); // Calculate extra margin and update state
        }
        foundPopup = true; // Set flag to true
        break; // Exit loop since a valid popup was found
      }
    }
  
    if (!foundPopup) {
      // If no valid popup was found, set extra margin to 0
      setExtraMargin(0);
    }
  }, [showTrifecta, showWIP, showIP, showAP, showIPAP, showIPWIP, showAPWIP, showIntro, setExtraMargin]); 



  // Track the width of the screen.
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const gRef = useRef(null);
  const divRef = useRef(null);

  const positionDiv = (screenWidth) => {
    const gElement = gRef.current;
    const divElement = divRef.current;

    if (gElement && divElement) {
      const gRect = gElement.getBoundingClientRect();
      const parentRect = gElement.parentNode.getBoundingClientRect();

      divElement.style.position = 'absolute';

      if (screenWidth < 640) {
        divElement.style.left = `${gRect.right}px`;
        divElement.style.top = `${gRect.top - 0.9*parentRect.top + 0.9*gRect.height}px`;
      } else if (screenWidth >= 640 && screenWidth < 1007) {
        divElement.style.left = `calc(50% + 180px)`;
        divElement.style.top = `380px`;
      } else {
        divElement.style.left = `600px`;
        divElement.style.top = `400px`;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    const handleResizeAndPosition = () => {
      handleResize();
      positionDiv(screenWidth);
    };
  
    window.addEventListener('resize', handleResizeAndPosition);
  
    handleResizeAndPosition(); // Call the combined function initially
  
    return () => {
      window.removeEventListener('resize', handleResizeAndPosition);
    };
  }, [screenWidth]);

  return (
    // <div className='telosChart2' style={{ paddingBottom: `${extraMargin}px` }}>
    <div className='telosChart2'>
      <IntroPopup isOpen={showIntro} onClose={closePopup} />
      <WIPPopup isOpen={showWIP} onClose={closePopup} wipGroupedResults={wipGroupedResults} />
      <IPPopup isOpen={showIP} onClose={closePopup} ipGroupedResults={ipGroupedResults} />
      <APPopup isOpen={showAP} onClose={closePopup} apGroupedResults={apGroupedResults} />
      <IPAPPopup isOpen={showIPAP} onClose={closePopup} IPAPGroupedResults={IPAPGroupedResults} />
      <IPWIPPopup isOpen={showIPWIP} onClose={closePopup} IPWIPGroupedResults={IPWIPGroupedResults} />
      <APWIPPopup isOpen={showAPWIP} onClose={closePopup} APWIPGroupedResults={APWIPGroupedResults} />
      <TrifectaPopup isOpen={showTrifecta} onClose={closePopup} trifectaGroupedResults={trifectaGroupedResults} />
      <div className="telosChart2__h1">
        The Trifecta Graph - Overlapping Results
      </div>
      <div className="telosChart2__h2">
        Please click on the areas below to see your results. Each job is also clickable and
        will take you to the O*Net description for that job.
      </div>
      <svg
        className='telosChart2__svg'
        viewBox="0 0 300 250"
        preserveAspectRatio="xMidYMid meet"
      >

        {/* WIP */}
        <g
          onClick={() => handleEllipseClick(0)}
          onMouseOver={() => handleHover(0)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 483.00,318.60
              C 483.00,280.10 398.30,248.90 293.90,248.90
                189.50,248.90 104.80,280.10 104.80,318.60
                104.80,357.10 189.50,388.30 293.90,388.30
                398.30,388.30 483.00,357.10 483.00,318.60
              " 
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-wip'
          />
          <text
            x="50%"
            y="61%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className={`
              telosChart2__ellipseText
              ${activeElement === 0 ? 'telosChart2__ellipseText-on' : 'telosChart2__ellipseText-wip'}
            `}
          >
            Values
          </text>
        </g>

        {/* IP */}
        <g
          onClick={() => handleEllipseClick(1)}
          onMouseOver={() => handleHover(1)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 468.50,343.80
              C 501.70,324.70 486.20,235.80 433.90,145.30
                381.60,54.80 312.20,-3.10 278.90,16.10
                245.70,35.20 261.20,124.10 313.50,214.60
                365.90,305.10 435.30,362.90 468.50,343.80
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-ip'
          />
          <text
            x="50%"
            y="-19%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(60 50 50)"
            className={`
            telosChart2__ellipseText
            ${activeElement === 1 ? 'telosChart2__ellipseText-on' : 'telosChart2__ellipseText-ip'}`}
          >
            Interests
          </text>
        </g>

        {/* AP */}
        <g
          onClick={() => handleEllipseClick(2)}
          onMouseOver={() => handleHover(2)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          <path
            d="
              M 119.40,343.80
              C 86.20,324.70 101.70,235.80 154.00,145.30
                206.30,54.80 275.70,-3.10 308.90,16.10
                342.10,35.20 326.60,124.10 274.30,214.60
                221.90,305.10 152.60,362.90 119.40,343.80
              "
            transform='scale(0.46) translate(32, 10)'
            className='telosChart2__ellipse telosChart2__ellipse-ap'
          />
          <text
            x="16.5%"
            y="49%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(-60 50 50)"
            className={`
            telosChart2__ellipseText
            ${activeElement === 2 ? 'telosChart2__ellipseText-on' : 'telosChart2__ellipseText-ap'}`}
          >
            Abilities
          </text>
        </g>

        {/* APIP */}
        <g
          onClick={() => handleEllipseClick(3)}
          onMouseOver={() => handleHover(3)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          <path
            fill="transparent" stroke="#6999A9" strokeWidth="1"
            d="
              M 293.90,177.10
              C 330.00,100.70 337.50,32.50 308.90,16.00
                306.80,14.80 304.60,13.90 302.20,13.30
                299.30,12.70 296.50,12.40 293.90,12.40
                291.20,12.40 288.50,12.70 285.60,13.30
                283.20,13.90 281.00,14.80 278.90,16.00
                250.30,32.60 257.90,100.70 293.90,177.10 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className={`'
              telosChart2__ellipseOverlapPath
              ${activeElement === 3 && 'telosChart2__ellipseOverlapPath-ipap'}
            `}
          />

          <text
            y="13%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className={`
            telosChart2__ellipseOverlapText
            ${activeElement === 3 ? 'telosChart2__ellipseOverlapText-on' : 'telosChart2__ellipseOverlapText-off'}`}
          >
            <tspan x='50%' dy='1.2em'>Interests</tspan>
            <tspan x='50%' dy='1.2em'>&</tspan>
            <tspan x='50%' dy='1.2em'>Abilities</tspan>
          </text>

        </g>

        {/* IPWIP */}
        <g
          onClick={() => handleEllipseClick(4)}
          onMouseOver={() => handleHover(4)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
          ref={gRef}
        >
          <path
            fill="transparent" stroke="#6788A8" strokeWidth="1"
            d="
              M 336.50,250.70
              C 384.70,319.80 439.90,360.20 468.40,343.80
                471.90,341.80 474.90,338.90 477.40,335.40
                477.60,335.10 477.80,334.80 478.00,334.50
                478.10,334.40 478.10,334.30 478.20,334.30
                481.30,329.30 483.00,324.00 483.00,318.70
                483.00,285.50 420.40,257.90 336.50,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className={`'
              telosChart2__ellipseOverlapPath
              ${activeElement === 4 && 'telosChart2__ellipseOverlapPath-ipwip'}
            `}
          />
          <text
            y="54.25%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className={`
            telosChart2__ellipseOverlapText
            ${activeElement === 4 ? 'telosChart2__ellipseOverlapText-on' : 'telosChart2__ellipseOverlapText-off'}`}
          >
            <tspan x='72.5%' dy='1.2em'>Values</tspan>
            <tspan x='72.5%' dy='1.2em'>&</tspan>
            <tspan x='72.5%' dy='1.2em'>Interests</tspan>
          </text>

        </g>

        {/* APWIP */}
        <g
          onClick={() => handleEllipseClick(5)}
          onMouseOver={() => handleHover(5)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          <path
            fill="transparent" stroke="#7C8FA9" strokeWidth="1"
            d="
              M 251.30,250.70
              C 167.30,257.80 104.70,285.50 104.70,318.60
                104.70,321.40 105.20,324.20 106.00,326.90
                108.90,334.50 113.30,340.30 119.30,343.80
                147.90,360.20 203.10,319.80 251.30,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className={`'
              telosChart2__ellipseOverlapPath
              ${activeElement === 5 && 'telosChart2__ellipseOverlapPath-apwip'}
            `}
          />
          <text
            y="54.25%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className={`
            telosChart2__ellipseOverlapText
            ${activeElement === 5 ? 'telosChart2__ellipseOverlapText-on' : 'telosChart2__ellipseOverlapText-off'}`}
          >
            <tspan x='27.5%' dy='1.2em'>Abilities</tspan>
            <tspan x='27.5%' dy='1.2em'>&</tspan>
            <tspan x='27.5%' dy='1.2em'>Values</tspan>
          </text>

        </g>

        {/* Trifecta */}
        <g
          onClick={() => handleEllipseClick(6)}
          onMouseOver={() => handleHover(6)}
          onMouseOut={handleMouseOut}
          className='telosChart2__ellipseButton'
        >
          {/* Define the linear gradient for the trifecta on hover. */}
          <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="20%" style={{ stopColor: '#910DB4' }} />
            <stop offset="50%" style={{ stopColor: '#006DB2' }} />
            <stop offset="80%" style={{ stopColor: '#008375' }} />
          </linearGradient>
          <path
            fill="transparent" stroke="white" strokeWidth="1"
            d="
              M 251.30,250.70
              C 265.00,249.50 279.20,248.90 293.80,248.90
                308.50,248.90 322.80,249.50 336.50,250.70
                328.60,239.40 320.90,227.30 313.50,214.60
                306.20,202.00 299.70,189.50 293.90,177.20
                288.10,189.50 281.50,202.10 274.30,214.60
                266.90,227.30 259.20,239.40 251.30,250.70 Z
              "
            transform='scale(0.46) translate(32, 10)'
            className={`'
              telosChart2__ellipseOverlapPath
              ${activeElement === 6 && 'telosChart2__ellipseOverlapPath-trifecta'}
            `}
          />
          <text
            y="39.5%"
            textAnchor="middle"
            dominantBaseline="middle"
            transform="rotate(0 50 50)"
            className={`
            telosChart2__ellipseOverlapText
            ${activeElement === 6 ? 'telosChart2__ellipseOverlapText-on' : 'telosChart2__ellipseOverlapText-off'}`}
          >
            <tspan x='50%' dy='1.2em'>THE</tspan>
            <tspan x='50%' dy='1.2em'>TRIFECTA</tspan>
          </text>

        </g>
      </svg>

      {/* Info button. */}
      <button
        ref={divRef}
        className="telosChart2__infoButton"
        onClick={infoClick}
        // style={{ left: position.left, top: position.top }}
      >
        <img
          src={infoButtonPic}
          alt="info-button-pic"
          className="telosChart2__infoImage"
          onMouseOver={e => e.currentTarget.src=infoButtonRolloverPic}
          onMouseLeave={e => e.currentTarget.src=infoButtonPic}
        />
      </button>

      {/* Bottom text. */}
      <div className="telosChart2__bottomTextBox">
        <div className="telosChart2__bottomText">
          *Note: Only the top portion of your jobs are shown here.
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    trifectaresults: state.firestore.ordered.trifectaresults
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTrifectaViewed: () => dispatch(updateTrifectaViewed())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'trifectaresults', doc: props.auth.uid }
    ]
  })
)(TelosChart2);

// Group jobs by category.
function groupJobs(results) {
  // Group occupations by first two digits of the O*Net code, which corresponds to the occupation group.
  const cats = results.reduce((catsSoFar, { onetCode, occupation }) => {
    const groupCode = onetCode.slice(0, 2);  // First two digits of ONet code to indicate occupation's category.
    if (!catsSoFar[groupCode]) catsSoFar[groupCode] = [];
    catsSoFar[groupCode].push({
      occupation: occupation,
      onetCode: onetCode
    });
    return catsSoFar;
  }, []);

  // Loop through 'ipCats', create array of objects where each object contains the group code and the occupations.
  const catsArray = cats.map((data, index) => {
    const obj = {
      occupations: data,
      group: index
    }
    return obj;
  });

  // Filter out based on whether the object is empty or not.
  const cleanCats = catsArray.filter(obj => obj);

  return cleanCats;
}