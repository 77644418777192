import React, { useState, useEffect } from 'react';
// import ProgressBar from '../../../../ProgressBar/ProgressBar';
import { values } from './Configs';
import variables from '../../../../../variables.module.scss';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const SAMultiChoice2 = ({count, answer, setAnswer, last, setLast}) => {

  // One line function to pad a number with a variable number of zeros.
  const zeroPad = (num, places) => String(num).padStart(places, '0')

  // Images.
  const webImage = `/images/saImages/web/Spatial Reasoning Test ${zeroPad(count + 1, 2)}.png`;
  const mobileImage = `/images/saImages/mobile/Spatial Reasoning Test m${zeroPad(count + 1, 2)}.png`;

  // If count is null, set it to 0.
  if (count === null) {
    count = 0;
  }

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // If already selected, clear it.
    const previousAnswer = answer[count].values;
    const currentAnswer = e.target.value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  const numTotalQuestions = 20;
  // const progress = Math.round((count+1)/numTotalQuestions*100*10)/10;

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView)+5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='sa-main-box'>
      {/* <div className="ap-instructions-header">
        Assessments 3: Part 3 - Three-Dimensional Space
      </div> */}
      {/* <div className='sa-instructions-header2'>
        Instructions
      </div> */}

      {/* <div className="question-sa">
        <div className="question__2d-box-sa">
          <div className="question__number-sa">
            {count+1}.
          </div> */}

          <div className="sa__content">
            <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="sa__choices" />
            
            <div className="sa__options">
              
              <div className="sa__option">
                <input
                  type="checkbox"
                  className="sa__optionButton"
                  checked={answer[count].values === values[0]}
                  name={count}
                  value={values[0]}
                  onChange={e => handleAnswer(e)}
                />
              </div>

              <div className="sa__option">
                <input
                  type="checkbox"
                  className="sa__optionButton"
                  checked={answer[count].values === values[1]}
                  name={count}
                  value={values[1]}
                  onChange={e => handleAnswer(e)}
                />
              </div>

              <div className="sa__option">
                <input
                  type="checkbox"
                  className="sa__optionButton"
                  checked={answer[count].values === values[2]}
                  name={count}
                  value={values[2]}
                  onChange={e => handleAnswer(e)}
                />
              </div>

              <div className="sa__option">
                <input
                  type="checkbox"
                  className="sa__optionButton"
                  checked={answer[count].values === values[3]}
                  name={count}
                  value={values[3]}
                  onChange={e => handleAnswer(e)}
                />
              </div>

            </div>
            <span className='sa__span'></span>
          </div>
        {/* </div> */}

      {/* </div> */}
      <div className="apMultiChoice__bottom">
        {/* <div className="apMultiChoice__progressBar">
          <ProgressBar completed={progress} bgColor='#008375' />
        </div> */}
        <div className="ap__question-count" style={{ width: '100%' }}>
          {count+1} of {numTotalQuestions} questions
        </div>
      </div>
    </div>
  )
}

export default SAMultiChoice2
