import React from 'react';
import { connect } from 'react-redux';
import { ipResultSubmit, ipCalculatedSubmit } from '../../../store/actions/submitActions';
import IPJobOverlap from './IPJobOverlap';
import { ipScoreCalculate } from '../functionalizedCalculations/ipCalculations';

const IPCalculate = ({ uid, ip, ipResultSubmit, ipCalculatedSubmit }) => {

  // console.log('IP CALCULATE');

  let rAnswers = null;
  let iAnswers = null;
  let aAnswers = null;
  let sAnswers = null;
  let eAnswers = null;
  let cAnswers = null;

  try {
    if (ip) {
      [
        rAnswers, iAnswers, aAnswers,
        sAnswers, eAnswers, cAnswers
      ] =
      ipScoreCalculate(uid, ip[0], ipResultSubmit, ipCalculatedSubmit);
    }
  } catch (error) {
    console.log('Error: ', error);
  }

  return (
    <div>
      {/* Calculating IP assessment results... */}
      {(rAnswers !== null && iAnswers !== null && aAnswers !== null &&
        sAnswers !== null && eAnswers !== null && cAnswers !== null) && (
        <IPJobOverlap uid={uid} rAnswers={rAnswers} iAnswers={iAnswers} aAnswers={aAnswers} sAnswers={sAnswers} eAnswers={eAnswers} cAnswers={cAnswers} />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    ipResultSubmit: (result) => dispatch(ipResultSubmit(result)),
    ipCalculatedSubmit: (result) =>
    dispatch(
      ipCalculatedSubmit(result)
    )
  }
}

export default connect(null, mapDispatchToProps)(IPCalculate)
