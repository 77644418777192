import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import PaymentBottom from '../paymentBottom/PaymentBottom';
import MultiGift from '../gift/MultiGift';
import './PaymentSignedOut.css';
import StudentSignedOut from './StudentSignedOut';

const laptopPic = `/images/purchaseImages/Laptop-phone.png`;
const laptopPicMobile = `/images/purchaseImages/TelosPurchasePage_Computer_96dpi.png`;
const assessmentsPic = `/images/purchaseImages/purchase-page-icons.png`;

const PaymentSignedOut = ({ setDisplay, gift, setGift, personal, setPersonal, user, auth, stripePurchase, purchasedOrder, student, setStudent }) => {
  // Check page width.
  const [view, setView] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 640) {
        setView('mobile');
      } else if (screenWidth > 640 && screenWidth < 1024) {
        setView('tablet');
      } else {
        setView('pc');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set pic types.
  let currentPic = laptopPic;
  if (view === 'mobile') {
    currentPic = laptopPicMobile;
  } else {
    currentPic = laptopPic;
  }

  // Function to run when the user clicks the 'personal' tab.
  const personalClick = (e) => {
    // if (singlePurchase) {
    // if (!purchased) {
    setPersonal(true);
    setGift(false);
    setStudent(false);
    // }
  }

  // Function to run when the user clicks the 'Gift' tab.
  const giftClick = (e) => {
    setGift(true);
    setPersonal(false);
    setStudent(false);
  }

  // Function to run when the user clicks the 'Student' tab.
  const studentClick = (e) => {
    setStudent(true);
    setGift(false);
    setPersonal(false);
  }
 
  return (
    <div className='paymentSignedOut__mainBox'>
      <div className="options">
        <img src={currentPic} alt="purchase-pic" className='options__image' />
        <div className="options__main">
          <div className="options__options">
            <button
              className={`options__select ${!personal && "options__select-off"}`}
              onClick={personalClick}
            >
              Personal Plan
            </button>
            <button
              className={`options__select ${!gift && "options__select-off"}`}
              onClick={giftClick}
            >
              Gifting
            </button>
            <button
              className={`options__select ${!student && "options__select-off"}`}
              onClick={studentClick}
            >
              Student
            </button>
            <div className="options__hr4" />
          </div>

          {personal === true &&
            <div className="options__personal">
              <div className="options__body">
                The Telos package includes access to <span>eight</span> research-backed assessments
                and your personalized results. These assessments evaluate your:
              </div>
              <img src={assessmentsPic} alt="assessments-pic" className="signedinAssessments__image2" />
              <div className="options__body">
                In addition, your results will be fed through our proprietary algorithm to determine:
              </div>

              <div className="options__body  options__body-flex">
                <div className="signedinAssessments__number">
                  1
                </div>
                <div className="options__body options__body-bold">
                  Which career paths are most likely to align with you
                </div>
              </div>

              <div className="options__body options__body-flex">
                <div className="signedinAssessments__number">
                  2
                </div>
                <div className="options__body options__body-bold">
                  Details of each career path, such as typical job requirements
                </div>
              </div>

              <div className="options__body options__body-flex">
                <div className="signedinAssessments__number">
                  3
                </div>
                <div className="options__body options__body-bold">
                  The job categories you most closely align to, in case you don't yet want to commit
                  to a specific career path
                </div>
              </div>

            </div>
          }

          {personal && (
            <div className="options__singlePay">
              <div className="signedinAssessments__price">
                ${process.env.REACT_APP_TELOS_PACKAGE_PRICE}
              </div>
              <NavLink to='/signup'>
                <button className="options__button">
                  SIGN UP TO PURCHASE
                </button>
              </NavLink>
            </div>
          )}

          {gift && (
            <div className="options__gift">
              <MultiGift />
            </div>
            // <button onClick={goToCheckout}>
            //   Purchase Gift
            // </button>
          )}

          {student && (
            <div className="options__institute">
              <StudentSignedOut />
            </div>
          )

          }
              
        </div>
      </div>
      <PaymentBottom />
    </div>
  )
}

export default PaymentSignedOut