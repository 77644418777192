import React, { useState, useEffect } from 'react';
import './HomeBottom.css';
import { NavLink } from 'react-router-dom';

const adPic = `images/homeImages/Telos_HomePage_Bottom_11-27-23_72dpi.png`;
const adPicMobile = `images/homeImages/TelosHOME_bottom_72dpi.png`;

const HomeBottom = () => {
  // Check page width.
  const [view, setView] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 640) {
        setView('mobile');
      } else if (screenWidth > 640 && screenWidth < 1024) {
        setView('tablet');
      } else {
        setView('pc');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set pic types.
  let currentPic = adPic;
  if (view === 'mobile') {
    currentPic = adPicMobile;
  } else {
    currentPic = adPic;
  }

  return (
    <div className='homeBottom'>
      <img
        src={currentPic}
        alt='benefits-pic'
        className='homeBottom__pic'
      />
      <NavLink to='/signup'>
        <button
          className="homeBottom__button homeBottom__button-button1"
        />
      </NavLink>
      <NavLink to='/signup'>
        <button
          className="homeBottom__button homeBottom__button-button2"
        />
      </NavLink>
      <NavLink to='/signup'>
        <button
          className="homeBottom__button homeBottom__button-button3"
        />
      </NavLink>
      <div className='homeBottom__greenBar homeBottom__greenBar-bar1' />
      <div className='homeBottom__greenBar homeBottom__greenBar-bar2' />
    </div>
  )
}

export default HomeBottom