import firebase from '../../../config/fbConfig.js';
// import { rescale } from '../Functions.js';
import corrcoeff from '../CalcCorrCoef.js';

export const ipScoreCalculate = (uid, ip, ipResultSubmit, ipCalculatedSubmit) => {
  // console.log('IP CALCULATE');

  let rAnswers = null;
  let iAnswers = null;
  let aAnswers = null;
  let sAnswers = null;
  let eAnswers = null;
  let cAnswers = null;

  try {    
    // Map values of the IP test to an array called 'ipValues'.
    let ipData;
    if (ip) {
      ipData = ip.ipAnswers;
    
      let ipValues = [];
      let count;
      for(let i = 0; i < 60; i ++){
        count = i;
        ipValues.push({
          id: ipData[count].id,
          values: ipData[count].values
        });
      }

      // Convert answers into an array, which will retain its order.
      let riasecAnswers = [];
      let matchId;
      let tempData;
      for(let i = 0; i < ipValues.length; i++) {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = ipValues.map(function(item) {
          let searchIndex = i;
          if (item.id === searchIndex) {
            return item.values;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);
        if (tempData === 'Strongly Like') {
          riasecAnswers[i] = 4;
        } else if (tempData === 'Like') {
          riasecAnswers[i] = 3;
        } else if (tempData === 'Unsure') {
          riasecAnswers[i] = 2;
        } else if (tempData === 'Dislike') {
          riasecAnswers[i] = 1;
        } else if (tempData === 'Strongly Dislike') {
          riasecAnswers[i] = 0;
        }
      }

      // RIASEC questions happen to be ordered in a wrap-around style.
      const rList = [0, 1, 12, 13, 24, 25, 36, 37, 48, 49];     // List of 'Realistic' question positions.
      const iList = [2, 3, 14, 15, 26, 27, 38, 39, 50, 51];     // List of 'Investigative' question positions.
      const aList = [4, 5, 16, 17, 28, 29, 40, 41, 52, 53];     // List of 'Artistic' question positions.
      const sList = [6, 7, 18, 19, 30, 31, 42, 43, 54, 55];     // List of 'Social' question positions.
      const eList = [8, 9, 20, 21, 32, 33, 44, 45, 56, 57];     // List of 'Enterprising' question positions.
      const cList = [10, 11, 22, 23, 34, 35, 46, 47, 58, 59];   // List of 'Conventional' question positions.

      // Add up the score for each category. Minimum is 0, maximum is 40.
      let rCheck;
      let iCheck;
      let aCheck;
      let sCheck;
      let eCheck;
      let cCheck;
      for (let i = 0; i < riasecAnswers.length; i++) {
        rCheck = rList.indexOf(i);
        iCheck = iList.indexOf(i);
        aCheck = aList.indexOf(i);
        sCheck = sList.indexOf(i);
        eCheck = eList.indexOf(i);
        cCheck = cList.indexOf(i);
        if (rCheck > -1) {
          rAnswers = rAnswers + riasecAnswers[i];
        }
        if (iCheck > -1) {
          iAnswers = iAnswers + riasecAnswers[i];
        }
        if (aCheck > -1) {
          aAnswers = aAnswers + riasecAnswers[i];
        }
        if (sCheck > -1) {
          sAnswers = sAnswers + riasecAnswers[i];
        }
        if (eCheck > -1) {
          eAnswers = eAnswers + riasecAnswers[i];
        }
        if (cCheck > -1) {
          cAnswers = cAnswers + riasecAnswers[i];
        }
      }
    }

    if (rAnswers !== null && iAnswers !== null && aAnswers !== null &&
        sAnswers !== null && eAnswers !== null && cAnswers !== null) {
          // Submit data to Firebase.
          ipResultSubmit({
            rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers, uid
          });
    }

    // Set 'ipCalculated' to true in user's document.
    ipCalculatedSubmit(uid);  // Sends calculated = true boolean to Firestore.

    return [rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers];

  } catch (error) {
    console.log('Error: ', error);
  }
}

// Function to calculate the user's IP jobs.
export const ipJobOverlapCalculate = async (
  uid, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers
) => {
  // Function contents go here.
  try {
    if (rAnswers !== null && iAnswers !== null && aAnswers !== null && sAnswers !== null && eAnswers !== null && cAnswers !== null) {
      // Only call functions and pass data.

      // Generate an array of O*Net doc IDs.
      const docIDs = await generateOnetDocIds();
      const filledDocs = await fetchAllOnetDocs(docIDs, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers);

      const sortedResults = filledDocs.sort((a, b) => a.calcOutput > b.calcOutput ? 1 : -1);

      sortedResults.reverse().map((element, index) => {
        element.rank = index;   // Add 'rank' element to the object.
        return element;
      });

      await submitSortedResults(sortedResults, uid);

      return sortedResults;
    }
  } catch (error) {
    console.log('Error: ', error);
  }
}

// Fetch a single O*Net doc from the Firebase database.
async function fetchOnetDoc(docId) {
  const onetDoc = await firebase.firestore().collection('onetData').doc(docId).get();
  const onetDocData = await onetDoc.data();
  return onetDocData;
}

// Submit the top 'resultCount' number of results to Firebase.
async function submitSortedResults(sortedResults, uid) {
  // const connection = await firebase.firestore().collection('apresults').doc(auth.uid).set({
  await firebase.firestore().collection('ipresults').doc(uid).set({
      ipSortedResults: sortedResults
  }, { merge: true });

  // Add a boolean to the user's document called 'ipJobsCalculated' and set it to true. This is used
  // in AllResults.js to determine whether to run the overlap calculations.
  await firebase.firestore().collection('users').doc(uid).set({
      ipJobsCalculated: true
    }, { merge: true });
}

// Generate O*Net doc IDs.
async function generateOnetDocIds() {
  //E000 - E872
  const onetDocIds = [];
  const maxDocCount = 872;
  for (let i = 0; i < maxDocCount; i++) {
      const docId = `E${String(i).padStart(3, '0')}`;
      onetDocIds.push(docId);
  }
  const data = onetDocIds;

  return data;
}

// Calculate the correlation coefficient for each occupation.
async function calcDoc(onetDoc, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers) {
  // Define a map (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Addition_assignment).
  // This maintains its order, unlike an array and unlike an object.
  const ipDataMap = new Map();

  ipDataMap.set("ipR",{
      onetVal: onetDoc.ipRealistic,
      userVal: rAnswers
  })
  ipDataMap.set("ipI",{
      onetVal: onetDoc.ipInvestigative,
      userVal: iAnswers
  })
  ipDataMap.set("ipA",{
      onetVal: onetDoc.ipArtistic,
      userVal: aAnswers
  })
  ipDataMap.set("ipS",{
      onetVal: onetDoc.ipSocial,
      userVal: sAnswers
  })
  ipDataMap.set("ipE",{
      onetVal: onetDoc.ipEnterprising,
      userVal: eAnswers
  })
  ipDataMap.set("ipC",{
      onetVal: onetDoc.ipConventional,
      userVal: cAnswers
  });

  // console.log(ipDataMap);

  // Calculate correlation coefficient.
  const ipCorrCoeff = corrcoeff(ipDataMap);

  // Return correlation coefficient.
  return ipCorrCoeff;
}

// Calculate the matching score.
async function matchCalc(calcOutput) {
  // // 'calcOutput' is the correlation coefficient on [-1, 1] and
  // // needs to be rescaled to [0, 1].
  // const originalRange = [-1, 1];
  // const desiredRange = [0, 100];
  // const matchScore = rescale(originalRange, desiredRange, calcOutput);

  // Convert ipArrayCalcOutput to z-score.
  const ipMean = -0.077131;   // Mean IP correlation coefficient based on beta tester results.
  const ipDev = 0.466503;     // Standard deviation of the IP correlation coefficient based on beta tester results.
  const ipZScore = (calcOutput - ipMean)/ipDev;  // Calculate IP Z-score.
  const ipPercentile = GetZPercent(ipZScore);           // Calculate IP percentile.

  const matchScore = ipPercentile;

  return matchScore;
}

// Fetch all O*Net docs.
async function fetchAllOnetDocs(docIdArray, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers) {
  try {
    let onetDoc = null;
    const returnVal = await Promise.all(docIdArray.map(async function stuff (id) {
      onetDoc = await fetchOnetDoc(id);
      if (!onetDoc) {
        console.error("Encountered error fetching doc, ID: ", id);
        return null;
      }
      if (!onetDoc.UID) {
        console.error("Encountered error: Doc does not have UID, Doc: ", onetDoc);
        return null;
      }
      if (onetDoc.UID !== id) {
          console.error({
            message: "Encountered error: the fetched doc id does not match the input id, Doc: ", 
            onetDoc,
            id
          });
          return null;
      }

      // Do calculation.
      const calcOutput = await calcDoc(onetDoc, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers);

      // Run match score calculation.
      const matchScore = await matchCalc(calcOutput);
      
      return {
        UID: onetDoc.UID,
        calcOutput: calcOutput,
        matchScore: matchScore,
        occupation: onetDoc.occupation,
        onetCode: onetDoc.code,
        jobZone: onetDoc.jobZone,
        brightOutlook: onetDoc.brightOutlook
      }            
    }));

    const filteredVals = returnVal.filter((element)=>element!==null);

    return filteredVals;
  }
  catch(error) {
    console.error("Encountered error processing onet docs: ", error);
  }
  return []
}

// Function to convert Z-score to percentile.
function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);

  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
}