import React from 'react';
import StronglyDislike from '../../../img/face-5@1x.png';
import Dislike from '../../../img/face-4@1x.png';
import Unsure from '../../../img/face-3@1x.png';
import Like from '../../../img/face-2@1x.png';
import StronglyLike from '../../../img/face-1@1x.png';
import './IPAssessment.css';
import { questions, answerOptions } from './Configs';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const IPMultiChoice = ({ count, answer, setAnswer, last, setLast }) => {

  const handleAnswer = (e) => {
    const { name, value } = e.target;

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  // Current 12 questions.
  const currentQuestions = questions.slice(count*12,count*12+12);
  
  return (
    <table className='ipass__table'>
      <thead>
        <tr className='ipass__headerRow'>
          <th className='ipass__numberColumn'></th>
          <th className='ipass__tableHeader'>
            <img src={StronglyDislike} alt='strongly-dislike' className='ipass__face' />
            <div className='ipass__faceText'>Strongly Dislike</div>
          </th>
          <th className='ipass__tableHeader'>
            <img src={Dislike} alt='dislike' className='ipass__face' />
            <div className='ipass__faceText'>Dislike</div>
          </th>
          <th className='ipass__tableHeader'>
            <img src={Unsure} alt='unsure' className='ipass__face' />
            <div className='ipass__faceText'>Unsure</div>
          </th>
          <th className='ipass__tableHeader'>
            <img src={Like} alt='like' className='ipass__face' />
            <div className='ipass__faceText'>Like</div>
          </th>
          <th className='ipass__tableHeader'>
            <img src={StronglyLike} alt='strongly-like' className='ipass__face' />
            <div className='ipass__faceText'>Strongly Like</div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          currentQuestions.map((question, index) => {
            const isOdd = index % 2;
            let prevAnswer;
            if (index > 0) {
              prevAnswer = answer[count*12 + index-1].values;
            } else {
              prevAnswer = 'First';
            }
            
            let disabled = false;
            if (prevAnswer) {
              disabled = false;
            } else {
              disabled = true;
            }

            return (
              <tr
                className="ipass__tableBodyRow"
                style={{ backgroundColor: isOdd ? "#DCF0FB" : "#C9E9F8" }}
                key={index}
              >
                {disabled ? (
                  <td className="ipass__numberColumn ipass__numberColumn-disabled">
                    {count*12 + index+1}
                  </td>
                ) : (
                  <td className="ipass__numberColumn">
                    {count*12 + index+1}
                  </td>
                )}
                <td className='ipass__tableBody'>
                  <input
                    type='radio'
                    disabled={disabled}
                    checked={answer[count*12+index].values === answerOptions[0]}
                    value={answerOptions[0]}
                    name={count*12 + index}
                    onChange={e => handleAnswer(e)}
                    className='ipass__radioButton'
                  />
                </td>
                <td className='ipass__tableBody'>
                  <input
                    type='radio'
                    disabled={disabled}
                    checked={answer[count*12+index].values === answerOptions[1]}
                    value={answerOptions[1]}
                    name={count*12 + index}
                    onChange={e => handleAnswer(e)}
                    className='ipass__radioButton'
                  />
                </td>
                <td className='ipass__tableBody'>
                  <input
                    type='radio'
                    disabled={disabled}
                    checked={answer[count*12+index].values === answerOptions[2]}
                    value={answerOptions[2]}
                    name={count*12 + index}
                    onChange={e => handleAnswer(e)}
                    className='ipass__radioButton'
                  />
                </td>
                <td className='ipass__tableBody'>
                  <input
                    type='radio'
                    disabled={disabled}
                    checked={answer[count*12+index].values === answerOptions[3]}
                    value={answerOptions[3]}
                    name={count*12 + index}
                    onChange={e => handleAnswer(e)}
                    className='ipass__radioButton'
                  />
                </td>
                <td className='ipass__tableBody'>
                  <input
                    type='radio'
                    disabled={disabled}
                    checked={answer[count*12+index].values === answerOptions[4]}
                    value={answerOptions[4]}
                    name={count*12 + index}
                    onChange={e => handleAnswer(e)}
                    className='ipass__radioButton'
                  />
                </td>
                {disabled ? (
                  <td className='ipass__tableBodyText ipass__tableBodyText-disabled'>
                    {question}
                  </td>
                ) : (
                  <td className='ipass__tableBodyText'>
                    {question}
                  </td>
                )}
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default IPMultiChoice
