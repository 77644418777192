import React, { useState, useEffect } from 'react';
import firebase from '../../../../config/fbConfig';
import TableHead from './TableHead';
import TableBody from './TableBody';
import Popup from './Popup';
import { submitFreeOrder } from '../../../../store/actions/orderActions';
import { connect } from "react-redux";
import { saveAs } from 'file-saver';

// Function to create Javascript date to a MM/DD/yyyy string.
// Source: https://stackoverflow.com/questions/11591854/format-date-to-mm-dd-yyyy-in-javascript
function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return month + '/' + day + '/' + year;
}

const Table = ({ submitFreeOrder }) => {

  // For popup.
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [ userData, setUserData ] = useState({
    firstName: null,
    lastName: null
  });

  const handleOpenPopup = (data) => {
    setIsPopupOpen(true);
    setUserData(data);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setUserData({
      firstName: null,
      lastName: null
    });
  };

  const freeUpgrade = () => {
    try {
      const configOrder = {
        orderTotal: 0,
        orderItems: 'Base Package',
        uid: userData.UID,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        signupDate: userData.signupDate,
        orderCreatedDate: new Date()
      }
  
      // Submit order.
      submitFreeOrder(configOrder);

      // Close popup.
      setIsPopupOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }

  }

  const [ tableData, setTableData ] = useState([]);

  const [ orderUIDs, setOrderUIDs ] = useState([]);


  // Get UIDs of all users who are registered with our internal 'orders'.
  useEffect(() => {
    try {
      async function checkOrders() {
        const orders = await firebase.firestore().collection('orders').get();
        return orders.docs.map(doc => doc.id);
      }

      const fetchOrders = async () => {
        const output = await checkOrders();
        setOrderUIDs(output);
      }
      fetchOrders();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // // Get UIDs of all users who paid through Stripe.
  // // Check each document in the 'customers' collection.
  // const [ customerUIDs, setCustomerUIDs ] = useState([]);
  // const [ paidUIDs, setPaidUIDs ] = useState([]);
  // useEffect(() => {
  //   try {
  //     async function CheckCustomers() {
  //       const customers = await firebase.firestore().collection('customers').get();
  //       return customers.docs.map(doc => doc.id);
  //     }

  //     const fetchCustomers = async () => {
  //       const output = await CheckCustomers();
  //       setCustomerUIDs(output);
  //     }
      
  //     // Get an array of all UIDs of users who looked at the Stripe checkout page.
  //     fetchCustomers()
  //   } catch (error) {
  //     console.log('Error: ', error);
  //   }
  // }, []);

  // // Check each subcollection in the 'customers' collection to see if a user paid.
  // useEffect(() => {
  //   try {
  //     customerUIDs.map((val, ind) => {
  //       const data = await firebase.firestore().collection('customers').doc(val)
  //     })
      
      // const userData = [];
      // customerUIDs.forEach((doc) => userData.push({ ...doc.data(), id: doc.id}))
      // // const db = firebase.firestore();
      // // return db.collection('customers').onSnapshot((snapshot) => {
      // //   const userData = [];
      // //   snapshot.forEach((doc) => userData.push({ ...doc.data(), id: doc.id }));
      // //   console.log(userData);
      // // })
      // console.log(userData);
  //   } catch (error) {
  //     console.log('Error: ', error);
  //   }
  // }, [customerUIDs]);

  // // Get UIDs of all users who paid through Stripe.
  // async function checkCustomers(currentUserID) {
  //   try {
  //     let stripePurchase = false;
  //     await firebase.firestore().collection('customers')
  //     .doc(currentUserID).collection('payments').get().then(snapshot => {
  //       if (snapshot.empty) {
  //         stripePurchase = false;
  //       } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
  //         snapshot.forEach(paymentDoc => {
  //           let status = paymentDoc.data().status;
  //           const items = paymentDoc.data().items;
  //           // if (items && status === 'succeeded') {
  //           if (status === 'succeeded') {
  //             items.forEach(item => {
  //               if (item.description === 'Deluxe Package') {
  //                 // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
  //                 // product in Stripe.
  //                 stripePurchase = true;
  //               } else {
  //                 stripePurchase = false;
  //                 // setStripePurchase(false);
  //               }
  //             })
  //           }
  //         })
  //       }
  //     })
  //     return stripePurchase;
  //   } catch (error) {
  //     console.log('Error: ', error);
  //   }
  // }

  async function checkCustomers(currentUserID) {
    try {
      let stripePurchase = false;
      const paymentsSnapshot = await firebase.firestore()
        .collection('customers')
        .doc(currentUserID)
        .collection('payments')
        .get();
  
      if (!paymentsSnapshot.empty) {
        stripePurchase = paymentsSnapshot.docs.some(paymentDoc => {
          const status = paymentDoc.data().status;
          const items = paymentDoc.data().items;
          return status === 'succeeded' && items.some(item => item.description === 'Deluxe Package');
        });
      }
  
      return stripePurchase;
    } catch (error) {
      console.log('Error: ', error);
      throw error; // Rethrow the error to be handled in the calling function if needed.
    }
  }

  useEffect(() => {
    try {
      const db = firebase.firestore();
      return db.collection('users').onSnapshot((snapshot) => {
        const userData = [];
        snapshot.forEach((doc) => userData.push({ ...doc.data(), id: doc.id }));

        // console.log(userData);

        // Set all data.
        setTableData(
          userData.map((element) => {
            let currentUID = null;
            let currentFirstName = null;
            let currentLastName = null;
            let currentSignUpDate = null;
            let currentIPDate = null;
            let currentWIPDate = null;
            let currentARDate = null;
            let currentSADate = null;
            let currentVADate = null;
            let currentCMDate = null;
            let currentCPDate = null;
            let currentFPDate = null;
            let currentResultsCalculated = null;
            let currentEmail = null;
            let currentStatus = null;

            // Get user's UID.
            if (element.id) {
              // currentUID = element.userId;
              currentUID = element.id;
            } else {
              currentUID = null;
            }

            // Get user's first name.
            if (element.firstName) {
              currentFirstName = element.firstName;
            } else {
              currentFirstName = null;
            }

            // Get user's last name.
            if (element.lastName) {
              currentLastName = element.lastName;
            } else {
              currentLastName = null;
            }

            // Get user's sign-up date.
            if (element.signupDate) {
              const output = new Date(element.signupDate.seconds * 1000);
              currentSignUpDate = getFormattedDate(output);
            } else {
              currentSignUpDate = null;
            }

            // Get user's IP complete date.
            if (element.ipDate) {
              const output = new Date(element.ipDate.seconds * 1000);
              currentIPDate =  getFormattedDate(output);
            } else {
              currentIPDate = 'Incomplete';
            }

            // Get user's WIP complete date.
            if (element.wipDate) {
              const output = new Date(element.wipDate.seconds * 1000);
              currentWIPDate =  getFormattedDate(output);
            } else {
              currentWIPDate = 'Incomplete';
            }

            // Get user's AR complete date.
            if (element.arDate) {
              const output = new Date(element.arDate.seconds * 1000);
              currentARDate =  getFormattedDate(output);
            } else {
              currentARDate = 'Incomplete';
            }

            // Get user's SA complete date.
            if (element.saDate) {
              const output = new Date(element.saDate.seconds * 1000);
              currentSADate =  getFormattedDate(output);
            } else {
              currentSADate = 'Incomplete';
            }

            // Get user's VA complete date.
            if (element.vaDate) {
              const output = new Date(element.vaDate.seconds * 1000);
              currentVADate =  getFormattedDate(output);
            } else {
              currentVADate = 'Incomplete';
            }

            // Get user's CM complete date.
            if (element.cmDate) {
              const output = new Date(element.cmDate.seconds * 1000);
              currentCMDate =  getFormattedDate(output);
            } else {
              currentCMDate = 'Incomplete';
            }

            // Get user's CP complete date.
            if (element.cpDate) {
              const output = new Date(element.cpDate.seconds * 1000);
              currentCPDate =  getFormattedDate(output);
            } else {
              currentCPDate = 'Incomplete';
            }

            // Get user's FP complete date.
            if (element.fpDate) {
              const output = new Date(element.fpDate.seconds * 1000);
              currentFPDate =  getFormattedDate(output);
            } else {
              currentFPDate = 'Incomplete';
            }

            // Get user's results calculated.
            if (element.apCalculated === true && element.ipCalculated === true && element.wipCalculated === true) {
              currentResultsCalculated = 'Yes';
            } else {
              currentResultsCalculated = 'No';
            }

            // Get user's email.
            if (element.email) {
              currentEmail = element.email;
            } else {
              currentEmail = null;
            }

            // Function to check if a user paid through Stripe or not.
            async function currentStripeCheck(currentUserID) {
              try {
                const stripePurchase = await checkCustomers(currentUserID);
                return stripePurchase;
              } catch (error) {
                console.log('Error: ', error);
              }
            }

            // Set the default status of the account.
            let accountStatus = 'Unpaid';
            // Check if user's UID matches any in the 'orders' collection.
            const ordersMatch = orderUIDs.includes(element.id);
            if (ordersMatch === true) {
              accountStatus = 'Free';
            }

            // Check if user paid through Stripe.
            currentStripeCheck(element.id)
              .then(stripePurchase => {
                // console.log(stripePurchase);
                if (stripePurchase === true) {
                  accountStatus = 'Stripe';
                }
              })
              .catch(error => {
                console.log('Error: ', error);
              })

            // Call function.
            // console.log(accountStatus);

            // Get user's account status, i.e. Unpaid, Paid, Free, Early.
            // Unpaid is a user who has signed up but not made a purchase.
            // Paid is a user who has made a purchase.
            // Free is a user who received a free account, either from early
            // access or by us granting it.
            currentStatus = accountStatus;
            // if (element.accountStatus) {
            // } else {
            //   currentStatus = 'Unpaid';
            // }

            return {
              UID: currentUID,
              firstName: currentFirstName,
              lastName: currentLastName,
              signupDate: currentSignUpDate,
              ipDate: currentIPDate,
              wipDate: currentWIPDate,
              arDate: currentARDate,
              saDate: currentSADate,
              vaDate: currentVADate,
              cmDate: currentCMDate,
              cpDate: currentCPDate,
              fpDate: currentFPDate,
              resultsCalculated: currentResultsCalculated,
              email: currentEmail,
              status: currentStatus
            }
          })
        );
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orderUIDs]);

  const columns = [
    { label: 'First Name', accessor: 'firstName', sortable: true },
    { label: 'Last Name', accessor: 'lastName', sortable: true },
    { label: 'Email', accessor: 'email', sortable: false },
    { label: 'Signup Date', accessor: 'signupDate', sortable: true },
    { label: 'Status', accessor: 'status', sortable: true }
  ];

  const handleSorting = (sortField, sortOrder) => {
    let sorted = [...tableData];
    if (sortField) {
      if (sortField === 'signupDate') {
        // If sorting by 'signupDate', use a specialized sorting logic for dates
        sorted = [...tableData].sort((a, b) => {
          // Convert 'signupDate' values to Date objects for comparison
          const dateA = new Date(a.signupDate);
          const dateB = new Date(b.signupDate);

          // Check if the date values are valid
          if (isNaN(dateA) || isNaN(dateB)) return 0;

          // Compare dates directly, taking into account sortOrder
          return (dateA - dateB) * (sortOrder === 'asc' ? 1 : -1);
        });
      } else {
        sorted = [...tableData].sort((a, b) => {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          return (
            a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        });
      }
      setTableData(sorted);
    }
  };

  // Function to generate and save the file when a user takes a specific
  // action, like clicking a button.
  const generateAndDownloadFile = (content, filename) => {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, filename);
  };  

  // Download all user emails.
  const emailDownload = async () => {
    try {

      // Pull relevant user info from tableData.
      const arrayData = tableData.map(element => [
        element.firstName,
        element.lastName,
        element.email
      ]);

      // Replace commas with tabs.
      const tabSeparatedData = arrayData.map(
        row => row.join('\t')
      ).join('\n');

      // Get header names.
      const headerStrings = [
        'firstName', 'lastName', 'email'
      ];

      // Save as column text file.
      const combinedData = [headerStrings.join('\t') + '\n'].concat(tabSeparatedData);

      // Put header on separate line.
      const finalData = combinedData.join('\n');

      // Name the file.
      const filename = 'telos_user_info.txt';
      
      // Save as column text file.
      generateAndDownloadFile(finalData, filename);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  return (
    <>
      <button className = 'table__button' onClick={emailDownload}>
        Download User Info
      </button>
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} freeUpgrade={freeUpgrade}>
        <h2>Would you like to upgrade <b>{userData.firstName} {userData.lastName}'s</b> account for free?</h2>
        <p>This action can not be undone.</p>
      </Popup>
      <table className='table'>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, tableData, handleOpenPopup, handleClosePopup }} />
      </table>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
	return {
		submitFreeOrder: (order) => dispatch(submitFreeOrder(order))
	}
}

export default connect(null, mapDispatchToProps)(Table)