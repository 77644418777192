import React, { useState, useEffect } from 'react';
import './IPExplanationVideo.css';
import ReactPlayer from 'react-player';
import { WIPDisplayStates } from "./Configs";

const IPExplanationVideo = ({ setDisplay }) => {
  const playThreshold = 85;     // Minimum percentage of video to be played before user can go to next page.
  const [ played, setPlayed ] = useState(0);
  const [ counter, setCounter ] = useState(100);
  const [ complete, setComplete ] = useState(false);
  const [ playing, setPlaying ] = useState(true);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    } else {
      setComplete(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter, setCounter]);

  const onDuration = (e) => {
    setCounter(e);
  }
 
  const onProgress = (e) => {
    setPlayed(e.played*100);

    // Pause the video with one second left to avoid showing related videos.
    if (e.playedSeconds > 74) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  }

  return (
    <div className='ipVideo'>
      <div className="ipVideo__video">
        <ReactPlayer
          url="https://youtu.be/82YltqY0Yto"
          controls={true}
          playing={playing}
          width='100%'
          height='100%'
          onDuration={onDuration}
          onProgress={onProgress}
        />
      </div>
      <div className="ipVideo__bottom">
        <div className="ipVideo__text">
          Because these instructions are important for your ability to complete the assessment,
          the next button will be disabled until the instruction video finishes.
        </div>
        <button
          type='button'
          className={"ipVideo__button " + ((played < playThreshold || !complete) ? "ipVideo__button-disabled" : "")}
          onClick={() => {
            setDisplay(WIPDisplayStates.ASSESSMENT);
          }}
          disabled={(played < playThreshold) || (!complete)}
          >
          Next
        </button>
      </div>
    </div>
  )
}

export default IPExplanationVideo
