import React from 'react';
import Popup from 'reactjs-popup';
import './ReadyPopup.css';

// This is the component for the popup asking the user if they are ready to continue.
// onClick = the function used to progress to the next page
// readyDestination = the variable for the desired next page
// [ open, setOpen ] = the state variable used to open or close the popup

export const ReadyPopup = ({ onClick, readyDestination, open, setOpen }) => {
  const closeModal = () => setOpen(false);

  // Close popup.
  const popupClick = (e) => {
    setOpen(false);
  }

  return (
    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
      <div className='readyPopup'>
        <div className='readyPopup__popup'>
          <button className='readyPopup__close' onClick={popupClick}>
            &times;
          </button>
          <div className='readyPopup__primary' onClick={closeModal}>
            Are You Sure?
          </div>
          <div className="readyPopup__secondary">
            <button
              className='readyPopup__ready'
              onClick={() => {
                onClick(readyDestination);
              }}
            >
              I'm Ready!! Go Now {'>>'}
            </button>
            <button
              onClick={() => setOpen(o => !o)}
              className='readyPopup__wait'
            >
              No. I'll wait until later.
            </button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

// export default ReadyPopup