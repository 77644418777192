import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../config/fbConfig';
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [ email, setEmail ] = useState("");
    const [ emailHasBeenSent, setEmailHasBeenSent ] = useState(false);
    const [ error, setError ] = useState(null);

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        firebase.auth()
        .sendPasswordResetEmail(email)
        .then(() => {
            setEmailHasBeenSent(true);
            // setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
            console.log('Email has been sent.');
        })
        .catch(() => {
            setError("We cannot find your email.");
        });
    }

  return (
    <div className='forgotpassword'>
      <div className="forgotpassword__title">
        Forgot Password
      </div>
      <div className="forgotpassword__subtitle">
        Enter your email and we'll send<br />
        you a link to reset your password.
      </div>
      <form
          action=""
          onSubmit={handleSubmit}
          className="forgotpassword__form"
      >
        <div>
          {emailHasBeenSent && (
            <div className="forgotpassword__message forgotpassword__message-good">
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div className="forgotpassword__message">
              {error}
            </div>
          )}
        </div>
        <input
          type="email"
          name="userEmail"
          id="userEmail"
          value={email}
          placeholder="Email"
          onChange={onChangeHandler}
          className="forgotpassword__input"
          style= {{ border: error ? 'red 1px solid' : '' }}
        />
        <button
          className="forgotpassword__button"
        >
          Email me a reset link
        </button>
      </form>
      <div className='forgotpassword__signup'>
        Don't have an account?&nbsp;
        <Link
        to ="/signup"
        className="forgotpassword__signup forgotpassword__signup-link"
        >
          Sign up
        </Link>
      </div>
      <Link to='/signin' className="forgotpassword__signin">
        {'<'} Back to Login
      </Link>
    </div>
  );
};

export default ForgotPassword
