import React from 'react';
import { connect } from 'react-redux';
import { apResultSubmit, apCalculatedSubmit } from '../../../store/actions/submitActions';
import APJobOverlap from './APJobOverlap2';
import { apScoreCalculate } from '../functionalizedCalculations/APCalculations';

// const APCalculate = ({ uid, ar, cm, cp, fp, sa, va, apResultSubmit, apCalculatedSubmit }) => {
const APCalculate = ({ uid, ar, cm, cp, fp, sa, va, apResultSubmit, apCalculatedSubmit }) => {
  // Set up percentile variables.
  let arPercentile = null;
  let vaPercentile = null;
  let saPercentile = null;
  let cmPercentile = null;
  let cpPercentile = null;
  let fpPercentile = null;

  // console.log('AP CALCULATE');

  try {
    if (ar && cm && cp && fp && sa && va) {
      [
        arPercentile, vaPercentile, saPercentile,
        cmPercentile, cpPercentile, fpPercentile
      ] =
      apScoreCalculate(
        uid, ar[0], va[0], sa[0], cm[0], cp[0], fp[0],
        apResultSubmit, apCalculatedSubmit
      );
    }
  } catch (error) {
    console.log('Error: ', error);
  }


  return (
    <div>
      {/* Calculating AP assessment results... */}
      {
        (
          arPercentile !== null && vaPercentile !== null && saPercentile !== null &&
          cmPercentile !== null && cpPercentile !== null && fpPercentile !== null
        ) && (
          <APJobOverlap
            uid={uid}
            arPercentile={arPercentile}
            vaPercentile={vaPercentile}
            saPercentile={saPercentile}
            cmPercentile={cmPercentile}
            cpPercentile={cpPercentile}
            fpPercentile={fpPercentile}
          />
        )
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apResultSubmit: (result) => dispatch(apResultSubmit(result)),
    apCalculatedSubmit: (result) => dispatch(apCalculatedSubmit(result))
  }
}

export default connect(null, mapDispatchToProps)(APCalculate)