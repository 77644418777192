import React, { useState, useEffect } from 'react';
import GridTableHead from './GridTableHead';
import GridTableBody from './GridTableBody';
import GridTablePagination from './GridTablePagination';
import './GridTable.css';

const GridTable = ({ apresults, ipresults, wipresults, trifectaresults, view }) => {
  
  // Create state variable for data to go into the table.
  const [ tableData, setTableData ] = useState([]); 

  useEffect(() => {
    try {
      // Get all AP sorted results.
      let apSortedResults = null;
      if (apresults) {
        if (apresults[0]) {
          apSortedResults = apresults[0].apSortedResults.map(item => {
            const { matchScore, ...rest } = item; // Destructure 'matchScore' and capture the rest of the properties.
            return { apMatchScore: matchScore*100, ...rest }; // Change 'matchScore' to 'apMatchScore'.
          });
        }
      }

      // Get all IP sorted results.
      let ipSortedResults = null;
      if (ipresults) {
        if (ipresults[0]) {
          ipSortedResults = ipresults[0].ipSortedResults.map(item => {
            const { matchScore, ...rest } = item;
            return { ipMatchScore: matchScore*100, ...rest };
          });
        }
      }

      // Get all WIP sorted results.
      let wipSortedResults = null;
      if (wipresults) {
        if (wipresults[0]) {
          wipSortedResults = wipresults[0].wipSortedResults.map(item => {
            const { matchScore, ...rest } = item;
            return { wipMatchScore: matchScore*100, ...rest };
          });
        }
      }
      
      // Get all trifecta sorted results.
      let trifectaSortedResults = null;
      if (trifectaresults) {
        if (trifectaresults[0]) {
          trifectaSortedResults = trifectaresults[0].trifectaResults.map(item => {
            const { matchScore, ...rest } = item;
            return { trifectaMatchScore: matchScore*100, ...rest };
          });
        }
      }

      // Create full array.
      if (apSortedResults !== null && ipSortedResults !== null &&
        wipSortedResults !== null && trifectaSortedResults !== null) {
        // Function to handle each score type to merge in (e.g., IP, WIP, and trifecta).
        const mergeScores = (baseArray, scoreArray, scoreKey) => {
          return baseArray.map(obj1 => {
            const obj2 = scoreArray.find(obj2 => obj2.onetCode === obj1.onetCode);
        
            if (obj2) {
              return { ...obj1, [scoreKey]: parseFloat(obj2[scoreKey]).toFixed(1) };
            } else {
              return obj1;
            }
          });
        };
        
        // Update fullArray with specific AP sorted result elements.
        const fullArray = trifectaSortedResults.map(
          ({ UID, jobZone, trifectaMatchScore, occupation: jobName, onetCode, brightOutlook, fitScore, careerName, careerCode, careerColor }) => ({
            UID,
            jobZone,
            trifectaMatchScore: parseFloat(trifectaMatchScore).toFixed(1),
            jobName,
            onetCode,
            brightOutlook,
            fitScore,
            careerName,
            careerCode,
            careerColor
          })
        );
        
        // Define the keys and corresponding arrays for each score type.
        const scoreMappings = [
          { key: 'ipMatchScore', array: ipSortedResults },
          { key: 'wipMatchScore', array: wipSortedResults },
          { key: 'apMatchScore', array: apSortedResults }
        ];
        
        // Apply the mergeScores function for each score type.
        let updatedArray = fullArray;
        scoreMappings.forEach(({ key, array }) => {
          updatedArray = mergeScores(updatedArray, array, key);
        });

        // Filter updatedArray by trifectaMatchScore. Any values below
        // 31.4451% are to be filtered out.
        const trifectaCutoff = 31.5937;
        const filteredArray = updatedArray.filter(item => item.trifectaMatchScore > trifectaCutoff);
        
        // Add updatedArray to state variable.
        setTableData(filteredArray);
      }

    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apresults, ipresults, wipresults, trifectaresults]);

  // Set table columns.
  let columns = [];
  if (view === 'pc') {
    columns = [
      { label: 'Job Zone', accessor: 'jobZone', sortable: true },
      { label: 'Job Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: 'Interests %', accessor: 'ipMatchScore', sortable: true },
      { label: 'Values %', accessor: 'wipMatchScore', sortable: true },
      { label: 'Abilities %', accessor: 'apMatchScore', sortable: true },
      { label: 'Trifecta %', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'Outlook', accessor: 'brightOutlook', sortable: true },
      { label: 'Career Cluster', accessor: 'careerName', sortable: true }
    ];
  } else if (view === 'tablet') {
    columns = [
      { label: 'Z', accessor: 'jobZone', sortable: true },
      { label: 'Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: '%', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'S', accessor: 'brightOutlook', sortable: true },
      { label: 'Cluster', accessor: 'careerName', sortable: true }
    ];
  } else if (view === 'mobile') {
    columns = [
      { label: 'Z', accessor: 'jobZone', sortable: true },
      { label: 'Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: '%', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'S', accessor: 'brightOutlook', sortable: true },
      { label: 'Cluster', accessor: 'careerName', sortable: true }
    ];
  }

  const handleSorting = (sortField, sortOrder) => {
    if (sortField === 'fitScore') {
      const scoreOrder = ['Excellent Fit', 'Great Fit', 'Good Fit', null];
      const sorted = [...tableData].sort((a, b) => {
        const scoreA = scoreOrder.indexOf(a[sortField]);
        const scoreB = scoreOrder.indexOf(b[sortField]);

        // If fit scores are the same, sort by trifectaMatchScore within that fit value.
        if (scoreA === scoreB && a['trifectaMatchScore'] && b['trifectaMatchScore']) {
          return (
            (a['trifectaMatchScore'] - b['trifectaMatchScore']) * (sortOrder === 'asc' ? 1 : -1)
          );
        }

        return (scoreA - scoreB) * (sortOrder === 'asc' ? -1 : 1);

      });
  
      setTableData(sorted);
    } else if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? -1 : 1)
        );
      });
      setTableData(sorted);
    }
  };

  const [ currentPage, setCurrentPage ] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleData = tableData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  return (
    <div className='gridTable__container'>
      
      {/* Pagination controls. */}
      {/* <GridTablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} view={view} /> */}
      
      {/* Table. */}
      <table className='gridTable'>
        <GridTableHead {...{ columns, handleSorting, view }} />
        <GridTableBody {...{ columns, visibleData, view }} />
      </table>

      {/* Pagination controls. */}
      <GridTablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} view={view} />

    </div>
  )
}

export default GridTable