import React, { useState, useEffect } from "react";
import WIPInstructionsA from "./WIPInstructionsA";
import WIPInstructionsB from "./WIPInstructionsB";
import WIPInstructionsC from "./WIPInstructionsC";
import WIPInstructionsD from "./WIPInstructionsD";
import WIPExplanationVideo from "./WIPExplanationVideo";
import WIPAssessment from "./WIPAssessment";
import WIPComplete from './WIPComplete';
import { WIPDisplayStates } from "./Configs";
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import DemoInfo from "../demoInfo/DemoInfo";
import firebase from '../../../config/fbConfig';
import { Redirect } from 'react-router-dom';

// import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import "./WIPAssessment.css";
import DnDTest from "./DnDTest";

const WIPFrontPage = ({ wip, user, auth }) => {
  const [display, setDisplay] = useState(WIPDisplayStates.LOADING);
  // const [display, setDisplay] = useState(WIPDisplayStates.INSTRUCTIONS_A);

  useEffect(() => {
    try {
      // Go directly to the assessment page if the user has already started the assessment.
      if (wip && wip.length > 0) {
        setDisplay(WIPDisplayStates.ASSESSMENT);
      } else {
        // Otherwise, go to the instructions page.
        setDisplay(WIPDisplayStates.INSTRUCTIONS_A);
      }

      // Go directly to the complete page if the user has finished the assessment.
      if (user) {
        let wipStatus = user[0].wipComplete;
        if (wipStatus === true) {
          setDisplay(WIPDisplayStates.COMPLETE);
        }
      }

      // Go directly to the demographic page if the user hasn't completed it yet.
      if (user) {
        let demoComplete = user[0].demoComplete;
        if (demoComplete !== true) {
          setDisplay(WIPDisplayStates.DEMOGRAPHIC_INFORMATION);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }    
  }, [wip, user]);

  const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);

  // Check if user has paid.
  useEffect(() => {
    try {
      async function checkPurchases(docId) {
        const order = await firebase.firestore().collection('orders').doc(docId).get();
        
        let purchased = false;
        let orderData = await order.data();
        if (orderData) {
          purchased = true;
        } else {
          purchased = false;
        }
        setPurchasedOrder(purchased);
        
        // Component is mounted after this check is complete.
        setIsMounted(true);
        
        return purchased;
      }
      const uid = auth.uid;
      checkPurchases(uid);
    } catch (error) {
      console.log('Error: ', error);
      setIsMounted(true);
    }

  }, [auth.uid]);

  // Check if user purchased through Stripe.
  useEffect(() => {
    try {
      // Check if the user used Stripe to purchase a 'Deluxe Package'.
      if (auth.uid !== undefined) {
        firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
          // Set stripePurchase to false if the user has no payments.
          if (snapshot.empty) {
            setStripePurchase(false);
          } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
            snapshot.forEach(paymentDoc => {
              let status = paymentDoc.data().status;
              const items = paymentDoc.data().items;
              if (items && status === 'succeeded') {
                items.forEach(item => {
                  if (item.description === 'Deluxe Package') {
                    // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                    // product in Stripe.
                    setStripePurchase(true);
                  } else {
                    setStripePurchase(false);
                  }
                })
              }
            })
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth.uid]);

  return (
    <div className='wipmain'>
      { isMounted === true && purchasedOrder === false && stripePurchase === false &&  // If the loading page is meant to check whether the user has paid, the user's profile is mounted, and the user didn't pay yet, redirect to the payment page.
        <Redirect
          to={{
            pathname: `/payment`,
          }}
        />
      }
      {user &&
        <div>
          {display === WIPDisplayStates.LOADING && (
            <LoadingPage checkPaid={true} />
            )
          }
          {display === WIPDisplayStates.INSTRUCTIONS_A && (
            <WIPInstructionsA onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.INSTRUCTIONS_B && (
            <WIPInstructionsB onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.INSTRUCTIONS_C && (
            <WIPInstructionsC onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.INSTRUCTIONS_D && (
            <WIPInstructionsD onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.VIDEO && (
            <WIPExplanationVideo onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.ASSESSMENT && (
            <WIPAssessment onClick={setDisplay} />
            )
          }
          {display === WIPDisplayStates.COMPLETE && (
            <WIPComplete user={user} />
            )
          }
          {display === WIPDisplayStates.DEMOGRAPHIC_INFORMATION && (
            <DemoInfo setDisplay={setDisplay} user={user} />
            )
          }
          {display === WIPDisplayStates.TEST && (
            <DnDTest />
            )
          }
        </div>
      }
      {!user && <div>
          <LoadingPage />
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    user: state.firestore.ordered.users,
    wip: state.firestore.ordered.wip
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
      { collection: 'wip', doc: props.auth.uid }
    ]
  })
)(WIPFrontPage)