// Firebase SDK v8 (OLD)
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Firebase SDK v9 (NEW)
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// require('dotenv').config();

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(
  { timestampsInSnapshots: true },
  // { merge: true }
);   // Initialize the firestore.

export default firebase;

export const functions = firebase.functions();