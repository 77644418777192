import React, { useState, useEffect } from 'react';
import './Carousel.css';
import { GrPrevious, GrNext } from 'react-icons/gr';

import Info from './carouselPages/Info';
import Interests from './carouselPages/Interests';
import Values from './carouselPages/Values';
import Abilities from './carouselPages/Abilities';
import TrifectaGraph from './carouselPages/TrifectaGraph/TrifectaGraph';
import JobZones from './carouselPages/JobZones/JobZones';
import OccupationGridFrontPage from './carouselPages/OccupationGrid/OccupationGridFrontPage';
import APCalculate from './ap/APCalculate';
import IPCalculate from './ip/IPCalculate';
import WIPCalculate from './wip/WIPCalculate';
import TrifectaCalculate from './trifecta/TrifectaCalculate';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { resetCalculated, resetIPCalculated, resetWIPCalculated, resetAPCalculated, resetOverlapCalculated } from '../../store/actions/userActions';

import { prepareDate } from './Functions';

const AllResults2 = ({ user, resetCalculated, resultsIndex, setResultsIndex, ar, cm, cp, sa, va, fp, ip, wip, apresults, ipresults, wipresults, trifectaresults }) => {
  // Carousel components and their props.
  const carouselComponents = [
    // Carousel item data.
    Info,
    Interests,
    Values,
    Abilities,
    TrifectaGraph,
    JobZones,
    OccupationGridFrontPage
  ];

  const carouselProps = [
    { /* Empty object for Info component. */ },
    {'user': user, 'ip': ip, 'ipresults': ipresults},
    {'user': user, 'wip': wip, 'wipresults': wipresults},
    {'user': user, 'apresults': apresults, 'ar': ar, 'cm': cm, 'cp': cp, 'sa': sa, 'va': va, 'fp': fp},
    {'user': user, 'apresults': apresults, 'ipresults': ipresults, 'wipresults': wipresults },
    { /* Empty object for JobZones component. */ },
    { 'user': user, 'apresults': apresults, 'ipresults': ipresults, 'wipresults': wipresults, 'trifectaresults': trifectaresults },
  ];

  // For the carousel.
  const itemCount = 7;

  const handleNext = () => {
    setResultsIndex((prevIndex) => (prevIndex + 1) % itemCount);
  };

  const handlePrev = () => {
    setResultsIndex((prevIndex) => (prevIndex - 1 + itemCount) % itemCount);
  }

  const RenderedComponent = carouselComponents[resultsIndex];
  const propsForRenderedComponent = carouselProps[resultsIndex]; // Get the corresponding props

  const [ apOutdated, setAPOutdated ] = useState(false);
  const [ ipOutdated, setIPOutdated ] = useState(false);
  const [ wipOutdated, setWIPOutdated ] = useState(false);

  // Check if there has been a new release since user's results were last calculated. If yes,
  // recalculate the user's results.
  useEffect(() => {
    try {
      let apDate = null;
      let ipDate = null;
      let wipDate = null;
      let lastReleaseDate = new Date(...prepareDate(process.env.REACT_APP_RELEASE_DATE)).getTime(); // Last release date, in milliseconds.
      let currentTime = new Date().getTime();   // Current time, in milliseconds.
      const waitTime = 5*60*1000;               // Time to wait between assessment calculate resets.

      if (user) {
        if (user[0]) {
          // Check for existing AP calculate date.
          if (user[0].apCalculateDate) {
            apDate = user[0].apCalculateDate['seconds']*1000; // Date in milliseconds.
          }
          
          // Check for existing IP calculate date.
          if (user[0].ipCalculateDate) {
            ipDate = user[0].ipCalculateDate['seconds']*1000; // Date in milliseconds.
          }
          
          // Check for existing WIP calculate date.
          if (user[0].wipCalculateDate) {
            wipDate = user[0].wipCalculateDate['seconds']*1000; // Date in milliseconds.
          }

          // If AP assessment results have been previously calculated and a new release has been
          // posted since then.
          if (
            (apDate !== null) &&
            (user[0].apCalculated === true) &&
            (apDate < lastReleaseDate) &&
            ((currentTime - apDate) > waitTime)
          ) {
            setAPOutdated(true);
          } else {
            setAPOutdated(false);
          }
          
          // If IP assessment results have been previously calculated and a new release has been
          // posted since then.
          if (
            (ipDate !== null) &&
            (user[0].ipCalculated === true) &&
            (ipDate < lastReleaseDate) &&
            ((currentTime - ipDate) > waitTime)
          ) {
            setIPOutdated(true);
          } else {
            setIPOutdated(false);
          }

          // If WIP assessment results have been previously calculated and a new release has been
          // posted since then.
          if (
            (wipDate !== null) && 
            (user[0].wipCalculated === true) && 
            (wipDate < lastReleaseDate) && 
            ((currentTime - wipDate) > waitTime)
          ) {
            setWIPOutdated(true);
          } else {
            setWIPOutdated(false);
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, resetCalculated]);

  // Set AP, IP, WIP, and trifecta calculated toggles to false so all get rerun.
  if (apOutdated || ipOutdated || wipOutdated) {
    resetCalculated();
  }

  return (
    <div className="carousel">
      { resultsIndex !== 0 &&
        <button
          className="carousel__control carousel__control-prev"
          onClick={handlePrev}
        >
          <GrPrevious className="carousel__icon" />
        </button>
      }

      <RenderedComponent {...propsForRenderedComponent} />

      { resultsIndex !== 6 &&
        <button
          className="carousel__control carousel__control-next"
          onClick={handleNext}
        >
          <GrNext className="carousel__icon" />
        </button>
      }

      {/* Check if AP results need to be calculated.  */}
      {
        user && (  // Check that the user is logged in.
          user[0].fpComplete && ( // Check that the user has completed this assessment.
            ((user[0].apCalculated === true && user[0].apJobsCalculated === true) === false) && ( // Check that the user's results have been calculated.
              (ar && cm && cp && sa && va && fp) && ( // Check for assessment answers.
                <APCalculate uid={user[0].userId} ar={ar} cm={cm} cp={cp} fp={fp} sa={sa} va={va} />  // Calculate results.
              )
            )
          )
        )
      }

      {/* Check if IP results need to be calculated.  */}
      {
        user && (  // Check that the user is logged in.
          user[0].ipComplete && ( // Check that the user has completed this assessment.
            ((user[0].ipCalculated === true && user[0].ipJobsCalculated === true) === false) && ( // Check that the user's results have been calculated.
              ip && ( // Check for assessment answers.
                <IPCalculate uid={user[0].userId} ip={ip} />  // Calculate results.
              )
            )
          )
        )
      }

      {/* Check if WIP results need to be calculated.  */}
      {
        user && (  // Check that the user is logged in.
          user[0].wipComplete && ( // Check that the user has completed this assessment.
            ((user[0].wipCalculated === true && user[0].wipJobsCalculated === true) === false) && ( // Check that the user's results have been calculated.
              wip && ( // Check for assessment answers.
                <WIPCalculate uid={user[0].userId} wip={wip} />  // Calculate results.
              )
            )
          )
        )
      }

      {/* Check if Trifecta results need to be calculated.  */}
      {
        user && (
          (user[0].overlapCalculated !== true &&
          user[0].apCalculated === true && user[0].apJobsCalculated === true &&
          user[0].ipCalculated === true && user[0].ipJobsCalculated === true &&
          user[0].wipCalculated === true && user[0].wipJobsCalculated === true) &&
            <TrifectaCalculate uid={user[0].userId} apresults={apresults} ipresults={ipresults} wipresults={wipresults} />
        )
      }

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    ar: state.firestore.ordered.ar,
    cm: state.firestore.ordered.cm,
    cp: state.firestore.ordered.cp,
    fp: state.firestore.ordered.fp,
    sa: state.firestore.ordered.sa,
    va: state.firestore.ordered.va,
    ip: state.firestore.ordered.ip,
    wip: state.firestore.ordered.wip,
    wipresults: state.firestore.ordered.wipresults,
    ipresults: state.firestore.ordered.ipresults,
    apresults: state.firestore.ordered.apresults,
    trifectaresults: state.firestore.ordered.trifectaresults
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCalculated: (submit) => dispatch(resetCalculated(submit)),
    resetIPCalculated: (submit) => dispatch(resetIPCalculated(submit)),
    resetWIPCalculated: (submit) => dispatch(resetWIPCalculated(submit)),
    resetAPCalculated: (submit) => dispatch(resetAPCalculated(submit)),
    resetOverlapCalculated: (submit) => dispatch(resetOverlapCalculated(submit))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
      { collection: 'ar', doc: props.auth.uid },
      { collection: 'cm', doc: props.auth.uid },
      { collection: 'cp', doc: props.auth.uid },
      { collection: 'fp', doc: props.auth.uid },
      { collection: 'sa', doc: props.auth.uid },
      { collection: 'va', doc: props.auth.uid },
      { collection: 'ip', doc: props.auth.uid },
      { collection: 'wip', doc: props.auth.uid },
      { collection: 'wipresults', doc: props.auth.uid },
      { collection: 'ipresults', doc: props.auth.uid },
      { collection: 'apresults', doc: props.auth.uid },
      { collection: 'trifectaresults', doc: props.auth.uid }
    ]
  })
)(AllResults2);