import { userActionTypes } from "../reducers/userReducer";

import firebase from 'firebase/app';
import 'firebase/functions';

export const selectAssessment = (assessmentId) => {
  return (dispatch, getState, { getFirestore }) => {
    // const firestore = getFirestore();
    // const userId = getState().firebase.auth.uid;

    dispatch({
      type: userActionTypes.SELECT_ASSESSMENT,
      payload: assessmentId,
    });

    /* firestore
      .collection("users")
      .doc(userId)
      .set({
        currentAssessment: assessmentId,
      })
      .then(() => {
        dispatch({
          type: userActionTypes.SELECT_ASSESSMENT,
          payload: assessmentId,
        });
      }); */
  };
};

export const selectPrompt = (promptId) => {
  return (dispatch, getState, { getFirestore }) => {
    // const firestore = getFirestore();
    // const userId = getState().firebase.auth.uid;

    // console.log("dispatch selectPrompt", promptId);
    dispatch({
      type: userActionTypes.SELECT_PROMPT,
      payload: promptId,
    });

    /* firestore
      .collection("users")
      .doc(userId)
      .set({
        currentPrompt: assementId,
      })
      .then(() => {
        dispatch({
          type: userActionTypes.SELECT_PROMPT,
          payload: promptId,
        });
      }); */
  };
};

// Delete user's document in the 'ip' collection.
export const deleteIP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('ip')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_IP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Delete user's document in the 'ipresults' collection.
export const deleteIPResults = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('ipresults')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_IPRESULTS", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Change ipComplete to false. Delete ipDate.
// Change ipCalculated to false. Delete ipCalculateDate.
export const resetIP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.
    // const FieldValue = firestore.FieldValue;     // FieldValue.
    const FieldValue = firestore.FieldValue;
    

    firestore
      .collection('users')
      .doc(userId)
      .update({
        ipComplete: false,                    // Set ipComplete to false.
        ipDate: FieldValue.delete(),          // Delete ipDate.
        ipCalculated: false,                  // Set ipCalculated to false.
        ipCalculateDate: FieldValue.delete(), // Delete ipCalculateDate.
        ipJobsCalculated: false,              // Set ipJobsCalculated to false.
        overlapCalculated: false,             // Set overlapCalculated to false.
        overlapCalculateDate: FieldValue.delete()   // Delete overlap calculated date.
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "RESET_IP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "RESET_ERROR", err });
      });
  }
}

// Delete user's document in the 'wip' collection.
export const deleteWIP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('wip')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_WIP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Delete user's document in the 'wipresults' collection.
export const deleteWIPResults = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('wipresults')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_WIPRESULTS", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Change wipComplete to false. Delete wipDate.
// Change wipCalculated to false. Delete wipCalculateDate.
export const resetWIP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.
    // const FieldValue = firestore.FieldValue;     // FieldValue.
    const FieldValue = firestore.FieldValue;
    

    firestore
      .collection('users')
      .doc(userId)
      .update({
        wipComplete: false,                     // Set wipComplete to false.
        wipDate: FieldValue.delete(),           // Delete wipDate.
        wipCalculated: false,                   // Set wipCalculated to false.
        wipCalculateDate: FieldValue.delete(),  // Delete wipCalculateDate.
        wipJobsCalculated: false,               // Set wipJobsCalculated to false.
        overlapCalculated: false,             // Set overlapCalculated to false.
        overlapCalculateDate: FieldValue.delete()   // Delete overlap calculated date.
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "RESET_WIP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "RESET_ERROR", err });
      });
  }
}

// Delete user's document in the 'ap' collection.
export const deleteAP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('ap')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_AP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Delete user's document in the 'apresults' collection.
export const deleteAPResults = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to delete document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('apresults')
      .doc(userId)
      .delete()
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "DELETE_APRESULTS", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_ERROR", err });
      });
  }
}

// Change apComplete to false. Delete apDate.
// Change apCalculated to false. Delete apCalculateDate.
export const resetAP = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.
    // const FieldValue = firestore.FieldValue;     // FieldValue.
    const FieldValue = firestore.FieldValue;
    

    firestore
      .collection('users')
      .doc(userId)
      .update({
        apComplete: false,                          // Set apComplete to false.
        apDate: FieldValue.delete(),                // Delete apDate.
        apCalculated: false,                        // Set apCalculated to false.
        apCalculateDate: FieldValue.delete(),       // Delete apCalculateDate.
        apJobsCalculated: false,                    // Set apJobsCalculated to false.
        overlapCalculated: false,                   // Set overlapCalculated to false.
        overlapCalculateDate: FieldValue.delete()   // Delete overlap calculated date.
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "RESET_AP", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "RESET_ERROR", err });
      });
  }
}

// Submit demographic information.
export const demoInfo = (input) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        educationYears: input.educationYears,
        jobExperience: input.jobExperience,
        race: input.race,
        sex: input.sex,
        demographicInfoDate: new Date(),
        demoComplete: true
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_DEMO_INFO", payload: input });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new first name.
export const firstNameSubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        firstName: submit,
        firstNameUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_FIRST_NAME", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new last name.
export const lastNameSubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        lastName: submit,
        lastNameUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_LAST_NAME", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new email.
export const emailSubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        email: submit,
        emailUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_EMAIL", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new birth day.
export const birthDaySubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        birthDay: submit,
        birthDayUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_BIRTH_DAY", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new birth month.
export const birthMonthSubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        birthMonth: submit,
        birthMonthUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_BIRTH_MONTH", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Submit new birth year.
export const birthYearSubmit = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        birthYear: submit,
        birthYearUpdate: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_BIRTH_YEAR", payload: submit });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}


// Reset user's assessment calculated booleans.
export const resetCalculated = (submit) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    let apState = false;
    let ipState = false;
    let wipState = false;
    if (submit === 'ap') {
      apState = false;
    } else if (submit === 'ip') {
      ipState = false;
    } else if (submit === 'wip') {
      wipState = false;
    }

    firestore
      .collection('users')
      .doc(userId)
      .update({
        apCalculated: apState,
        ipCalculated: ipState,
        wipCalculated: wipState,
        overlapCalculated: false,
        apJobsCalculated: false,
        ipJobsCalculated: false,
        wipJobsCalculated: false
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Reset user's ipCalculated boolean to false.
export const resetIPCalculated = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        ipCalculated: false,
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_IP_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Reset user's wipCalculated boolean to false.
export const resetWIPCalculated = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        wipCalculated: false,
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_WIP_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Reset user's apCalculated boolean to false.
export const resetAPCalculated = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        apCalculated: false,
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_AP_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Reset user's ipCalculated boolean to false.
export const resetOverlapCalculated = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        overlapCalculated: false,
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_OVERLAP_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Reset user's result calculations.
export const resetResults = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        apCalculated: false,
        apJobsCalculated: false,
        ipCalculated: false,
        ipJobsCalculated: false,
        wipCalculated: false,
        wipJobsCalculated: false,
        overlapCalculated: false,
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_RESET_OVERLAP_CALCULATED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}

// Send welcome email to user.
export const sendWelcomeEmail = () => {
  // console.log('Entering email send function.');
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log('Inside the return statement.');

    // This is where some stuff might happen.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
      }, { merge: true })
    .then(() => {
      // Send welcome email through SendGrid.
      // console.log('Connected to Firebase.');
      try {
        // Call the cloud function using its name.
        const sendWelcomeEmail = firebase.functions().httpsCallable('sendEmailTemplateModule');
  
        // console.log('Function defined.');

        const dynamicData = {
          firstName: 'test',
          lastName: 'tester'
        }
  
        // Provide any necessary data as an argument to the Cloud Function.
        sendWelcomeEmail({
          to: 'wisefi8054@onlcool.com',
          from: 'hi@knowtelos.com',
          templateId: 'd-c40e9362c3184a59b76928add4db3c55',
          dynamic_template_data: dynamicData,
        }).then(() => {
          dispatch({ type: 'EMAIL_SENT_SUCCESS' });
          // console.log('Email successfully sent.');
        }).catch((error) => {
          console.log('Error: ', error);
        });
      } catch (error) {
        console.error('Error sending welcome email: ', error);
      }
    }).catch((error) => {
      dispatch({ type: 'EMAIL_SENT_ERROR', error });
      console.log('Error: ', error);
    })
  }
}

// Make a note in the user's document that he has viewed the trifecta results.
export const updateTrifectaViewed = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // This is where some stuff might happen.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;    // User UID.

    firestore
      .collection('users')
      .doc(userId)
      .update({
        trifectaViewed: true
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "UPDATE_TRIFECTA_VIEWED" });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      })
  }
}