import React, { useState, useEffect } from 'react';
import './DemoInfo.css';
import { demoInfo } from '../../../store/actions/userActions';
import { connect } from "react-redux";
import firebase from '../../../config/fbConfig';
import { Redirect } from 'react-router-dom';
import LoadingPage from '../../pages/loadingPage/LoadingPage';

const DemoInfo = ({ setDisplay, demoInfo, auth, user }) => {
  const [ yearAnswer, setYearAnswer ] = useState(null);
  const [ jobYears, setJobYears ] = useState('');
  const [ race, setRace ] = useState('');
  const [ sex, setSex ] = useState('');

  const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);

  // Check if user has paid.
  useEffect(() => {
    try {
      async function checkPurchases(docId) {
        const order = await firebase.firestore().collection('orders').doc(docId).get();
        
        let purchased = false;
        let orderData = await order.data();
        if (orderData) {
          purchased = true;
        } else {
          purchased = false;
        }
        setPurchasedOrder(purchased);
        
        // Component is mounted after this check is complete.
        setIsMounted(true);
        
        return purchased;
      }
      const uid = auth.uid;
      checkPurchases(uid);
    } catch (error) {
      console.log('Error: ', error);
      setIsMounted(true);
    }

  }, [auth.uid]);

  // Check if user purchased through Stripe.
  useEffect(() => {
    try {
      // Check if the user used Stripe to purchase a 'Deluxe Package'.
      if (auth.uid !== undefined) {
        firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
          // Set stripePurchase to false if the user has no payments.
          if (snapshot.empty) {
            setStripePurchase(false);
          } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
            snapshot.forEach(paymentDoc => {
              let status = paymentDoc.data().status;
              const items = paymentDoc.data().items;
              if (items && status === 'succeeded') {
                items.forEach(item => {
                  if (item.description === 'Deluxe Package') {
                    // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                    // product in Stripe.
                    setStripePurchase(true);
                  } else {
                    setStripePurchase(false);
                  }
                })
              }
            })
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth.uid]);

  let allResults = {
    educationYears: '',
    jobExperience: '',
    race: '',
    sex: ''
  };

  const handleYearAnswer = (e) => {
    const { value } = e.target;
    setYearAnswer(value);
  }

  const handleJobYears = (e) => {
    const { value } = e.target;

    const result = value.replace(/\D/g, '');
    setJobYears(result);
  }

  const handleRaceAnswer = (e) => {
    const { value } = e.target;
    setRace(value);
  }

  const handleSexAnswer = (e) => {
    const { value } = e.target;
    setSex(value);
  }

  const buttonClick = (e) => {
    e.preventDefault();
    
    try {
    // Submit results.
    allResults.educationYears = yearAnswer;
    allResults.jobExperience = jobYears;
    allResults.race = race;
    allResults.sex = sex;

    demoInfo(allResults);
    
    // Go back to the current assessment.
      setDisplay('INSTRUCTIONS');

    } catch (error) {
      console.log('Error: ', error);
    }
  }

  const yearOptions = [
    'Some high school or less',
    'Currently in 10th grade',
    'Currently in 11th grade',
    'Currently in 12th grade',
    'High school diploma or GED',
    'Some college, but no degree',
    'Associates or technical degree',
    "Bachelor's degree",
    'Graduate or professional degree (MA, MS, MBA, PhD, JD, MD, DDS, etc.)',
    'Prefer not to say'
  ];

  const raceOptions = [
    'White',
    'American Indian/Alaskan Native',
    'African American/Black',
    'Asian or Pacific Islander',
    'Hispanic/Latino',
    'Other'
  ];

  const sexOptions = [
    'Male',
    'Female',
    'Prefer not to say'
  ];

  return (
    <div className='demo'>
      { isMounted === true && purchasedOrder === false && stripePurchase === false &&  // If the loading page is meant to check whether the user has paid, the user's profile is mounted, and the user didn't pay yet, redirect to the payment page.
        <Redirect
          to={{
            pathname: `/payment`,
          }}
        />
      }
      {(purchasedOrder === true || stripePurchase === true) && 
        <>
          <div className="demo__header1">
            Pre-Assessment<br /><b>Demographic Information</b>
          </div>
          <hr className='demo__line' />
          <div className="demo__header2">
            Years of Education
          </div>
            <div className="demo__yearsWrapper">
              {
                yearOptions.map((element, index) => (
                  <div className='demo__years' key={index}>
                    <input
                      type='radio'
                      checked={yearAnswer === element}
                      value={element}
                      name='year'
                      onChange={e => handleYearAnswer(e)}
                      className='demo__radioYears'
                    />
                    <div>
                      {element}
                    </div>
                  </div>
                ))
              }
            </div>
          <div className="demo__header2 demo__header2-extraSpace">
            Job Experience
          </div>
          <input
            type="text"
            value={jobYears}
            name='job_years'
            placeholder='years'
            className="demo__fillin"
            onChange={e => handleJobYears(e)}
          />
          <div className="demo__description">
            Put in the total number of years of full-time job experience you've had.<br />
            If you don't know exactly, an estimate is ok.
          </div>
          <div className="demo__header2 demo__header2-extraSpace">
            Race/Ethnic Group<span>optional</span>
          </div>
          {
            raceOptions.map((element, index) => (
              index < 3 &&
                <div className="demo__races" key={index}>
                  <input
                    type='radio'
                    checked={race === raceOptions[index*2]}
                    value={raceOptions[index*2]}
                    name='race'
                    onChange={e => handleRaceAnswer(e)}
                    className='demo__radioRace'
                  />
                  <div className='demo__raceName demo__raceName-left'>
                    {raceOptions[index*2]}
                  </div>
                  <input
                    type='radio'
                    checked={race === raceOptions[index*2+1]}
                    value={raceOptions[index*2+1]}
                    name='race'
                    onChange={e => handleRaceAnswer(e)}
                    className='demo__radioRace'
                  />
                  <div className='demo__raceName'>
                    {raceOptions[index*2+1]}
                  </div>
                </div>
            ))
          }
          <div className="demo__header2 demo__header2-lessSpace">
            Sex<span>optional</span>
          </div>
          <div className="demo__sexWrapper">
            {
              sexOptions.map((element, index) => (
                <div className='demo__sex' key={index}>
                  <input
                    type='radio'
                    checked={sex === element}
                    value={element}
                    name='sex'
                    onChange={e => handleSexAnswer(e)}
                    className='demo__radioSex'
                  />
                  <div>
                    {element}
                  </div>
                </div>
              ))
            }
          </div>
          <hr className='demo__line' />
          {
            (
              (yearAnswer !== null && jobYears !== '')
            ) ? (
              <button className='demo__button' onClick={buttonClick}>
                Next
              </button>
            ) : (
              <button className='demo__button demo__button-disabled' onClick={buttonClick} disabled={true}>
                Next
              </button>
            )
          }
        </>
      }
      {!user && <div>
        <LoadingPage />
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	};
};

const mapDispatchToProps = (dispatch) => {
  return {
    demoInfo: (result) => dispatch(demoInfo(result))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoInfo)