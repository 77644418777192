export const WIPDisplayStates = {
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q3: "INSTRUCTIONS_Q3",
  INSTRUCTIONS_Q4: "INSTRUCTIONS_Q4",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: 'DEMOGRAPHIC_INFORMATION'
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: 'B'}
]

// Initial state.
export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''},
  {id: 19, values: ''},
  {id: 20, values: ''},
  {id: 21, values: ''},
  {id: 22, values: ''},
  {id: 23, values: ''},
  {id: 24, values: ''},
  {id: 25, values: ''},
  {id: 26, values: ''},
  {id: 27, values: ''},
  {id: 28, values: ''},
  {id: 29, values: ''},
  {id: 30, values: ''},
  {id: 31, values: ''},
  {id: 32, values: ''},
  {id: 33, values: ''},
  {id: 34, values: ''},
  {id: 35, values: ''},
  {id: 36, values: ''},
  {id: 37, values: ''},
  {id: 38, values: ''},
  {id: 39, values: ''},
  {id: 40, values: ''},
  {id: 41, values: ''}
];

export const values = [
  'A',
  'B',
  'C',
  'D'
];