import firebase from '../../../config/fbConfig.js';
import { calcIPAPOverlap, calcWIPAPOverlap, calcWIPIPOverlap, calcTrifectaOverlap } from '../Functions.js';

export const trifectaJobOverlapCalculate = (uid, apResults, ipResults, wipResults, trifectaResultSubmit, overlapCalculatedSubmit) => {
  // console.log('OVERLAP JOBS CALCULATE');

  // Calculate overlaps.
  if (ipResults && apResults && wipResults) {
    try {
      // 1. Pull job results from IP assessment.
      const ipSortedResults = ipResults;

      // 2. Pull job results from AP assessment.
      const apSortedResults = apResults;
      // let apTrueResults = [];
      // let apFalseResults = [];
      // if (tempAPSortedResults !== undefined) {
      //   apTrueResults = tempAPSortedResults.filter(element => element.pass === true);
      //   apFalseResults = tempAPSortedResults.filter(element => element.pass === false);
      // }
      // const apTrueResults = tempAPSortedResults.filter(element => element.pass === true);
      // const apFalseResults = tempAPSortedResults.filter(element => element.pass === false);
      // const apLength = apTrueResults.length;
      // let apExtras = null;
      // let apSortedResults = [];

      // // No limits on AP jobs, for now. Can set the limit on number shown in the trifecta graph, but should calculate everything.
      // const minAPLength = 50000;
      // const maxAPLength = 50000;
      
      // // Make sure there are at least minAPLength jobs from the AP assessment.
      // if (apLength < minAPLength) {
      //   apExtras = apFalseResults.slice(0, (minAPLength - apLength));
      //   if (apTrueResults.length === 1) {
      //     apSortedResults = [...[apTrueResults], ...apExtras];
      //   } else {
      //     apSortedResults = [...apTrueResults, ...apExtras];
      //   }
      // } else {
      //   apSortedResults = [...apTrueResults];
      // }

      // // Make sure there are no more than maxAPLength jobs from the AP assessment.
      // apSortedResults = apSortedResults.slice(0, maxAPLength);

      // 3. Pull job results from WIP assessment.
      const wipSortedResults = wipResults;

      // 4. Calculate IPAP results.
      const ipapResults = calcIPAPOverlap(ipSortedResults, apSortedResults)
        .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);
      // const ipapResults = filterAndMeanCorrCoeff(ipSortedResults, apSortedResults)
        // .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);

      // 5. Calculate IPWIP results.
      const ipwipResults = calcWIPIPOverlap(wipSortedResults, ipSortedResults)
        .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);
      // const ipwipResults = filterAndMeanCorrCoeff(ipSortedResults, wipSortedResults)
      //   .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);

      // 6. Calculate APWIP results.
      const apwipResults = calcWIPAPOverlap(wipSortedResults, apSortedResults)
        .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);
      // const apwipResults = filterAndMeanCorrCoeff(apSortedResults, wipSortedResults)
      //   .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);

      // 7. Calculate trifecta results.
      const trifectaResults = calcTrifectaOverlap(wipSortedResults, ipSortedResults, apSortedResults)
        .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);
      // const trifectaResultsTemp = filterAndMeanCorrCoeff(ipapResults, ipwipResults);
      // const trifectaResults = filterAndMeanCorrCoeff2(trifectaResultsTemp, apwipResults)
      //   .sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);

      // 7. Submit data to Firebase.
      submitSortedResults(ipapResults, ipwipResults, apwipResults, trifectaResults, uid);
      trifectaResultSubmit({ trifectaResults, uid });  // Adds timestamp and user ID to user document.
      
      // 8. Set trifectaCalculated boolean to 'true'.
      overlapCalculatedSubmit(uid);    // Sends calculated = true boolean to Firestore for job overlaps.


    } catch (error) {
      console.log('Error: ', error);
    }
  } else {
    // console.log('Loading user data...');
  }
}

// Submit trifecta results.
async function submitSortedResults(ipapSortedResults, ipwipSortedResults, apwipSortedResults, trifectaResults, uid) {
  // const connection = await firebase.firestore().collection('apresults').doc(auth.uid).set({
  await firebase.firestore().collection('trifectaresults').doc(uid).set({
      ipapSortedResults: ipapSortedResults,
      ipwipSortedResults: ipwipSortedResults,
      apwipSortedResults: apwipSortedResults,
      trifectaResults: trifectaResults
  }, { merge: true });
}