import React from 'react';
import './Popups.css';
import '../../../Results.css';

const IPAPPopup = ({ isOpen, onClose, IPAPGroupedResults }) => {
  if (!isOpen) return null;
  if (isOpen === false) return null;

  return (
    <div id='ipapPopup' className="doublePopup doublePopup-ipap">
      <div className="doublePopup__topRow">
        <div className="doublePopup__closeButton">

        </div>
        <div className="doublePopup__header">
          INTERESTS &<br />ABILITIES
        </div>
        <button className="doublePopup__closeButton" onClick={onClose}>
          X
        </button>
      </div>

      {/* Vertical centered line. */}
      <div className="doublePopup__verticalLine doublePopup__verticalLine-ipap" />

      <div className={`doublePopup__content ${isOpen && 'doublePopup__content-open'}`}>

        {
          IPAPGroupedResults.length > 0 ? (
            IPAPGroupedResults.map((element, index) => (
              <div key={index} className='doublePopup__text'>
                <h1>
                  {getGroupName(element.group)}
                </h1>
                {element.occupations.map((innerElement, innerIndex) => (
                  <span key={innerIndex}>
                    <a
                      className='doublePopup__link doublePopup__link-ipap'
                      href={`https://www.onetonline.org/link/summary/${innerElement.onetCode}`}
                      target='blank'
                      rel='noreferrer'
                    >
                      {innerElement.occupation}<br />
                    </a>
                  </span>
                ))}
              </div>
            ))
          ) : (
            <div>
              No careers here.
            </div>
          )
        }

      </div>

    </div>
  )
}

export default IPAPPopup

// Convert an O*Net group code to a human-readable group name.
function getGroupName(groupCode) {
  const codes = ['11', '13', '15', '17', '19', '21', '23', '25', '27', '29', '31', '33', '35', '37', '39', '41', '43', '45', '47', '49', '51', '53'];
  const groups = [
    'Management',
    'Business and Financial Operations',
    'Computer and Mathematical',
    'Architecture and Engineering',
    'Life, Physical, and Social Science',
    'Community and Social Service',
    'Legal Occupations',
    'Education, Training, and Library',
    'Arts, Design, Entertainment, Sports, and Media',
    'Healthcare Practitioners and Technical',
    'Healthcare Support',
    'Protective Service',
    'Food Preparation and Serving Related',
    'Building and Grounds Cleaning and Maintenance',
    'Personal Care and Service',
    'Sales and Related Occupations',
    'Office and Administrative Support',
    'Farming, Fishing, and Forestry',
    'Construction and Extraction',
    'Installation, Maintenance, and Repair',
    'Production',
    'Transportation and Material Moving'
  ];

  // Match the code, get its index.
  const codeMatch = (element) => Math.round(element - groupCode) === 0;
  const index = codes.findIndex(codeMatch);

  // Use the index to get the group name.
  return groups[index];
}