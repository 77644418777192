export const WIPDisplayStates = {
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q345: "INSTRUCTIONS_Q345",
  INSTRUCTIONS_Q678: "INSTRUCTIONS_Q678",
  INSTRUCTIONS_Q9: "INSTRUCTIONS_Q9",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: 'DEMOGRAPHIC_INFORMATION'
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''}
]

// Initial state.
export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''},
  {id: 19, values: ''},
  {id: 20, values: ''},
  {id: 21, values: ''},
  {id: 22, values: ''},
  {id: 23, values: ''},
  {id: 24, values: ''},
  {id: 25, values: ''},
  {id: 26, values: ''},
  {id: 27, values: ''},
  {id: 28, values: ''},
  {id: 29, values: ''},
  {id: 30, values: ''},
  {id: 31, values: ''},
  {id: 32, values: ''},
  {id: 33, values: ''},
  {id: 34, values: ''},
  {id: 35, values: ''},
  {id: 36, values: ''},
  {id: 37, values: ''},
  {id: 38, values: ''},
  {id: 39, values: ''},
  {id: 40, values: ''},
  {id: 41, values: ''},
  {id: 42, values: ''},
  {id: 43, values: ''},
  {id: 44, values: ''},
  {id: 45, values: ''},
  {id: 46, values: ''},
  {id: 47, values: ''},
  {id: 48, values: ''},
  {id: 49, values: ''},
  {id: 50, values: ''},
  {id: 51, values: ''},
  {id: 52, values: ''},
  {id: 53, values: ''},
  {id: 54, values: ''},
  {id: 55, values: ''},
  {id: 56, values: ''},
  {id: 57, values: ''},
  {id: 58, values: ''},
  {id: 59, values: ''},
  {id: 60, values: ''},
  {id: 61, values: ''},
  {id: 62, values: ''},
  {id: 63, values: ''},
  {id: 64, values: ''},
  {id: 65, values: ''},
  {id: 66, values: ''},
  {id: 67, values: ''},
  {id: 68, values: ''},
  {id: 69, values: ''},
  {id: 70, values: ''},
  {id: 71, values: ''},
  {id: 72, values: ''},
  {id: 73, values: ''},
  {id: 74, values: ''},
  {id: 75, values: ''},
  {id: 76, values: ''},
  {id: 77, values: ''},
  {id: 78, values: ''},
  {id: 79, values: ''},
  {id: 80, values: ''},
  {id: 81, values: ''},
  {id: 82, values: ''},
  {id: 83, values: ''},
  {id: 84, values: ''},
  {id: 85, values: ''},
  {id: 86, values: ''},
  {id: 87, values: ''},
  {id: 88, values: ''},
  {id: 89, values: ''},
];

export const questionsA = [
  'Paramore & Co.',
  'Bimler',
  'E-Z Neon',
  'Blackstone',
  'Chris Brasch',
  'A & V Mech.',
  'Bustamante & Co.',
  'Endospace',
  'Fran Barber',
  'T.S. Mankus',
  'Broadway Dance Co.',
  'Marine Salvage',
  'R.V. Knoll',
  'Cue Comic',
  'T.A. Bowles',
  'Abbey Dwayne',
  'Sunbeam Lamps',
  'Waylan R. Massell',
  'Rolon Rodes',
  'Genro Dawson Ed.',
  'Anawuye Inc.',
  'Sungold Stencils',
  'Western Steel Wagon',
  'Culp Div.',
  'Carlos’ Catering',
  'J & H Denims',
  'Rashid P.T.',
  'J.B. Midori',
  'Commerce Dr.',
  'Ida Muni',
  'Joe T. Bara',
  'Allen Div.',
  'Champlain Ave.',
  'Berlin-Soonam-Fiske',
  'I.M. Bonregime',
  'Fairway Golf Course',
  'C.F. Tool & Die',
  'Donna Brendan',
  'Village Pizza Place',
  'Ardis Deckert',
  'W. Ulfilas',
  'Arcway Machine',
  'Gindel’s Gages',
  'Conte Verde Academy',
  'Jina M. Holland Esq.',
  'Neper & Brown Co., Inc.',
  'Eve Moemu',
  'Bo Serna’s Supply',
  'Arimoto & Cook, Inc.',
  'B.K. Baiamonte Sr.',
  'Bryn Mawr Food Mart',
  'Lake Shore Day Care',
  'Sunland Cement Co.',
  'Builders Exchange',
  'T.R. Humphrey Stores',
  'College Cycle Center',
  'Kin Flagg',
  'Phoenix Foundry',
  'Mika Langings',
  'Breeze V.N. & Co.',
  'Mei Jean Wholesaler',
  'Teeterboard',
  'Atlas Flagg, Inc.',
  'C.G. Munguia',
  'Q & Q Quaint Bazaar',
  'Bradley Gillberston',
  'Hanglas Bakery',
  'Franklin Rd.',
  'D.O. Etumu & Sons',
  'Ben Spark',
  'I.K. Season Corp.',
  'Cole’s Heat Systems',
  'Ridgewood Writers',
  'Progressive Svc.',
  'Cai Cheung Wax Co.',
  'Wraner Olukayode',
  'Capital Press Center',
  'Chesmu Cross C.',
  'Edwin J. Rotzoll',
  'Harkenville, Jerome',
  'Dresser Ctge.',
  'Juana N. Parks',
  'Pobgee Dr.',
  'Anastasia Cowan',
  'Jonina Jannini',
  'Velocity Rwys.',
  'Corona Stefan',
  'Co-op Plumbers Ass’n',
  'Big Sky Balloons Co.',
  'Henderson St. Ctr. '
];

export const questionsB = [
  'Paramore & Co.',
  'Binler',
  'E-Z Neon',
  'Blackstone',
  'Chris Grasch',
  'A & V Mech.',
  'Bustamante & Co.',
  'Endospace',
  'Fran Barber',
  'T.S. Mankos',
  'Broadway Dance Co.',
  'Marine Salvage',
  'R.V. Knoell',
  'Cue Comic',
  'T.A. Bowls',
  'Abbey Dwaayne',
  'Sunbeam Lamps',
  'Waybin R. Massell',
  'Rolon Rodes',
  'Jenro Dawson Ed.',
  'Anawuuye Inc.',
  'Sungold Stencils',
  'Western Steel Wagon',
  'Culp Dir.',
  'Carlos’ Catering',
  'J & H Denim',
  'Rashed P.T.',
  'J.B. Midori',
  'Commerce Dr.',
  'Ida Muni',
  'Jo T. Bara',
  'Allens Div.',
  'Champain Ave.',
  'Berlin-Soonam-Fiske',
  'I.M. Bonregme',
  'Fareway Golf Course',
  'C.F. Tool & Die',
  'Donna Brendon',
  'Village Pizza Place',
  'Ardis Deckeart',
  'W. Ulfilas',
  'Arcway Machine',
  'Gundel’s Gages',
  'Conte Verde Academy',
  'Jina M. Holland Esq.',
  'Neper & Brown Co., Inc.',
  'Eve Moemu',
  'Bo Sirna’s Supply',
  'Arinoto & Cook, Inc.',
  'B.K. Bajamonte Sr.',
  'Bryn Mawyr Food Mart',
  'Lake Shore Bay Care',
  'Sunlund Cement Co.',
  'Builders Exchange',
  'T.R. Humphry Stores',
  'College Cycle Centre',
  'Kin Flagg',
  'Phoenix Foundry',
  'Miki Langings',
  'Breeze V.N. & Co.',
  'Mei Jeen Wholesaler',
  'Teeterbooard',
  'Atlas Flag, Inc.',
  'C.G. Munguia',
  'Q & Q Quaint Bazar',
  'Bradley Gillbertsen',
  'Hanglas Bakery',
  'Franklin Rd.',
  'D.O. Etumu & Sons',
  'Ben Sprak',
  'I.K. Season Corp.',
  'Cole’s Heat Systens',
  'Ridgwood Writers',
  'Progressive Svc.',
  'Cai Cheung Wax Co.',
  'Warner Olukayode',
  'Capitol Press Center',
  'Chesmu Cross C.',
  'Edwin J. Rotzoll',
  'Harkenville, Jerome',
  'Dresser Ctge.',
  'Juana N. Park',
  'Pogbee Dr.',
  'Anastasia Cowan',
  'Jonina Jannini',
  'Velocity Rwys.',
  'Corona Stefan',
  'Co-op Plumber Ass’n',
  'Big Sky Baloons Co.',
  'Hendersen St. Ctr.'
];

export const questions = [
  'Paramore & Co. — Paramore & Co.',
  'Bimler — Binler',
  'E-Z Neon — E-Z Neon',
  'Blackstone — Blackstone',
  'Chris Brasch — Chris Grasch',
  'A & V Mech. — A & V Mech.',
  'Bustamante & Co. — Bustamante & Co.',
  'Endospace — Endospace',
  'Fran Barber — Fran Barber',
  'T.S. Mankus — T.S. Mankos',
  'Broadway Dance Co. — Broadway Dance Co.',
  'Marine Salvage — Marine Salvage',
  'R.V. Knoll — R.V. Knoell',
  'Cue Comic — Cue Comic',
  'T.A. Bowles — T.A. Bowls',
  'Abbey Dwayne — Abbey Dwaayne',
  'Sunbeam Lamps — Sunbeam Lamps',
  'Waylan R. Massell — Waybin R. Massell',
  'Rolon Rodes — Rolon Rodes',
  'Genro Dawson Ed. — Jenro Dawson Ed.',
  'Anawuye Inc. — Anawuuye Inc.',
  'Sungold Stencils — Sungold Stencils',
  'Western Steel Wagon — Western Steel Wagon',
  'Culp Div. — Culp Dir.',
  'Carlos’ Catering — Carlos’ Catering',
  'J & H Denims — J & H Denim',
  'Rashid P.T. — Rashed P.T.',
  'J.B. Midori — J.B. Midori',
  'Commerce Dr. — Commerce Dr.',
  'Ida Muni — Ida Muni',
  'Joe T. Bara — Jo T. Bara',
  'Allen Div. — Allens Div.',
  'Champlain Ave. — Champain Ave.',
  'Berlin-Soonam-Fiske — Berlin-Soonam-Fiske',
  'I.M. Bonregime — I.M. Bonregme',
  'Fairway Golf Course — Fareway Golf Course',
  'C.F. Tool & Die — C.F. Tool & Die',
  'Donna Brendan — Donna Brendon',
  'Village Pizza Place — Village Pizza Place',
  'Ardis Deckert — Ardis Deckeart',
  'W. Ulfilas — W. Ulfilas',
  'Arcway Machine — Arcway Machine',
  'Gindel’s Gages — Gundel’s Gages',
  'Conte Verde Academy — Conte Verde Academy',
  'Jina M. Holland Esq. — Jina M. Holland Esq.',
  'Neper & Brown Co., Inc. — Neper & Brown Co., Inc.',
  'Eve Moemu — Eve Moemu',
  'Bo Serna’s Supply — Bo Sirna’s Supply',
  'Arimoto & Cook, Inc. — Arinoto & Cook, Inc.',
  'B.K. Baiamonte Sr. — B.K. Bajamonte Sr.',
  'Bryn Mawr Food Mart — Bryn Mawyr Food Mart',
  'Lake Shore Day Care — Lake Shore Bay Care',
  'Sunland Cement Co. — Sunlund Cement Co.',
  'Builders Exchange — Builders Exchange',
  'T.R. Humphrey Stores — T.R. Humphry Stores',
  'College Cycle Center — College Cycle Centre',
  'Kin Flagg — Kin Flagg',
  'Phoenix Foundry — Phoenix Foundry',
  'Mika Langings — Miki Langings',
  'Breeze V.N. & Co. — Breeze V.N. & Co.',
  'Mei Jean Wholesaler — Mei Jeen Wholesaler',
  'Teeterboard — Teeterbooard',
  'Atlas Flagg, Inc. — Atlas Flag, Inc.',
  'C.G. Munguia — C.G. Munguia',
  'Q & Q Quaint Bazaar — Q & Q Quaint Bazar',
  'Bradley Gillberston — Bradley Gillbertsen',
  'Hanglas Bakery — Hanglas Bakery',
  'Franklin Rd. — Franklin Rd.',
  'D.O. Etumu & Sons — D.O. Etumu & Sons',
  'Ben Spark — Ben Sprak',
  'I.K. Season Corp. — I.K. Season Corp.',
  'Cole’s Heat Systems — Cole’s Heat Systens',
  'Ridgewood Writers — Ridgwood Writers',
  'Progressive Svc. — Progressive Svc.',
  'Cai Cheung Wax Co. — Cai Cheung Wax Co.',
  'Wraner Olukayode — Warner Olukayode',
  'Capital Press Center — Capitol Press Center',
  'Chesmu Cross C. — Chesmu Cross C.',
  'Edwin J. Rotzoll — Edwin J. Rotzoll',
  'Harkenville, Jerome — Harkenville, Jerome',
  'Dresser Ctge. — Dresser Ctge.',
  'Juana N. Parks — Juana N. Park',
  'Pobgee Dr. — Pogbee Dr.',
  'Anastasia Cowan — Anastasia Cowan',
  'Jonina Jannini — Jonina Jannini',
  'Velocity Rwys. — Velocity Rwys.',
  'Corona Stefan — Corona Stefan',
  'Co-op Plumbers Ass’n — Co-op Plumber Ass’n',
  'Big Sky Balloons Co. — Big Sky Baloons Co.',
  'Henderson St. Ctr. — Hendersen St. Ctr.'
];

export const values = [
  'S',
  'D'
];