import React from 'react';
import { NavLink } from 'react-router-dom';

const CPCompleted = () => {

  return (
    <div className='ipcomplete'>
      <div className="ipcomplete__header">
        Congratulations!
      </div>
      <div className="ipcomplete__body">
        You've completed the Name Comparison section of the Abilities Profiler.
      </div>
      <NavLink to='/apassessment' className='ipcomplete__navlink'>
        <button className="ipcomplete__button">
          Continue
        </button>
      </NavLink>
    </div>
  )
}

export default CPCompleted
