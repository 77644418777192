import React, { useState, useEffect } from 'react';
import Timer from '../../../../timer/Timer';
import CPMultiChoice2 from './CPMultiChoice2';
import { connect } from "react-redux";
import { cpSubmit, cpTime, cpCompleteSubmit } from '../../../../../store/actions/submitActions';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { WIPDisplayStates, initialState } from './Configs';
import firebase from '../../../../../config/fbConfig';

const CPAssessment2 = ({ cpSubmit, cpTime, setDisplay, cpCompleteSubmit, auth }) => {
  const timeLimit = 6*60; // Time limit for this section, in seconds.
  const [ counter, setCounter ] = useState(timeLimit);
  // Keep track of the question number within this component.
  const [ count, setCount ] = useState(null);

  // Total number of questions.
  const numTotalQuestions = 90;

  // Create a state array for the answers.
  const [ cpAnswers, setCPAnswers ] = useState([...initialState]);  // Store CP answers.

  // Most recent question answered.
  const [ last, setLast ] = useState(0);

  // Watch currentPromptId for changes, update initial display order upon change.
  useEffect(() => {

    let isMounted = true;   // Add boolean to handle no-op warning.

    // Get data from this user's 'cp' document, if it exists.
    const getData = firebase.firestore().collection('cp').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let fbCPAnswers = null; // Firebase answers.
        let newCounter = 0;
        if (doc.exists) {
          fbCPAnswers = doc.data().cpAnswers;
          if (doc.data().timeLeft) {
            if (doc.data().timeLeft.counter) {
              newCounter = doc.data().timeLeft.counter;
            } else {
              newCounter = timeLimit;
            }
          } else {
            newCounter = timeLimit;
          }

          if (count === null && isMounted) {
            // If the doc exists, that means the user has answered at least one question at some point.
            // If count is null, that means the user either just started for the first time or reloaded the assessment.
            setCounter(newCounter);
          }
        }
        let filledCPAnswers = null;
        let countValue = 0;
        try {
          if (fbCPAnswers) {
            filledCPAnswers = fbCPAnswers.filter(e => e.values !== "");
            // Get maximum id value from filledCPAnswers.
            if (filledCPAnswers.length > 0) {
              const maxValue = filledCPAnswers.reduce((p, c) => p.id > c.id ? p: c);
              countValue = Math.floor(maxValue.id/5);
            } else {
              countValue = 0;
            }
          } else {
            filledCPAnswers = null;
            countValue = 0;
          }
          if (countValue < 0) {
            countValue = 0;
          }
          // Only update the count if the page has been reloaded, otherwise, allow count to get updated
          // when the user clicks the forward and back buttons.
          if (count === null && isMounted) {
            setCount(countValue);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
        return [
          {
            id: 'cp',
            data: fbCPAnswers
          }
        ];
      }
    });

    const output = () => {
      getData.then((result) => {
        if(isMounted) {
          // User's stored data in Firebase.
          let storedResults = result[0].data;

          // Filled data.
          let filledCPAnswers = null;

          try {
            if (storedResults){
              filledCPAnswers = result[0].data.filter(e => e.values !== "");
            } else {
              filledCPAnswers = null;
            }
          } catch (error) {
            console.log('Error: ', error);
          }

          // Only set 'last' if the page has been reloaded, otherwise 'last' will be set when the user
          // selects an answer to a question.
          if (count === null) {
            try {
              if (storedResults) {
                setLast(filledCPAnswers.length);
              } else {
                setLast(0); // If no results are stored in Firebase.
              }
            } catch (error) {
              console.log('Error: ', error);
            }
          }

          // Combine Firebase stored answers with cpAnswers state array.
          let currentCPAnswers = null;
          try {
            if (filledCPAnswers) {
              currentCPAnswers = Object.values([...initialState, ...filledCPAnswers].reduce((result, {
                id,
                ...rest
              }) => {
                result[id] = {
                  ...(result[id] || {}),
                  id,
                  ...rest
                };
                return result;
              }, {}));
            } else {
              currentCPAnswers = initialState; // If filledCPAnswers is null (can't be loaded from Firebase, or Firebase is empty).
            }
          } catch (error) {
            console.log('Error: ', error);
          }
          
          // Store Firebase data in cpAnswers state array.
          setCPAnswers(currentCPAnswers);
        }
      })
    }
    output();

    // Clean-up.
    return () => {
      isMounted = false;
    }
  }, [auth, count, timeLimit]);

  const nextClick = (e) => {
    // Increment 'count'.
    setCount(count + 1);

    // Send all results up to this page to Firestore.
    cpSubmit({ cpAnswers });

    // Submit current time left.
    cpTime({ counter });
  }

  const backClick = (e) => {
    // Decrement 'count'.
    setCount(count - 1);

    // Send all results up to this page to Firestore.
    cpSubmit({ cpAnswers });

    // Submit current time left.
    cpTime({ counter });
  }

  const submitClick = (e) => {
    cpSubmit({ cpAnswers });    // Sends results to Firestore.

    // Submit current time left.
    cpTime({ counter });

    // Set 'cpComplete' to 'true' in user document.
    cpCompleteSubmit(); // Sends complete = true boolean to Firestore.

    // Updates display state.
    setDisplay(WIPDisplayStates.COMPLETE);
  }

  // Submit assessment results if counter goes to zero.
  if (counter === 0) {
    submitClick();
  }

  return (
    <div className="ar-main-box">
      <div className="timer">
        <Timer counter={counter} setCounter={setCounter} />
      </div>
      <CPMultiChoice2 count={count} answer={cpAnswers} setAnswer={setCPAnswers} last={last} setLast={setLast} />
      <div className="cp-row-2 cp-row-2-spaced cp-row-2-nomargin">
        {count > 0 ? (
          <div className="cp-row-2 cp-row-2-spaced cp-row-2-nomargin">
            <button className='ap-button' onClick={backClick}>
              <MdNavigateBefore className='ar-nav-button' />
            </button>
            {count === (numTotalQuestions/5)-1 ? (
              <button className='ap-button ap-button-submit' onClick={submitClick}>Submit</button>
            ) : (
              <button className='ap-button' onClick={nextClick}>
                    <MdNavigateNext className='ar-nav-button' />
              </button>
            )}
          </div>
        ) : (
          <div className="cp-row-2 cp-row-2-nomargin">
            <button className='ap-button' onClick={nextClick}>
              <MdNavigateNext className='ar-nav-button' />
            </button>
          </div>
        )}
      </div>

      <div className="cpass__questionBoxes">
        <div className="cpass__questionBoxesRow">
          { cpAnswers.map((val, ind) => (
            ((ind + 1) % 5 === 0) && (
              (cpAnswers[ind].values !== '' && cpAnswers[ind-1].values !== '' &&
               cpAnswers[ind-2].values !== '' && cpAnswers[ind-3].values !== '' &&
               cpAnswers[ind-4].values !== ''
              ) ? (
                <input
                  type="radio"
                  checked={(ind + 1)/5 - 1 === count}
                  className="cpass__questionBox cpass__questionBox-answered"
                  readOnly={true}
                  key={ind}
                />
              ) : (
                <input
                  type="radio"
                  checked={(ind + 1)/5 - 1 === count}
                  className="cpass__questionBox"
                  readOnly={true}
                  key={ind}
                />
              )
              ))
            )
          }
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cpSubmit: (scores) => dispatch(cpSubmit(scores)),
    cpTime: (counter) => dispatch(cpTime(counter)),
    cpCompleteSubmit: (result) =>
      dispatch(
        cpCompleteSubmit(result)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CPAssessment2)
