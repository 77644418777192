import React from 'react';
// import firebase from '../../../config/fbConfig';
import { connect } from 'react-redux';
// import { rescale, calcEuclideanDistance } from '../Functions';
import { apJobOverlapCalculate } from '../functionalizedCalculations/APCalculations';

const APJobOverlap2 = ({ uid, arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile }) => {

  // console.log('AP JOB OVERLAP CALCULATIONS');

  try {
    if (arPercentile !== null && vaPercentile !== null &&
        saPercentile !== null && cmPercentile !== null &&
        cpPercentile !== null && fpPercentile !== null
    ) {
      // Calculate AP job overlap.
      apJobOverlapCalculate(
        uid,
        arPercentile, vaPercentile, saPercentile,
        cmPercentile, cpPercentile, fpPercentile
      );
    }
  } catch (error) {
    console.log('Error: ', error);
  }

  return (
    <div></div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(APJobOverlap2)