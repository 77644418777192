import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PurchasedAssessments.css';
import { FaLock } from 'react-icons/fa';

const Assessments = () => {
  // Pictures.
  const ipPic = `/images/assessmentsImages/01-interests.png`;
  const wipPic = `/images/assessmentsImages/02-workImportance.png`;
  const apPic = `/images/assessmentsImages/03-abilities.png`;
  return (
    <div className="purchased">
      <Container className='purchased__main'>
        <Row>
          <Col>
            <div className='purchased__header'>
              Sign up to access these assessments!    
            </div>
            <div className='purchased__subheader'>
              Ready to learn what careers are best for you? Sign up to get started!
            </div>
            
            <div className="purchased__box">
              <div>
                <div className="purchased__overlay">
                  <div className="purchased__overlayText">
                    <FaLock />
                  </div>
                </div>
                <div className="purchased__button">
                  <div className="purchased__image">
                    <img src={ipPic} alt="ip-pic" className='purchased__imageSelf' />
                  </div>
                  <div className="purchased__words">
                    <div className='purchased__title'>
                      Assessment 1: Interest Profiler
                    </div>
                    <div className='purchased__times'>
                      <div className='purchased__buttonSubtitle'>
                        Time Commitment =
                      </div>
                      <div className="purchased__time">
                        &nbsp;30 minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="purchased__overlay">
                  <div className="purchased__overlayText">
                    <FaLock />
                  </div>
                </div>
                <div className="purchased__button">
                  <div className="purchased__image">
                    <img src={wipPic} alt="wip-pic" className='purchased__imageSelf' />
                  </div>
                  <div className="purchased__words">
                    <div className='purchased__title'>
                      Assessment 2: Values Locator
                    </div>
                    <div className='purchased__times'>
                      <div className='purchased__buttonSubtitle'>
                        Time Commitment =
                      </div>
                      <div className="purchased__time">
                        &nbsp;20 minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="purchased__overlay">
                  <div className="purchased__overlayText">
                    <FaLock />
                  </div>
                </div>
                <div className="purchased__button">
                  <div className="purchased__image">
                    <img src={apPic} alt="ap-pic" className='purchased__imageSelf' />
                  </div>
                  <div className="purchased__words">
                    <div className='purchased__title'>
                      Assessment 3: Abilities Profiler
                    </div>
                    <div className='purchased__times'>
                      <div className='purchased__buttonSubtitle'>
                        Time Commitment =
                      </div>
                      <div className="purchased__time">
                        &nbsp;5-20 minutes each
                      </div>
                    </div>
                    <div className='purchased__buttonSubSubtitle'>
                      6 sections that can be done individually
                    </div>
                  </div>
                </div>  
              </div>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Assessments
