const initState = {
  authError: null,
  WIP: {}
};

const submitReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "SUBMIT_ERROR":
      // console.log('submit to database failed');
      return {
        ...state,
        authError: "Data submit failed",
      };
    case "SUBMIT_WIP":
      // console.log('wip responses successfully submitted to database');
      /* {
        id: currentPrompt.id,
        order || multiValues,
      } */
      const newWIPState = {
        ...state.WIP,
      };
      if (payload.order) {
        newWIPState[payload.id] = payload.order;
      }
      if (payload.multiValues) {
        newWIPState[payload.id] = payload.multiValues;
      }
      return {
        ...state,
        WIP: newWIPState,
      };
    case "SUBMIT_IP":
      // console.log('ip responses successfully submitted to database');
      return state;
    case "SUBMIT_AR":
      // console.log('ar responses successfully submitted to database');
      return state;
    case "SUBMIT_VA":
      // console.log('va responses successfully submitted to database');
      return state;
    case "SUBMIT_SA":
      // console.log('sa responses successfully submitted to database');
      return state;
    case "SUBMIT_CM":
      // console.log('cm responses successfully submitted to database');
      return state;
    case "SUBMIT_CP":
      // console.log('cp responses successfully submitted to database');
      return state;
    case "SUBMIT_FP":
      // console.log('fp responses successfully submitted to database');
      return state;
      case "SUBMIT_WIP_RESULTS":
        // console.log('WIP results successfully submitted to database');
        return state;
      case "SUBMIT_IP_RESULTS":
        // console.log('IP results successfully submitted to database');
        return state;
      case "SUBMIT_AP_RESULTS":
        // console.log('AP results successfully submitted to database');
        return state;
      case "SUBMIT_TRIFECTA_RESULTS":
        // console.log('trifecta results successfully submitted to database');
        return state;
      default:
      return state;
  }
};

export default submitReducer;
