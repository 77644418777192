import React, { useState, useEffect } from 'react';
import { PaymentDisplayStates } from './Config';
import LoadingPage from '../loadingPage/LoadingPage';
// import Options from './Options';
import OptionsNoCode from './userSignedIn/OptionsNoCode';
// import Payment from './Payment';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import firebase from '../../../config/fbConfig';
import { prepareDate } from '../../results/Functions';
import PaymentUnverified from './PaymentUnverified';
import PaymentSignedOut from './userSignedOut/PaymentSignedOut';
// import { setIn } from 'formik';

const PaymentFrontPage = ({ order, user, auth }) => {
  const [display, setDisplay] = useState(PaymentDisplayStates.LOADING);
  const [ institute, setInstitute ] = useState(false);
  const [ gift, setGift ] = useState(false);
  const [ personal, setPersonal ] = useState(true);
  // const [ total, setTotal ] = useState(process.env.REACT_APP_TELOS_PACKAGE_PRICE);
  // const [ dropValue, setDropValue ] = useState(1);
  // const [ singlePurchase, setSinglePurchase ] = useState(true);   // Sets if user can purchase a single assessment.
  
  // State variables to check if the user's email has been verified.
  const [ createTime, setCreateTime ] = useState(null);
  const [ emailVerified, setEmailVerified ] = useState(null);

  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);

  let verificationStartDate = new Date(...prepareDate(process.env.REACT_APP_VERIFICATION_START_DATE)).getTime();  // In milliseconds.

  // useEffect() to check if user's account is created.
  useEffect(() => {
    try {
      // Get user's account creation time.
      if (firebase.auth().currentUser) {
        setCreateTime(firebase.auth().currentUser.metadata.creationTime);
      }

      // Get user's email verification status.
      if (auth) {
        setEmailVerified(auth.emailVerified);

        // console.log(auth.emailVerified);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth, createTime, emailVerified, verificationStartDate]);

  // // Refresh the page when the user first goes to it. This makes sure there are no states left over from when the user
  // // was signed in to a different account.
  // useEffect(() => {
  //   if (location) {
  //     if (location.pathname !== '/payment') {
  //       window.location.reload();
  //     }
  //   }
  //   setPersonal(true);
  // },[location]);

  // This is necessary to reset the states when a user signs out and signs in.
  // This only works because I switched the loading order of 'order' and user' above. Should always
  // load 'order' first since I check for 'user' first in the if-else logic below.
  // useEffect(() => {
  //   setPersonal(true);
  //   setSinglePurchase(true);
  //   setGift(false);
  // }, [user, order]);

  const history = useHistory();

  useEffect(() => {
    if (user) {
      setDisplay(PaymentDisplayStates.OPTIONS);
    }

    try {
      // Check if user is signed in.
      if (auth.isLoaded) {
        if (auth.isEmpty) {
          // User isn't signed in, so go to signed out payment page.
          // history.push('/signup');
          setDisplay(PaymentDisplayStates.SIGNED_OUT);
        } else {
          if (user) {
            if (emailVerified === true || Date.parse(createTime) < verificationStartDate) {
              // Check if single assessment was purchased.
              if (order && order.length > 0) {
                if (order[0].order) {
                  if (order[0].order.orderItems === 'Base Package' || order[0].order.orderItems === 'basePackage') { // Single assessment was purchased.
                    // setSinglePurchase(false);
                    setPurchasedOrder(true);
                  } else {
                    // setSinglePurchase(true);
                    setPurchasedOrder(false);
                  }
                  // User is signed in and exists in the database.
                  setDisplay(PaymentDisplayStates.OPTIONS);
                }
              }

              // Check if the user used Stripe to purchase a 'Deluxe Package'.
              try {
                if (auth.uid !== undefined) {
                  firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
                    snapshot.forEach(paymentDoc => {
                      let status = paymentDoc.data().status;
                      const items = paymentDoc.data().items;
                      if (items && status === 'succeeded') {
                        items.forEach(item => {
                          if (item.description === 'Deluxe Package') {
                            // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                            // product in Stripe.
                            // setSinglePurchase(false);
                            setStripePurchase(true);
                          } else {
                            setStripePurchase(false);
                          }
                        })
                      }
                    })
                  });
                }
              } catch (error) {
                console.log('Error: ', error);
              }
              
            } else if (emailVerified === false && Date.parse(createTime) > verificationStartDate ) {
              setDisplay(PaymentDisplayStates.UNVERIFIED);
            }
          } else {
            setDisplay(PaymentDisplayStates.LOADING);
          }
        }
      } else {
        setDisplay(PaymentDisplayStates.LOADING);
      }

    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, auth, order, history, createTime, emailVerified, verificationStartDate]);

  return (
    <div>
      {user &&
        <div className='paymentFrontPage'>
          {display === PaymentDisplayStates.LOADING && (
            <LoadingPage />
            )
          }
          {/* {display === PaymentDisplayStates.OPTIONS && (
            <Options
              setDisplay={setDisplay}
              gift={gift}
              setGift={setGift}
              setTotal={setTotal}
              dropValue={dropValue}
              setDropValue={setDropValue}
              personal={personal}
              setPersonal={setPersonal}
              singlePurchase={singlePurchase}
            />
            )
          } */}
          {display === PaymentDisplayStates.OPTIONS && (
            <OptionsNoCode
              setDisplay={setDisplay}
              // gift={gift}
              // setGift={setGift}
              // setTotal={setTotal}
              // dropValue={dropValue}
              // setDropValue={setDropValue}
              personal={personal}
              setPersonal={setPersonal}
              institute={institute}
              setInstitute={setInstitute}
              // singlePurchase={singlePurchase}
              user={user}
              auth={auth}
              stripePurchase={stripePurchase}
              purchasedOrder={purchasedOrder}
              gift={gift}
              setGift={setGift}
            />
            )
          }
          {/* {display === PaymentDisplayStates.PAYMENT && (
            <Payment
              setDisplay={setDisplay}
              gift={gift}
              setGift={setGift}
              total={total}
              setTotal={setTotal}
              dropValue={dropValue}
              setDropValue={setDropValue}
              personal={personal}
              setPersonal={setPersonal}
            />
            )
          } */}
          {display === PaymentDisplayStates.UNVERIFIED && (
            <PaymentUnverified auth={auth} setEmailVerified={setEmailVerified} />
            )
          }
        </div>
      }
      {!user &&
        <div className='paymentFrontPage'>
          {display === PaymentDisplayStates.SIGNED_OUT && (
            <PaymentSignedOut
            setDisplay={setDisplay}
            gift={gift}
            setGift={setGift}
            personal={personal}
            setPersonal={setPersonal}
            // institute={institute}
            // setInstitute={setInstitute}
            user={user}
            auth={auth}
            stripePurchase={stripePurchase}
            purchasedOrder={purchasedOrder}
            student={institute}
            setStudent={setInstitute}
            />
            )
          }
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    order: state.firestore.ordered.orders,
    user: state.firestore.ordered.users
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'orders', doc: props.auth.uid },
      { collection: 'users', doc: props.auth.uid }
    ]
  })
)(PaymentFrontPage)