import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import { signUp } from '../../store/actions/authActions';
import { Container, Row, Col } from 'react-bootstrap';
import './SignUp.css';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { useLastLocation } from 'react-router-last-location';
import Popup from 'reactjs-popup';

const SignUp2 = (props) => {
    const { auth, signUp, authError } = props;
    const [ state, setState ] = useState({
        email: '',
        password: '',
				confirmPassword: '',
        firstName: '',
        lastName: '',
				birthYear: undefined,
				birthMonth: undefined,		// 0 = January, 1 = February, etc.
				birthDay: undefined,
				signedUp: ''
    });
		const [ checked, setChecked ] = useState(false);

		const [ location, setLocation ] = useState(useLastLocation());
  	const [ visible, setVisible ] = useState(false);
		const [ matchPasswords, setMatchPasswords ] = useState(true);

		// For popup.
		const [ popupText ] = useState("We're sorry, but you must be 13 years or older to join Telos.");
		const [popupOpen, setPopupOpen] = useState(false);
		// Popup.
		const closeModal = () => setPopupOpen(false);

		// Close popup.
		const popupClick = (e) => {
			setPopupOpen(false);
		}

		useEffect(() => {
			if (location) {
				if (location.pathname !== '/signup' && authError) {
					window.location.reload();
				}
			}
			setVisible(true);
		},[location, authError])
	
		useEffect(() => {
			setVisible(false);
		}, [])

		// Check if state.password and state.confirmPassword are equal.
		useEffect(() => {
			// Check if password and confirm password match.
			if (state.password !== state.confirmPassword) {
				setMatchPasswords(false);
			} else {
				setMatchPasswords(true);
			}
		}, [state])

    // If auth.uid does exist, then return a redirect (route guarding).
    if (auth.uid) return <Redirect to='/assessments' />

    // Fires when a user changes an input field. This is the proper way to update a single element of an object
    // using 'useState' in functional components.
    const handleChange = (e) => {
        setState(prevState => {
        return {
            ...prevState,
            [e.target.id]: e.target.value
        }
        });
    }

		// Fires when a user checks the checkbox.
		const checkboxChange = (e) => {
			setChecked(!checked);
		};

    // When the user submits the form.
    const handleSubmit = (e) => {
        e.preventDefault();

				// Check if user is 13 or older.
				const currentAge = calculateAge(state.birthYear, state.birthMonth, state.birthDay);
				if (currentAge > 13) {
					try {
						setLocation(null);    // Set location to null when user clicks submit button to prevent the page from reloading in this case.
						signUp(state);				// Submit user data to create account.
					} catch (error) {
						console.log('Error: ', error);
					}
				} else {
					setPopupOpen(true);
				}
    }

		// Function to calculate a user's current age.
		const calculateAge = (year, month, day) => {
			const userAge = (new Date() - new Date(year, month, day))/(1000*3600*24*365.25);
			return userAge;
		}

    return (
			<Container fluid className='signup'>
				{popupOpen === true && 
					<div>
						<Popup open={popupOpen} closeOnDocumentClick onClose={closeModal}>
							<div>
								<div className='signup__popup'>
									<button className='signup__popupClose' onClick={popupClick}>
										&times;
									</button>
									<div className='signup__popupPrimary' onClick={closeModal}>
										{popupText}
									</div>
								</div>
							</div>
						</Popup>
					</div>
				}
				<Row>
					<Col md="auto">
						<p className="signup__header">
							Sign Up
						</p>
						<form onSubmit={handleSubmit}>
							<div className='signup__mini-container'>
								<input type='text' id='firstName' onChange={handleChange} placeholder='First name' className='signup__input' />
								<input type='text' id='lastName' onChange={handleChange} placeholder='Last name' className='signup__input' />
							</div>
							<div className='signup__mini-container'>
								<input type='email' id='email' onChange={handleChange} placeholder='Email address' className='signup__input' />
							</div>
							<div className='signup__mini-container'>
								<input type='password' id='password' onChange={handleChange} placeholder='Password' className='signup__input' />
							</div>
							<div className='signup__mini-container'>
								<input type='password' id='confirmPassword' onChange={handleChange} placeholder='Re-enter Password' className='signup__input' />
							</div>
							<div className='signup__mini-container'>
								<YearPicker
									defaultValue={'Birth Year'}
									start={new Date().getFullYear()-110}
									end={new Date().getFullYear()}
									reverse
									required={true}
									value={state.birthYear}
									onChange={(year) => {
										setState(prevState => {
											return {
													...prevState,
													'birthYear': year
											}
										});
									}}
									id={'birthYear'}
									name={'birthYear'}
									classes={'signup__date'}
								/>
								<MonthPicker
									defaultValue={'Month'}
									short
									endYearGiven
									year={parseInt(state.birthYear)}
									required={true}
									value={state.birthMonth}
									onChange={(month) => {
										setState(prevState => {
											return {
													...prevState,
													'birthMonth': month
											}
										});
									}}
									id={'birthMonth'}
									name={'birthMonth'}
									classes={'signup__date'}
								/>
								<DayPicker
									defaultValue={'Day'}
									year={parseInt(state.birthYear)}
									month={parseInt(state.birthMonth)}
									endYearGiven
									required={true}
									value={state.birthDay}
									onChange={(day) => {
										setState(prevState => {
											return {
													...prevState,
													'birthDay': day
											}
										});
									}}
									id={'birthDay'}
									name={'birthDay'}
									classes={'signup__date'}
								/>
							</div>
							<div className='signup__consent'>
								<input type="checkbox" className='signup__checkbox' checked={checked} onChange={checkboxChange} />
								&nbsp;I hereby consent to the&nbsp;
								<NavLink to='/termsandconditions' className='signup__links' target="_blank">
									terms
								</NavLink>
								&nbsp;and&nbsp;
								<NavLink to='/privacypolicy' className='signup__links' target="_blank">
									privacy policy
								</NavLink>.
							</div>
							<div className='signup__button-box'>
							{state.email !== '' && state.password !=='' && state.createPassword !== '' &&
							state.firstName !== '' && state.lastName !== '' && state.birthDay !== undefined &&
							state.birthMonth !== undefined && state.birthYear !== undefined && checked !== false
							&& state.password === state.confirmPassword
								? 
									<button className='signup__button'>
										Create My Account
									</button>
								:
									<button className='signup__button' disabled={true}>
										Create My Account
									</button>
							}
							</div>
							<div className='signup__error'>
								{ visible && authError ? <p>{authError}</p> : null }
								{ matchPasswords ? (null) : (<p>Passwords don't match.</p>) }
							</div>
						</form>
						<div className='signup__signin-box'>
							<div className='signup__signin'>
								Already have an account?&nbsp;
							</div>
							<NavLink to='/signin' className='signup__signin-link'>
								Sign In
							</NavLink>
						</div>
					</Col>
				</Row>
			</Container>
    )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		signUp: (newUser) => dispatch(signUp(newUser))
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SignUp2)