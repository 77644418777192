import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
// import { Container, Row, Col } from 'react-bootstrap';
import './PurchasedAssessments.css';
import Popup from 'reactjs-popup';
import { connect } from "react-redux";
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { deleteIP, deleteIPResults, resetIP, deleteWIP, deleteWIPResults, resetWIP } from '../../../store/actions/userActions';

const PurchasedAssessments = ({ user, order, deleteIP, deleteIPResults, resetIP, deleteWIP, deleteWIPResults, resetWIP }) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [openIPRetake, setOpenIPRetake] = useState(false);
  const closeIPRetake = () => setOpenIPRetake(false);

  const [openWIPRetake, setOpenWIPRetake] = useState(false);
  const closeWIPRetake = () => setOpenWIPRetake(false);

  const lastLocation = useLastLocation();
  
  
  useEffect(() => {
    if (lastLocation) {
      if (lastLocation.pathname === '/payment') {
        if (order) {
          if (order[0]) {
            const timeDiffMinutes = (new Date().getTime()/1000 - order[0].order.orderCreatedDate.seconds)/60;
            if (timeDiffMinutes < 1) {
              setOpen(true);
            }
          }
        }
      }
    }
  }, [lastLocation, order]);

  // Close popup.
  const popupClick = (e) => {
    setOpen(false);
    setOpenIPRetake(false);
    setOpenWIPRetake(false);
  }

  // Open retake IP assessment popup.
  const retakeIPPopup = (e) => {
    // Popup window.
    setOpenIPRetake(true);
  }

  // Open retake WIP assessment popup.
  const retakeWIPPopup = (e) => {
    // Popup window.
    setOpenWIPRetake(true);
  }

  // Retake IP assessment.
  const retakeIPAssessment = (e) => {
    // Delete previous assessment results and any calculated IP results.
    deleteIP();
    deleteIPResults();

    // Set ipComplete to false and delete ipDate field.
    // Change ipCalculated to false and delete ipCalculateDate.
    // Set ipJobsCalculated to false.
    resetIP(); 
  }
  
  // Retake WIP assessment.
  const retakeWIPAssessment = (e) => {
    // Delete previous assessment results and any calculated WIP results.
    deleteWIP();
    deleteWIPResults();

    // Set wipComplete to false and delete wipDate field.
    // Change wipCalculated to false and delete wipCalculateDate.
    // Set wipJobsCalculated to false.
    resetWIP(); 
  }


  // Check whether each assessment has been completed.
  let ipComplete = null;
  let wipComplete = null;
  let apComplete = null;

  if (user) {
    try {
      ipComplete = user[0].ipComplete;
      wipComplete = user[0].wipComplete;
      if (user[0].arComplete && user[0].vaComplete && user[0].saComplete && user[0].cmComplete && user[0].cpComplete && user[0].fpComplete) {
        apComplete = true;
      }
      else {
        apComplete = false;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Pictures.
  const ipPic = `/images/assessmentsImages/01-interests.png`;
  const wipPic = `/images/assessmentsImages/02-workImportance.png`;
  const apPic = `/images/assessmentsImages/03-abilities.png`;

  return (
    <div className="purchased">
      {open === true
      && 
        <div>
          <Popup open={open} closeOnDocumentClick onClose={closeModal}>
            <div>
              <div className='purchased-assessments-popup'>
                <button className='purchased-assessments-popup-close' onClick={popupClick}>
                  &times;
                </button>
                <div className='purchased-assessments-popup-primary' onClick={closeModal}>
                  Thank you for your order.
                </div>
                <div className="purchased-assessments-popup-sub">
                  A receipt has been sent to the email associated with your account.
                  <br /><br />
                  Click outside this window to access your assessments.
                </div>
              </div>
            </div>
          </Popup>
        </div>
      }
      {openIPRetake === true &&
        <div>
          <Popup open={openIPRetake} closeOnDocumentClick onClose={closeIPRetake}>
            <div>
              <div className='purchased-assessments-popup'>
                <button className='purchased-assessments-popup-close' onClick={popupClick}>
                  &times;
                </button>
                <div className='purchased-assessments-popup-primary'>
                  Are You Sure?
                </div>
                <div className="purchased-assessments-popup-sub">
                  <div className='retake-text_yes_container'>
                    <NavLink to='/ipassessment' onClick={retakeIPAssessment} className='retake-text_yes'>
                      Yes! Delete My Previous Results<br />and Retake this Profiler
                      <span> {'>>'} </span>
                    </NavLink>
                  </div>
                  <div className='retake-text_no_container'>
                    <button className='retake-text_no' onClick={closeIPRetake}>
                      No, I'll keep my original results.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      }
      {openWIPRetake === true &&
        <div>
          <Popup open={openWIPRetake} closeOnDocumentClick onClose={closeWIPRetake}>
            <div>
              <div className='purchased-assessments-popup'>
                <button className='purchased-assessments-popup-close' onClick={popupClick}>
                  &times;
                </button>
                <div className='purchased-assessments-popup-primary'>
                  Are You Sure?
                </div>
                <div className="purchased-assessments-popup-sub">
                  <div className='retake-text_yes_container'>
                    <NavLink to='/vpassessment' onClick={retakeWIPAssessment} className='retake-text_yes'>
                      Yes! Delete My Previous Results<br />and Retake this Profiler {'>>'}
                    </NavLink>
                  </div>
                  <div className='retake-text_no_container'>
                    <button className='retake-text_no' onClick={closeWIPRetake}>
                      No, I'll keep my original results.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      }
      <div className='purchased__main'>
        <div className='purchased__header'>
          Click on the links below to begin your Telos assessments.    
        </div>
        <div className='purchased__subheader'>
        Before taking any of the assessments, please make sure that you have the correct amount of time allotted for them available. 
        </div>
        <div className='purchased__subheader'>
          <span>
            You will only be able to take each section of the Abilities Profiler once
          </span>
          &nbsp;(please contact us&nbsp;
          {/* <NavLink
            to={{
              pathname:'/aboutus',
              state: {title:'from home page'}  
            }}
            className='purchased__inline'
            target="_blank"
          >
            here
          </NavLink> */}
          <a
            href="mailto:info@knowtelos.com"
            className = 'purchased__inline'
          >
            here
          </a>
          &nbsp;if there are extenuating circumstances while
          taking an assessment.) 
        </div>
        
        <div className="purchased__box">
          {ipComplete ? (
            <div>
              <div className='purchased__overlay'>
                <div className="purchased__overlayText">
                  <div>
                    Interest Profiler Complete!
                  </div>
                  <button className='purchased__retake' onClick = {retakeIPPopup}>
                    retake
                  </button>
                </div>
              </div>
              <div className="purchased__button">
                <div className="purchased__image">
                  <img src={ipPic} alt="ip-pic" className='purchased__imageSelf' />
                </div>
                <div className="purchased__words">
                  <div className='purchased__title'>
                    Assessment 1: Interest Profiler
                  </div>
                  <div className='purchased__times'>
                    <div className='purchased__buttonSubtitle'>
                      Time Commitment =
                    </div>
                    <div className="purchased__time">
                      &nbsp;30 minutes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NavLink to="/ipassessment" className='purchased__navlink'>
              <button className="purchased__button">
                <div className="purchased__image">
                  <img src={ipPic} alt="ip-pic" className='purchased__imageSelf' />
                </div>
                <div className="purchased__words">
                  <div className='purchased__title'>
                    Assessment 1: Interest Profiler<span>{'>>'}</span>
                  </div>
                  <div className='purchased__times'>
                    <div className='purchased__buttonSubtitle'>
                      Time Commitment =
                    </div>
                    <div className="purchased__time">
                      &nbsp;30 minutes
                    </div>
                  </div>
                </div>
              </button>
            </NavLink>
          )}

          {wipComplete ? (
            <div className='purchased__complete'>
              <div className='purchased__overlay'>
                <div className="purchased__overlayText">
                    Values Profiler Complete!
                  <button className='purchased__retake' onClick = {retakeWIPPopup}>
                    retake
                  </button>
                </div>
              </div>
              <div className="purchased__button">
                <div className="purchased__image">
                  <img src={wipPic} alt="wip-pic" className='purchased__imageSelf' />
                </div>
                <div className="purchased__words">
                  <div className='purchased__title'>
                    Assessment 2: Values Profiler
                  </div>
                  <div className='purchased__times'>
                    <div className='purchased__buttonSubtitle'>
                      Time Commitment =
                    </div>
                    <div className="purchased__time">
                      &nbsp;20 minutes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
          <NavLink to="/vpassessment" className='purchased__navlink'>
            <button className="purchased__button">
              <div className="purchased__image">
                <img src={wipPic} alt="wip-pic" className='purchased__imageSelf' />
              </div>
              <div className="purchased__words">
                <div className='purchased__title'>
                  Assessment 2: Values Profiler<span>{'>>'}</span>
                </div>
                <div className='purchased__times'>
                  <div className='purchased__buttonSubtitle'>
                    Time Commitment =
                  </div>
                  <div className="purchased__time">
                    &nbsp;20 minutes
                  </div>
                </div>
              </div>
            </button>
          </NavLink>
          )}

          {apComplete ? (
            <div>
              <div className='purchased__overlay'>
                <div className="purchased__overlayText">
                  Abilities Profiler Complete!
                </div>
              </div>
              <div className="purchased__button">
                <div className="purchased__image">
                  <img src={apPic} alt="ap-pic" className='purchased__imageSelf' />
                </div>
                <div className="purchased__words">
                  <div className='purchased__title'>
                    Assessment 3: Abilities Profiler
                  </div>
                  <div className='purchased__times'>
                    <div className='purchased__buttonSubtitle'>
                      Time Commitment =
                    </div>
                    <div className="purchased__time">
                      &nbsp;5-20 minutes each
                    </div>
                  </div>
                  <div className='purchased__buttonSubSubtitle'>
                    6 sections that can be done individually
                  </div>
                </div>
              </div>  
            </div>
          ) : (
          <NavLink to="/apassessment" className='purchased__navlink'>
            <button className="purchased__button">
              <div className="purchased__image">
                <img src={apPic} alt="ap-pic" className='purchased__imageSelf' />
              </div>
              <div className="purchased__words">
                <div className='purchased__title'>
                  Assessment 3: Abilities Profiler<span>{'>>'}</span>
                </div>
                <div className='purchased__times'>
                  <div className='purchased__buttonSubtitle'>
                    Time Commitment =
                  </div>
                  <div className="purchased__time">
                    &nbsp;5-20 minutes each
                  </div>
                </div>
                <div className='purchased__buttonSubSubtitle'>
                  6 sections that can be done individually
                </div>
              </div>
            </button>  
          </NavLink>
          )}

        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError,
    user: state.firestore.ordered.users,
    order: state.firestore.ordered.orders
	};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteIP: (input) => dispatch(deleteIP(input)),
    deleteIPResults: (input) => dispatch(deleteIPResults(input)),
    resetIP: (input) => dispatch(resetIP(input)),
    deleteWIP: (input) => dispatch(deleteWIP(input)),
    deleteWIPResults: (input) => dispatch(deleteWIPResults(input)),
    resetWIP: (input) => dispatch(resetWIP(input))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid },
      { collection: 'orders', doc: props.auth.uid }
    ]
  })
)(PurchasedAssessments);

// export default PurchasedAssessments