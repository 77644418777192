import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CP.css';

const InstructionsQ345 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled3, setDisabled3 ] = useState(true);
  const [ disabled4, setDisabled4 ] = useState(true);
  const [ disabled5, setDisabled5 ] = useState(true);
  const [ correct3, setCorrect3 ] = useState(false);
  const [ correct4, setCorrect4 ] = useState(false);
  const [ correct5, setCorrect5 ] = useState(false);
  const [ currentQuestion3 ] = useState(2);
  const [ currentQuestion4 ] = useState(3);
  const [ currentQuestion5 ] = useState(4);

  useEffect(() => {
    if (practiceAnswers[currentQuestion3].values !== '') {
      setDisabled3(false);
    } else {
      setDisabled3(true);
    }

    if (practiceAnswers[currentQuestion4].values !== '') {
      setDisabled4(false);
    } else {
      setDisabled4(true);
    }

    if (practiceAnswers[currentQuestion5].values !== '') {
      setDisabled5(false);
    } else {
      setDisabled5(true);
    }

    if (practiceAnswers[currentQuestion3].values === 'D') {
      setCorrect3(true);
    } else {
      setCorrect3(false);
    }

    if (practiceAnswers[currentQuestion4].values === 'S') {
      setCorrect4(true);
    } else {
      setCorrect4(false);
    }

    if (practiceAnswers[currentQuestion5].values === 'D') {
      setCorrect5(true);
    } else {
      setCorrect5(false);
    }
  }, [practiceAnswers, currentQuestion3, currentQuestion4, currentQuestion5]);

  // Answer options.
  const answerOptions3 = [
    'S',
    'D'
  ];

  const answerOptions4 = [
    'S',
    'D'
  ];

  const answerOptions5 = [
    'S',
    'D'
  ];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // Question 3
    if (name === String(currentQuestion3)) {
      const previousAnswer = practiceAnswers[currentQuestion3].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion3,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion3) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }


    // Question 4
    if (name === String(currentQuestion4)) {
      const previousAnswer = practiceAnswers[currentQuestion4].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion4,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion4) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }

    // Question 5
    if (name === String(currentQuestion5)) {
      const previousAnswer = practiceAnswers[currentQuestion5].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion5,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion5) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }
  }

  return (
    <div className='cp-main-box cp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 5 - Name Comparison
      </div>
      <div className='cp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Now do the next six practice questions in the same way. If the names are <i>exactly</i> the <i>same</i>, select
        the circle under <b>S</b>. If they are <i>different in any way</i>, select the oval under <b>D</b>.
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          3.
        </div>
        <div className="cp-question__content">
          Brimms Co. &ndash; Brimms Company
        </div>

          <div className="cp-question__answers">
            {
              answerOptions3.map((element, index) => (
                <label className="cp-question__answer-option" key={index}>
                  <div className="cp-question__answer-text">
                    {element}
                  </div>
                  <input
                    type="checkbox"
                    className='question__radio-button question__radio-button-cp'
                    checked={practiceAnswers[currentQuestion3].values === element}
                    name={currentQuestion3}
                    value={element}
                    onChange={e => handleAnswer(e)}
                  />
                </label>
              ))
            }
          </div>
            { practiceAnswers[currentQuestion3].values !== '' || practiceAnswers[currentQuestion3].values.length > 0 ? (
              correct3 ? (
                <div className="cp-display-answer cp-display-answer-q3">
                  Correct
                </div>
              ) : (
                <div className="cp-display-answer cp-display-answer-q3">
                  Incorrect
                </div>
              )
          ) : (
            <div className="cp-display-answer cp-display-answer-q3"></div>
          )
          }
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          4.
        </div>
        <div className="cp-question__content">
          Wesson & Wyle &ndash; Wesson & Wyle
        </div>

        <div className="cp-question__answers">
          {
            answerOptions4.map((element, index) => (
              <label className="cp-question__answer-option" key={index}>
                <div className="cp-question__answer-text">
                  {element}
                </div>
                <input
                  type="checkbox"
                  className='question__radio-button question__radio-button-cp'
                  checked={practiceAnswers[currentQuestion4].values === element}
                  name={currentQuestion4}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            ))
          }
        </div>
        { practiceAnswers[currentQuestion4].values !== '' || practiceAnswers[currentQuestion4].values.length > 0 ? (
          correct4 ? (
            <div className="cp-display-answer cp-display-answer-q3">
              Correct
            </div>
          ) : (
            <div className="cp-display-answer cp-display-answer-q3">
              Incorrect
            </div>
          )
        ) : (
          <div className="cp-display-answer cp-display-answer-q3"></div>
        )
        }
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          5.
        </div>
        <div className="cp-question__content">
          Remington, D. E. &ndash; Remington, D. F.
        </div>

        <div className="cp-question__answers">
          {
            answerOptions5.map((element, index) => (
              <label className="cp-question__answer-option" key={index}>
                <div className="cp-question__answer-text">
                  {element}
                </div>
                <input
                  type="checkbox"
                  className='question__radio-button question__radio-button-cp'
                  checked={practiceAnswers[currentQuestion5].values === element}
                  name={currentQuestion5}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            ))
          }
        </div>
        { practiceAnswers[currentQuestion5].values !== '' || practiceAnswers[currentQuestion5].values.length > 0 ? (
          correct5 ? (
            <div className="cp-display-answer cp-display-answer-q3">
              Correct
            </div>
          ) : (
            <div className="cp-display-answer cp-display-answer-q3">
              Incorrect
            </div>
          )
          ) : (
            <div className="cp-display-answer cp-display-answer-q3"></div>
          )
          }
      </div>

      <div className="cp-row-2 cp-row-2-spaced cp-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Back
        </button>
        { disabled3 === true || disabled4 === true || disabled5 === true ? (
          <button
            type='button'
            disabled={disabled3 === true || disabled4 === true || disabled5 === true}
            className="ap-instructions-button"
            style={{ backgroundColor: 'grey' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q678);
            }}
          >
            Next
          </button>
        ) : (
          <button
            type='button'
            className="ap-instructions-button"
            style={{ backgroundColor: '#008375' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q678);
            }}
          >
            Next
          </button>
        ) }
      </div>

    </div>
  )
}

export default InstructionsQ345