import React from "react";
import { WIPDisplayStates } from "./Configs";
import './WIPInstructions.css';

const WIPInstructionsA = ({ onClick }) => {
  return (
    <div className='wipInstructions'>
        <div className="wipInstructions__header">
          Assessment 2: Values Profiler
        </div>
        <div className='wipInstructions__subheader'>
          Instructions
        </div>
        <div className="wipInstructions__sectionheader">
          Answer Questions By Dragging
        </div>
        <div className="wipInstructions__body">
          Welcome to the Values Profiler assessment. The first half of this assessment consists of
          drag-and-drop style questions. Click on the answer, and while holding the button down move 
          your mouse up or down to drag the answer. When you are over the location where you would like 
          the answer inserted release your mouse button to drop the answer into the list. When the 
          answers are ordered the way you want them click the next arrows.
        </div>
        <div className="wipInstructions__body">
          At any point during the Profiler you can change your answer to a question. Simply use your
          mouse to click on the previous or next buttons to get to the page where you want to change your answer.
          Then select your new answer.
        </div>
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_B);
          }}
        >
          Next
        </button>
    </div>
  );
};

export default WIPInstructionsA;
