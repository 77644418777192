import React, { useState } from 'react';
import { WIPDisplayStates } from '../Configs';
import { ReadyPopup } from '../../../../../../Utils/ReadyPopup';

const InstructionsFinal = ({ setDisplay }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className='sa-main-box sa-main-box-practice'>
      <ReadyPopup
        onClick = {setDisplay}
        readyDestination = {WIPDisplayStates.ASSESSMENT}
        open = {open}
        setOpen = {setOpen}
      />
      <div className="ap-instructions-header">
        Assessments 3: Part 3 - Three-Dimensional Space
      </div>
      <div className='sa-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        On the next pages are more problems like the ones you've just answered. Only <b><i>one </i></b>
        object can be made from the flat shape by folding <i>or</i> rolling <i>or</i> both. For each
        problem, click on the circle under your answer.
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        Work <b>CAREFULLY</b>. You should have enough time to answer each problem. It is to your advantage
        to <b>ANSWER EVERY PROBLEM</b>. Even if you're not sure of an answer, make your <b>BEST GUESS</b>.
        You'll receive one point for each correct answer. Points will not be subtracted for questions you
        answer incorrectly.
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        If you finish before the time is up, you may go back and check your work.
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        <b style={{ fontWeight: '800' }}>You will have 8 minutes to complete this part.</b>
      </div>
      <div className="sa-row-2 sa-row-2-spaced sa-row-2-practice">
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q5);
            }}  
          >
            Back
          </button>
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => setOpen(true)}
          >
            Begin
          </button>
      </div>
    </div>
  )
}

export default InstructionsFinal
