import React, { useEffect, useState } from 'react';
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import WIPListNoData from '../WIPListNoData';
import WIPCalculate from '../wip/WIPCalculate';

import WIPList2 from '../WIPList2';

const Values = ({ user, wip, wipresults }) => {
  const [ wipData, setWIPData ] = useState(null);

  // Get WIP results.
  useEffect(() => {
    // WIP results data.
    try {
      if (wipresults && wipresults.length > 0 && (user[0].wipCalculated === true)) {
        const achieveAnswers = wipresults[0].achieveZScore;
        const indepAnswers = wipresults[0].indepZScore;
        const recogAnswers = wipresults[0].recogZScore;
        const relateAnswers = wipresults[0].relateZScore;
        const supportAnswers = wipresults[0].supportZScore;
        const workAnswers = wipresults[0].workZScore;

        const tempWIPData = [
          {name: 'Achievement', color: '#F5EA61', value: achieveAnswers, id: 0},
          {name: 'Independence', color: '#93D500', value: indepAnswers, id: 1},
          {name: 'Recognition', color: '#007960', value: recogAnswers, id: 2},
          {name: 'Relationships', color: '#009ADE', value: relateAnswers, id: 3},
          {name: 'Support', color: '#8CB7C9', value: supportAnswers, id: 4},
          {name: 'Working Conditions', color: '#C2A6E1', value: workAnswers, id: 5}
        ];

        setWIPData(tempWIPData);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, wipresults]);

  return (
    <div className='values'>
      {
        user ? (  // Check that the user is logged in.
          user[0].wipComplete ? ( // Check that the user has completed this assessment.
            (user[0].wipCalculated === true && user[0].wipJobsCalculated) ? ( // Check that the user's results have been calculated.
              wipData ? ( // Check that the user's results are loaded.
                <WIPList2 wipData={wipData} /> // Display results.
              ) : (
                <LoadingPage /> // Wait for results to load.
              ) 
            ) : (
              wip ? ( // Check for assessment answers.
                <WIPCalculate wip={wip} />  // Calculate results.
              ) : (
                <LoadingPage /> // Wait for assessment answers to load.
              )
            )
          ) : (
            <WIPListNoData /> // Show if the user hasn't completed this assessment.
          )
        ) : (
          <LoadingPage /> // Wait for user auth to load, or display if user isn't logged in.
        )
      }
    </div>
  )
}

export default Values;