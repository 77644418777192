import './TableBody.css';

const TableBody = ({ tableData, columns, handleOpenPopup, handleClosePopup }) => {

  return (
   <tbody>
    {tableData.map((data, ind) => {
     return (
      <tr key={ind}>
        <td key={ind}>{ind+1}.</td>
        {columns.map(({ accessor }, ind) => {
          const tData = data[accessor] ? data[accessor] : "——";
          if (accessor === 'status' && data.UID) {
            return (
              <td key={ind}>
                { tData === 'Unpaid' ? (
                <button onClick={() => handleOpenPopup(data)} className='tableBody__button'>
                  {tData}
                </button>
                ) : (
                  tData
                ) }
              </td>
            )
          } else {
            return (
              <td key={ind}>{tData}</td>
            )
          }
        })}
      </tr>
     );
    })}
   </tbody>
  );
 };

 export default TableBody;