import React from 'react';
import './TableBody.css';

const TableBody = ({ tableData, columns, handleCheckboxChange, showArchived }) => {
  // Filter tableData based on showArchived prop
  const filteredTableData = showArchived ? tableData : tableData.filter(data => !data.archived);

  return (
    <tbody>
      {filteredTableData.map((data, ind) => (
        <tr key={ind}>
          <td key={ind} className='codeTableBody__cell'>{ind + 1}.</td>
          {columns.map(({ accessor }, colIndex) => (
            <td key={colIndex} className='codeTableBody__cell'>
              {accessor === 'archived' ? (
                <input
                  type="checkbox"
                  checked={data[accessor] === true}
                  onChange={() => handleCheckboxChange(ind)}
                />
              ) : (
                data[accessor] ? data[accessor] : "——"
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
