export const ResultDisplayStates = {
  LOADING: 'LOADING',
  SINGLE_COMPLETE: 'SINGLE_COMPLETE',
  NOT_PURCHASED: 'NOT_PURCHASED',
  NONE_COMPLETE: 'NONE_COMPLETE',
  SIGNED_OUT: 'SIGNED_OUT'
};

export const IPChartText = [
  {
    header: 'Realistic',
    text: 'Realistic types ("do-ers") are those who tend to work with their hands and solve real-world problems.'
  },
  {
    header: 'Investigative',
    text: 'Investigative types ("thinkers") are those who tend to work with data and information.'
  },
  {
    header: 'Artistic',
    text: 'Artistic types ("creators") are those who tend to self-express in one or more mediums, such as music, words, or materials.'
  },
  {
    header: 'Social',
    text: 'Social types ("helpers") are those who tend to be concerned with the well-being of others and deal with problems through feelings.'
  },
  {
    header: 'Enterprising',
    text: 'Enterprising types ("persuaders") are those who tend to be heavily goal-oriented and want to see results.'
  },
  {
    header: 'Conventional',
    text: 'Conventional types ("organizers") are those who tend to be detail-oriented and conscientious.'
  }
];

export const WIPListText = [
  {
    header: 'Achievement',
    text: 'The Achievement work value involves the need to use your individual abilities and have a feeling of accomplishment.'
  },
  {
    header: 'Independence',
    text: 'The Independence work value refers to the need to do tasks on your own and use creativity in the workplace. It also involves the need to get a job where you can make your own decisions.'
  },
  {
    header: 'Recognition',
    text: 'The Recognition work value involves the need to have the opportunity for advancement, obtain prestige, and have the potential for leadership.'
  },
  {
    header: 'Relationships',
    text: 'The Relationships work value includes the need for friendly co-workers, to be able to help others, and not be forced to go against your sense of right and wrong.'
  },
  {
    header: 'Support',
    text: "The Support work value involves the need for a supportive company, to be compfortable with management's style of supervision, and competent, considerate, and fair management."
  },
  {
    header: 'Working Conditions',
    text: "The Working Conditions work value refers to the need to have your pay comparable to others, and have job security and good working conditions. You also need to be busy all the time and have many different types of tasks on the job."
  }
];

export const APChartText = [
  {
    header: 'Verbal Ability',
    text: 'Verbal ability is understanding the meaning of words and using them correctly in good communication when you listen, speak, or write.'
  },
  {
    header: 'Arithmetic Reasoning',
    text: 'Arithmetic reasoning is an ability that uses several math skills and logical thinking to solve problems in everyday situations.'
  },
  {
    header: 'Computation',
    text: 'Computation ability is using arithmetic operations to solve everyday problems involving numbers.'
  },
  {
    header: 'Spatial Ability',
    text: 'Spatial ability is being able to form pictures of objects in your mind.'
  },
  {
    header: 'Form Perception',
    text: 'Form perception is the ability to see details in objects, pictures, or drawings quickly and correctly.'
  },
  {
    header: 'Clerical Perception',
    text: ' Clerical perception is the ability to see details in written materials quickly and correctly.'
  }
];