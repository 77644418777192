import React, { useState, useEffect } from 'react';
import './Profile.css';
import { compose } from 'redux';
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase';
import LoadingPage from '../loadingPage/LoadingPage';
import { firstNameSubmit, lastNameSubmit, emailSubmit, birthDaySubmit, birthMonthSubmit, birthYearSubmit } from '../../../store/actions/userActions';
import Popup from 'reactjs-popup';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
};

const securityPic = `/images/profileImages/Security-Icon.png`;

const Profile = ({ user, firstNameSubmit, lastNameSubmit, emailSubmit, birthDaySubmit, birthMonthSubmit, birthYearSubmit }) => {
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  // const [ password, setPassword ] = useState('********');
  const [ birthDay, setBirthDay ] = useState('');
  const [ birthMonth, setBirthMonth ] = useState('');
  const [ birthYear, setBirthYear ] = useState('');
  const [ firstNameEdit, setFirstNameEdit ] = useState(false);
  const [ lastNameEdit, setLastNameEdit ] = useState(false);
  // const [ emailEdit, setEmailEdit ] = useState(false);
  const [ birthDateEdit, setBirthDateEdit ] = useState(false);

  // For popup.
  const [ popupText, setPopupText ] = useState('Info');
  // const [ open, setOpen ] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  // Popup.
  const closeModal = () => setPopupOpen(false);

  // Close popup.
  const popupClick = (e) => {
    setPopupOpen(false);
  }

  useEffect(() => {
    try {
      if (user){
        if (user[0] !== null) {
          setFirstName(user[0].firstName);
          setLastName(user[0].lastName);
          setEmail(user[0].email);
          setBirthDay(user[0].birthDay);
          setBirthYear(user[0].birthYear);
          setBirthMonth(user[0].birthMonth);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user]);

  const firstNameChange = (e) => {
    try {
      if (firstNameEdit === true && firstName !== user[0].firstName) {
        firstNameSubmit(firstName);
        setPopupText('First Name');
        setPopupOpen(true);
      }
      setFirstNameEdit(!firstNameEdit);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  const lastNameChange = (e) => {
    try {
      if (lastNameEdit === true && lastName !== user[0].lastName) {
        lastNameSubmit(lastName);
        setPopupText('Last Name');
        setPopupOpen(true);
      }
      setLastNameEdit(!lastNameEdit);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // const emailChange = (e) => {
  //   try {
  //     if (emailEdit === true && email !== user[0].email) {
  //       emailSubmit(email);
  //       setPopupText('Email');
  //       setPopupOpen(true);
  //     }
  //     setEmailEdit(!emailEdit);
  //   } catch (error) {
  //     console.log('Error: ', error);
  //   }
  // }

  const birthDateChange = (e) => {
    try {
      if (birthDateEdit === true && ( birthDay !== user[0].birthDay || birthMonth !== user[0].birthMonth || birthYear !== user[0].birthYear )) {
        birthDaySubmit(birthDay);
        birthMonthSubmit(birthMonth);
        birthYearSubmit(birthYear);
        setPopupText('Birth Date');
        setPopupOpen(true);
      }
      setBirthDateEdit(!birthDateEdit);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  const handleFirstNameAnswer = (e) => {
    const { value } = e.target;
    setFirstName(value);
  }

  const handleLastNameAnswer = (e) => {
    const { value } = e.target;
    setLastName(value);
  }

  // const handleEmailAnswer = (e) => {
  //   const { value } = e.target;
  //   setEmail(value);
  // }

  const handleBirthDayAnswer = (day) => {
    if (day === undefined || day === '') {
      setBirthDay(birthDay);  // Don't change the value if user selects 'Day' option from dropdown.
    } else {
      setBirthDay(day);
    }
  }

  const handleBirthMonthAnswer = (month) => {
    if (month === undefined || month === '') {
      setBirthMonth(birthMonth);  // Don't change the value if user selects 'Month' option from dropdown.
    } else {
      setBirthMonth(month);
    }
  }

  const handleBirthYearAnswer = (year) => {
    if (year === undefined || year === '') {
      setBirthYear(birthYear);  // Don't change the value if user selects 'Year' option from dropdown.
    } else {
      setBirthYear(year);
    }
  }

  return (
    <div className='profile'>

      {popupOpen === true && 
        <div>
          <Popup open={popupOpen} closeOnDocumentClick onClose={closeModal}>
            <div>
              <div className='profile__popup'>
                <button className='profile__popupClose' onClick={popupClick}>
                  &times;
                </button>
                <div className='profile__popupPrimary' onClick={closeModal}>
                  Thank you!<br />Your {popupText} was Successfully Changed.
                </div>
              </div>
            </div>
          </Popup>
        </div>
      }

      {user && 
        <div className='profile__main'>
          <div className="profile__account">
            <div className="profile__heading1">
              Account Information
            </div>
            
            <div className="profile__row">
              <div className="profile__heading2">
                First Name
              </div>
                {firstNameEdit ? (
                  <>
                    <input
                      className="profile__data profile__data-input"
                      placeholder='First Name'
                      defaultValue={firstName}
                      onChange={e => handleFirstNameAnswer(e)}
                      maxLength={11}
                    />
                    <button className="profile__edit profile__edit-on" onClick={firstNameChange}>
                      {">>"}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="profile__data">
                      {firstName}
                    </div>
                    <button className="profile__edit" onClick={firstNameChange}>
                        Edit
                    </button>
                  </>
                )}
            </div>

            <div className="profile__row">
              <div className="profile__heading2">
                Last Name
              </div>
              {lastNameEdit ? (
                <>
                  <input
                    className="profile__data profile__data-input"
                    placeholder='Last Name'
                    defaultValue={lastName}
                    onChange={e => handleLastNameAnswer(e)}
                  />
                  <button className="profile__edit profile__edit-on" onClick={lastNameChange}>
                    {">>"}
                  </button>
                </>
              ) : (
                <>
                  <div className="profile__data">
                    {lastName}
                  </div>
                  <button className="profile__edit" onClick={lastNameChange}>
                      Edit
                  </button>
                </>
              )}
            </div>

            <div className="profile__row">
              <div className="profile__heading2">
                Email
              </div>
              <div className="profile__data">
                {email}
              </div>
              {/* {emailEdit ? (
                <>
                  <input
                    className="profile__data profile__data-input"
                    placeholder='Email'
                    defaultValue={email}
                    onChange={e => handleEmailAnswer(e)}
                    type='email'
                  />
                  <button className="profile__edit profile__edit-on" onClick={emailChange}>
                    {">>"}
                  </button>
                </>
              ) : (
                <>
                  <div className="profile__data">
                    {email}
                  </div>
                  <button className="profile__edit" onClick={emailChange}>
                      Edit
                  </button>
                </>
              )} */}
            </div>

            <div className="profile__row">
              <div className="profile__heading2">
                Birth Date
              </div>
                {birthDateEdit ? (
                  <>
                    <MonthPicker
                      defaultValue={'Month'}
                      short
                      endYearGiven
                      year={parseInt(birthYear)}
                      required={true}
                      value={birthMonth}
                      onChange={month => handleBirthMonthAnswer(month)}
                      id={'birthMonth'}
                      name={'birthMonth'}
                      classes={'profile__data profile__data-dropdown'}
                    />
                    <DayPicker
                      defaultValue={'Day'}
                      year={parseInt(birthYear)}
                      month={parseInt(birthMonth)}
                      endYearGiven
                      required={true}
                      value={birthDay}
                      onChange={day => handleBirthDayAnswer(day)}
                      id={'birthDay'}
                      name={'birthDay'}
                      classes={'profile__data profile__data-dropdown'}
                    />
                    <YearPicker
                      defaultValue={'Year'}
                      start={new Date().getFullYear()-110}
                      end={new Date().getFullYear()}
                      reverse
                      required={true}
                      value={birthYear}
                      onChange={year => handleBirthYearAnswer(year)}
                      id={'birthYear'}
                      name={'birthYear'}
                      classes={'profile__data profile__data-dropdown'}
                    />
                    <button className="profile__edit profile__edit-on" onClick={birthDateChange}>
                      {">>"}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="profile__data">
                      {months[birthMonth]} {birthDay}, {birthYear}
                    </div>
                    <button className="profile__edit" onClick={birthDateChange}>
                        Edit
                    </button>
                  </>
                )}
            </div>

            {/* <div className="profile__row">
              <div className="profile__heading2">
                Birth Date
              </div>
              <div className="profile__data">
                {birthMonth} {birthDay}, {birthYear}
              </div>
            </div> */}

          </div>

          {/* <div className="profile__contact">
            If you need to change your birth date, please email Customer Service at <span>hi@telos.com</span>.
          </div> */}

        </div>
      }
      {!user && <LoadingPage />}
      <div className="profile__security">
        <img src={securityPic} alt="security" className="profile__image" />
        <div className="profile__secureText">
          Privacy guaranteed, we'll never share your data without permission.
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    user: state.firestore.ordered.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    firstNameSubmit: (submit) => dispatch(firstNameSubmit(submit)),
    lastNameSubmit: (submit) => dispatch(lastNameSubmit(submit)),
    emailSubmit: (submit) => dispatch(emailSubmit(submit)),
    birthDaySubmit: (submit) => dispatch(birthDaySubmit(submit)),
    birthMonthSubmit: (submit) => dispatch(birthMonthSubmit(submit)),
    birthYearSubmit: (submit) => dispatch(birthYearSubmit(submit))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'users', doc: props.auth.uid }
    ]
  })
)(Profile);