import React, { useState, useEffect } from 'react';
import Timer from '../../../../timer/Timer';
import FPMultiChoice3 from './FPMultiChoice3';
import { connect } from "react-redux";
import { fpSubmit, fpTime, fpCompleteSubmit } from '../../../../../store/actions/submitActions';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { WIPDisplayStates, initialState } from './Configs';
import firebase from '../../../../../config/fbConfig';

const FPAssessment2 = ({ fpSubmit, fpTime, setDisplay, fpCompleteSubmit, auth }) => {
  const timeLimit = 5 * 60;   // Time limit for this section, in seconds.
  const [ counter, setCounter ] = useState(timeLimit);
  // Keep track of the question number within this component.
  const [ count, setCount ] = useState(null);

  // Total number of questions.
  const numTotalQuestions = 42;

  // Create a state array for the answers.
  const [ fpAnswers, setFPAnswers ] = useState([...initialState]);    // Store FP answers.

  // Most recent question answered.
  const [ last, setLast ] = useState(0);

  // Watch currentPromptId for changes, update initial display order upon change.
  useEffect(() => {
    
    let isMounted = true; // Add boolean to handle no-op warning.

    // Get data from this user's 'fp' document, if it exists.
    const getData = firebase.firestore().collection('fp').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let fbFPAnswers = null; // Firebase answers.
        let newCounter = 0;

        if (doc.exists) {
          fbFPAnswers = doc.data().fpAnswers;
          if (doc.data().timeLeft) {
            if (doc.data().timeLeft.counter) {
              newCounter = doc.data().timeLeft.counter;
            } else {
              newCounter = timeLimit;
            }
          } else {
            newCounter = timeLimit;
          }

          if (count === null) {
            // If the doc exists, that means the user has answered at least one question at some point.
            // If count is null, that means the user either just started for the first time or reloaded the assessment.
            setCounter(newCounter);
          }
        }

        let filledFPAnswers = null;
        let countValue = 0;
        try {
          if (fbFPAnswers) {
            filledFPAnswers = fbFPAnswers.filter(e => e.values !== "");
            // Get maximum id value from filledFPAnswers.
            if (filledFPAnswers.length > 0) {
              const maxValue = filledFPAnswers.reduce((p, c) => p.id > c.id ? p: c);
              countValue = maxValue.id;
            } else {
              countValue = 0;
            }
          } else {
            filledFPAnswers = null;
            countValue = 0;
          }
          if (countValue < 0) {
            countValue = 0;
          }
          // Only update the count if the page has been reloaded, otherwise, allow count to get updated
          // when the user clicks the forward and back buttons.
          if (count === null) {
            setCount(countValue);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
        return [
          {
            id: 'fp',
            data: fbFPAnswers
          }
        ];
      }
    });

    const output = () => {
      getData.then((result) => {
        if(isMounted) {
          // User's stored data in Firebase.
          let storedResults = result[0].data;

          // Filled data.
          let filledFPAnswers = null;

          try {
            if (storedResults){
              filledFPAnswers = result[0].data.filter(e => e.values !== "");
            } else {
              filledFPAnswers = null;
            }
          } catch (error) {
            console.log('Error: ', error);
          }

          // Only set 'last' if the page has been reloaded, otherwise 'last' will be set when the user
          // selects an answer to a question.
          if (count === null) {
            try {
              if (storedResults) {
                setLast(filledFPAnswers.length);
              } else {
                setLast(0); // If no results are stored in Firebase.
              }
            } catch (error) {
              console.log('Error: ', error);
            }
          }

          // Combine Firebase stored answers with fpAnswers state array.
          let currentFPAnswers = null;
          try {
            if (filledFPAnswers) {
              currentFPAnswers = Object.values([...initialState, ...filledFPAnswers].reduce((result, {
                id,
                ...rest
              }) => {
                result[id] = {
                  ...(result[id] || {}),
                  id,
                  ...rest
                };
                return result;
              }, {}));
            } else {
              currentFPAnswers = initialState; // If filledFPAnswers is null (can't be loaded from Firebase, or Firebase is empty).
            }
          } catch (error) {
            console.log('Error: ', error);
          }
        
          // Store Firebase data in fpAnswers state array.
          setFPAnswers(currentFPAnswers);
        }
      });
    }
    output();

    // Clean-up.
    return () => { isMounted = false };
  }, [auth, count, timeLimit]);

  const nextClick = (e) => {
    // Increment 'count'.
    setCount(count + 1);

    // Send all results up to this page to Firestore.
    fpSubmit({ fpAnswers });

    // Submit current time left.
    fpTime({ counter });
  }

  const backClick = (e) => {
    // Decrement 'count'.
    setCount(count - 1);

    // Send all results up to this page to Firestore.
    fpSubmit({ fpAnswers });

    // Submit current time left.
    fpTime({ counter });
  }

  const submitClick = (e) => {
    fpSubmit({ fpAnswers });    // Sends results to Firestore.

    // Submit current time left.
    fpTime({ counter });

    // Set 'fpComplete' to 'true' in user document.
    fpCompleteSubmit(); // Sends complete = true boolean to Firestore.

    // Updates display state.
    setDisplay(WIPDisplayStates.COMPLETE);
  }

  // Submit assessment results if counter goes to zero.
  if (counter === 0) {
    submitClick();
  }

  return (
    <div className="ar-main-box">
      <div className="timer">
        <Timer counter={counter} setCounter={setCounter} />
      </div>
      <FPMultiChoice3 count={count} answer={fpAnswers} setAnswer={setFPAnswers} last={last} setLast={setLast} />
      <div className="fp-row-2 fp-row-2-spaced fp-row-2-nomargin">
        {count > 0 ? (
          <div className="fp-row-2 fp-row-2-spaced fp-row-2-nomargin">
            <button className='ap-button' onClick={backClick}>
              <MdNavigateBefore className='ar-nav-button' />
            </button>
            {count === numTotalQuestions-1 ? (
              <button className='ap-button ap-button-submit' onClick={submitClick}>Submit</button>
            ) : (
              <button className='ap-button' onClick={nextClick}>
                    <MdNavigateNext className='ar-nav-button' />
              </button>
            )}
          </div>
        ) : (
          <div className="fp-row-2 fp-row-2-nomargin">
            <button className='ap-button' onClick={nextClick}>
              <MdNavigateNext className='ar-nav-button' />
            </button>
          </div>
        )}
      </div>

      <div className="fpass__questionBoxes">
        <div className="fpass__questionBoxesRow">
          { fpAnswers.map((val, ind) => (
            val.values !== '' ? (
              <input
                type="radio"
                checked={ind === count}
                className="fpass__questionBox fpass__questionBox-answered"
                readOnly={true}
                key={ind}
              />
            ) : (
              <input
                type="radio"
                checked={ind === count}
                className="fpass__questionBox"
                readOnly={true}
                key={ind}
              />
            )
            ))
          }
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fpSubmit: (scores) => dispatch(fpSubmit(scores)),
    fpTime: (counter) => dispatch(fpTime(counter)),
    fpCompleteSubmit: (result) =>
      dispatch(
        fpCompleteSubmit(result)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FPAssessment2);