import React from 'react';
import Overlay from './Overlay';

const trianglePic = `/images/resultsImages/trifecta/trifecta_popup1.png`;
const teardropPic = `/images/resultsImages/trifecta/trifecta_popup2.png`;

const IntroPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  if (isOpen === false) return null;

  return (
    <>
    <Overlay onClose={onClose} />
      <div id='introPopup' className="introPopup">
        <div className="introPopup__topRow">
          <div className="introPopup__header">
            THE TRIFECTA GRAPH
            <div className='introPopup__header2'>
              YOUR TOP JOBS
            </div>
          </div>
          <button className="introPopup__closeButton" onClick={onClose}>
            X
          </button>
        </div>

        <div className={`introPopup__content ${isOpen && 'introPopup__content-open'}`}>
          <div className="introPopup__bodyHeader">
            <img src={trianglePic} alt="triangle-pic" className="introPopup__trianglePic" />
            The <b>CENTER TRIANGLE</b> contains jobs that were in all three
            assessment results for you.&nbsp;
            <span className="introPopup__bodyHeader introPopup__bodyHeader-blue">
              These should be the closest match to a good career path for
              you to pursue because they are something you have the ability
              for, something you have shown interest in, and something that
              should give you meaning in your work.
            </span>
          </div>
          <div className="introPopup__bodyHeader">
            <img src={teardropPic} alt="teardrop-pic" className="introPopup__teardropPic" />
            The <b>TEAR DROPS</b> are where you have two areas of overlap.&nbsp;
            <span className="introPopup__bodyHeader introPopup__bodyHeader-blue">
              In particular, example the two tear drops that meet your abilities,
              and especially the one that has results for overlap between your
              abilities and work that would give you meaning. There may be jobs
              listed there that you hadn't considered before.
            </span>
          </div>
          

        </div>

      </div>
    </>
  )
}

export default IntroPopup