import React from 'react';
import { NavLink } from "react-router-dom";
import './StudentSignedOut.css';

const StudentSignedOut = () => {
  return (
    <div className="studentSignedOut">
      <div className='studentSignedOut__body'>
        Please&nbsp;
        <NavLink to='/signup'>
          <button className='studentSignedOut__button'>
            Sign Up
          </button>
        </NavLink>
        &nbsp;or&nbsp;
        <NavLink to='/signin'>
          <button className='studentSignedOut__button'>
            Sign In
          </button>
        </NavLink>
        &nbsp;to enter your access code!
      </div>
    </div>
  )
}

export default StudentSignedOut