import React, { useEffect, useState } from 'react';
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import APChartNoData from '../APChartNoData';
import APCalculate from '../ap/APCalculate';

import APChart2 from '../APChart2';


const Abilities = ({ user, apresults, ar, cm, cp, sa, va, fp }) => {
  const [ apData, setAPData ] = useState(null);

  useEffect(() => {
    // AP results.
    try {
      if (apresults && apresults.length > 0 && (user[0].apCalculated === true)) {
        const vaAnswers = apresults[0].vaPercentile;
        const arAnswers = apresults[0].arPercentile;
        const cmAnswers = apresults[0].cmPercentile;
        const saAnswers = apresults[0].saPercentile;
        const fpAnswers = apresults[0].fpPercentile;
        const cpAnswers = apresults[0].cpPercentile;
        
        const tempAPData = [
          {name: 'Verbal Ability', color: '#F5EA61', value: vaAnswers, id: 0},
          {name: 'Arithmetic Reasoning', color: '#93D500', value: arAnswers, id: 1},
          {name: 'Computation', color: '#007960', value: cmAnswers, id: 2},
          {name: 'Spatial Ability', color: '#009ADE', value: saAnswers, id: 3},
          {name: 'Form Perception', color: '#8CB7C9', value: fpAnswers, id: 4},
          {name: 'Clerical Perception', color: '#C2A6E1', value: cpAnswers, id: 5}
        ];
        
        setAPData(tempAPData);
      }
    } catch (error) {
      console.log('Error: ', error);
    }

  }, [apresults, user]);

  return (
    <div className='ipChart2__container'>
      {
        // <APCalculate ar={ar} cm={cm} cp={cp} fp={fp} sa={sa} va={va} />  // Calculate results.
        user ? (  // Check that the user is logged in.
          user[0].fpComplete ? ( // Check that the user has completed this assessment.
            (user[0].apCalculated === true && user[0].apJobsCalculated === true) ? ( // Check that the user's results have been calculated.
              apData ? ( // Check that the user's results are loaded.
                <APChart2 apData={apData} /> // Display results.
              ) : (
                <LoadingPage /> // Wait for results to load.
              ) 
            ) : (
              (ar && cm && cp && sa && va && fp) ? ( // Check for assessment answers.
                <APCalculate ar={ar} cm={cm} cp={cp} fp={fp} sa={sa} va={va} />  // Calculate results.
              ) : (
                <LoadingPage /> // Wait for assessment answers to load.
              )
            )
          ) : (
            <APChartNoData /> // Show if the user hasn't completed this assessment.
          )
        ) : (
          <LoadingPage /> // Wait for user auth to load, or display if user isn't logged in.
        )
      }
    </div>
  )
}

export default Abilities;