import React from "react";

const WIPMultiChoice = ({ defaultPrompt, multiValues, onChange }) => {
  const { questionText, id } = defaultPrompt;

  return (
    <div className="wipMulti">
      <div className="wipMulti__title">{questionText}</div>
      <div className="wipMulti__body">
        {multiValues.map(({ label, value, isSelected, isDefault }) => (
          <div className="wipMulti__form" key={`${id}-${value}`}>
            <input
              label={label}
              type="radio"
              value={value}
              checked={isSelected}
              name={value}
              onChange={onChange}
              className="wipMulti__input"
            />
            <label className="wipMulti__label">{label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WIPMultiChoice;
