import React, { useState, useEffect } from 'react';
// import '../../../styles/IPExplanationVideo.css';
import { WIPDisplayStates } from "./Configs";
import ReactPlayer from 'react-player';
import { wipVideoWatched } from '../../../store/actions/submitActions';
import { connect } from "react-redux";

const WIPExplanationVideo = ({ onClick, wipVideoWatched }) => {
  const playThreshold = 85;     // Minimum percentage of video to be played before user can go to next page.
  const [ played, setPlayed ] = useState(0);
  const [ counter, setCounter ] = useState(100);
  const [ complete, setComplete ] = useState(false);
  const [ playing, setPlaying ] = useState(true);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    } else {
      setComplete(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter, setCounter]);

  const onDuration = (e) => {
    setCounter(e);
  }
 
  const onProgress = (e) => {
    setPlayed(e.played*100);
    
    // Pause the video with one second left to avoid showing related videos.
    // if (e.playedSeconds > e.loadedSeconds - 10) {
    if (e.playedSeconds > 77) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  }

  // Actions to happen on click.
  const submitClick = (e) => {
    e.preventDefault();
    // // Send 'wipVideoWatched=true' to Firebase.
    // wipVideoWatched();
    // Go to assessment page.
    onClick(WIPDisplayStates.ASSESSMENT);
    // try {
    // } catch (error) {
    //   console.log('Error: ', error);
    // }
    
  }

  return (
    <div className='wipVideo'>
      <div className="wipVideo__video">
        <ReactPlayer
          url="https://youtu.be/9PnMcZm9WCA"
          controls={true}
          playing={playing}
          width='100%'
          height='100%'
          onDuration={onDuration}
          onProgress={onProgress}
          playsinline={true}
        />
      </div>
      <div className="wipVideo__bottom">
        <div className="ipVideo__text">
          Because these instructions are important for your ability to complete the assessment,
          the begin button will be disabled until the instruction video finishes.
        </div>
        <button
          type='button'
          className={"wipVideo__button " + ((played < playThreshold || !complete) ? "wipVideo__button-disabled" : "")}
          onClick={submitClick}
          disabled={(played < playThreshold) || (!complete)}
        >
          Begin
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		wipVideoWatched: () => dispatch(wipVideoWatched())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WIPExplanationVideo)
