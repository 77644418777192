import React, { useEffect, useState } from 'react';
import './StudentPage.css';
import { pullAllCodes, submitOrder, startTracker } from '../../../../store/actions/orderActions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const StudentPage = ({ purchased, pullAllCodes, submitOrder, user, auth, startTracker, orderStatus }) => {
  // Store user input info.
  const [ userCode, setUserCode ] = useState([]);
  const handleChange = (e) => {
    setUserCode(e.target.value);
  }

  // Get all promo codes.
  const [ codes, setCodes ] = useState({});
  const [ codeNames, setCodeNames ] = useState([]);
  useEffect(() => {
    try {
      // Call the action to pull the codes when the component mounts
      pullAllCodes().then(codesData => {
        // Here, you have access to the codesData retrieved from Firestore.
        setCodes(codesData);
      });
    } catch (error) {
      console.log('Error getting codes: ', error);
    }
  }, [pullAllCodes]);

  useEffect(() => {
    // Create a list of all the code names.
    Object.entries(codes).map((element) => {
      setCodeNames(prevState => [...prevState, Object.values(element)[1].code]);
      return null;
    })
  }, [codes]);

  // On click function.
  const [ userMsg, setUserMsg ] = useState('');
  const [ clicked, setClicked ] = useState(false);
  const onClick = (e) => {
    // Check if the code matches one of our internal codes.
    const isMatch = codeNames.includes(userCode);
    if (isMatch === true) {
      // Get the data for that code.
      const matchedCode = Object.keys(codes).map(key => codes[key]).find(item => item.code === userCode);
      
      // Check if code is expired.
      if (
        matchedCode.expirationDate !== null &&
        matchedCode.expirationDate !== undefined &&
        typeof matchedCode.expirationDate === 'object' &&
        matchedCode.expirationDate.seconds > (Date.now() / 1000)
      ) {
        // Code is not expired. Check if it is a student code.
        if (
          matchedCode.type !== null &&
          matchedCode.type !== undefined &&
          typeof matchedCode.type === 'string' &&
          matchedCode.type === 'student'
        ) {
          // Code is a student code. Check if it has been used up.
          if (
            matchedCode.uses !== null &&
            matchedCode.uses !== undefined &&
            typeof matchedCode.uses === 'number'
          ) {
            if (
              matchedCode.timesUsed !== null &&
              matchedCode.timesUsed !== undefined &&
              typeof matchedCode.timesUsed === 'number'
            ) {
              // Perform subtraction to check if number of uses has been exceeded.
              if (matchedCode.uses > matchedCode.timesUsed) {
                // Set clicked.
                setClicked(true);
                
                // Give access and increment 'timesUsed' by 1.
                let userEmail = null;
                if (user[0].email) {
                  userEmail = user[0].email;
                }
                const configOrder = {
                  uid: auth.uid,
                  orderCreatedDate: new Date(),
                  firstName: user[0].firstName,
                  lastName: user[0].lastName,
                  codeUsed: userCode,
                  email: userEmail,
                  orderItems: 'basePackage'
                }
                
                // Submit order.
                submitOrder(configOrder);

                // Increment 'timesUsed' by 1.
                const keys = Object.keys(codes);
                const foundIndex = keys.findIndex(key => codes[key].code === userCode);
                const pcName = keys[foundIndex];
                startTracker(pcName);
                
                setUserMsg('');
              } else {
                setUserMsg('Code capacity reached.');

                setClicked(false);
              }
            } else {
              // Set clicked.
              setClicked(true);

              // Number of times used not being tracked.
              // Give access and start tracking number of times used.

              const configOrder = {
                uid: auth.uid,
                orderCreatedDate: new Date(),
                firstName: user[0].firstName,
                lastName: user[0].lastName,
                codeUsed: userCode,
                email: user[0].email,
                orderItems: 'basePackage'
              }

              submitOrder(configOrder);

              // Start tracking number of times the code has been used.
              const keys = Object.keys(codes);
              const foundIndex = keys.findIndex(key => codes[key].code === userCode);
              const pcName = keys[foundIndex];
              startTracker(pcName);

              setUserMsg('');
            }
          } else {
            // Set clicked.
            setClicked(true);

            // Number of uses not specified. Give access.
            setUserMsg('');

            const configOrder = {
              uid: auth.uid,
              orderCreatedDate: new Date(),
              firstName: user[0].firstName,
              lastName: user[0].lastName,
              codeUsed: userCode,
              email: user[0].email,
              orderItems: 'basePackage'
            }

            submitOrder(configOrder);
          }
        } else {
          // Not a student code.
          setUserMsg('Incorrect code.');

          setClicked(false);
        }
      } else {
        setUserMsg('Code is expired.');

        setClicked(false);
      }

    } else {
      setUserMsg('Incorrect code.');

      setClicked(false);
    }
  }

  // Check if order was a success.
  const [ purchaseSuccess, setPurchaseSuccess ] = useState(false);
  useEffect(() => {
    try {
      if (orderStatus.purchased === true) {
        // Redirect to the assessment page.
        setPurchaseSuccess(true);
        
      } else if (orderStatus.purchase === false) {
        setClicked(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orderStatus]);

  if (purchaseSuccess === true) return <Redirect to='/assessments' />;

  return (
    <div className='studentPage'>
      <div className="studentPage__bodyText">
        <b>Are you a student? Enter your access code below!</b>
      </div>
      <div className='studentPage__body'>
        <input
          type="text"
          className='studentPage__input'
          onChange={handleChange}
          placeholder='Enter access code'
        />
        { !purchased ? (
          clicked === false ? (
            <button
              className='studentPage__button'
              onClick={onClick}
            >
              Enter
            </button>
          ) : (
            orderStatus.purchased !== true ? (
            <button className='studentPage__button studentPage__button-disabled' disabled>
              Processing
            </button>
            ) : (
            <button className='studentPage__button studentPage__button-success' disabled>
              Success!
            </button>
            )
          )
        ) : (
          <div>
            <div className='options__buttonClicked options__buttonClicked-gray'>
              Purchased
            </div>
          </div>
        ) }
      </div>
      <div className="studentPage__errorMessage">
        {userMsg}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
    user: state.firestore.ordered.users,
    orderStatus: state.order
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		pullAllCodes: () => dispatch(pullAllCodes()),
    submitOrder: (order) => dispatch(submitOrder(order)),
    startTracker: (pcName) => dispatch(startTracker(pcName))
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(StudentPage)