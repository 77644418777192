import React, { useState, useEffect } from 'react';
import { WIPListText } from './Configs';
import './WIPList2.css';

const WIPList2 = ({ wipData }) => {
  // Update the screenWidth state when the window is resized.
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Copy array because sort will mutate the original, then sort it by value in descending order.
  const wipCopy = [].concat(wipData)
    .sort((a, b) => a.value < b.value ? 1 : -1)
    .map((element, index) => ({
      ...element,
      position: index
    })
  );

  // Make a colored line.
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            opacity: 1,
            height: '1px'
        }}
    />
  );

  // Tooltip.
  const [ tooltipContent, setTooltipContent ] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const handleInteractionStart = (event, index) => {
    try {
      // Select the correct tooltip.
      const matchedContent = WIPListText.find(item => item.header === wipCopy[index].name);

      setTooltipContent({
        header: matchedContent.header,
        body: matchedContent.text
      });

      // Calculate the tooltip position based on the event coordinates.

      // Calculate the tooltip position based on the event coordinates.
      const tooltipLeft = screenWidth/2 - event.offsetWidth / 2;
      const tooltipTop = 100 + 25*index;
      // const tooltipLeft = parentRect.left + parentRect.width / 2;
      // const tooltipTop = parentRect.top - 10; // Example adjustment for positioning above the parent

      setTooltipPosition({ left: tooltipLeft, top: tooltipTop });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleInteractionEnd = () => {
    setTooltipContent(null);
  }

  try {
    return (
      <div className='wipList'>
        <div className='bar-h1 bar-h1-wip'>
          Values highlights
        </div>
        <div className='bar-h2 bar-h2-wip'>
          {screenWidth <= 640 ? (
            <>
              Your Values Profile -<br />In Order of Importance
            </>
          ) : (
            <>
              Your Values Profile - In Order of Importance
            </>
          )}
          <ColoredLine color='white' />
        </div>

        <div className="bar-h1">
          Primary
        </div>

        <div
          className="wip-primary-item"
        >
          <img src={imageSelect(wipCopy[0])} alt="firstWIPImage" className='wip-primary-image' />
          <div
            className='wipList__name'
            onMouseOver={(event) => handleInteractionStart(event, 0)}
            onMouseOut={handleInteractionEnd}
          >
            1. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 0) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        <div
          className='wip-primary-item'
        >
          <img src={imageSelect(wipCopy[1])} alt="secondWIPImage" className='wip-primary-image' />
          <div
            className='wipList__name'
            onMouseOver={(event) => handleInteractionStart(event, 1)}
            onMouseOut={handleInteractionEnd}
          >
            2. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 1) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        <div className="bar-h1">
          <br />Secondary
        </div>

        <div
          className='wip-secondary-item'
        >
          <img src={imageSelect(wipCopy[2])} alt="thirdWIPImage" className='wip-secondary-image' />
          <div
            onMouseOver={(event) => handleInteractionStart(event, 2)}
            onMouseOut={handleInteractionEnd}
          >
            3. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 2) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        <div
          className='wip-secondary-item'
        >
          <img src={imageSelect(wipCopy[3])} alt="fourthWIPImage" className='wip-secondary-image' />
          <div
            onMouseOver={(event) => handleInteractionStart(event, 3)}
            onMouseOut={handleInteractionEnd}
          >
            4. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 3) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        <div
          className='wip-secondary-item'
        >
          <img src={imageSelect(wipCopy[4])} alt="fifthWIPImage" className='wip-secondary-image' />
          <div
            onMouseOver={(event) => handleInteractionStart(event, 4)}
            onMouseOut={handleInteractionEnd}
          >
            5. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 4) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        <div
          className='wip-secondary-item'
        >
          <img src={imageSelect(wipCopy[5])} alt="sixthWIPImage" className='wip-secondary-image' />
          <div
            onMouseOver={(event) => handleInteractionStart(event, 5)}
            onMouseOut={handleInteractionEnd}
          >
            6. {wipCopy[wipCopy.findIndex(element => {
              if (element.position === 5) {
                return true;
              } else {
                return false;
              }
            })].name}
          </div>
        </div>

        {tooltipContent && (
          <div
            className="wipList__tooltip"
            style={{ left: tooltipPosition.left + 'px', top: tooltipPosition.top + 'px' }}
          >
            <div className='wipList__tooltip-h1'>
              {tooltipContent.header}
            </div>
            <div className="wipList__tooltip-body">
              {tooltipContent.body}
            </div>
          </div>
        )}
        
      </div>
    )
  } catch (error) {
    console.log('Error: ', error);
    return (
      <div className='wipList'>
        <div className='bar-h1'>
          Values highlights
        </div>
        <div className='bar-h2'>
          Your Values Profile - Summary
        </div>
        <div style={{ height: '450px', paddingBottom: '30px', paddingRight: '30px' }}>
          There is an error, please reload the page.
        </div>
      </div>
    )
  }
}

export default WIPList2

// Select correct image.
function imageSelect(obj) {
   
  // Available images.
   const achievePic = `/images/resultsImages/wipList/WIP-results-achievement.png`;
   const indepPic = `/images/resultsImages/wipList/WIP-results-independence.png`;
   const recogPic = `/images/resultsImages/wipList/WIP-results-recognition.png`;
   const relatePic = `/images/resultsImages/wipList/WIP-results-relationships.png`;
   const suppPic = `/images/resultsImages/wipList/WIP-results-support.png`;
   const workPic = `/images/resultsImages/wipList/WIP-results-workingconditions.png`;

   let correctImage;
   if (obj.name === 'Achievement') {
    correctImage = achievePic;
   } else if (obj.name === 'Independence') {
     correctImage = indepPic;
   } else if (obj.name === 'Recognition') {
     correctImage = recogPic;
   } else if (obj.name === 'Relationships') {
     correctImage = relatePic;
   } else if (obj.name === 'Support') {
     correctImage = suppPic;
   } else {
     correctImage = workPic;
   }

  return correctImage;
}