import React, { useState, useEffect } from 'react';
import { FaLock } from 'react-icons/fa';

const IPChartNoData = () => {
  const [ ipData ] = useState([
    {name: 'Realistic', color: '#163c67', value: 5, id: 0},
    {name: 'Investigative', color: '#91d400', value: 5, id: 1},
    {name: 'Artistic', color: '#007961', value: 5, id: 2},
    {name: 'Social', color: '#009ade', value: 5, id: 3},
    {name: 'Enterprising', color: '#89b7c9', value: 5, id: 4},
    {name: 'Conventional', color: '#163c67', value: 5, id: 5}
  ]);

  // Check screen size.
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  const [ chartWidth, setChartWidth ] = useState(800);
  const [ barSpacing, setBarSpacing ] = useState(30);
  const [ yOffset, setYOffset ] = useState(-30);

  // Update the screenWidth state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update the chartWidth and chartHeight based on screen size.
  const tabletSize = 1025;
  const phoneSize = 640;
  useEffect(() => {
    if (screenWidth <= tabletSize && screenWidth > phoneSize) {
      setChartWidth(500);
      setBarSpacing(30);
      setYOffset(-32);
    } else if (screenWidth <= phoneSize) {
      setChartWidth(260);
      setBarSpacing(8);
      setYOffset(-35);
    } else if (screenWidth > tabletSize) {
      setChartWidth(600);
      setBarSpacing(30);
      setYOffset(-30);
    }
  }, [screenWidth]);

  const chartHeight = 400;
  const chartBuffer = 0.05;   // Fraction offset to make sure labels don't get cut off.
  const xAxisHeight = 55;
  const yAxisWidth = 40;
  const data = ipData.map(obj => obj.value);
  const barLabel = ipData.map(obj => obj.name);
  const maxValue = 40;
  const barWidth = (chartWidth - 1 - yAxisWidth) / data.length - barSpacing;

  // Determine the number of y-axis ticks
  const numYAxisTicks = 9;

  // Calculate tick values based on data range and number of ticks.
  const tickInterval = 5;
  const tickValues = Array.from({ length: numYAxisTicks }, (_, i) => tickInterval * i);

  return (
    <div className='ipChart2'>
      <h1>Interest highlights</h1>
      <h2>Your Interest Profile - RIASEC</h2>
      <svg
        width={chartWidth}
        height={chartHeight + xAxisHeight}
        className='ipChart2__svg'
      >

        {/* Draw filled area. */}
        <g>
            {/* Draw single bar to fill entire space */}
            <rect
              x={40}
              y={20}
              width={chartWidth - yAxisWidth}
              height={chartHeight-xAxisHeight + 34}
              fill={'#163c66'}
            />

            <foreignObject x="6.5%" y="4.5%" width="94%" height="83.5%">
              {/* Adjust width and height as needed */}
              <div className='chart__rectangle'>
                <div className='chart__icon'>
                  <FaLock />
                </div>
                <div className='chart__body'>
                  Exciting things coming soon!
                </div>
                <div className='chart__subbody'>
                  Finish the interest profiler to see results here.
                </div>
              </div>
            </foreignObject>
          </g>

        {/* Draw y-axis */}
        <line
          x1={yAxisWidth}
          y1={chartHeight*chartBuffer}
          x2={yAxisWidth}
          y2={chartHeight}
          className='ipChart2__axis'
        />

        {/* Render y-axis ticks and tick labels */}
        {tickValues.map((tickValue, index) => {
          const yTick = chartHeight - (tickValue / maxValue) * (chartHeight*(1-chartBuffer));
          const xTickLabel = yAxisWidth - 5; // Adjust the x position of the tick labels

          return (
            <g key={index}>
              {/* Tick line */}
              {/* <line
                x1={yAxisWidth}
                y1={yTick}
                x2={yAxisWidth + 10}
                y2={yTick}
                className='ipChart2__ticks'
              /> */}

              {/* Tick label */}
              <text
                x={xTickLabel - 5}
                y={yTick}
                alignmentBaseline="middle"
                textAnchor="end"
                className="ipChart2__ylabel"
              >
                {tickValue}
              </text>
            </g>
          );
        })}

        {/* Draw x-axis */}
        <line
          x1={yAxisWidth}
          y1={chartHeight}
          x2={chartWidth}
          y2={chartHeight}
          className='ipChart2__axis'
        />

        {/* Add x-axis labels */}
        {data.map((_, index) => {
          const x = yAxisWidth + barSpacing/2 + index * (barWidth + barSpacing) + barWidth / 2;
          const y = chartHeight + xAxisHeight + yOffset;

          return (
            <g key={index}>
              {/* First line */}
              <text
                x={x}
                y={y}
                textAnchor="middle"
                className='ipChart2__xlabel ipChart2__xlabel-first'
              >
                {barLabel[index][0]}
              </text>
              {/* Second line */}
              <text x={x} y={y + 18} textAnchor="middle" className='ipChart2__xlabel ipChart2__xlabel-second'>
                {barLabel[index]}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  )
}

export default IPChartNoData