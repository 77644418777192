import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../VA.css';

const InstructionsQ345 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled3, setDisabled3 ] = useState(true);
  const [ disabled4, setDisabled4 ] = useState(true);
  const [ disabled5, setDisabled5 ] = useState(true);
  const [ correct3, setCorrect3 ] = useState(false);
  const [ correct4, setCorrect4 ] = useState(false);
  const [ correct5, setCorrect5 ] = useState(false);
  const [ currentQuestion3 ] = useState(2);
  const [ currentQuestion4 ] = useState(3);
  const [ currentQuestion5 ] = useState(4);

  useEffect(() => {
    if (practiceAnswers[currentQuestion3].values !== '') {
      setDisabled3(false);
    } else {
      setDisabled3(true);
    }

    if (practiceAnswers[currentQuestion4].values !== '') {
      setDisabled4(false);
    } else {
      setDisabled4(true);
    }

    if (practiceAnswers[currentQuestion5].values !== '') {
      setDisabled5(false);
    } else {
      setDisabled5(true);
    }

    if (practiceAnswers[currentQuestion3].values === 'B-C') {
      setCorrect3(true);
    } else {
      setCorrect3(false);
    }

    if (practiceAnswers[currentQuestion4].values === 'C-D') {
      setCorrect4(true);
    } else {
      setCorrect4(false);
    }

    if (practiceAnswers[currentQuestion5].values === 'A-D') {
      setCorrect5(true);
    } else {
      setCorrect5(false);
    }
  }, [practiceAnswers, currentQuestion3, currentQuestion4, currentQuestion5]);

  // Answer options.
  const answerOptions3 = [
    'A-B',
    'A-C',
    'A-D',
    'B-C',
    'B-D',
    'C-D'
  ];

  const answerOptions4 = [
    'A-B',
    'A-C',
    'A-D',
    'B-C',
    'B-D',
    'C-D'
  ];
  
  const answerOptions5 = [
    'A-B',
    'A-C',
    'A-D',
    'B-C',
    'B-D',
    'C-D'
  ];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // Question 3
    if (name === String(currentQuestion3)) {
      const previousAnswer = practiceAnswers[currentQuestion3].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion3,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion3) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }


    // Question 4
    if (name === String(currentQuestion4)) {
      const previousAnswer = practiceAnswers[currentQuestion4].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion4,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion4) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }


    // Question 5
    if (name === String(currentQuestion5)) {
      const previousAnswer = practiceAnswers[currentQuestion5].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion5,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion5) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }
  }

  return (
    <div className='va-main-box va-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 2 - Vocabulary
      </div>
      <div className='va-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body va-instructions-body">
        Now do the next three practice questions in the same way.
        For each question, choose the two words that are either <b><i>most nearly the same</i></b>
        &nbsp;in meaning or <b><i>most nearly the opposite</i></b> in meaning.
      </div>
      <div className="ap-instructions-body va-instructions-body">
        Consider all of the choices before selecting an answer to be sure you haven't
        overlooked a choice that is better. Then click the circle under the letter combination
        of your answer.
      </div>
      
      <div className="vaInstructionsQ345__questions">
        <div className="question-va" style={{ position: 'relative' }}>
          <div className="question__number-va">
            3.
          </div>
          <div className="question__body-va">
            <div className="question__text-va">
              A. smart
            </div>
            <div className="question__text-va">
              B. false
            </div>
            <div className="question__text-va">
              C. true
            </div>
            <div className="question__text-va">
              D. good
            </div>
            <div className="question__answers-horizontal-va">
              {
                answerOptions3.map((element, index) => (
                  <label className="question__answer-option-horizontal-va" key={index}>
                    <div className="question__answer-text-va">
                      {element}
                    </div>
                    <input
                      type="checkbox"
                      className='question__radio-button question__radio-button-va'
                      checked={practiceAnswers[currentQuestion3].values === element}
                      name={currentQuestion3}
                      value={element}
                      onChange={e => handleAnswer(e)}
                    />
                  </label>
                ))
              }
            </div>
          </div>

          { practiceAnswers[currentQuestion3].values !== '' || practiceAnswers[currentQuestion3].values.length > 0 ? (
              correct3 ? (
                <div className="display-answer-va display-answer-va-q3">
                  Correct
                </div>
              ) : (
                <div className="display-answer-va display-answer-va-q3">
                  Incorrect
                </div>
              )
          ) : (
            <div className="display-answer-va display-answer-va-q3"></div>
          )
          }

        </div>
        
        <div className="question-va" style={{ position: 'relative' }}>
          <div className="question__number-va">
            4.
          </div>
          <div className="question__body-va">
            <div className="question__text-va">
              A. help
            </div>
            <div className="question__text-va">
              B. strike
            </div>
            <div className="question__text-va">
              C. begin
            </div>
            <div className="question__text-va">
              D. end
            </div>
            <div className="question__answers-horizontal-va">
              {
                answerOptions4.map((element, index) => (
                  <label className="question__answer-option-horizontal-va" key={index}>
                    <div className="question__answer-text-va">
                      {element}
                    </div>
                    <input
                      type="checkbox"
                      className='question__radio-button question__radio-button-va'
                      checked={practiceAnswers[currentQuestion4].values === element}
                      name={currentQuestion4}
                      value={element}
                      onChange={e => handleAnswer(e)}
                    />
                  </label>

                ))
              }
            </div>
          </div>

          { practiceAnswers[currentQuestion4].values !== '' || practiceAnswers[currentQuestion4].values.length > 0 ? (
            correct4 ? (
              <div className="display-answer-va display-answer-va-q4">
                Correct
              </div>
            ) : (
              <div className="display-answer-va display-answer-va-q4">
                Incorrect
              </div>
            )
        ) : (
          <div className="display-answer-va display-answer-va-q4"></div>
        )
        }

        </div>

        <div className="question-va" style={{ position: 'relative' }}>
          <div className="question__number-va">
            5.
          </div>
          <div className="question__body-va">
            <div className="question__text-va">
              A. frighten
            </div>
            <div className="question__text-va">
              B. accept
            </div>
            <div className="question__text-va">
              C. bother
            </div>
            <div className="question__text-va">
              D. scare
            </div>
            <div className="question__answers-horizontal-va">
              {
                answerOptions5.map((element, index) => (
                  <label className="question__answer-option-horizontal-va" key={index}>
                    <div className="question__answer-text-va">
                      {element}
                    </div>
                    <input
                      type="checkbox"
                      className='question__radio-button question__radio-button-va'
                      checked={practiceAnswers[currentQuestion5].values === element}
                      name={currentQuestion5}
                      value={element}
                      onChange={e => handleAnswer(e)}
                    />
                  </label>
                ))
              }
            </div>
          </div>

          { practiceAnswers[currentQuestion5].values !== '' || practiceAnswers[currentQuestion5].values.length > 0 ? (
            correct5 ? (
              <div className="display-answer-va display-answer-va-q5">
                Correct
              </div>
            ) : (
              <div className="display-answer-va display-answer-va-q5">
                Incorrect
              </div>
            )
        ) : (
          <div className="display-answer-va display-answer-va-q5"></div>
        )
        }

        </div>
      </div>


      <div className="va-row-2 va-row-2-spaced va-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}  
        >
          Back
        </button>
        { disabled3 === true || disabled4 === true || disabled5 === true ? (
          <button
            type='button'
            disabled={disabled3 === true || disabled4 === true || disabled5 === true}
            className="ap-instructions-button"
            style={{ backgroundColor: 'grey' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_FINAL);
            }}
          >
            Next
          </button>
        ) : (
          <button
            type='button'
            className="ap-instructions-button"
            style={{ backgroundColor: '#008375' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_FINAL);
            }}
          >
            Next
          </button>
        ) }
      </div>
    </div>
  )
}

export default InstructionsQ345
