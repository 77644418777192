import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import './WIPAssessment.css';
import { BsGrid3X3Gap } from 'react-icons/bs';

const Task = ({ id, index, content }) => {

  return (
    <div className='wipass__dndMaster'>
      {/* <div className={'wipass__dragdropcontext ' + ((index % 2 === 1) ? "wipass__dragdropcontext-odd" : "wipass__dragdropcontext-even")}> */}
      <div className={'wipass__dragdropcontext'}>
        <Draggable draggableId={id} key={id} index={index} type='TASK'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className='wipass__item'
            >
              <div>
                <BsGrid3X3Gap className='wipass__icon' />
              </div>
              <div className='task-col'>
                {content}
              </div>
            </div>
          )}
        </Draggable>
      </div>
    </div>
  );
};

export default Task
