import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../SA.css';
import variables from '../../../../../../variables.module.scss';

const InstructionsQ3 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');
  const [ currentQuestion ] = useState(2);

  useEffect(() => {
    if (practiceAnswers[currentQuestion].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[currentQuestion].values === 'C') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers, currentQuestion]);

  // Images.
  const webImage = `/images/saImages/web/Spatial Reasoning Ins 3.png`;
  const mobileImage = `/images/saImages/mobile/Spatial Reasoning Ins m3.png`;

  // Answer options.
  const answerOptions = [
    'A',
    'B',
    'C',
    'D'
  ];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[currentQuestion].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: currentQuestion,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === currentQuestion) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === 'A-B') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView) + 5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='sa-main-box sa-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 3 - Three-Dimensional Space
      </div>
      <div className='sa-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        Now do the next three practice problems in the same way.
      </div>

      <div className="sa__content">
        <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="sa__choices" />

        <div className="sa__options">
          {
            answerOptions.map((element, index) => (
              <div className="sa__option" key={index}>
                <input
                  type="checkbox"
                  className='sa__optionButton'
                  checked={practiceAnswers[currentQuestion].values === element}
                  name={currentQuestion}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </div>
            ))
          }
        </div>
        <span className='sa__span'></span>
      </div>

      <div className="display-answer-sa">
        { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
          correct ? (
            <div className="display-answer-sa__content">
              Correct
            </div>
          ) : (
            <div className="display-answer-sa__content">
              Incorrect
            </div>
          )
        ) : (
          <div className="display-answer-sa__content">&nbsp;</div>
        )
        }
      </div>

      <div className="sa-row-2 sa-row-2-spaced sa-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}  
        >
          Back
        </button>
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q4);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ3