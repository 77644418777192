import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

function ArrowLeft({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='navbar__arrow navbar__arrow-left'
      style={{
        opacity: disabled ? 0 : 1,
      }}
    >
      {children}
    </button>
  );
}

function ArrowRight({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='navbar__arrow navbar__arrow-right'
      style={{
        opacity: disabled ? 0 : 1,
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <ArrowLeft disabled={disabled} onClick={() => scrollPrev()}>
      <AiOutlineLeft />
    </ArrowLeft>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <ArrowRight disabled={disabled} onClick={() => scrollNext()}>
      <AiOutlineRight />
    </ArrowRight>
  );
}