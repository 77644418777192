import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../FP.css';
import variables from '../../../../../../variables.module.scss';

const InstructionsQ1 = ({ setDisplay }) => {
  // Images.
  const webImage = `/images/fpImages/web/Object Matching Ins 1.png`;
  const mobileImage = `/images/fpImages/mobile/Object Matching Ins m1.png`;

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView)+5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='fp-main-box fp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 6 - Object Matching
      </div>
      <div className='fp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        On this page are some problems in which you compare objects. Look at Object 1 and the four objects that follow.
      </div>

      <div className="fp__content">
        <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="fp__choices" />
      </div>

      <div className="ap-instructions-body fp-instructions-body">
        Notice that only Object B is <i>exactly</i> the <i>same</i> as Object 1.
      </div>
      <div className="fp-row-2 fp-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Next
        </button>
      </div>

    </div>
  )
}

export default InstructionsQ1
