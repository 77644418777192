import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Addition = ({ elements }) => {
  const ColoredLine = ({ color, height }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: height
        }}
    />
  );

  return (
    <Container>
      <Row>
        <Col>
          <div className="cm-math-box">
            <div className="cm-math-box__header">
              ADD
            </div>
            <div className="cm-calc-box">
              {elements.map(((item, index) =>
                <div className='cm-calc-box__item' key={index}>
                  { index === elements.length-1 && '+ ' }
                {item.toLocaleString('en-US', {maximumFractionDigits:2})}
                </div>
              ))}
            </div>
            <div style={{ width: '75%', alignSelf: 'flex-end' }}>
              <ColoredLine color='black' height='1px' />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Addition
