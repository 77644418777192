import React, { useState, useEffect } from 'react';
import TableHead from './TableHead';
import TableBody from './TableBody';

// Function to create Javascript date to a MM/DD/yyyy string.
// Source: https://stackoverflow.com/questions/11591854/format-date-to-mm-dd-yyyy-in-javascript
function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return month + '/' + day + '/' + year;
}

const Table = ({ codes, updateArchiveStatus, showArchived }) => {

  // Update the 'expirationDate' and 'creationDate' values.
  const [ tableData, setTableData ] = useState([]);
  useEffect(() => {
    setTableData(
      codes.map((element) => {
        let currentCode = null;
        let currentExpirationDate = null;
        let currentType = null;
        let currentUses = null;
        let currentTimesUsed = null;
        let currentDiscountFraction = null;
        let currentCreator = null;
        let currentDescription = null;
        let currentCreationDate = null;
        let currentArchived = false;
  
        // Get code name.
        if (element.code) {
          currentCode = element.code;
        } else {
          currentCode = null;
        }
  
        // Get expiration date.
        if (element.expirationDate) {
          const output = new Date(element.expirationDate.seconds * 1000);
          currentExpirationDate = getFormattedDate(output);
        } else {
          currentExpirationDate = null;
        }
  
        // Get type.
        if (element.type) {
          currentType = element.type;
        } else {
          currentType = null;
        }
  
        // Get total allowed number of uses.
        if (element.uses) {
          currentUses = element.uses;
        } else {
          currentUses = null;
        }
  
        // Get total number of times used so far.
        if (element.timesUsed) {
          currentTimesUsed = element.timesUsed;
        } else {
          currentTimesUsed = null;
        }
  
        // Get discount fraction.
        if (element.discountFraction) {
          currentDiscountFraction = String(element.discountFraction*100) + '%';
        } else {
          currentDiscountFraction = null;
        }
  
        // Get code creator.
        if (element.creator) {
          currentCreator = element.creator;
        } else {
          currentCreator = null;
        }
  
        // Get code description.
        if (element.description) {
          currentDescription = element.description;
        } else {
          currentDescription = null;
        }
  
        // Get code creation date.
        if (element.creationDate) {
          const output = new Date(element.creationDate.seconds * 1000);
          currentCreationDate = getFormattedDate(output);
        } else {
          currentCreationDate = null;
        }

        // Get code archive status.
        if (element.archived) {
          currentArchived = element.archived;
        } else {
          currentArchived = false;
        }
  
        return {
          code: currentCode,
          expirationDate: currentExpirationDate,
          type: currentType,
          uses: currentUses,
          timesUsed: currentTimesUsed,
          discountFraction: currentDiscountFraction,
          creator: currentCreator,
          description: currentDescription,
          creationDate: currentCreationDate,
          archived: currentArchived
        }
      })
    );
  }, [codes]);
  

  const columns = [
    { label: 'Code', accessor: 'code', sortable: true },
    { label: 'Exp. Date', accessor: 'expirationDate', sortable: true },
    { label: 'Type', accessor: 'type', sortable: true },
    { label: 'Uses', accessor: 'uses', sortable: true },
    { label: 'Times Used', accessor: 'timesUsed', sortable: true },
    { label: 'Discount', accessor: 'discountFraction', sortable: true },
    { label: 'Creator', accessor: 'creator', sortable: true },
    { label: 'Description', accessor: 'description', sortable: true },
    { label: 'Creation Date', accessor: 'creationDate', sortable: true },
    { label: 'Archived', accessor: 'archived', sortable: true },
  ];

  const handleSorting = (sortField, sortOrder) => {
    let sorted = [...tableData];
    if (sortField) {
      if (sortField === 'expirationDate' || sortField === 'creationDate') {
        // If sorting by 'signupDate', use a specialized sorting logic for dates
        sorted = [...tableData].sort((a, b) => {
          // Convert 'signupDate' values to Date objects for comparison
          const dateA = new Date(a[sortField]);
          const dateB = new Date(b[sortField]);

          // Check if the date values are valid
          if (isNaN(dateA) || isNaN(dateB)) return 0;

          // Compare dates directly, taking into account sortOrder
          return (dateA - dateB) * (sortOrder === 'asc' ? 1 : -1);
        });
      } else {
        sorted = [...tableData].sort((a, b) => {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          return (
            a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        });
      }
      setTableData(sorted);
    }
  };

  // Deal with 'archived' checkbox per row.
  const handleCheckboxChange = (index) => {
    // Create a shallow copy of the tableData array.
    const updatedTableData = [...tableData];

    // Toggle the 'archived' property for the item at the specified index.
    updatedTableData[index].archived = !updatedTableData[index].archived;

    // Update the state with the modified tableData
    setTableData(updatedTableData);

    // Update firebase with the updated table.
    updateArchiveStatus(tableData[index].code, updatedTableData[index].archived);
    
  }

  return (
    <>
      <table className='table'>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, tableData, handleCheckboxChange, showArchived }} />
      </table>
    </>
  )
}

export default Table