import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../AR.css';

const InstructionsQ34 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled3, setDisabled3 ] = useState(true);
  const [ disabled4, setDisabled4 ] = useState(true);
  const [ correct3, setCorrect3 ] = useState(false);
  const [ correct4, setCorrect4 ] = useState(false);

  useEffect(() => {
    if (practiceAnswers[2].values !== '') {
      setDisabled3(false);
    } else {
      setDisabled3(true);
    }

    if (practiceAnswers[3].values !== '') {
      setDisabled4(false);
    } else {
      setDisabled4(true);
    }

    if (practiceAnswers[2].values === '$3.90') {
      setCorrect3(true);
    } else {
      setCorrect3(false);
    }

    if (practiceAnswers[3].values === '7') {
      setCorrect4(true);
    } else {
      setCorrect4(false);
    }
  }, [practiceAnswers]);

  // Answer options.
  const answerOptions3 = [
    '$2.50',
    '$2.80',
    '$3.60',
    '$3.90',
    'None of these'
  ];

  const answerOptions4 = [
    '6',
    '6-1/2',
    '7',
    '7-1/2',
    'None of these'
  ];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    if (name === '2') {
      const previousAnswer = practiceAnswers[2].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: 2,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === 2) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }

    if (name === '3') {
      const previousAnswer = practiceAnswers[3].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: 3,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === 3) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }
  }

  return (
    <div className='ar-main-box'>
      <div className="ap-instructions-header">
        Assessments 3: Part 1 - Arithmetic Reasoning
      </div>
      <div className='ar-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Here are some arithmetic questions.
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Now do Practice Questions 3 and 4 in the same way.
      </div>

      <div className="instructionsQ34__questions">
        <div className="question-ar question-ar-34">
          <div className="question__number-ar">
            3.
          </div>
          <div className="question__body-ar">
            <div className="question__text-ar">
              Candy bars cost $0.65 each. How much will 6 candy bars cost?
            </div>
            <div className="question-ar__answerBody">
              <div className="question__answers-ar">
                {
                  answerOptions3.map((element, index) => (
                    <label className="question__answer-option-ar" key={index}>
                      <input
                        type="checkbox"
                        className='question__radio-button'
                        checked={practiceAnswers[2].values === element}
                        name={2}
                        value={element}
                        onChange={e => handleAnswer(e)}
                      />
                      <div className="question__answer-text-ar">
                        {element}
                      </div>
                    </label>
                  ))
                }
              </div>
              { practiceAnswers[2].values !== '' || practiceAnswers[2].values.length > 0 ? (
                correct3 ? (
                  <div className="display-answer display-answer_upper">
                    Correct
                  </div>
                ) : (
                  <div className="display-answer display-answer_upper">
                    Incorrect
                  </div>
                )
              ) : (
                <div className="display-answer display-answer_upper"></div>
              )
              }
            </div>
          </div>
        </div>

        <div className="question-ar question-ar-34">
          <div className="question__number-ar">
            4.
          </div>
          <div className="question__body-ar">
            <div className="question__text-ar">
              Jim has used 2-1/2 feet of fencing from a piece that was 9-1/2 feet long. How
              many feet are left?
            </div>
            <div className="question-ar__answerBody">
              <div className="question__answers-ar">
                {
                  answerOptions4.map((element, index) => (
                    <label className="question__answer-option-ar" key={index}>
                      <input
                        type="checkbox"
                        className='question__radio-button'
                        checked={practiceAnswers[3].values === element}
                        name={3}
                        value={element}
                        onChange={e => handleAnswer(e)}
                      />
                      <div className="question__answer-text-ar">
                        {element}
                      </div>
                    </label>
                  ))
                }
              </div>
              { practiceAnswers[3].values !== '' || practiceAnswers[3].values.length > 0 ? (
                correct4 ? (
                  <div className="display-answer display-answer_lower">
                    Correct
                  </div>
                ) : (
                  <div className="display-answer display-answer_lower">
                    Incorrect
                  </div>
                )
              ) : (
                <div className="display-answer display-answer_lower"></div>
              )
              }
            </div>
          </div>
        </div>
      </div>

      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}  
        >
          Back
        </button>
        { disabled3 === true || disabled4 === true ? (
          <button
            type='button'
            disabled={disabled3 === true || disabled4 === true}
            className="ap-instructions-button"
            style={{ backgroundColor: 'grey' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_FINAL);
            }}
          >
            Next
          </button>
        ) : (
          <button
            type='button'
            className="ap-instructions-button"
            style={{ backgroundColor: '#008375' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_FINAL);
            }}
          >
            Next
          </button>
        ) }
      </div>

      {/* { practiceAnswers[2].values !== '' || practiceAnswers[2].values.length > 0 ? (
          correct3 ? (
            <div className="display-answer display-answer_upper">
              Correct
            </div>
          ) : (
            <div className="display-answer display-answer_upper">
              Incorrect
            </div>
          )
      ) : (
        <div className="display-answer display-answer_upper"></div>
      )
      } */}

      {/* { practiceAnswers[3].values !== '' || practiceAnswers[3].values.length > 0 ? (
          correct4 ? (
            <div className="display-answer display-answer_lower">
              Correct
            </div>
          ) : (
            <div className="display-answer display-answer_lower">
              Incorrect
            </div>
          )
      ) : (
        <div className="display-answer display-answer_lower"></div>
      )
      } */}

    </div>
  )
}

export default InstructionsQ34
