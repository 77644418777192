import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CM.css';
import Addition from '../Addition';

const InstructionsQ2 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');
  const [ currentQuestion ] = useState(1);

  useEffect(() => {
    if (practiceAnswers[currentQuestion].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[currentQuestion].values === 'none of these') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers, currentQuestion]);

  // Answer options.
  const answerOptions = [
    '6',
    '7',
    '10',
    '11',
    'none of these'
  ];

  const elements = [8, 1];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[currentQuestion].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: currentQuestion,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === currentQuestion) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === 'A-B') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  return (
    <div className='cm-main-box cm-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 4 - Computation
      </div>
      <div className='cm-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Now, read and answer Practice Question 2:
      </div>
      <div className="cm-question">
        <div className="cm-question__number">
          2.
        </div>
        <div className="cm-question__content">
          <Addition elements={elements} />
        </div>

          <div className="cm-question__answers">
            {
              answerOptions.map((element, index) => (
                <label className="cm-question__answer-option" key={index}>
                  <input
                    type="checkbox"
                    className='question__radio-button question__radio-button-cm'
                    checked={practiceAnswers[currentQuestion].values === element}
                    name={currentQuestion}
                    value={element}
                    onChange={e => handleAnswer(e)}
                  />
                  <div className="cm-question__answer-text">
                    {element}
                  </div>
                </label>
              ))
            }
          </div>
          { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
              correct ? (
                <div className="cm-display-answer-flex">
                  Correct
                  <div className="cm-display-answer__body">
                    That's right! In Practice Question 2, the correct answer is 9. However, 9 does not
                    appear in the answer column. Therefore, "none of these" is the correct answer.
                  </div>
                </div>
              ) : (
                <div className="cm-display-answer-flex">
                  Incorrect
                  <div className="cm-display-answer__body">
                    In Practice Question 2, the correct answer is 9. However, 9 does not
                    appear in the answer column. Therefore, "none of these" is the correct answer.
                  </div>
                </div>
              )
          ) : (
            <div className="cm-display-answer-flex"></div>
          )
          }
      </div>
      <div className="cm-row-2 cm-row-2-spaced cm-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}  
        >
          Back
        </button>
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q34);
          }}
        >
          Next
        </button>
      </div>
        {/* { practiceAnswers[currentQuestion].values !== '' || practiceAnswers[currentQuestion].values.length > 0 ? (
          correct ? (
            <div className="cm-display-answer cm-display-answer-q2">
              Correct
              <div className="cm-display-answer__body">
                That's right! In Practice Question 2, the correct answer is 9. However, 9 does not
                appear in the answer column. Therefore, "none of these" is the correct answer.
              </div>
            </div>
          ) : (
            <div className="cm-display-answer cm-display-answer-q2">
              Incorrect
              <div className="cm-display-answer__body">
                In Practice Question 2, the correct answer is 9. However, 9 does not
                appear in the answer column. Therefore, "none of these" is the correct answer.
              </div>
            </div>
          )
      ) : (
        <div className="cm-display-answer cm-display-answer-q2"></div>
      )
      } */}
    </div>
  )
}

export default InstructionsQ2