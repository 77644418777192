import React, { useState } from 'react';
import GeneralInstructions1 from './GeneralInstructions1';
import GeneralInstructions2 from './GeneralInstructions2';
import APExplanationVideo from './APExplanationVideo';
// import { Container, Row, Col } from 'react-bootstrap';
import { GIDisplayStates } from "./Configs";
import './APAssessment.css';

const GeneralInstructions = () => {
  const [display, setDisplay] = useState(GIDisplayStates.INSTRUCTIONS1);

  return (
    <div className='generalinstructions'>
      {display === GIDisplayStates.INSTRUCTIONS1 && (
        <GeneralInstructions1 onClick={setDisplay} />
        )
      }
      {display === GIDisplayStates.APEXPLANATIONVIDEO && (
        <APExplanationVideo />
        )
      }
      {display === GIDisplayStates.INSTRUCTIONS2 && (
        <GeneralInstructions2 />
        )
      }
    </div>
  )
}

export default GeneralInstructions
