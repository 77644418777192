import React, { useState, useEffect } from 'react';
import GridTable from './GridTable';

const brightPic = `/images/resultsImages/occupationGrid/outlook_sun.png`;

const OccupationGrid = ({ apresults, ipresults, wipresults, trifectaresults }) => {

  // Check page width.
  const [view, setView] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 640) {
        setView('mobile');
      } else if (screenWidth >= 640 && screenWidth < 1024) {
        setView('tablet');
      } else {
        setView('pc');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='occupationGrid__container'>
      <div className='occupationGrid'>
        <div className="occupationGrid__topRow">
          <h1>
            { (view === 'mobile') ? (
              <>The Occupation Grid:<br />Sortable Results</>
            ) : (
              <>The Occupation Grid - Sortable Results</>
            ) }
          </h1>
          <h2>
            Click on the headings below to sort by that column. Click on a job
            name to see more information about that specific job.
          </h2>

          { (view !== 'pc') &&
            <div className="occupationGrid__extras">
              <div className="occupationGrid__extraRow">
                <div className="occupationGrid__extraElement">Z</div>
                <div className="occupationGrid__extraText">Job Zone</div>
              </div>
              <div className="occupationGrid__extraRow">
                <div className="occupationGrid__extraElement">%</div>
                <div className="occupationGrid__extraText">
                  Trifecta Percentage. The higher the number, the better
                  match for you across all areas. Individual percentages
                  for interests, values, and abilities can be viewed
                  on a computer.
                </div>
              </div>
              <div className="occupationGrid__extraRow">
                <div className="occupationGrid__extraElement occupationGrid__extraElement-pic">
                  <img src={brightPic} alt="bright-pic" className="occupationGrid__pic" />
                </div>
                <div className="occupationGrid__extraText">
                  A bright outlook career is one that will grow
                  rapidly in the next few years.
                </div>
              </div>
            </div>
          }

        </div>

        <div className="occupationGrid__grid">
          <div className="occupationGrid__buttonRow">
            <GridTable apresults={apresults} ipresults={ipresults} wipresults={wipresults} trifectaresults={trifectaresults} view={view} />
          </div>
        </div>

        <div className="occupationGrid__endMatter">
        <img src={brightPic} alt="bright-pic" className="occupationGrid__pic occupationGrid__pic-bottom" />
          <span>A bright outlook career is one that will grow rapidly in the next few years.</span>
        </div>

      </div>
    </div>
  )
}

export default OccupationGrid;