const initState = {
  orderError: null,
  purchased: null,
  codeSubmit: true
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ORDER_ERROR':
      // console.log('Order failed');
      return {
        ...state,
        orderError: 'Order failed',
        purchased: false
      }
    case 'ORDER_SUCCESS':
      return {
        ...state,
        orderError: null,
        purchased: true
      }
    case 'CODE_SUBMIT_SUCCESS':
      return {
        ...state,
        orderError: null,
        codeSubmit: true
      }
    case 'CODE_SUBMIT_ERROR':
      return {
        ...state,
        orderError: null,
        codeSubmit: false
      }
    default:
      return state;
  }
};

export default orderReducer;