import React, { useState, useEffect, useRef } from 'react';
// import { compose } from 'redux';
// import { firestoreConnect } from 'react-redux-firebase';
// import { connect } from 'react-redux';
import WIPCalculate from '../wip/WIPCalculate';
// import WIPIncomplete from '../wip/WIPIncomplete';
import IPCalculate from '../ip/IPCalculate';
// import IPIncomplete from '../ip/IPIncomplete';
import APCalculate from '../ap/APCalculate';
// import APIncomplete from '../ap/APIncomplete';
import TrifectaCalculate from '../trifecta/TrifectaCalculate';
import OnetWebService from './onetQuery/OnetWebService';
import { Modal } from 'react-bootstrap';
import ProgressBar from '../../ProgressBar/ProgressBar';
// import LoadingPage from '../../pages/loadingPage/LoadingPage';

const JobResults = ({ pageHeight, auth, apresults, ipresults, wipresults, trifectaresults, ip, wip, ar, cm, cp, sa, va, fp }) => {
  // Variables.
  const [ wipCompleted, setWIPCompleted ] = useState(false);  // Is the WIP assessment completed?
  const [ ipCompleted, setIPCompleted ] = useState(false);  // Is the IP assessment completed?
  const [ apCompleted, setAPCompleted ] = useState(false);  // Is the AP assessment completed?
  const [ wipState, setWIPState ] = useState(true);    // Does the 'wipresults' collection exist for this user?
  const [ ipState, setIPState ] = useState(true);      // Does the 'ipresults' collection exist for this user?
  const [ apState, setAPState ] = useState(true);      // Does the 'apresults' collection exist for this user?
  const [ trifectaState, setTrifectaState ] = useState(true);   // Does the 'trifectaresults' collection exist for this user?

  const [trifectaResults, setTrifectaResults] = useState([]);
  const [ apwipRemain, setAPWIPRemain ] = useState([]);
  const [ ipapRemain, setIPAPRemain ] = useState([]);
  const [ ipwipRemain, setIPWIPRemain ] = useState([]);

  const [ riasec, setRiasec ] = useState([]);
  const [ jobZone, setJobZone ] = useState('');
  const [ tasks, setTasks ] = useState([]);
  const [ abilities, setAbilities ] = useState([]);
  const [ workActivities, setWorkActivities ] = useState([]);
  const [ skills, setSkills ] = useState([]);
  const [ relatedOccupations, setRelatedOccupations ] = useState([]);

  const [ showPopup, setShowPopup ] = useState(false);
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);
  const [ showTrifectaPopup, setShowTrifectaPopup ] = useState(false);
  const handleCloseTrifectaPopup = () => setShowTrifectaPopup(false);
  const handleShowTrifectaPopup = () => setShowTrifectaPopup(true);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ currentJob, setCurrentJob ] = useState('');

  const [ OnetURL, setOnetURL ] = useState('');

  // Initialize states and refs for scroll arrows of overlap circle.
  const [ scrollYOverlap, setScrollYOverlap ] = useState(0);
  const [ scrollEndOverlap, setScrollEndOverlap ] = useState(false);
  
  let scrlOverlap = useRef(null);
  // All of this is needed to check if bottom arrow is needed upon page load.
  // There's probably a better way to do it, but I couldn't figure it out.
  // Spent the better part of a day trying to do it with only useRef and/or
  // useCallback. (10/11/2022)
  const [ refMountCheckOverlap, setRefMountCheckOverlap ] = useState(false);
  if (scrlOverlap.current !== null && refMountCheckOverlap === false) {
    setRefMountCheckOverlap(true);
  }
  useEffect(() => {
    try {
      if (refMountCheckOverlap === true) {
        if ((scrlOverlap.current.scrollHeight - scrlOverlap.current.clientHeight) > 0) {
          setScrollEndOverlap(true);
        } else {
          setScrollEndOverlap(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [refMountCheckOverlap]);

  // Initialize states and refs for scroll arrows of APWIP circle.
  const [ scrollYAPWIP, setScrollYAPWIP ] = useState(0);
  const [ scrollEndAPWIP, setScrollEndAPWIP ] = useState(false);
  
  let scrlAPWIP = useRef(null);        // To keep track of scrolling.
  const [ refMountCheckAPWIP, setRefMountCheckAPWIP ] = useState(false);
  try {
    if (scrlAPWIP.current !== null && refMountCheckAPWIP === false) {
      setRefMountCheckAPWIP(true);
    }
  } catch (error) {
    console.log('Error: ', error);
  }
  useEffect(() => {
    try {
      if (refMountCheckAPWIP === true) {
        if ((scrlAPWIP.current.scrollHeight - scrlAPWIP.current.clientHeight) > 0) {
          setScrollEndAPWIP(true);
        } else {
          setScrollEndAPWIP(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [refMountCheckAPWIP]);

  // Initialize states and refs for scroll arrows of APIP circle.
  const [ scrollYAPIP, setScrollYAPIP ] = useState(0);
  const [ scrollEndAPIP, setScrollEndAPIP ] = useState(false);
  
  let scrlAPIP = useRef(null);
  const [ refMountCheckAPIP, setRefMountCheckAPIP ] = useState(false);
  if (scrlAPIP.current !== null && refMountCheckAPIP === false) {
    setRefMountCheckAPIP(true);
  }
  useEffect(() => {
    if (refMountCheckAPIP === true) {
      if ((scrlAPIP.current.scrollHeight - scrlAPIP.current.clientHeight) > 0) {
        setScrollEndAPIP(true);
      } else {
        setScrollEndAPIP(false);
      }
    }
  }, [refMountCheckAPIP]);


  // Initialize states and refs for scroll arrows of IPWIP circle.
  const [ scrollYIPWIP, setScrollYIPWIP ] = useState(0);
  const [ scrollEndIPWIP, setScrollEndIPWIP ] = useState(false);
  
  let scrlIPWIP = useRef(null);
  const [ refMountCheckIPWIP, setRefMountCheckIPWIP ] = useState(false);
  if (scrlIPWIP.current !== null && refMountCheckIPWIP === false) {
    setRefMountCheckIPWIP(true);
  }
  useEffect(() => {
    if (refMountCheckIPWIP === true) {
      if ((scrlIPWIP.current.scrollHeight - scrlIPWIP.current.clientHeight) > 0) {
        setScrollEndIPWIP(true);
      } else {
        setScrollEndIPWIP(false);
      }
    }
  }, [refMountCheckIPWIP]);


  // Number of top jobs to show.
  const N = 10;

  useEffect(() => {
    // Check if the WIP assessment has been completed.
    if (wip) {
      if (wip.length > 0) {
        const rbLength = Object.keys(wip[0].rbAnswers).length;    // Has the user answered all the questions?
        if (rbLength === 42) {
          setWIPCompleted(true);
        } else {
          setWIPCompleted(false);
        }
      }
    }

    // Check if the IP assessment has been completed.
    if (ip) {
      if (ip.length > 0) {
        const ipLength = ip[0].ipAnswers.length;
        if (ipLength === 60) {
          setIPCompleted(true);
        } else {
          setIPCompleted(false);
        }
      }
    }

    // Check if the AP assessment has been completed.
    if (fp) {
      if (fp.length > 0) {
        const fpLength = Object.keys(fp[0].fpAnswers).length;
        if (fpLength) {
          setAPCompleted(true);
        } else {
          setAPCompleted(false);
        }
      }
    }

    // Check if WIP results have already been calculated.
    if (wipresults) {
      if (wipresults.length > 0) {
        setWIPState(true);
      } else {
        setWIPState(false);
      }
    }

    // Check if IP results have already been calculated.
    if (ipresults) {
      if (ipresults.length > 0) {
        setIPState(true);
      } else {
        setIPState(false);
      }
    }

    // Check if AP results have already been calculated.
    if (apresults) {
      if (apresults.length > 0) {
        setAPState(true);
      } else {
        setAPState(false);
      }
    }

    // Check if apresults, ipresults, and wipresults have already been calculated.
    if (apresults && ipresults && wipresults) {
      if (apresults.length > 0 && ipresults.length > 0 && wipresults.length > 0) {
        if (trifectaresults) {
          if (trifectaresults.length > 0) {
            setTrifectaState(true);

            let apwipResults = trifectaresults[0].apwipSortedResults;

            let ipapResults = trifectaresults[0].ipapSortedResults;

            let ipwipResults = trifectaresults[0].ipwipSortedResults;

            let trifectaResults = trifectaresults[0].trifectaResults;

            let shownTrifectaResults = trifectaResults.slice(0,N);
            // Set state variable.
            setTrifectaResults(shownTrifectaResults);

            // Get all jobs in apwip and not in trifecta.
            let apwipRemain = apwipResults.filter(value => shownTrifectaResults.every(value2 => value.UID !== value2.UID));
            setAPWIPRemain(apwipRemain.slice(0,N));

            // Get all jobs in apip and not in trifecta.
            let ipapRemain = ipapResults.filter(value => shownTrifectaResults.every(value2 => value.UID !== value2.UID));
            setIPAPRemain(ipapRemain.slice(0,N));
            
            // Get all jobs in ipwip and not in trifecta.
            let ipwipRemain = ipwipResults.filter(value => shownTrifectaResults.every(value2 => value.UID !== value2.UID));
            setIPWIPRemain(ipwipRemain.slice(0,N));

          } else {
            setTrifectaState(false);
          }
        }
      }
    }
  }, [wip, ip, fp, wipresults, ipresults, apresults, trifectaresults, auth]);

  const ColoredLine = ({ color, height = 0.1, className='' }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            opacity: 1,
            height: height
        }}
        className={className}
    />
  );

  const titles = [
    'Abilities + Values',
    'Abilities + Interests',
    'Interests + Values'
  ];

  // Array names to map through for the last three circles (AP/WIP, AP/IP, and IP/WIP).
  const arrays = [
    apwipRemain,
    ipapRemain,
    ipwipRemain
  ];

  // Function to run when an occupation is clicked.
  function passJob(val, ind) {
    let code = val.onetCode;
    let occupation = val.occupation;
    
    // Query O*Net database, get relevant info, and populate that info into state variables.
    const fetchData = async () => {
      // Reset states.
      setRiasec([]);
      setJobZone([]);
      setTasks([]);
      setAbilities([]);
      setWorkActivities([]);
      setSkills([]);
      setRelatedOccupations([]);

      // Get new data.
      setRiasec(await getRIASEC(code, occupation));
      setJobZone(await getJobZone(code, occupation));
      setTasks(await getTasks(code, occupation));
      setAbilities(await getAbilities(code, occupation));
      setWorkActivities(await getWorkActivities(code, occupation));
      setSkills(await getSkills(code, occupation));
      setRelatedOccupations(await getRelatedOccupations(code, occupation));

      setCurrentIndex(ind);

      setCurrentJob(occupation);

      handleShowPopup();

      // Set O*Net URL for this job.
      setOnetURL(`https://www.onetonline.org/link/summary/${val.onetCode}`);
    }
    fetchData();
  }


  // Function to run when an occupation is clicked in the Trifecta section.
  function passTrifectaJob(val, ind) {
    let code = val.onetCode;
    let occupation = val.occupation;
    
    // Query O*Net database, get relevant info, and populate that info into state variables.
    const fetchData = async () => {
      // Reset states.
      setRiasec([]);
      setJobZone([]);
      setTasks([]);
      setAbilities([]);
      setWorkActivities([]);
      setSkills([]);
      setRelatedOccupations([]);

      // Get new data.
      setRiasec(await getRIASEC(code, occupation));
      setJobZone(await getJobZone(code, occupation));
      setTasks(await getTasks(code, occupation));
      setAbilities(await getAbilities(code, occupation));
      setWorkActivities(await getWorkActivities(code, occupation));
      setSkills(await getSkills(code, occupation));
      setRelatedOccupations(await getRelatedOccupations(code, occupation));

      setCurrentIndex(ind);

      setCurrentJob(occupation);

      handleShowTrifectaPopup();

      // Set O*Net URL for this job.
      setOnetURL(`https://www.onetonline.org/link/summary/${val.onetCode}`);
    }
    fetchData();
  }

  // Functions to shift the element up or down.
  const slideOverlap = (shift) => {
    scrlOverlap.current.scrollTop += shift;

    setScrollYOverlap(scrollYOverlap + shift);

    if (
      Math.floor(scrlOverlap.current.scrollHeight - scrlOverlap.current.scrollTop) <=
      scrlOverlap.current.offsetHeight
    ) {
      setScrollEndOverlap(false);
    } else {
      setScrollEndOverlap(true);
    }
  }

  const slideAPWIP = (shift) => {
    scrlAPWIP.current.scrollTop += shift;

    setScrollYAPWIP(scrollYAPWIP + shift);

    if (
      Math.floor(scrlAPWIP.current.scrollHeight - scrlAPWIP.current.scrollTop) <=
      scrlAPWIP.current.offsetHeight
    ) {
      setScrollEndAPWIP(false);
    } else {
      setScrollEndAPWIP(true);
    }
  }

  const slideAPIP = (shift) => {
    scrlAPIP.current.scrollTop += shift;

    setScrollYAPIP(scrollYAPIP + shift);

    if (
      Math.floor(scrlAPIP.current.scrollHeight - scrlAPIP.current.scrollTop) <=
      scrlAPIP.current.offsetHeight
    ) {
      setScrollEndAPIP(false);
    } else {
      setScrollEndAPIP(true);
    }
  }

  const slideIPWIP = (shift) => {
    scrlIPWIP.current.scrollTop += shift;

    setScrollYIPWIP(scrollYIPWIP + shift);

    if (
      Math.floor(scrlIPWIP.current.scrollHeight - scrlIPWIP.current.scrollTop) <=
      scrlIPWIP.current.offsetHeight
    ) {
      setScrollEndIPWIP(false);
    } else {
      setScrollEndIPWIP(true);
    }
  }

  const scrollCheckOverlap = () => {
    setScrollYOverlap(scrlOverlap.current.scrollTop);
    if (
      Math.floor(scrlOverlap.current.scrollHeight - scrlOverlap.current.scrollTop) <=
      scrlOverlap.current.offsetHeight
    ) {
      setScrollEndOverlap(false);
    } else {
      setScrollEndOverlap(true);
    }
  }

  const scrollCheckAPWIP = () => {
    setScrollYAPWIP(scrlAPWIP.current.scrollTop);
    if (
      Math.floor(scrlAPWIP.current.scrollHeight - scrlAPWIP.current.scrollTop) <=
      scrlAPWIP.current.offsetHeight
    ) {
      setScrollEndAPWIP(false);
    } else {
      setScrollEndAPWIP(true);
    }
  }

  const scrollCheckAPIP = () => {
    setScrollYAPIP(scrlAPIP.current.scrollTop);
    if (
      Math.floor(scrlAPIP.current.scrollHeight - scrlAPIP.current.scrollTop) <=
      scrlAPIP.current.offsetHeight
    ) {
      setScrollEndAPIP(false);
    } else {
      setScrollEndAPIP(true);
    }
  }

  const scrollCheckIPWIP = () => {
    setScrollYIPWIP(scrlIPWIP.current.scrollTop);
    if (
      Math.floor(scrlIPWIP.current.scrollHeight - scrlIPWIP.current.scrollTop) <=
      scrlIPWIP.current.offsetHeight
    ) {
      setScrollEndIPWIP(false);
    } else {
      setScrollEndIPWIP(true);
    }
  }

  // List all scroll ref variable names.
  const scrlList = [
    scrlAPWIP,
    scrlAPIP,
    scrlIPWIP
  ];

  const slideList = [
    slideAPWIP,
    slideAPIP,
    slideIPWIP
  ]

  const scrollCheckList = [
    scrollCheckAPWIP,
    scrollCheckAPIP,
    scrollCheckIPWIP
  ]

  const scrollList = [
    scrollYAPWIP,
    scrollYAPIP,
    scrollYIPWIP
  ]

  const scrollEndList = [
    scrollEndAPWIP,
    scrollEndAPIP,
    scrollEndIPWIP
  ]

  return (
    <div style={{ height: pageHeight, marginBottom: '100px'}}>
      {/* {!wipCompleted && !wipState && <WIPIncomplete />} Runs when the WIP results do not exist and the WIP assessment has not been completed. */}
      {/* {!ipCompleted && !ipState && <IPIncomplete />} Runs when the IP results do not exist and the IP assessment has not been completed. */}
      {/* {!apCompleted && !apState && <APIncomplete />} Runs when the AP results do not exist and the AP assessment has not been completed. */}
      {wipCompleted && !wipState && <WIPCalculate wip={wip} />} {/* Runs when the WIP assessment is complete and has not yet been calculated. */}
      {ipCompleted && !ipState && <IPCalculate ip={ip} />} {/* Runs when the IP assessment is complete and has not yet been calculated. */}
      {apCompleted && !apState && <APCalculate ar={ar} cm={cm} cp={cp} fp={fp} sa={sa} va={va} />} {/* Runs when the AP assessments are complete and have not yet been calculated. */}
      {wipCompleted && ipCompleted && apCompleted && !trifectaState && <TrifectaCalculate apresults={apresults} ipresults={ipresults} wipresults={wipresults} />}  {/* Runs when the AP assessments are complete and have not yet been calculated. */}
      {
        wipCompleted && wipState && ipCompleted && ipState && apCompleted && apState && trifectaState ?
        (
          <div className='top-jobs' style={{ height: pageHeight}}>
            <h1>
              Your Top Jobs
            </h1>

            <div className='top-jobs__outer-box'>
              <div className='triangle triangle-center'></div>
              <div className="top-jobs__boundingBox">
                <div className="top-jobs__boxBox">
                  <div className="top-jobs__box-left">
                    The <b>top circle</b> contains jobs that were in all
                    three assessment results for you. These should be the
                    closest match to a good career path for you to pursue
                    because they are something you have the ability for,
                    something you have shown interest in, and something
                    that should give you meaning in your work. It may be
                    that you do not have any jobs that overlap in all three
                    areas. If this is the case, turn your attention to the
                    following circles instead.
                  </div>
                  <div className="top-jobs__box-right">
                    The <b>following circles </b> are where you have two areas
                    of overlap. In particular, examine the circles that meet
                    your abilities, and especially the one that has results for
                    overlap between your abilities and work that would give you
                    meaning. There may be jobs listed there that you hadn't
                    considered before.<br /><br />While you may have more results
                    than are shown here, we have limited results to your top 10.
                  </div>
                </div>
              </div>
              {/* <div className="job-zones__box">
                <h1>
                  A Job Zone is a group of occupations that are similar in these ways:
                </h1>
                <ul className='bullets'>
                  <li>How much education people need to do the job.</li>
                  <li>How much related experience people need to do the job.</li>
                  <li>How much on-the-job training people need to do the job.</li>
                </ul>
                <p>
                  In other words, occupations within a Job Zone require a similar level of preparation.
                </p>
              </div> */}
            </div>


            <div className="top-jobs__vertical-line">
              <ColoredLine color='#901DB4' height='0.1px' />
            </div>

            <div className="top-jobs__trifecta">
              <div className="outer-1">
                <div className="inner-1">
                  &nbsp;
                </div>
                <div className="inner-2">
                  &nbsp;
                </div>
                <div className="inner-3">
                  {scrollYOverlap !==0 && <button
                    className='topJobs__listButton topJobs__listButton-up'
                    onClick={() => slideOverlap(-25)}
                  >
                    <i className="fa fa-angle-up"></i>
                  </button> }
                  <div
                    className="top-jobs__trifecta-list"
                    ref={scrlOverlap}
                    onScroll={scrollCheckOverlap}
                  >
                    {trifectaResults.length > 0 ? (
                      trifectaResults.map((val, ind) => (
                        <div key={ind} className='top-jobs__trifecta-list-item'>
                          <button
                            className='top-jobs__trifecta-list-item__button'
                            onClick={() => passTrifectaJob(val, ind)}
                          >
                            {val.occupation}
                          </button>

                          <Modal
                            show={showTrifectaPopup}
                            onHide={handleCloseTrifectaPopup}
                            centered
                            dialogClassName='top-jobs__modal'
                            backdropClassName='top-jobs__modal-backdrop'
                          >
                            <Modal.Header
                              closeButton
                              className='top-jobs__modal-header'
                            >
                              <Modal.Title className='top-jobs__modal-title'>
                                <h2>
                                  {titles[currentIndex]}
                                </h2>
                                <h1>
                                  {currentJob}
                                </h1>
                                <h3>
                                  {jobZone}
                                </h3>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body
                              className='top-jobs__modal-body'
                            >
                              <div>

                                <ColoredLine color='#662D91' height='20px' className='top-jobs__modal-hor-line' />

                                <div className="top-jobs__modal-main-body">

                                  <div className='top-jobs__body-column'>
                                    <div className='top-jobs__section-title'>
                                      Top 5 Tasks
                                    </div>
                                    <ul className='top-jobs__bullets'>
                                      {tasks !== null ? (
                                        tasks.map((task, index) => (
                                          <li key={index} className='top-jobs__bullet-item'>
                                            {task}
                                          </li>
                                        ))
                                      ) : (
                                        <div className='top-jobs__bullet-item'>
                                          No data available.
                                        </div>
                                      )}
                                    </ul>
                                    <div className='top-jobs__section-title'>
                                      Top 5 Abilities
                                    </div>
                                    <ul className='top-jobs__bullets'>
                                      {abilities !== null ? (
                                        abilities.map((ability, index) => (
                                          <li key={index} className='top-jobs__bullet-item'>
                                            <b>{ability.name}&nbsp;</b>
                                            <span>{ability.description}</span>
                                          </li>
                                        ))
                                      ) : (
                                        <div className='top-jobs__bullet-item'>
                                          No data available.
                                        </div>
                                      )}
                                    </ul>
                                  </div>

                                  <div className='top-jobs__body-column'>
                                    <div className='top-jobs__section-title'>
                                      Top 5 Skills
                                    </div>
                                    <ul className='top-jobs__bullets'>
                                      {skills !== null ? (
                                        skills.map((skill, index) => (
                                          <li key={index} className='top-jobs__bullet-item'>
                                            <b>{skill.name}:&nbsp;</b>
                                            <span>{skill.description}</span>
                                          </li>
                                        ))
                                      ) : (
                                        <div className='top-jobs__bullet-item'>
                                          No data available.
                                        </div>
                                      )}
                                    </ul>
                                    <div className='top-jobs__section-title'>
                                      Top 5 Work Activities
                                    </div>
                                    <ul className='top-jobs__bullets'>
                                      {workActivities !== null ? (
                                        workActivities.map((activity, index) => (
                                          <li key={index} className='top-jobs__bullet-item'>
                                            <b>{activity.name}:&nbsp;</b>
                                            <span>{activity.description}</span>
                                          </li>
                                        ))
                                      ) : (
                                        <div className='top-jobs__bullet-item'>
                                          No data available.
                                        </div>
                                      )}
                                    </ul>
                                  </div>

                                  <div className='top-jobs__body-column-line'>
                                    <div className="top-jobs__body-line">
                                    </div>
                                  </div>

                                  <div className="top-jobs__body-column">
                                    <div className='top-jobs__section-title'>
                                      Related Occupations
                                    </div>
                                    <ul className="top-jobs__bullets">
                                      {relatedOccupations !== null ? ( 
                                        relatedOccupations.map((related, index) => (
                                          <li key={index} className='top-jobs__bullet-item'>
                                            {related}
                                          </li>
                                        ))
                                      ) : (
                                        <div className='top-jobs__bullet-item'>
                                          No data available.
                                        </div>
                                      )}
                                    </ul>
                                    <div className='top-jobs__riasec-section'>
                                      {riasec.map((element, index) => (
                                          element.score > 49 ? (
                                            <div key={index} className='top-jobs__riasec-item'>
                                              <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                                                {element.score}
                                              </div>
                                              <div style={{ width: '50%' }}>
                                                <ProgressBar bgColor='#001343' completed={element.score}  />
                                              </div>
                                              <div style={{ marginLeft: '5px' }}>
                                                {element.name}
                                              </div>
                                            </div>

                                          ) : (

                                            <div key={index} className='top-jobs__riasec-item-gray'>
                                              <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                                                {element.score}
                                              </div>
                                              <div style={{ width: '50%' }}>
                                                <ProgressBar bgColor='#999999' completed={element.score}  />
                                              </div>
                                              <div style={{ marginLeft: '5px' }}>
                                                {element.name}
                                              </div>
                                            </div>

                                          )
                                      ))}
                                    </div>

                                    <div className="top-jobs__link">
                                      <a href={OnetURL} target='blank' rel='noreferrer' style={{ color: '#901DB4' }}>
                                        Full description on O*Net
                                      </a>
                                    </div>
                                  </div>

                                </div>
                                
                              </div>
                            </Modal.Body>
                          </Modal>

                        </div>
                      ))
                    ) : (
                      <div style={{ fontSize: '16px' }}>
                        None
                      </div>
                    )}
                  </div>
                  { scrollEndOverlap === true && <button
                    className='topJobs__listButton topJobs__listButton-down'
                    onClick={() => slideOverlap(+25)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button> }
                </div>
              </div>
            </div>

            <div className="top-jobs__h2">
              Abilities + Interests + Values
            </div>

            <div className="top-jobs__many-lines">
              <div className="top-jobs__vertical-line-bottom">
                <ColoredLine color='#901DB4' height='0.1px' />
              </div>
              <div className="top-jobs__vertical-line-bottom-left">
                <ColoredLine color='#901DB4' height='0.1px' />
              </div>
              <div className="top-jobs__vertical-line-bottom-center">
                <ColoredLine color='#901DB4' height='0.1px' />
              </div>
              <div className="top-jobs__vertical-line-bottom-right">
                <ColoredLine color='#901DB4' height='0.1px' />
              </div>
            </div>

            <div className="top-jobs__overlaps">
              {
                titles.map((val, ind) => (
                  <div key={ind}>
                    <div className="top-jobs__trifecta">
                      <div className={`outer-1 outer-1-${ind}`}>
                        <div className={`inner-1 inner-1-${ind}`}>
                          &nbsp;
                        </div>
                        {/* <div className="inner-2">
                          &nbsp;
                        </div> */}
                        <div className="inner-3">
                        {scrollList[ind] !==0 && <button
                            className='topJobs__listButton topJobs__listButton-up'
                            onClick={() => slideList[ind](-25)}
                          >
                            <i className="fa fa-angle-up"></i>
                          </button>}
                          <div
                            className="top-jobs__trifecta-list"
                            ref={scrlList[ind]}
                            onScroll={scrollCheckList[ind]}
                          >
                            {arrays[ind].length > 0 ? (
                              arrays[ind].map((val2, ind2) => (
                                <div key={ind2} className='top-jobs__trifecta-list-item'>
                                  <button
                                    className='top-jobs__trifecta-list-item__button'
                                    onClick={() => passJob(val2, ind)}
                                  >
                                    {val2.occupation}
                                  </button>

                                  <Modal
                                    show={showPopup}
                                    onHide={handleClosePopup}
                                    centered
                                    dialogClassName='top-jobs__modal'
                                    backdropClassName='top-jobs__modal-backdrop'
                                  >
                                    <Modal.Header
                                      closeButton
                                      className='top-jobs__modal-header'
                                    >
                                      <Modal.Title className='top-jobs__modal-title'>
                                        <h2>
                                          {titles[currentIndex]}
                                        </h2>
                                        <h1>
                                          {currentJob}
                                        </h1>
                                        <h3>
                                          {jobZone}
                                        </h3>
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='top-jobs__modal-body'>
                                      <div>
                                      <ColoredLine color='#662D91' height='20px' className='top-jobs__modal-hor-line' />

                                        <div className="top-jobs__modal-main-body">

                                          <div className='top-jobs__body-column'>
                                            <div className='top-jobs__section-title'>
                                              Top 5 Tasks
                                            </div>
                                            <ul className='top-jobs__bullets'>
                                              {tasks ? (
                                                tasks.map((task, index) => (
                                                  <li key={index} className='top-jobs__bullet-item'>
                                                    {task}
                                                  </li>
                                                ))
                                              ) : (
                                                <div className='top-jobs__bullet-item'>
                                                  No data available.
                                                </div>
                                              )}
                                            </ul>
                                            <div className='top-jobs__section-title'>
                                              Top 5 Abilities
                                            </div>
                                            <ul className='top-jobs__bullets'>
                                              {abilities ? (
                                                abilities.map((ability, index) => (
                                                  <li key={index} className='top-jobs__bullet-item'>
                                                    <b>{ability.name}&nbsp;</b>
                                                    <span>{ability.description}</span>
                                                  </li>
                                                ))
                                              ) : (
                                                <div className='top-jobs__bullet-item'>
                                                  No data available.
                                                </div>
                                              )}
                                            </ul>
                                          </div>

                                          <div className='top-jobs__body-column'>
                                            <div className='top-jobs__section-title'>
                                              Top 5 Skills
                                            </div>
                                            <ul className='top-jobs__bullets'>
                                              {skills ? (
                                                skills.map((skill, index) => (
                                                  <li key={index} className='top-jobs__bullet-item'>
                                                    <b>{skill.name}:&nbsp;</b>
                                                    <span>{skill.description}</span>
                                                  </li>
                                                ))
                                              ) : (
                                                <div className='top-jobs__bullet-item'>
                                                  No data available.
                                                </div>
                                              )}
                                            </ul>
                                            <div className='top-jobs__section-title'>
                                              Top 5 Work Activities
                                            </div>
                                            <ul className='top-jobs__bullets'>
                                              {workActivities ? (
                                                workActivities.map((activity, index) => (
                                                  <li key={index} className='top-jobs__bullet-item'>
                                                    <b>{activity.name}:&nbsp;</b>
                                                    <span>{activity.description}</span>
                                                  </li>
                                                ))
                                              ) : (
                                                <div className='top-jobs__bullet-item'>
                                                  No data available.
                                                </div>
                                              )}
                                            </ul>
                                          </div>

                                          <div className='top-jobs__body-column-line'>
                                            <div className="top-jobs__body-line">
                                            </div>
                                          </div>

                                          <div className="top-jobs__body-column">
                                            <div className='top-jobs__section-title'>
                                              Related Occupations
                                            </div>
                                            <ul className="top-jobs__bullets">
                                              {relatedOccupations !== null ? (
                                                relatedOccupations.map((related, index) => (
                                                  <li key={index} className='top-jobs__bullet-item'>
                                                    {related}
                                                  </li>
                                                ))
                                              ) : (
                                                <div className='top-jobs__bullet-item'>
                                                  No data available.
                                                </div>
                                              )}
                                            </ul>
                                            <div className='top-jobs__riasec-section'>
                                              {riasec.map((element, index) => (
                                                  element.score > 49 ? (
                                                    <div key={index} className='top-jobs__riasec-item'>
                                                      <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                                                        {element.score}
                                                      </div>
                                                      <div style={{ width: '50%' }}>
                                                        <ProgressBar bgColor='#001343' completed={element.score}  />
                                                      </div>
                                                      <div style={{ marginLeft: '5px' }}>
                                                        {element.name}
                                                      </div>
                                                    </div>

                                                  ) : (

                                                    <div key={index} className='top-jobs__riasec-item-gray'>
                                                      <div style={{ width: '30px', textAlign: 'right', marginRight: '5px' }}>
                                                        {element.score}
                                                      </div>
                                                      <div style={{ width: '50%' }}>
                                                        <ProgressBar bgColor='#999999' completed={element.score}  />
                                                      </div>
                                                      <div style={{ marginLeft: '5px' }}>
                                                        {element.name}
                                                      </div>
                                                    </div>

                                                  )
                                              ))}
                                            </div>
                                            <div className="top-jobs__link">
                                              <a href={OnetURL} target='blank' rel='noreferrer' style={{ color: '#901DB4' }}>
                                                Full description on O*Net
                                              </a>
                                            </div>
                                          </div>

                                        </div>

                                        </div>
                                    </Modal.Body>
                                  </Modal>

                                </div>
                              ))
                            ) : (
                              <div style={{ fontSize: '16px' }}>
                                None
                              </div>
                            )}
                          </div>
                          { scrollEndList[ind] === true && <button
                            className='topJobs__listButton topJobs__listButton-down'
                            onClick={() => slideList[ind](+25)}
                          >
                            <i className="fa fa-angle-down"></i>
                          </button> }
                        </div>
                      </div>
                    </div>
                    <div className="top-jobs__h2" style={{ textAlign: 'center' }}>
                      {val}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : (
          <div className='top-jobs__alternate'>
            {/* <LoadingPage /> */}
            No results available. Please make sure you've completed all your assessments.
          </div>
        )
      }

    </div>
  )
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth,
//     wipresults: state.firestore.ordered.wipresults,
//     ipresults: state.firestore.ordered.ipresults,
//     apresults: state.firestore.ordered.apresults,
//     trifectaresults: state.firestore.ordered.trifectaresults,
//     ip: state.firestore.ordered.ip,
//     wip: state.firestore.ordered.wip,
//     ar: state.firestore.ordered.ar,
//     cm: state.firestore.ordered.cm,
//     cp: state.firestore.ordered.cp,
//     fp: state.firestore.ordered.fp,
//     sa: state.firestore.ordered.sa,
//     va: state.firestore.ordered.va
//   };
// };

// export default compose(
//   connect(mapStateToProps),
//   firestoreConnect((props) => {
//     return [
//       { collection: 'wipresults', doc: props.auth.uid },
//       { collection: 'ipresults', doc: props.auth.uid },
//       { collection: 'apresults', doc: props.auth.uid },
//       { collection: 'trifectaresults', doc: props.auth.uid },
//       { collection: 'ip', doc: props.auth.uid },
//       { collection: 'wip', doc: props.auth.uid },
//       { collection: 'ar', doc: props.auth.uid },
//       { collection: 'cm', doc: props.auth.uid },
//       { collection: 'cp', doc: props.auth.uid },
//       { collection: 'fp', doc: props.auth.uid },
//       { collection: 'sa', doc: props.auth.uid },
//       { collection: 'va', doc: props.auth.uid }
//     ]
//   })
// )(JobResults);

export default JobResults;



// Get RIASEC scores for each occupation.
async function getRIASEC(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/details/interests', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let riasecResults = kwresults.element;
      const riasecs = riasecResults.map((val, ind) => {
        const element = {
          name: val.name,
          score: val.score.value
        };
        return element;
      });
      // Sort RIASEC results based on score, from highest to lowest.
      const sortedRiasecs = riasecs.sort((a, b) => a.score > b.score ? 1 : -1).reverse();
      result(sortedRiasecs);
    });
  });
}

// Get job zone for each occupation.
async function getJobZone(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/job_zone', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let zone = kwresults.title;
      result(zone);
    });
  });
}

// Get top tasks for each occupation.
async function getTasks(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/tasks', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let taskResults = kwresults.task;
      const tasks = taskResults.map((val, ind) => {
        return val.name;
      });
      result(tasks);
    });
  });
}

// Get top abilities for each occupation.
async function getAbilities(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/abilities', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      let abilityResults = kwresults.element;
      const abilities = abilityResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(abilities);
    });
  });
}

// Get top work activities for each occupation.
async function getWorkActivities(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/work_activities', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const workResults = kwresults.element;
      const workActivities = workResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(workActivities);
    });
  });
}

// Get top skills for each occupation.
async function getSkills(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/skills', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const skillsResults = kwresults.element;
      const skills = skillsResults.map((val, ind) => {
        const element = {
          name: val.name,
          description: val.description
        };
        return element;
      });
      result(skills);
    });
  });
}

// Get related occupations for each occupation.
async function getRelatedOccupations(code, occupation) {
  const username = "telos";
  const onet_ws = new OnetWebService(username);
  const kwquery = occupation;
  // let data = null;
  return new Promise((result, reject) => {
    onet_ws.call("/" + code + '/summary/related_occupations', { keyword: kwquery, end: 1 }, function(kwresults) {
      if (kwresults.error) {
        result(null);
        reject(kwresults.error);
        return;
      }
      const relatedResults = kwresults.occupation;
      const relatedOccupations = relatedResults.map((val, ind) => {
        return val.title;
      });
      result(relatedOccupations);
    });
  });
}