import React from "react";

const ProgressBar = (props) => {
  const { bgColor, completed } = props;

  const containerStyles = {
    // height: 'calc(10px + 0.40vw)',
    height: '0.75rem',
    width: '100%',
    backgroundColor: "white",
    marginTop: 'calc(2px + 0.10vw)',
    border: '1px solid #9A9A9A',
    borderRadius: '0px'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgColor,
    transition: 'width 0.5s ease-in-out',
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;