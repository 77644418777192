import React from 'react';
import './SignedInMultiGift.css';

const giftPic = `/images/purchaseImages/Gift-for-gift-page.png`;

const SignedInMultiGift = () => {
  return (
    <div className='signedInMultiGift'>
      <div className="signedInMultiGift__topText">
        Give the gift of research-backed guidance in an easy-to-use and
        understandable format to family and friends.
      </div>
      <div className="signedInMultiGift__row signedInMultiGift__row-outer">
        <div className="signedInMultiGift__col">
          <table className='signedInMultiGift__table'>
            <thead>
              <tr>
                <th className='signedInMultiGift__tableHeader'>Plans</th>
                <th className='signedInMultiGift__tableHeader signedInMultiGift__tableHeader-underline'>Each</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <button
                    className="signedInMultiGift__button signedInMultiGift__button-single"
                    onClick={() => window.open('https://buy.stripe.com/7sI2araGycj2bnO9AC', '_blank')}
                  >
                    Buy <span>Single</span> Plan
                  </button>
                </td>
                <td className='signedInMultiGift__tableBody signedInMultiGift__tableBody-single'>$79.95</td>
              </tr>
              <tr>
                <td>
                  <button
                    className="signedInMultiGift__button signedInMultiGift__button-two"
                    onClick={() => window.open('https://buy.stripe.com/5kA2ar4iaaaU77y4gj', '_blank')}
                  >
                    Buy <span>Two</span> Plan Bundle
                  </button>
                </td>
                <td className='signedInMultiGift__tableBody signedInMultiGift__tableBody-two'>$69.95</td>
              </tr>
              <tr>
                <td>
                  <button
                    className="signedInMultiGift__button signedInMultiGift__button-five"
                    onClick={() => window.open('https://buy.stripe.com/00g9CT4ia6YIgI88wA', '_blank')}
                  >
                    Buy <span>Five</span> Plan Bundle
                  </button>
                </td>
                <td className='signedInMultiGift__tableBody signedInMultiGift__tableBody-five'>$54.95</td>
              </tr>
              <tr>
                <td>
                  <button
                    className="signedInMultiGift__button signedInMultiGift__button-ten"
                    onClick={() => window.open('https://buy.stripe.com/cN2cP515Y4QAfE44gl', '_blank')}
                  >
                    Buy <span>Ten</span> Plan Bundle
                  </button>
                </td>
                <td className='signedInMultiGift__tableBody signedInMultiGift__tableBody-ten'>$44.95</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="signedInMultiGift__col signedInMultiGift__col-bottom">
          <div className='signedInMultiGift__imageBox'>
            <img src={giftPic} alt="gift-pic" className='signedInMultiGift__image' />
          </div>
          {/* <div className="signedInMultiGift__button">
            Button goes here.
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SignedInMultiGift