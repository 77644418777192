import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './APAssessment.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import { Redirect } from 'react-router-dom';
import GeneralInstructions from './GeneralInstructions';
import firebase from '../../../config/fbConfig';

// Images.
const pics = {
  '1': `/images/assessmentsImages/abilitiesNumbers/abilities_01.png`,
  '2': `/images/assessmentsImages/abilitiesNumbers/abilities_02.png`,
  '3': `/images/assessmentsImages/abilitiesNumbers/abilities_03.png`,
  '4': `/images/assessmentsImages/abilitiesNumbers/abilities_04.png`,
  '5': `/images/assessmentsImages/abilitiesNumbers/abilities_05.png`,
  '6': `/images/assessmentsImages/abilitiesNumbers/abilities_06.png`
}
const picsGrey = {
  '1': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_02.png`,
  '2': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_02.png`,
  '3': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_03.png`,
  '4': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_04.png`,
  '5': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_05.png`,
  '6': `/images/assessmentsImages/abilitiesNumbers/abilities_grey_06.png`
}

const APFrontPage = ({ user, auth }) => {
  // const [ giComplete, setGIComplete ] = useState(false);
  const [ arComplete, setARComplete ] = useState(false);
  const [ vaComplete, setVAComplete ] = useState(false);
  const [ saComplete, setSAComplete ] = useState(false);
  const [ siComplete, setSIComplete ] = useState(false);
  const [ cmComplete, setCMComplete ] = useState(false);
  const [ cpComplete, setCPComplete ] = useState(false);
  const [ fpComplete, setFPComplete ] = useState(false);

  const completesCol1 = [
    true, // arComplete,
    vaComplete,
    saComplete
  ];
  const completesCol2 = [
    cmComplete,
    cpComplete,
    fpComplete
  ];

  const [ isMounted, setIsMounted ] = useState(false);
  const [ purchasedOrder, setPurchasedOrder ] = useState(null);
  const [ stripePurchase, setStripePurchase ] = useState(null);

  // Check if user has paid.
  useEffect(() => {
    try {
      async function checkPurchases(docId) {
        const order = await firebase.firestore().collection('orders').doc(docId).get();
        
        let purchased = false;
        let orderData = await order.data();
        if (orderData) {
          purchased = true;
        } else {
          purchased = false;
        }
        setPurchasedOrder(purchased);
        
        // Component is mounted after this check is complete.
        setIsMounted(true);
        
        return purchased;
      }
      const uid = auth.uid;
      checkPurchases(uid);
    } catch (error) {
      console.log('Error: ', error);
      setIsMounted(true);
    }

  }, [auth.uid]);

  // Check if user purchased through Stripe.
  useEffect(() => {
    try {
      // Check if the user used Stripe to purchase a 'Deluxe Package'.
      if (auth.uid !== undefined) {
        firebase.firestore().collection('customers').doc(auth.uid).collection('payments').get().then(snapshot => {
          // Set stripePurchase to false if the user has no payments.
          if (snapshot.empty) {
            setStripePurchase(false);
          } else {  // If the user has made payments, check if one is for the 'Deluxe Package'.
            snapshot.forEach(paymentDoc => {
              let status = paymentDoc.data().status;
              const items = paymentDoc.data().items;
              if (items && status === 'succeeded') {
                items.forEach(item => {
                  if (item.description === 'Deluxe Package') {
                    // Set 'singlePurchase' to true if the user has made a purchase of the 'Deluxe Package'
                    // product in Stripe.
                    setStripePurchase(true);
                  } else {
                    setStripePurchase(false);
                  }
                })
              }
            })
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth.uid]);

  useEffect(() => {
    // Check which assessments the user has completed.
    // If assessment has been completed, grey out assessment and allow next assessment to be taken.
    // If assessment has not been completed, and its prior assessment has been completed, make it available to take.
    // If no completion variable exists for the assessment, set it to false.
    try {
      if (user) {
        // if (user[0].giComplete === true) {
        //   setGIComplete(true);
        // } else {
        //   setGIComplete(false);
        // }
        if (user[0].arComplete === true) {
          setARComplete(true);
        } else {
          setARComplete(false);
        }
        if (user[0].vaComplete === true) {
          setVAComplete(true);
        } else {
          setVAComplete(false);
        }
        if (user[0].saComplete === true) {
          setSAComplete(true);
        } else {
          setSAComplete(false);
        }
        if (user[0].siComplete === true) {
          setSIComplete(true);
        } else {
          setSIComplete(false);
        }
        if (user[0].cmComplete === true) {
          setCMComplete(true);
        } else {
          setCMComplete(false);
        }
        if (user[0].cpComplete === true) {
          setCPComplete(true);
        } else {
          setCPComplete(false);
        }
        if (user[0].fpComplete === true) {
          setFPComplete(true);
        } else {
          setFPComplete(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }

  }, [user]);

  // Route guarding.
  let apComplete = null;
  if (user) {
    try {
      if (user[0].arComplete && user[0].vaComplete && user[0].saComplete && user[0].cmComplete && user[0].cpComplete && user[0].fpComplete) {
        apComplete = true;
      }
      else {
        apComplete = false;
      }
      if (apComplete === true) return <Redirect to='/assessments' />
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  const checksCol1 = [
    arComplete,    // If true, don't allow the user to take the arithmetic assessment.
    // (arComplete && giComplete) || !giComplete,    // If true, don't allow the user to take the arithmetic assessment.
    (vaComplete && arComplete) || !arComplete,    // If true, don't allow the user to take the verbal assessment.
    (saComplete && vaComplete) || !vaComplete,    // If true, don't allow the user to take the spatial awareness assessment.
  ]
  const checksCol2 = [
    (cmComplete && saComplete) || !saComplete,
    (cpComplete && cmComplete) || !cmComplete,
    (fpComplete && cpComplete) || !cpComplete
  ]

  const namesCol1 = [
    'Part 1 - Arithmetic Reasoning',
    'Part 2 - Vocabulary',
    'Part 3 - Three-Dimensional Space'
  ]
  const subtextCol1 = [
    `Time Commitment = `,
    'Time Commitment = ',
    'Time Commitment = '
  ]
  const timesCol1 = [
    '20 minutes',
    '8 minutes',
    '8 minutes'
  ]
  
  const namesCol2 = [
    'Part 4 - Computation',
    'Part 5 - Name Comparison',
    'Part 6 - Object Matching'
  ]
  const subtextCol2 = [
    'Time Commitment = ',
    'Time Commitment = ',
    'Time Commitment = '
  ]
  const timesCol2 = [
    '6 minutes',
    '6 minutes',
    '5 minutes'
  ]

  const pathsCol1 = [
    '/arassessment',
    '/vaassessment',
    '/saassessment'
  ]
  const pathsCol2 = [
    '/cmassessment',
    '/cpassessment',
    '/fpassessment'
  ]

  return (
    <>
      { isMounted === true && purchasedOrder === false && stripePurchase === false &&  // If the loading page is meant to check whether the user has paid, the user's profile is mounted, and the user didn't pay yet, redirect to the payment page.
        <Redirect
          to={{
            pathname: `/payment`,
          }}
        />
      }
      {
        user ? (
          (!user[0].giComplete === true) ? (
            <GeneralInstructions />
          ) : (
            <div className = 'apfrontpage'>
              {user && <div style={{ width: '100%' }}>
                <div className="apassessments-header">
                  Abilities Profiler Sections
                  <p>
                    Times are for the assessment portion only and do not include time spent on instruction.
                  </p>
                </div>
                <div className="assessment-options">

                  {/* Left column. */}
                  <div className='apassessment__column'>
                    <ol className="assessment-options__list-1">
                      {checksCol1.map((element, index) => (
                        checksCol1[index] === true ? (
                          (completesCol1[index] === true) ? ( // Assessment has been completed.
                            <div key={index} className='assessment-option-gray'>
                              <div>
                                <img src={pics[index+1]} alt="" className='apfrontpage__pic' />
                              </div>
                              <div className='ap-option-text'>
                                {namesCol1[index]}
                                <p>
                                  {subtextCol1[index]}<b>{timesCol1[index]}</b>
                                </p>
                                <div className='ap-option-text-complete'>
                                  Completed!
                                </div>
                              </div>
                            </div>
                          ) : ( // Assessment is grayed out to prevent the user from taking it prematurely.
                            <div key={index} className='assessment-option-gray'>
                              <div>
                                <img src={picsGrey[index+1]} alt="" className='apfrontpage__pic' />
                              </div>
                              <div className='ap-option-text-gray'>
                                {namesCol1[index]}
                                <p>
                                  {subtextCol1[index]}<b>{timesCol1[index]}</b>
                                </p>
                              </div>
                            </div>
                          )
                        ) : (
                          // Assessment is available to be taken.
                          <NavLink to={pathsCol1[index]} key={index}>
                            <button className="apassessment-button">
                              <div key={index} className='assessment-option-available'>
                                <div>
                                  <img src={pics[index+1]} alt="" className='apfrontpage__pic' />
                                </div>
                                <div className='ap-option-text'>
                                  {namesCol1[index]}
                                  <p>
                                    {subtextCol1[index]}<b>{timesCol1[index]}</b>
                                  </p>
                                </div>
                              </div>
                            </button>
                            <br />
                          </NavLink>
                        )
                      ))}
                      <NavLink className='apassessment-frontpage__button-box' to='/apassessment-general-instructions'>
                        <button className='apassessment-frontpage__button apassessment-frontpage__button-left'>
                          Re-read General Instructions
                        </button>
                      </NavLink>
                    </ol>
                  </div>

                  {/* Right column. */}
                  <div className='apassessment__column'>
                    <ol className="assessment-options__list-2">
                      {checksCol2.map((element, index) => (
                        checksCol2[index] ? (
                          (
                            completesCol2[index]
                          ) ? ( // Assessment has been completed.
                            <div key={index} className='assessment-option-gray'>
                              <div>
                                <img src={pics[index+1+3]} alt="" className='apfrontpage__pic' />
                              </div>
                              <div className='ap-option-text'>
                                {namesCol2[index]}
                                <p>
                                  {subtextCol2[index]}<b>{timesCol2[index]}</b>
                                </p>
                                <div className='ap-option-text-complete'>
                                  Completed!
                                </div>
                              </div>
                            </div>
                          ) : ( // Assessment is grayed out to prevent the user from taking it prematurely.
                            <div key={index} className='assessment-option-gray'>
                              <div>
                                <img src={picsGrey[index+1+3]} alt="" className='apfrontpage__pic' />
                              </div>
                              <div className='ap-option-text-gray'>
                                {namesCol2[index]}
                                <p>
                                  {subtextCol2[index]}<b>{timesCol2[index]}</b>
                                </p>
                              </div>
                            </div>
                          )
                        ) : (
                          (index === 0) ? (
                            (!siComplete) ? (
                              // Navigate to SI instructions.
                              <NavLink to='/apassessment-secondary-instructions' key={index}>
                                <button className="apassessment-button">
                                  <div key={index} className='assessment-option-available'>
                                    <div>
                                      <img src={pics[index+1+3]} alt="" className='apfrontpage__pic' />
                                    </div>
                                    <div className='ap-option-text'>
                                      {namesCol2[index]}
                                      <p>
                                        {subtextCol2[index]}<b>{timesCol2[index]}</b>
                                      </p>
                                    </div>
                                  </div>
                                </button>
                                <br />
                              </NavLink>
                            ) : (
                              // Normal behavior.
                              <NavLink to={pathsCol2[index]} key={index}>
                                <button className="apassessment-button">
                                  <div key={index} className='assessment-option-available'>
                                    <div>
                                      <img src={pics[index+1+3]} alt="" className='apfrontpage__pic' />
                                    </div>
                                    <div className='ap-option-text'>
                                      {namesCol2[index]}
                                      <p>
                                        {subtextCol2[index]}<b>{timesCol2[index]}</b>
                                      </p>
                                    </div>
                                  </div>
                                </button>
                                <br />
                              </NavLink>
                            )
                          ) : (
                            // Normal behavior.
                            <NavLink to={pathsCol2[index]} key={index}>
                              <button className="apassessment-button">
                                <div key={index} className='assessment-option-available'>
                                  <div>
                                    <img src={pics[index+1+3]} alt="" className='apfrontpage__pic' />
                                  </div>
                                  <div className='ap-option-text'>
                                    {namesCol2[index]}
                                    <p>
                                      {subtextCol2[index]}<b>{timesCol2[index]}</b>
                                    </p>
                                  </div>
                                </div>
                              </button>
                              <br />
                            </NavLink>
                          )
                        )
                      ))}
                      {siComplete && 
                        <NavLink className='apassessment-frontpage__button-box' to='/apassessment-secondary-instructions'>
                          <button className='apassessment-frontpage__button apassessment-frontpage__button-right'>
                            Re-read Instructions for parts 4, 5, and 6
                          </button>
                        </NavLink>
                      }
                    </ol>
                  </div>

                </div>
                <div className="ap-lower-section">
                  {/* <NavLink className='welcomeButton__container' to='/assessments-signedin'>
                    <button className='apassessment-frontpage__button'>
                      Back
                    </button>
                  </NavLink> */}
                </div>
              </div> }
            </div>

          )
        ) : (
          <LoadingPage checkPaid={true} />
        )
      }
    </>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		user: state.firestore.ordered.users
	};
};

export default compose(
	connect(mapStateToProps),
	firestoreConnect((props) => {
		return [
			{ collection: 'users', doc: props.auth.uid }
		]
	})
)(APFrontPage);