import React from "react";
// import ReactDOM from "react-dom";
import * as ReactDOMClient from 'react-dom/client';
// import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux"; // Used to pass the store into the application.
import thunk from "redux-thunk";
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import {
  getFirebase,
  ReactReduxFirebaseProvider,
} from "react-redux-firebase";
import fbConfig from "./config/fbConfig";

// Firebase SDK v8 (OLD).
import firebase from "firebase/app";
// Firebase SDK v9 (NEW).
// import firebase from 'firebase/compat/app';

// Not sure if these are doing anything after the massive dependency update I just did (1/23/23).
import './styleguide.css';
import './globals.css';
import 'bootstrap/dist/css/bootstrap.css';

// Import Google Analytics for React.
import ReactGA from 'react-ga4';
import dotenv from 'dotenv';
dotenv.config();
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // This comes from the Telos Google Analytics product.

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// For redux and reducers.
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebase, fbConfig)
  )
);

const config = {
  userProfile: "users", // Where profiles are stored in the database.
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  // config: fbConfig,
  config: config,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// Create a container.
const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render.
root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
)

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
// ReactDOM.render(
//   <Provider store={store}>
//     <ReactReduxFirebaseProvider {...rrfProps}>
//         <App />
//     </ReactReduxFirebaseProvider>
//   </Provider>,
//   document.getElementById("root")
// );