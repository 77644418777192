import React from 'react';
import GridTableHeadUncalculated from './GridTableHeadUncalculated';
import { FaLock } from 'react-icons/fa';

const GridTableUncalculated = ({ view }) => {

  // Set table columns.
  let columns = [];
  if (view === 'pc') {
    columns = [
      { label: 'Job Zone', accessor: 'jobZone', sortable: true },
      { label: 'Job Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: 'Interests %', accessor: 'ipMatchScore', sortable: true },
      { label: 'Values %', accessor: 'wipMatchScore', sortable: true },
      { label: 'Abilities %', accessor: 'apMatchScore', sortable: true },
      { label: 'Trifecta %', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'Outlook', accessor: 'brightOutlook', sortable: true },
      { label: 'Career Cluster', accessor: 'careerName', sortable: true }
    ];
  } else if (view === 'tablet') {
    columns = [
      { label: 'Z', accessor: 'jobZone', sortable: true },
      { label: 'Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: '%', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'S', accessor: 'brightOutlook', sortable: true },
      { label: 'Cluster', accessor: 'careerName', sortable: true }
    ];
  } else if (view === 'mobile') {
    columns = [
      { label: 'Z', accessor: 'jobZone', sortable: true },
      { label: 'Name', accessor: 'jobName', sortable: true },
      { label: 'Fit', accessor: 'fitScore', sortable: true },
      { label: '%', accessor: 'trifectaMatchScore', sortable: true },
      { label: 'S', accessor: 'brightOutlook', sortable: true },
      { label: 'Cluster', accessor: 'careerName', sortable: true }
    ];
  }

  return (
    <div className='gridTable__container'>
      
      {/* Table. */}
      <table className='gridTable'>
        <GridTableHeadUncalculated {...{ columns, view }} />
      </table>
      <div className='gridTable__bodyUnoccupied'>
        <div className='chart__icon chart__icon'>
          <FaLock />
        </div>
        <div className='chart__body chart__body'>
          Exciting things coming soon!
        </div>
        <div className='chart__subbody chart__subbody'>
          Finish all profilers to see results here.
        </div>
      </div>

    </div>
  )
}

export default GridTableUncalculated