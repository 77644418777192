import React from 'react';

const hollandPic = `/images/scienceImages/science-holland-full.png`;

const Holland = () => {
  return (
    <div className="holland">
      <div className="holland__content">
        <div className="holland__header">
          The Holland Occupational Themes (RIASEC)
        </div>
        <div className="holland__columns">
          <div className="holland__body holland__body-gray">
            The Holland Occupational Themes (RIASEC) refers to a theory of careers and vocational
            choice (based upon personality types) that was initially developed by American
            psychologist John L. Holland.
          </div>
          <div className="holland__body holland__body-gray">
            The US Department of Labor's Employment and Training Administration has been using an
            updated and expanded version of the RIASEC model to develop the "Interests" section of
            the assessments offered by Telos.
          </div>
          <div className="holland__body holland__body-gray">
            According to the Committee on Scientific Awards, Holland's "research shows that personalities
            seek out and flourish in career environments they fit and that jobs and career environments
            are classifiable by the personalities that flourish in them." While Holland suggests that
            people can be "categorized as one of six types," he also argues that "a six-category scheme
            built on the assumption that there are only six kinds of people in the world is
            unacceptable on the strength of common sense alone. But a six category scheme that allows a
            simple ordering of a person's resemblance to each of the six models provides the possibility
            of 720 different personality patterns."
          </div>
          <div className="holland__body holland__body-gray">
            While other models for occupational themes have been and are being developed, Holland's
            RIASEC model is still the standard in practice.
          </div>
        </div>
        <img src={hollandPic} alt='holland' className='holland__pic' />
      </div>
    </div>
  )
}

export default Holland