import React from 'react';

const onetPic = `/images/scienceImages/science-onet.png`;

const ONet = () => {
  return (
    <div className="onet">
      <div className="onet__content">
        <div className="onet__header">
          O*Net
        </div>
        <div className="onet__box">
          <img src={onetPic} alt='onet' className='onet__pic' />
          <div className="onet__body onet__body-white">
            O*Net (Occupational Information Network) is the United States Department of Labor's
            free online database for all career/job titles recognized in the U.S. Each job includes
            a wide array of information, including but not limited to the knowledge, skills, and abilities
            required to be able to successfully work in it.
          </div>
          <div className="onet__body onet__body-white">
            The assessments used on this site were originally developed by the National Center for O*NET
            Development through the US Department of Labor, and included many scientists, psychologists,
            and statisticians in their creation. We have taken these publicly available assessments and
            re-envisioned them for an online platform that combines them in a new way, with some minimal
            adjustment to algorithms, creating well-rounded results and a good user experience.
          </div>
          <div className="onet__body onet__body-white">
            Our results continue to utilize the O*Net career database for the most detailed and
            accurate information on job titles available.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ONet