import React, { useState } from 'react';
import { WIPDisplayStates } from '../Configs';
import { ReadyPopup } from '../../../../../../Utils/ReadyPopup';

const InstructionsFinal = ({ setDisplay }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className='fp-main-box fp-main-box-practice'>
      <ReadyPopup
        onClick = {setDisplay}
        readyDestination = {WIPDisplayStates.ASSESSMENT}
        open = {open}
        setOpen = {setOpen}
      />
      <div className="ap-instructions-header">
        Assessments 3: Part 6 - Object Matching
      </div>
      <div className='fp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        On the next pages are more questions like the ones you've just answered. For each question, select the circle under the letter of your answer.
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        Remember, on this part <b>SPEED</b> is very important. Work as <b>FAST</b> as you can, but don't be careless. If you have even the slightest idea
        of the answer but are not sure, then it is to your advantage to make your <b>BEST GUESS</b>. If you can eliminate one or more wrong choices
        to a problem, then make your <b>BEST GUESS</b> from the remaining choices. However, if you have no idea of the correct answer,
        don't spend time guessing; go to the next question. You'll receive one point for each correct answer. You'll lose one third (1/3) of a point for each wrong answer.
        Points will not be subtracted for problems you don't answer.
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        If you finish before the time is up, you may go back and check your work.
      </div>
      <div className="ap-instructions-body fp-instructions-body">
        <b style={{ fontWeight: '800' }}>You will have 5 minutes to complete this part.</b>
      </div>
      <div className="fp-row-2 fp-row-2-spaced fp-row-2-practice">
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q4);
            }}
          >
            Back
          </button>
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => setOpen(true)}
          >
            Begin
          </button>
      </div>
    </div>
  )
}

export default InstructionsFinal
