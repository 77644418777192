import React from 'react';

const statsPic = `/images/scienceImages/science-stats1_smaller.png`;
const statsEquations = `/images/scienceImages/Stat_Equations.png`;
const statsDistribution = `/images/scienceImages/Stat_Distribution.png`;

const Statistics = () => {
  return (
    <div className="statistics">
      <img src={statsPic} alt='statistics' className='statistics__pic' />
      <div className="statistics__header">
        Statistics
      </div>
      <div className="statistics__content">
        <div className="statistics__section">
          <div className="statistics__body">
            Behind every good assessment are usually a whole lot of statistics 
            and the assessments Telos uses are no different. All three instruments 
            have proven validity and reliability.
          </div>
          <div className="statistics__body statistics__body-bold">
            The Interest Profiler
          </div>
          <div className="statistics__body">
            This assessment uses a sample size of 1,061 participants.
          </div>
          <div className="statistics__body">
            <div className="statistics__body">
              It has reliability estimates of:
            </div>
            <div className="statistics__riasec">
              <div className="statistics__item">
                Realistic = .93
              </div>
              <div className="statistics__item">
                Investigative = .94
              </div>
              <div className="statistics__item">
                Artistic = .94
              </div>
              <div className="statistics__item">
                Social = .94
              </div>
              <div className="statistics__item">
                Enterprising = .93
              </div>
              <div className="statistics__item">
                Conventional = .96
              </div>
            </div>
          </div>
          <div className="statistics__body">
            This means that there is a very high internal consistency across the RIASEC scale.
            The test-retest correlations for it are also high, 0.81 to 0.91 (1 is perfect).
          </div>
          <div className="statistics__body">
            The Interest Profiler also has high construct validity. This means that the questions
            used are accurately measuring what they are intended to measure. For example, a question
            asking whether you like to go to art museums accurately measures whether you have
            artistic interests.
          </div>
          <div className="statistics__body">
            Overall this means that this instrument is both reliable (you'll get the same score over
            and over again) and accurately depicts your vocational interests. It utilizes the Holland
            occupational themes for measurements (see above).
          </div>
        </div>

        <hr className='statistics__hr' />

        <div className="statistics__section">
          <div className="statistics__body statistics__body-bold">
            The Ability Profiler
          </div>
          <div className="statistics__body">
            Each occupation listed in the O*Net database has an ability score associated with it that
            corresponds to the ability profiler assessment scores. If your score meets or exceeds the
            ability score necessary for the occupation, it is included in your results.
          </div>
          <div className="statistics__body">
            The Ability Profiler is based on two forms of the GATB (General Aptitude Test Battery).
            This is the most well known and validated multiple test system for career guidance because
            of its extensive research base.
          </div>
          <div className="statistics__body">
            Reliability of this set of assessments is high (.778 - .919) based on a repeated-measures
            sample of 820 test takers and the Fisher's z transformation used to test the significance of
            differences between the GATB and the Ability Profiler.
          </div>
          <div className="statistics__body">
            This means that the assessments included in the Ability Profiler are measuring what they're
            meant to.
          </div>
          <img src={statsEquations} alt='equations' className='statistics__pic statistics__pic-normal' />
        </div>

        <hr className='statistics__hr' />

        <div className="statistics__bottom">
          <img src={statsDistribution} alt='distribution' className='statistics__pic statistics__pic-float' />
          <div className="statistics__body statistics__body-bold">
            The Values Profiler
          </div>
          <div className="statistics__body">
            This assessment is intended to measure what you value when it comes to the world of work and
            was modeled after the Minnesota Importance Questionnaire (MIQ).
          </div>
          <div className="statistics__body">
            The development of the Values Profiler included a pilot study first with measurements
            at two time points followed by a main study with measurements at two time points to check for
            reliability. It was found that about 88% of the time, participants ranked the same two values
            at the top of their list when taking the same test one to two months later.
          </div>
          <div className="statistics__body">
            To make sure matching was done correctly, the over 1,000 occupations listed on O*Net were rated
            by experts to create a profile for each job based on the values in the Values Profiler.
            When you take the assessment, those jobs that most closely match your top values are elevated
            to the top of your list.
          </div>
          <div className="statistics__body">
            A confirmatory factor analysis (a 'factor' is each area of the values profiler - like autonomy or
            altruism) was done on a six factor model and was supported.
          </div>
          <div className="statistics__body">
            This means that the questions included in the assessment are measuring your values like they
            should be. For example, when you rate the statement 'On my ideal job it is important that I
            could do things for other people', that statement links directly to the Altruism value.
          </div>
        </div>

      </div>
    </div>
  )
}

export default Statistics