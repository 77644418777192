import React from 'react'

const PersonalPage = () => {

  const assessmentsPic = `/images/purchaseImages/purchase-page-icons.png`;

  return (
    <div>
      <div className="options__body">
        The Telos package includes access to <span>eight</span> research-backed assessments
        and your personalized results. These assessments evaluate your:
      </div>
      <img src={assessmentsPic} alt="assessments-pic" className="signedinAssessments__image2" />
      <div className="options__body">
        In addition, your results will be fed through our proprietary algorithm to determine:
      </div>

      <div className="options__body  options__body-flex">
        <div className="signedinAssessments__number">
          1
        </div>
        <div className="options__body options__body-bold">
          Which career paths are most likely to align with you
        </div>
      </div>

      <div className="options__body options__body-flex">
        <div className="signedinAssessments__number">
          2
        </div>
        <div className="options__body options__body-bold">
          Details of each career path, such as typical job requirements
        </div>
      </div>

      <div className="options__body options__body-flex">
        <div className="signedinAssessments__number">
          3
        </div>
        <div className="options__body options__body-bold">
          The job categories you most closely align to, in case you don't yet want to commit
          to a specific career path
        </div>
      </div>
    </div>
  )
}

export default PersonalPage