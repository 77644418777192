import React, { useState, useEffect } from 'react';
import './APChart.css';
import { APChartText } from './Configs';

const APChart2 = ({ apData }) => {
  // Check screen size.
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  const [ chartWidth, setChartWidth ] = useState(800);
  const [ barSpacing, setBarSpacing ] = useState(30);
  const [ yOffset, setYOffset ] = useState(-30);
  const [ tooltipWidth, setTooltipWidth ] = useState(300);
  const [ tooltipHeight, setTooltipHeight ] = useState(125);

  // Update the screenWidth state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update the chartWidth and chartHeight based on screen size.
  const tabletSize = 1025;
  const phoneSize = 640;
  useEffect(() => {
    if (screenWidth <= tabletSize && screenWidth > phoneSize) {
      setChartWidth(500);
      setBarSpacing(30);
      setYOffset(-32);
      setTooltipWidth(250);
      setTooltipHeight(125);
    } else if (screenWidth <= phoneSize) {
      setChartWidth(260);
      setBarSpacing(8);
      setYOffset(-35);
      setTooltipWidth(175);
      setTooltipHeight(120);
    } else if (screenWidth > tabletSize) {
      setChartWidth(600);
      setBarSpacing(30);
      setYOffset(-30);
      setTooltipWidth(300);
      setTooltipHeight(125);
    }
  }, [screenWidth]);

  const chartHeight = 400;
  const chartBuffer = 0.05;   // Fraction offset to make sure labels don't get cut off.
  const xAxisHeight = 55;
  const yAxisWidth = 40;
  const barColor = apData.map(obj => obj.color);
  const data = apData.map(obj => obj.value);
  const barLabel = apData.map(obj => obj.name);
  const maxValue = 100;
  const barWidth = (chartWidth - 1 - yAxisWidth) / data.length - barSpacing;

  const [tooltip, setTooltip] = useState(null);

  const handleInteractionStart = (index, x, y, value) => {
    // Tooltip math.
    let xDiff = 0;
    const tooltipBuffer = 5;
    if (x + tooltipWidth > chartWidth) {
      xDiff = (x + tooltipWidth) - chartWidth + tooltipBuffer;
    } else if (x + tooltipWidth/2 - yAxisWidth < 0) {
      xDiff = (x + tooltipWidth/2 - yAxisWidth) - tooltipBuffer;
    }

    let yDiff = 0;
    if (y + tooltipHeight > chartHeight) {
      yDiff = (y + tooltipHeight) - chartHeight + tooltipBuffer;
    }
    
    setTooltip({
      header: `${APChartText[index].header} | ${value}`,
      body: APChartText[index].text,
      x: x - xDiff,
      y: y - yDiff
    });
  };

  const handleInteractionEnd = () => {
    setTooltip(null);
  };

  // Determine the number of y-axis ticks
  const numYAxisTicks = 9;

  // Calculate tick values based on data range and number of ticks.
  const tickInterval = 20;
  const tickValues = Array.from({ length: numYAxisTicks }, (_, i) => tickInterval * i);


  return (
    <div className='apChart2'>
      <div className='ipChart2'>
        <h1>Abilities highlights</h1>
        <h2>Your Ability Profiler - Percentile Scores</h2>
        <svg
          width={chartWidth}
          height={chartHeight + xAxisHeight}
          className='ipChart2__svg'
        >

          {/* Draw bars */}
          {data.map((value, index) => {
            // const barHeight = (value / maxValue) * (chartHeight - xAxisHeight);
            const barHeight = (value / maxValue) * (chartHeight*(1-chartBuffer));
            const x = yAxisWidth + barSpacing/2 + index * (barWidth + barSpacing);
            const y = chartHeight - barHeight;

            return (
              <g key={index}>
                {/* Draw bars */}
                <rect
                  key={index}
                  x={x}
                  y={y}
                  width={barWidth}
                  height={barHeight}
                  fill={barColor[index]}
                  onMouseOver={() => handleInteractionStart(index, x, y, value)}
                  onTouchStart={() => handleInteractionStart(index, x, y, value)}
                  onMouseOut={handleInteractionEnd}
                  onTouchEnd={handleInteractionEnd}
                />
                
                {/* Bar value text */}
                <text
                  x={x + barWidth / 2} // Center the text horizontally above the bar
                  y={y - 12} // Adjust the vertical position as needed
                  textAnchor="middle"
                  className="ipChart2__barValue"
                >
                  {value}% {/* Display the actual value of the bar */}
                </text>
              </g>
            );
          })}

          {/* Draw y-axis */}
          <line
            x1={yAxisWidth}
            y1={chartHeight*chartBuffer}
            x2={yAxisWidth}
            y2={chartHeight}
            className='ipChart2__axis'
          />

          {/* Render y-axis ticks and tick labels */}
          {tickValues.map((tickValue, index) => {
            const yTick = chartHeight - (tickValue / maxValue) * (chartHeight*(1-chartBuffer));
            const xTickLabel = yAxisWidth - 5; // Adjust the x position of the tick labels

            return (
              <g key={index}>
                {/* Tick line */}
                {/* <line
                  x1={yAxisWidth}
                  y1={yTick}
                  x2={yAxisWidth + 8}
                  y2={yTick}
                  className='ipChart2__ticks'
                /> */}

                {/* Tick label */}
                <text
                  x={xTickLabel - 5}
                  y={yTick}
                  alignmentBaseline="middle"
                  textAnchor="end"
                  className="ipChart2__ylabel"
                >
                  {tickValue}
                </text>
              </g>
            );
          })}

          {/* Draw x-axis */}
          <line
            x1={yAxisWidth}
            y1={chartHeight}
            x2={chartWidth}
            y2={chartHeight}
            className='ipChart2__axis'
          />

          {/* Render x-axis ticks */}
          {/* {data.map((_, index) => {
            const xTick = yAxisWidth + barWidth/2 + barSpacing/2 + (index + 0.5) * (barWidth + barSpacing);
            const yTickLine = chartHeight;
            
            return (
              <line
                key={index}
                x1={xTick}
                y1={yTickLine - 10}
                x2={xTick}
                y2={yTickLine} // Length of the tick line
                className='ipChart2__ticks'
              />
            );
          })} */}

          {/* Add x-axis labels */}
          {data.map((_, index) => {
            const x = yAxisWidth + barSpacing/2 + index * (barWidth + barSpacing) + barWidth / 2;
            const y = chartHeight + xAxisHeight + yOffset;

            return (
              <g key={index}>
                {/* First line, only appears on mobile. */}
                <text
                  x={x}
                  y={y}
                  textAnchor="middle"
                  className='ipChart2__xlabel ipChart2__xlabel-firstMobile'
                >
                  {barLabel[index][0]}
                </text>
                {/* X-axis labels */}
                <text x={x} y={y - 3} textAnchor="middle" className='ipChart2__xlabel ipChart2__xlabel-second'>
                  {/* Split the label into words */}
                  {barLabel[index].split(' ').map((word, wordIndex) => (
                    <tspan key={wordIndex} x={x} dy={wordIndex > 0 ? '1.375em' : 0}>
                      {word}
                    </tspan>
                  ))}
                </text>
              </g>
            );
          })}

          {/* Draw tooltips */}
          {tooltip && (
            <g>
              <foreignObject
                x={tooltip.x + 10}
                y={tooltip.y + 10}
                width={tooltipWidth - 10} // Adjust the width for padding
                height={tooltipHeight - 10} // Adjust the height for padding
                className='ipChart2__foreignObject'
              >
                <div>
                  <div className='ipChart2__tooltip-h1'>{tooltip.header}</div>
                  <div className='ipChart2__tooltip-body'>{tooltip.body}</div>
                </div>
              </foreignObject>
            </g>
          )}
        </svg>

      </div>

      <div className="apChart__blurbBox">
        Remember, these are percentiles in the population, not the percent that you got correct. This means that if you are at the 50th percentile for Verbal Ability, you
        scored better on that section of this assessment than 49.5% of the population, and worse than 49.5% of the population. In some sections (like form perception)
        4% of the population score a 100% correct on the test, meaning you can only ever be at the 97th percetile (which is a perfect score).
      </div>
    </div>
  )
}

export default APChart2