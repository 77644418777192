import React, { useState } from 'react';
import { WIPDisplayStates } from '../Configs';
import { ReadyPopup } from '../../../../../../Utils/ReadyPopup';

const InstructionsFinal = ({ setDisplay }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className='cm-main-box cm-main-box-practice'>
      <ReadyPopup
        onClick = {setDisplay}
        readyDestination = {WIPDisplayStates.ASSESSMENT}
        open = {open}
        setOpen = {setOpen}
      />
      <div className="ap-instructions-header">
        Assessments 3: Part 4 - Computation
      </div>
      <div className='cm-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Remember, on this part <b>SPEED</b> is very important. Work as <b>FAST</b> as you can, but
        don't be careless. If you have even the slightest idea of the answer, it is to your advantage
        to make your <b>BEST GUESS</b>. If you can eliminate one or more wrong choices to a problem,
        make your <b>BEST GUESS</b> from the remaining choices. However, if you have no idea of the
        correct answer, don't spend time guessing; go to the next problem.
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        You'll receive one point for each correct answer. You'll lose one quarter (1/4) of a point for
        each wrong answer. Points will not be subtracted for problems you don't answer.
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        If you finish before the time is up, you may go back and check your work.
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        <b style={{ fontWeight: '800' }}>You will have 6 minutes to complete this part.</b>
      </div>
      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q5);
            }}  
          >
            Back
          </button>
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => setOpen(true)}
          >
            Begin
          </button>
      </div>
    </div>
  )
}

export default InstructionsFinal
