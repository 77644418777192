export const wipSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    // const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    const parsedString = result.id.split('-');
    const newId = 'id' + parsedString[2];   // Unique identifier for each question in the document.

    // Add to the 'wip' database.
    // firestore.collection('wip').add({
    firestore
      .collection("wip")
      .doc(userId)
      .set({
        // Add to the 'wip' collection using the user's UID.
        wipAnswers: {
          [newId]: [parsedString[2]] <= 21 && {
            id: result.id,
            order: result.order ? result.order : null,
            isDnd: result.isDnd,
            isMulti: result.isMulti
          },
        },
        rbAnswers: {
          [newId]: [parsedString[2]] > 21 && {
            id: result.id,
            multiValues: result.multiValues ? result.multiValues : null,
            answerOptions: result.multiValues ? result.multiValues: null,
            isDnd: result.isDnd,
            isMulti: result.isMulti
          },
        },
        userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_WIP", payload: result });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

export const ipSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;
    // const parsedString = scores.id.splint('-');
    // const newId = 'id' + parsedString[2]; // Unique identifier for each question in the document.

    // Add to the 'ip' database.
    firestore
      .collection("ip")
      .doc(userId)
      .set({
        // Add to the 'ip' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),

        // ipAnswers: {
        //   // [newId]: {
        //   id: scores.id,
        //   values: scores.values
        //   // },
        // },
        // userId,
        // authorFirstName: profile.firstName,
        // authorLastName: profile.lastName,
        // createdAt: new Date()
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_IP", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit arithmetic scores.
export const arSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'ar' database.
    firestore
      .collection("ar")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_AR", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit arithmetic time.
export const arTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'ar' database.
    firestore
      .collection("ar")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_AR_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit verbal scores.
export const vaSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("va")
      .doc(userId)
      .set({
        // Add to the 'va' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_VA", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit verbal time.
export const vaTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("va")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_VA_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit spatial scores.
export const saSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'sa' database.
    firestore
      .collection("sa")
      .doc(userId)
      .set({
        // Add to the 'sa' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_SA", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit spatial time.
export const saTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("sa")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_SA_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit computation scores.
export const cmSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'cm' database.
    firestore
      .collection("cm")
      .doc(userId)
      .set({
        // Add to the 'cm' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_CM", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit computation time.
export const cmTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("cm")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_CM_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit name comparison scores.
export const cpSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'cp' database.
    firestore
      .collection("cp")
      .doc(userId)
      .set({
        // Add to the 'cp' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_CP", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit name comparison time.
export const cpTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("cp")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_CP_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit ojbect matching scores.
export const fpSubmit = (scores) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'fp' database.
    firestore
      .collection("fp")
      .doc(userId)
      .set({
        // Add to the 'fp' collection using the user's UID.
        ...scores, // Provides responses.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_FP", scores: scores });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit object matching time.
export const fpTime = (counter) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    // Add to the 'va' database.
    firestore
      .collection("fp")
      .doc(userId)
      .set({
        // Add to the 'ar' collection using the user's UID.
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        userId: userId,
        createdAt: new Date(),
        timeLeft: counter
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_FP_TIME", counter: counter });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
}

// Submit WIP calculated results (z-score).
export const wipResultSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (result.uid) {
      userId = result.uid;
    }

    // Add to the 'wipresults' database.
    firestore
      .collection("wipresults")
      .doc(userId)
      .set({
        // Add to the 'wipresults' collection using the user's UID for the document ID.
        achieveZScore: result.achieveZScore,
        workZScore: result.workZScore,
        recogZScore: result.recogZScore,
        relateZScore: result.relateZScore,
        supportZScore: result.supportZScore,
        indepZScore: result.indepZScore,
        userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_WIP_RESULTS", payload: result });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit IP calculated results (RIASEC scores, on a scale of 0-40).
export const ipResultSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (result.uid) {
      userId = result.uid;
    }

    // Add to the 'ipresults' database.
    firestore
      .collection("ipresults")
      .doc(userId)
      .set({
        // Add to the 'ipresults' collection using the user's UID for the document ID.
        rAnswers: result.rAnswers,
        iAnswers: result.iAnswers,
        aAnswers: result.aAnswers,
        sAnswers: result.sAnswers,
        eAnswers: result.eAnswers,
        cAnswers: result.cAnswers,
        userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_IP_RESULTS", payload: result });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit AP calculated results (AR, SA, VA, CM, CP, FP percentiles).
export const apResultSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.

    let userId = getState().firebase.auth.uid;
    if (result.uid) {
      userId = result.uid;
    }

    // Add to the 'apresults' database.
    firestore
      .collection("apresults")
      .doc(userId)
      .set({
        // Add to the 'apresults' collection using the user's UID for the document ID.
        arPercentile: result.arPercentile,
        vaPercentile: result.vaPercentile,
        saPercentile: result.saPercentile,
        cmPercentile: result.cmPercentile,
        cpPercentile: result.cpPercentile,
        fpPercentile: result.fpPercentile,
        arPercentScore: result.arPercentScore,
        vaPercentScore: result.vaPercentScore,
        saPercentScore: result.saPercentScore,
        cmPercentScore: result.cmPercentScore,
        cpPercentScore: result.cpPercentScore,
        fpPercentScore: result.fpPercentScore,
        userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_AP_RESULTS", payload: result });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit trifecta calculated results (ip/ap, ip/wip, ap/wip, and ip/wip/ap job results).
export const trifectaResultSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore(); // Gives us a reference to our firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (result.uid) {
      userId = result.uid;
    }

    // Add to the 'trifectaresults' database.
    firestore
      .collection("trifectaresults")
      .doc(userId)
      .set({
        // Add to the 'trifectaresults' collection using the user's UID for the document ID.
        userId,
        createdAt: new Date(),
      }, { merge: true })
      .then(() => {
        // Perform dispatch.
        dispatch({ type: "SUBMIT_TRIFECTA_RESULTS", payload: result });
      })
      .catch((err) => {
        dispatch({ type: "SUBMIT_ERROR", err });
      });
  };
};

// Submit 'Complete' status to user's database for general instructions boolean.
export const giCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        giDate: new Date(),
        giComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for arithmetic reasoning AP section boolean.
export const arCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        arDate: new Date(),
        arComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for vocabulary AP section boolean.
export const vaCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        vaDate: new Date(),
        vaComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for spatial awareness AP section boolean.
export const saCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        saDate: new Date(),
        saComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for secondary instructions boolean.
export const siCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        siDate: new Date(),
        siComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for computation AP section boolean.
export const cmCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        cmDate: new Date(),
        cmComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for clerical perception AP section boolean.
export const cpCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        cpDate: new Date(),
        cpComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for form perception AP section boolean.
export const fpCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        fpDate: new Date(),
        fpComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for interest profiler boolean.
export const ipCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        ipDate: new Date(),
        ipComplete: true
      }, { merge: true })
  }
}

// Submit 'Complete' status to user's database for work importance profiler boolean.
export const wipCompleteSubmit = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        wipDate: new Date(),
        wipComplete: true
      }, { merge: true })
  }
}

// Submit 'Calculated' status to user's database for work importance profiler boolean.
export const wipCalculatedSubmit = (uid) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (uid) {
      userId = uid;
    }

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        wipCalculateDate: new Date(),
        wipCalculated: true
      }, { merge: true })
  }
}

// Submit 'Calculated' status to user's database for interest profiler boolean.
export const ipCalculatedSubmit = (uid) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (uid) {
      userId = uid;
    }

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        ipCalculateDate: new Date(),
        ipCalculated: true
      }, { merge: true })
  }
}

// Submit 'Calculated' status to user's database for abilities profiler boolean.
export const apCalculatedSubmit = (uid) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.

    let userId = getState().firebase.auth.uid;
    if (uid) {
      userId = uid;
    }

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        apCalculateDate: new Date(),
        apCalculated: true
      }, { merge: true })
  }
}

// Submit 'Calculated' status to user's database for job overlap boolean.
export const overlapCalculatedSubmit = (uid) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    
    let userId = getState().firebase.auth.uid;
    if (uid) {
      userId = uid;
    }

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        userId,
        overlapCalculateDate: new Date(),
        overlapCalculated: true
      }, { merge: true })
  }
}

// Submit a comment on the 'About Us' page.
export const commentSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const d = new Date();
    const time = Math.round(d.getTime()/1000);
    const userId = Math.random().toString(36).slice(2) + time.toString();

    // Add to the 'users' database.
    firestore
      .collection('mail')
      .doc(userId)
      .set({
        message: {
          subject: result.subject,
          text: result.text
        },
        to: result.to
      })
  }
}

// Add a promo code to the 'promoCode' document.
export const promoCodeSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.

    // Add to the 'promoCodes' database.
    firestore
      .collection('promoCodes')
      .doc('promoCodes')
      .set({
        [result.name]: {
          code: result.code,
          uses: result.uses,
          discountFraction: result.discountFraction,
          expirationDate: result.expirationDate
        }
      }, { merge: true })
  }
}

// Add a document to the 'giftMail' collection when a user submits a purchase for a gifted account.
export const giftMailSubmit = (result) => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();
    const d = new Date();
    const time = Math.round(d.getTime()/1000);
    const userId = Math.random().toString(36).slice(2) + time.toString();

    // Add to the 'giftMail' database.
    firestore
      .collection('mail')
      .doc(userId)
      .set({
        message: {
          subject: result.subject,
          text: result.text
        },
        to: result.to,
        giftCode: result.giftCode,
        giftEmail: result.giftEmail,
        gifterFirstName: result.gifterFirstName,
        gifterLastName: result.gifterLastName,
        giftDate: new Date()
      }, { merge: true })
  }
}

// Submit 'wipVideoWatched' to user's Firebase 'user' document.
export const wipVideoWatched = () => {
  // Dispatch is the thing (function) that dispatches an action to the reducer.
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // Make async call to database to add document.
    const firestore = getFirestore();   // Gives us a reference to our Firestore database.
    const userId = getState().firebase.auth.uid;

    // Add to the 'users' database.
    firestore
      .collection('users')
      .doc(userId)
      .set({
        // Add to the 'users' collection using the user's UID for the document ID.
        wipVideoWatched: true
      }, { merge: true })
  }
}