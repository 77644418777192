import React, { useState, useEffect } from 'react';
import './CarouselPages.css';

const welcome1Pic = `/images/resultsImages/infoIcons/welcome_icon1.png`;
const welcome2Pic = `/images/resultsImages/infoIcons/welcome_icon2.png`;
const welcome3Pic = `/images/resultsImages/infoIcons/welcome_icon3.png`;
const welcome4Pic = `/images/resultsImages/infoIcons/welcome_icon4.png`;
const welcome5Pic = `/images/resultsImages/infoIcons/welcome_icon5.png`;

const Info = () => {
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsBelowThreshold(window.innerWidth < 640); // Change 640 to your desired threshold
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call the handleResize function once to set the initial state
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Text describing the interest graph.
  const interestText = (
    <>
      An updated and expanded version of the Holland Occupational Themes
      (RIASEC) model was used to develop the interests section of the
      assessments you took. This graph will give you a good idea of where your
      interests tend to group. We use these results to map onto occupations
      that best match your levels of interest.
    </>
  );

  // Text describing the values assessment list.
  const valuesText = (
    <>
      This assessment uses a six factor (Achievement, Independence, Recognition, Relationships, Support, &
      Working Conditions) model based on the Minnesota Importance Questionnaire (MIQ) that has good test-
      retest reliability for your top two results. This is why we single out those top two results for you to focus on.
      Those are the values that should matter to you most when choosing a career path. These results are also
      mapped onto occupations that prioritize the same values you do.
    </>
  );

  // Text describing the abilities assessment graph.
  const abilitiesText = (
    <>
      These results will show you your abilities in specific areas relative to the population at large. Your percentile
      in an area shows not how many you got right or wrong, but how many people in the population scored better
      or worse than you did. All the careers in the database have scores for each of the abilities areas, and the
      closer your scores are to each set score for a certain job, the higher that job will be ranked for you.
    </>
  );

  // Text describing the trifecta graph.
  const trifectaText = (
    <>
      This graph visually depicts the crossover careers for the above three assessments you took. We combine
      your interests, values, and abilities for a much more meaningful and useful final result. Pay close attention to
      any careers that are in the center of your graph, as these should be your best ‘hits’. However, don’t dismiss
      the other crossover areas. If you know that you get test anxiety and so don’t generally test well, look at the
      interests x values crossover. If your interests change from moment to moment, then look more closely at the
      abilities x values crossover.
    </>
  );

  // Text describing the occupation grid.
  const occupationText = (
    <>
      This grid includes everything in one place. You can sort by any of the column headers so that you can drill
      down to exactly what you think is most relevant to your situation. So if you’re only interested in Job Zone 3
      jobs, you can find the highest rated trifecta percentage for you in only those level jobs. If you sort by career
      cluster, you can also see which cluster tends to be your largest, and look at more careers in that area. Pay
      special attention to bright outlook careers, as those have the best growth potential in the future.
    </>
  );

  // Data to loop over in the return statement.
  const data = [
    {
      pic: welcome1Pic,
      title: 'The Interest Assessment',
      title2: 'Graph',
      text: interestText,
    },
    {
      pic: welcome2Pic,
      title: 'The Values Assessment',
      title2: 'List',
      text: valuesText,
    },
    {
      pic: welcome3Pic,
      title: 'The Abilities Assessment',
      title2: 'Graph',
      text: abilitiesText,
    },
    {
      pic: welcome4Pic,
      title: 'The Trifecta',
      title2: 'Graph',
      text: trifectaText,
    },
    {
      pic: welcome5Pic,
      title: 'The Occupation',
      title2: 'Grid',
      text: occupationText,
    },
  ];
  

  return (
    <div className='carouselItem'>
      <h1>
        Welcome to Your Results Section!
      </h1>
      <h2>
        This section will guide you through results for each assessment
        you took, and then show you the combination of those results in
        both the Trifecta Graph and the Occupation Grid.
      </h2>

      {data.map((element, index) => (
      <React.Fragment key={index}>
        {isBelowThreshold ? (
          <>
            <div className="carouselItemInfo__row">
              <div className="carouselItemInfo__imageContainer">
                <img src={element.pic} alt={`welcome-icon-${index + 1}`} className="carouselItemInfo__image" />
              </div>
              <div className="carouselItemInfo__col">
                <div className='carouselItemInfo__col-h1'>
                  {element.title}
                  <div className='carouselItemInfo__col-h1 carouselItemInfo__h1-italics'>
                    {element.title2}
                  </div>
                </div>
              </div>
            </div>
            <div className="carouselItemInfo__row carouselItemInfo__row-bottomRow">
              <div className='carouselItemInfo__col-p'>
                {element.text}
              </div>
            </div>
          </>
        ) : (
          <div className="carouselItemInfo__row">
            <div className="carouselItemInfo__imageContainer">
              <img src={element.pic} alt={`welcome-icon-${index + 1}`} className="carouselItemInfo__image" />
            </div>
            <div className="carouselItemInfo__col">
              <div className='carouselItemInfo__col-h1'>
                {element.title} {element.title2}
              </div>
              <div className='carouselItemInfo__col-p'>
                {element.text}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    ))}

    </div>
  )
}

export default Info