import React, { useState } from 'react';
import { WIPDisplayStates } from '../Configs';
import { ReadyPopup } from '../../../../../../Utils/ReadyPopup';

const InstructionsFinal = ({ setDisplay }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className='va-main-box va-main-box-practice'>
      <ReadyPopup
        onClick = {setDisplay}
        readyDestination = {WIPDisplayStates.ASSESSMENT}
        open = {open}
        setOpen = {setOpen}
      />
      <div className="ap-instructions-header">
        Assessments 3: Part 2 - Vocabulary
      </div>
      <div className='va-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body va-instructions-body">
        On the next pages are more questions like the ones you've just answered.
        For each question, click on the circle under the <b><i>letter combination</i></b> of your answer.
      </div>
      <div className="ap-instructions-body va-instructions-body">
        Work <b>CAREFULLY</b>. You should have enough time to answer each question.
        It is to your advantage to <b>ANSWER EACH QUESTION</b>. Even if you're not sure of
        an answer, make your <b>BEST GUESS</b>. You'll receive one point for each correct answer.
        Points will not be subtracted for questions you answer incorrectly.
      </div>
      <div className="ap-instructions-body va-instructions-body">
        If you finish before the time is up, you may go back and check your work.
      </div>
      <div className="ap-instructions-body va-instructions-body">
        <b style={{ fontWeight: '800' }}>You will have 8 minutes to complete this part.</b>
      </div>
      <div className="va-row-2 va-row-2-spaced va-row-2-practice">
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q345);
            }}  
          >
            Back
          </button>
          <button
            type='button'
            className="ap-instructions-button"
            onClick={() => setOpen(true)}
          >
            Begin
          </button>
      </div>
    </div>
  )
}

export default InstructionsFinal
