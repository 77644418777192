import fetch from "node-fetch";

export default class OnetWebService {
  _config = null;
  constructor(username) {
    this._config = {
      auth: {
        username: username
      },
    };
    this.set_version();
  }
  call(path, query, callback) {
    var url =
      this._config.baseURL +
      path +
      "?client=" +
      encodeURIComponent(this._config.auth.username);
    if (query !== null && query !== undefined) {
      url += "&" + this._encode_query(query);
    }

    if (fetch || (window && window.self.fetch)) {
      this._call_fetch(url, callback);
    } else {
      this._call_xhr(url, callback);
    }
  }
  set_version(version) {
    if (version === undefined) {
      // this._config.baseURL = 'https://services.onetcenter.org/ws/';
      this._config.baseURL =
        "https://services.onetcenter.org/ws/online/occupations";
    } else {
      // this._config.baseURL = 'https://services.onetcenter.org/v' + version + '/ws/';
      this._config.baseURL =
        "https://services.onetcenter.org/v" +
        version +
        "/ws/online/occupations";
    }
  }
  _encode_query(query) {
    return Object.keys(query)
      .map(function (key) {
        var nkey = encodeURIComponent(key);
        var vals = query[key];
        if (!Array.isArray(vals)) {
          vals = [query[key]];
        }
        return vals
          .map(function (value) {
            return nkey + "=" + encodeURIComponent(value);
          })
          .join("&");
      })
      .join("&");
  }
  _call_xhr(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.timeout = 10000;
    xhr.setRequestHeader("Accept", "application/json");
    // xhr.setRequestHeader("Authorization", "Basic dGVsb3M6NDM2M2pzcQ==");
    xhr.ontimeout = function (e) {
      callback({
        error: "Call to " + url + " failed with no response from server",
      });
    };
    xhr.onerror = function (e) {
      if (xhr.statusText !== "") {
        callback({
          error: "Call to " + url + " failed with reason: " + xhr.statusText,
        });
      } else {
        callback({ error: "Call to " + url + " failed with unknown reason" });
      }
    };
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 422) {
          callback(JSON.parse(xhr.responseText));
        } else {
          callback({
            error: "Call to " + url + " failed with error code " + xhr.status,
          });
        }
      }
    };
    xhr.send();
  }
  _call_fetch(url, callback) {
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers: {
        Accept: "application/json"
        // Authorization: 'Basic dGVsb3M6NDM2M2pzcQ=='
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 422) {
          response
            .json()
            .then(callback)
            .catch(function (error) {
              callback({ error: "Call to " + url + " failed on JSON parse" });
            });
        } else {
          callback({
            error:
              "Call to " + url + " failed with error code " + response.status,
          });
        }
      })
      .catch(function (error) {
        if (error.message) {
          callback({
            error: "Call to " + url + " failed with reason: " + error.message,
          });
        } else {
          callback({ error: "Call to " + url + " failed with unknown reason" });
        }
      });
  }
}
