import React, { useState, useEffect } from 'react';
import '../PaymentDetails.css';
import firebase from '../../../../config/fbConfig';
import { loadStripe } from '@stripe/stripe-js';
import { FaSpinner } from "react-icons/fa";
import PersonalPage from './PersonalPage';
import StudentPage from './StudentPage';
import PaymentBottom from '../paymentBottom/PaymentBottom';
import SignedInMultiGift from './SignedInMultiGift';

const laptopPic = `/images/purchaseImages/Laptop-phone.png`;
// const giftPic = `/images/purchaseImages/gift-shorter.png`;

// const OptionsNoCode = ({ setDisplay, gift, setGift, setTotal, dropValue, setDropValue, personal, setPersonal, singlePurchase, user, auth }) => {
const OptionsNoCode = ({ setDisplay, personal, setPersonal, institute, setInstitute, user, auth, stripePurchase, purchasedOrder, gift, setGift }) => {
  
  const [ products, setProducts ] = useState([]);
  const [ clicked, setClicked ] = useState(false);
  const [ purchased, setPurchased ] = useState(true);
  useEffect(() => {
    firebase.firestore().collection('products').where('active', '==', true).get().then(snapshot => {
      const products = {};
      snapshot.forEach(async productDoc => {
        products[productDoc.id] = productDoc.data();
        const priceSnapshot = await productDoc.ref.collection('prices').get();
        priceSnapshot.forEach(priceDoc => {
          products[productDoc.id].prices = {
            priceId: priceDoc.id,
            priceData: priceDoc.data()
          }
        })
      })
      setProducts(products);
    })
  }, []);

  // Function to run when the user clicks the 'personal' tab.
  const personalClick = (e) => {
    // if (singlePurchase) {
    // if (!purchased) {
    setPersonal(true);
    setInstitute(false);
    setGift(false);
    // }
  }

  // Function to run when the user clicks the 'gift' tab.
  const giftClick = (e) => {
    setGift(true);
    setPersonal(false);
    setInstitute(false);
  }

  // Function to run when the user clicks the 'institute' tab.
  const instituteClick = (e) => {
    setInstitute(true);
    setPersonal(false);
    setGift(false);
  }

  // Create checkout functions.
  const checkOut = async (priceId) => {
    setClicked(true);
    try {
      const docRef = await firebase.firestore().collection('customers').doc(auth.uid).collection
      ('checkout_sessions').add({
        mode: 'payment',
        price: priceId,   // One-time price created in Stripe.
        allow_promotion_codes: true,      // Allows promo codes.
        success_url: window.location.origin + '/assessments',
        cancel_url: window.location.origin + '/payment',
        uid: auth.uid,
        orderCreatedDate: new Date(),
        automatic_tax: {
          enabled: true
        },
        receipt_email: user[0].email
      });
      docRef.onSnapshot(async(snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
          // We have a session, let's redirect to Checkout.
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY);
          // console.log('Stripe: ', stripe);
          // console.log('Session ID: ', sessionId);
          await stripe.redirectToCheckout({ sessionId });
        }
      })
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Check if the assessment has been purchased by this user or not.
  useEffect(() => {
    try {
      if (purchasedOrder !== true && stripePurchase !== true) {
        setPurchased(false);
      } else {
        setPurchased(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [purchasedOrder, stripePurchase])

  return (
    <div className='noCode__main-box'>
      <div className="options">
        <img src={laptopPic} alt="purchase-pic" className='options__image' />
        <div className="options__main">
          <div className="options__options">
            <button
              className={`options__select ${!personal && "options__select-off"}`}
              onClick={personalClick}
            >
              Personal Plan
            </button>
            <button
              className={`options__select ${!gift && "options__select-off"}`}
              onClick={giftClick}
            >
              Gifting
            </button>
            <button
              className={`options__select ${!institute && "options__select-off"}`}
              onClick={instituteClick}
            >
              Student
            </button>
            <div className="options__hr4" />
          </div>

          {personal === true && <div className="options__personal">
            <PersonalPage />
          </div> }

          { institute === true && <div className="options__institute">
            <StudentPage purchased={purchased} />
          </div> }
          { gift === true && <div className='options__institute'>
            <SignedInMultiGift />
          </div> }

          <div className="options__singlePay">
            {personal && (
              <div className="signedinAssessments__price">
                ${process.env.REACT_APP_TELOS_PACKAGE_PRICE}
              </div>
            )}
            {/* {Object.entries(products).map(([ productId, productData ]) => { */}
            {Object.entries(products).map(([ productId, productData ]) => {
              if (productId === process.env.REACT_APP_PRODUCT_ID) {
                return (
                  <div className="plans" key={productId}>
                    <div>
                      { personal && (
                       !purchased ? (
                          clicked ? (
                            <div className='options__buttonClicked'>
                              Loading <FaSpinner
                                className='options__spinner'
                              />
                            </div>
                          ) : (
                            <button
                              className="options__button"
                              onClick={() => checkOut(productData.prices.priceId)}
                            >
                              Purchase
                            </button>
                          )
                        ) : (
                          <div>
                            <div className='options__buttonClicked options__buttonClicked-gray'>
                              Already Purchased
                            </div>
                          </div>
                        )
                      )
                      }
                    </div>
                  </div>
                )
              } else {
                return null;
              }
            })}
          </div>
              
        </div>
      </div>
      <PaymentBottom />
    </div>
  )
}

export default OptionsNoCode