import React from 'react';
import './DataPopup.css'; // Add CSS styles for the popup

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="codePopup">
      <div className="codePopup__content">
        <button className="codePopup__closeButton" onClick={onClose}>
          X
        </button>
        <div className="codePopup__children">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;