export const WIPDisplayStates = {
  GENERAL_INSTRUCTIONS: "GENERAL_INSTRUCTIONS",
  INSTRUCTIONS: "INSTRUCTIONS",
  INSTRUCTIONSB: "INSTRUCTIONSB",
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q34: "INSTRUCTIONS_Q34",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: "DEMOGRAPHIC_INFORMATION"
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''}
]

export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''}
];

// List of all the questions and their answer choices.
export const questions = [
  { questionText: 'Charles works regularly 45 hours a week. Last week he worked 17 hours overtime. How many hours did he work?',
    answerOptions: [{ answerText: '28 hours' },{ answerText: '38 hours' },{ answerText: '52 hours' },{ answerText: '61 hours' },{ answerText: 'none of these' }] },
  { questionText: 'It usually takes 45 minutes to get to work. This morning it took 1 hour and 5 minutes. How much longer did it take this morning?',
    answerOptions: [{ answerText: '5 minutes' },{ answerText: '10 minutes' },{ answerText: '20 minutes' },{ answerText: '30 minutes' },{ answerText: 'none of these' }] },
  { questionText: 'Howi usually runs 75 miles each month. Last month he ran an extra 16 miles. How many miles did he run last month?',
    answerOptions: [{ answerText: '59' },{ answerText: '81' },{ answerText: '134' },{ answerText: '166' },{ answerText: 'none of these' }] },
  { questionText: 'A hair stylist can do 16 haircuts in 1 day. How many haircuts can he do in 13 days?',
    answerOptions: [{ answerText: '29' },{ answerText: '64' },{ answerText: '91' },{ answerText: '208' },{ answerText: 'none of these' }] },
  { questionText: 'A boy earned $12.50 doing errands. He owes his mother $5.75. How much will he have left after he pays his mother?',
    answerOptions: [{ answerText: '$6.85' },{ answerText: '$7.75' },{ answerText: '$7.85' },{ answerText: '$8.75' },{ answerText: 'none of these' }] },
  { questionText: 'A group of 12 friends bought a boat costing $5,424. Each of the friends paid an equal share of the cost. How much did each person pay?',
    answerOptions: [{ answerText: '$442' },{ answerText: '$462' },{ answerText: '$542' },{ answerText: '$562' },{ answerText: 'none of these' }] },
  { questionText: 'A doctor sees each of her patients for 15 minutes. How many patients can she see in 4-1/2 hours?',
    answerOptions: [{ answerText: '18' },{ answerText: '19' },{ answerText: '20' },{ answerText: '30' },{ answerText: 'none of these' }] },
  { questionText: 'A motorcycle was bought for $4,375.00. Later it was sold for $452.25 less than the buying price. What was the selling price?',
    answerOptions: [{ answerText: '$3,822.75' },{ answerText: '$3,832.75' },{ answerText: '$3,922.75' },{ answerText: '$3,923.75' },{ answerText: 'none of these' }] },
  { questionText: 'A baker can decorate 5 cakes in 3 hours. How many cakes can he decorate in 4 days if he works 9 hours each day?',
    answerOptions: [{ answerText: '20' },{ answerText: '60' },{ answerText: '180' },{ answerText: '540' },{ answerText: 'none of these' }] },
  { questionText: 'A table measures 3.5 feet long and 2.745 feet wide. How many feet longer is it than wide?',
    answerOptions: [{ answerText: '0.745' },{ answerText: '0.755' },{ answerText: '1.275' },{ answerText: '6.245' },{ answerText: 'none of these' }] },
  { questionText: 'An ice cream store sold 1,545 ice cream cones in June. Of these, 60% were vanilla. How many vanilla ice cream cones were sold?',
    answerOptions: [{ answerText: '600' },{ answerText: '618' },{ answerText: '927' },{ answerText: '945' },{ answerText: 'none of these' }] },
  { questionText: 'A carpenter is building a gate that will be 3-1/4 feet wide. The gate will be made of boards 3 inches wide. How many pieces of board will it take to fit across the front of the gate?',
    answerOptions: [{ answerText: '9-3/4' },{ answerText: '11' },{ answerText: '12' },{ answerText: '13' },{ answerText: 'none of these' }] },
  { questionText: 'A classroom will seat 80 people. If 56 seats are filled, what percentage of the seats are filled?',
    answerOptions: [{ answerText: '24%' },{ answerText: '30%' },{ answerText: '36%' },{ answerText: '70%' },{ answerText: 'none of these' }] },
  { questionText: 'Michi put $7.50 worth of gas in her car. Two co-workers who were riding to work with her offered to share the cost of the gas. One paid 1/2 of the total amount and the other paid 1/3 of the total amount. How much of the total amount was left for Michi to pay?',
    answerOptions: [{ answerText: '$1.25' },{ answerText: '$2.25' },{ answerText: '$5.25' },{ answerText: '$6.25' },{ answerText: 'none of these' }] },
  { questionText: 'A bookshelf is 4-3/4 feet wide. How many 3-inch-wide books will fit on the shelf?',
    answerOptions: [{ answerText: '14-1/4' },{ answerText: '17' },{ answerText: '18' },{ answerText: '19' },{ answerText: 'none of these' }] },
  { questionText: 'It takes a woman 12 minutes to drive one way to work. She goes home for lunch 3 days each week. How much time does she spend driving to and from work each 5-day work week?',
    answerOptions: [{ answerText: '2 hours, 36 minutes' },{ answerText: '3 hours' },{ answerText: '3 hours, 12 minutes' },{ answerText: '3 hours, 20 minutes' },{ answerText: 'none of these' }] },
  { questionText: 'A business buys invoice forms at a cost of $4.45 a box for the first 20 boxes, $4.00 a box for the next 25 boxes, and $3.75 a box for any additional boxes. At most, how many boxes of invoice forms can be bought for $234.00?',
    answerOptions: [{ answerText: '47' },{ answerText: '57' },{ answerText: '67' },{ answerText: '81' },{ answerText: 'none of these' }] },
  { questionText: 'A student returned 4 overdue books to the library. The fine for each overdue book is $0.35 for the first day, $0.40 for each of the next 3 days, and $0.45 for each day after that. The total fine was $13.40. How many days overdue were the books?',
    answerOptions: [{ answerText: '6' },{ answerText: '8' },{ answerText: '18' },{ answerText: '20' },{ answerText: 'none of these' }] },
];

export const values = [
  'A',
  'B',
  'C',
  'D',
  'E'
];