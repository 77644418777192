import React from 'react';
import { connect } from 'react-redux';
import { wipCalculatedSubmit, wipResultSubmit } from '../../../store/actions/submitActions';
import WIPJobOverlap from './WIPJobOverlap';
import { wipScoreCalculate } from '../functionalizedCalculations/wipCalculations';

const WIPCalculate = ({ uid, wip, wipResultSubmit, wipCalculatedSubmit }) => {

  // console.log('WIP CALCULATE');

  let achieveZScore = null;
  let workZScore = null;
  let recogZScore = null;
  let relateZScore = null;
  let supportZScore = null;
  let indepZScore = null;

  try {
    if (wip) {
      [
        achieveZScore, workZScore, recogZScore,
        relateZScore, supportZScore, indepZScore
      ] =
      wipScoreCalculate(uid, wip[0], wipResultSubmit, wipCalculatedSubmit);
    }
  } catch (error) {
    console.log('Error: ', error);
  }

  return (
    <div>
      {/* Calculating WIP scores... */}
      {(
        achieveZScore !== null && workZScore !== null && recogZScore !== null &&
        relateZScore !== null && supportZScore !== null && indepZScore !== null
      ) && (
        <WIPJobOverlap
          uid={uid}
          achieveZScore={achieveZScore}
          workZScore={workZScore}
          recogZScore={recogZScore}
          relateZScore={relateZScore}
          supportZScore={supportZScore}
          indepZScore={indepZScore}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    wipResultSubmit: (result) => dispatch(wipResultSubmit(result)),
    wipCalculatedSubmit: (result) =>
    dispatch(
      wipCalculatedSubmit(result)
    ),
  }
}

export default connect(null, mapDispatchToProps)(WIPCalculate)
