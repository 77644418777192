import React from 'react';
import { connect } from 'react-redux';
// import SignedInLinks from './SignedInLinks';
import SignedInLinks2Dark from './SignedInLinks2Dark';
// import SignedOutLinks from './SignedOutLinks';
// import SignedOutLinks2 from './SignedOutLinks2';
import SignedOutLinks2Dark from './SignedOutLinks2Dark';

const NavbarDark = (props) => {
  const { auth, resultsIndex, setResultsIndex } = props;
  // Show either the signedInLinks or the signedOutLinks dependent on whether the user is signed in.
  const links = auth.uid ? <SignedInLinks2Dark resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} /> : <SignedOutLinks2Dark />;

  return (
    <div className='navbar-dark'>
      {auth.isLoaded && links}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(NavbarDark)
