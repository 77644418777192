import React from 'react';
import './Popups.css';
import '../../../Results.css';

const apPopup = ({ isOpen, onClose, apGroupedResults }) => {
  if (!isOpen) return null;
  if (isOpen === false) return null;

  return (
    <div id='apPopup' className="singlePopup">
      <div className="singlePopup__topRow">
        <div className="singlePopup__header">
          ABILITIES
        </div>
        <button className="singlePopup__closeButton" onClick={onClose}>
          X
        </button>
      </div>

      <div className={`singlePopup__content ${isOpen && 'singlePopup__content-open'}`}>
        
        {
          apGroupedResults.length > 0 ? (
            apGroupedResults.map((element, index) => (
              <div key={index} className='singlePopup__text'>
                <h1>
                  {getGroupName(element.group)}
                </h1>
                {element.occupations.map((innerElement, innerIndex) => (
                  <a
                    className='singlePopup__link singlePopup__link-ap'
                    href={`https://www.onetonline.org/link/summary/${innerElement.onetCode}`}
                    target='blank'
                    rel='noreferrer'
                    key={innerIndex}
                  >
                    {innerElement.occupation}
                  </a>
                ))}
              </div>
            ))
          ) : (
            <div>
              No careers here.
            </div>
          )
        }

      </div>

    </div>
  )
}

export default apPopup

// Convert an O*Net group code to a human-readable group name.
function getGroupName(groupCode) {
  const codes = ['11', '13', '15', '17', '19', '21', '23', '25', '27', '29', '31', '33', '35', '37', '39', '41', '43', '45', '47', '49', '51', '53'];
  const groups = [
    'Management',
    'Business and Financial Operations',
    'Computer and Mathematical',
    'Architecture and Engineering',
    'Life, Physical, and Social Science',
    'Community and Social Service',
    'Legal Occupations',
    'Education, Training, and Library',
    'Arts, Design, Entertainment, Sports, and Media',
    'Healthcare Practitioners and Technical',
    'Healthcare Support',
    'Protective Service',
    'Food Preparation and Serving Related',
    'Building and Grounds Cleaning and Maintenance',
    'Personal Care and Service',
    'Sales and Related Occupations',
    'Office and Administrative Support',
    'Farming, Fishing, and Forestry',
    'Construction and Extraction',
    'Installation, Maintenance, and Repair',
    'Production',
    'Transportation and Material Moving'
  ];

  // Match the code, get its index.
  const codeMatch = (element) => Math.round(element - groupCode) === 0;
  const index = codes.findIndex(codeMatch);

  // Use the index to get the group name.
  return groups[index];
}