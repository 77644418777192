export const submitOrder = (order) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userID = getState().firebase.auth.uid;
    firestore.collection('orders').doc(userID).set({
      order
    })
      .then(() => {
        dispatch({ type: 'ORDER_SUCCESS'});
      }).catch((err) => {
        dispatch({ type: 'ORDER_ERROR', err });
      });
  }
}

export const submitFreeOrder = (order) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userID = order.uid;
    firestore.collection('orders').doc(userID).set({
      order
    })
      .then(() => {
        dispatch({ type: 'ORDER_SUCCESS'});
      }).catch((err) => {
        dispatch({ type: 'ORDER_ERROR', err });
      });
  }
}

// Pull all internal codes.
export const pullAllCodes = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const codesDoc = await firestore.collection('promoCodes').doc('promoCodes').get();
      if (codesDoc.exists) {
        const codesData = codesDoc.data();

        // Dispatch the codesData to the Redux store if needed.
        // Example: dispatch({ type: 'SET_CODES', payload: codesData });

        // You can also return the codesData if you want to use it elsewhere.
        return codesData;
      }
    } catch (error) {
      console.error('Error fetching codes:', error);
    }
  }
}

// Increment the number of times a code has been used to 
// get access.
export const startTracker = (pcName) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // Open the 'pcName' variable inside the promoCodes document in the 
    // promoCodes collection. Create a variable called 'timesUsed' and set
    // it to 1.
    const promoCodeDocRef = firestore.collection('promoCodes').doc('promoCodes');

    // Get the document's data.
    promoCodeDocRef.get()
      .then(doc => {
        if (doc.exists) {
          // Retrieve the data
          const promoCodeData = doc.data();

          // Assuming 'pcName' is the object containing 'timesUsed'
          if (promoCodeData[pcName]) {
            // Update the nested 'timesUsed' field
            promoCodeData[pcName].timesUsed = (promoCodeData[pcName].timesUsed || 0) + 1;

            // Update the document with the modified data
            return promoCodeDocRef.update(promoCodeData); // Use set() or update() based on your use case
          }
        }
      })
      .then(() => {
        // console.log('Document updated successfully.');
      })
      .catch(error => {
        console.error('Error updating document:', error);
      });
  };
};

// Create a new promo code in the 'promoCodes' collection.
export const createCode = (code) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise((resolve, reject) => {
      const firestore = getFirestore();
      const promoCodeDocRef = firestore.collection('promoCodes').doc('promoCodes');

      // Get the document's data.
      promoCodeDocRef.get()
        .then(doc => {
          if (doc.exists) {
            
            // Retrieve the data.
            const promoCodeData = doc.data();

            // Update the 'creationDate' element to the current date.
            code.creationDate = new Date();

            // Add the new code to the promoCodeData doc.
            promoCodeData[code.code] = code;

            // Update the document with the modified data.
            return promoCodeDocRef.update(promoCodeData);
          }
        })
        .then(() => {
          // console.log('Document updated successfully.');
          dispatch({ type: 'CODE_SUBMIT_SUCCESS'});
          resolve();
        })
        .catch(error => {
          dispatch({ type: 'CODE_SUBMIT_ERROR', error });
          reject(error);
        });
    })
  };
}

// Update 'archived' status for a promo code.
export const updateArchiveStatus = (codeName, archiveStatus) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise((resolve, reject) => {
      const firestore = getFirestore();
      const promoCodeDocRef = firestore.collection('promoCodes').doc('promoCodes');

      // Get the document's data.
      promoCodeDocRef.get()
        .then(doc => {
          if (doc.exists) {
            try {
              
              // Retrieve the data.
              const promoCodeData = doc.data();
              
              // Set the 'archived' value for this promo code based on the
              // value of archiveStatus.
              promoCodeData[codeName].archived = archiveStatus;
              
              // Update the document with the modified data.
              return promoCodeDocRef.update(promoCodeData);
            } catch (error) {
              console.error('Error: ', error);
              return Promise.reject(error); // Propagate the error.
            }
          }
        })
        .then(() => {
          // console.log('Document updated successfully.');
          dispatch({ type: 'CODE_UPDATE_SUCCESS'});
          resolve();
        })
        .catch(error => {
          dispatch({ type: 'CODE_UPDATE_ERROR', error });
          reject(error);
        });
    })
  };
}

// Pull all existing users.
export const pullAllUsers = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const querySnapshot = await firestore.collection('users').get();
      const usersData = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          
          // Extract specific information from each document.
          const firstName = userData.firstName;
          const lastName = userData.lastName;
          const overlapCalculated = userData.overlapCalculated;

          let resultsCalculated = 'No';
          if (overlapCalculated === true) {
            resultsCalculated = 'Yes';
          }

          usersData.push({
            userID: doc.id,
            firstName,
            lastName,
            resultsCalculated
          });
        }
      });

      return usersData;
    } catch (error) {
      console.log('Error: ', error);
    }
  }
}

// Get calcOutput from each item in 'apSortedResults' in a document
// in the 'apresults' collection.
export const getAPCalcOutputs = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('apresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the apSortedResults array.
        if (userData.apSortedResults) {
          const apResults = userData.apSortedResults;
          const calcOutputs = apResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.calcOutput;
          });

          return calcOutputs; // Returns calcOutputs array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get correlationCoefficient from each item in 'apSortedResults' in a
// document in the 'apresults' collection.
export const getAPCorrelationCoefficients = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('apresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the apSortedResults array.
        if (userData.apSortedResults) {
          const apResults = userData.apSortedResults;
          const correlationCoefficients = apResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.correlationCoefficient;
          });

          return correlationCoefficients; // Returns correlation coefficient array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get apAverage from each item in 'apSortedResults' in a
// document in the 'apresults' collection.
export const getAPAverages = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('apresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the apSortedResults array.
        if (userData.apSortedResults) {
          const apResults = userData.apSortedResults;
          const apAverages = apResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.apAverage;
          });

          return apAverages; // Returns apAverage array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get a user's AP scores.
export const getAPScores = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    return new Promise((resolve, reject) => {
      try {
        const apCollection = firestore.collection('apresults');

        apCollection.onSnapshot((snapshot) => {
          const docs = [];
          snapshot.forEach((doc) => {
            docs.push({
              id: doc.data().userId,
              arScore: doc.data().arPercentScore,
              saScore: doc.data().saPercentScore,
              vaScore: doc.data().vaPercentScore,
              cmScore: doc.data().cmPercentScore,
              cpScore: doc.data().cpPercentScore,
              fpScore: doc.data().fpPercentScore
            });
          });
          resolve(docs);
        });
      } catch (error) {
        console.log('Error: ', error);
        reject(error);
      }
    })
    // try {
    //   const apCollection = await firestore.collection('apresults');

    //   const results = apCollection.onSnapshot((snapshot) => {
    //     const docs = [];
    //     snapshot.forEach((doc) => {
    //       docs.push({
    //         id: doc.userId,
    //         arScore: doc.arPercentScore,
    //         saScore: doc.saPercentScore,
    //         vaScore: doc.vaPercentScore,
    //         cmScore: doc.cmPercentScore,
    //         cpScore: doc.cpPercentScore,
    //         fpScore: doc.fpPercentScore,
    //         ...doc.data()
    //       });
    //     });
    //   });

    //   return results;

    // } catch (error) {
    //   console.log('Error: ', error);
    // }

    // // If no calcOutputs are found or if an error occurs, it will reach here
    // return []; // Return an empty array or handle it as per your requirements
  }
}

// Get calcOutput from each item in 'ipSortedResults' in each document
// in the 'ipresults' collection.
export const getIPCalcOutputs = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('ipresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the ipSortedResults array.
        if (userData.ipSortedResults) {
          const ipResults = userData.ipSortedResults;
          const calcOutputs = ipResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.calcOutput;
          });

          return calcOutputs; // Returns calcOutputs array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get calcOutput from each item in 'wipSortedResults' in each document
// in the 'wipresults' collection.
export const getWIPCalcOutputs = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('wipresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the ipSortedResults array.
        if (userData.wipSortedResults) {
          const wipResults = userData.wipSortedResults;
          const calcOutputs = wipResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.calcOutput;
          });

          return calcOutputs; // Returns calcOutputs array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get calcOutput from each item in 'wipSortedResults' in each document
// in the 'wipresults' collection.
export const getTrifectaCalcOutputs = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('trifectaresults').doc(uid).get();

      if (userDoc.exists) {
        const userData = userDoc.data();

        // Access the ipSortedResults array.
        if (userData.trifectaResults) {
          const trifectaResults = userData.trifectaResults;
          const calcOutputs = trifectaResults.map((element) => {
            // Assuming calcOutput is a property in each element.
            return element.calcOutput;
          });

          return calcOutputs; // Returns calcOutputs array.
        }
      }

    } catch (error) {
      console.log('Error: ', error);
    }

    // If no calcOutputs are found or if an error occurs, it will reach here
    return []; // Return an empty array or handle it as per your requirements
  }
}

// Get AR test results for a specific user.
export const getARTestResults = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection('ar').doc(uid).get();
      const data = userDoc.data();  // Extract data from the document.

      return data;

    } catch (error) {
      console.log('Error: ', error);
    }
  }
}

// Get test results for a specific user.
export const getTestResults = (uid, collectionName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      const userDoc = await firestore.collection(collectionName).doc(uid).get();
      const data = userDoc.data();  // Extract data from the document.

      return data;

    } catch (error) {
      console.log('Error: ', error);
    }
  }
}