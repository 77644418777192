import React from 'react';
// import ProgressBar from '../../../../ProgressBar/ProgressBar';
import { questions, values } from './Configs';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const ARMultiChoice = ( {count, answer, setAnswer, last, setLast} ) => {

  // If count is null, set it to 0.
  if (count === null) {
    count = 0;
  }

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // If already selected, clear it.
    const previousAnswer = answer[count].values;
    const currentAnswer = e.target.value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  const title = questions[count].questionText;
  // let options = [];
  const options = questions[count].answerOptions.map((key, index) => {
    return key.answerText;
  });

  const numTotalQuestions = 18;
  // const progress = Math.round((count+1)/numTotalQuestions*100*10)/10;

  return (
    <div className='ar-main-box ar-main-box__centered'>
      <div className="question-ar">
        <div className="question__number-ar">
          {count+1}.
        </div>
        <div className="question__body-ar">
          <div className="question__text-ar">
          {title}
          </div>
          <div className=" question__answers-ar">
            { options.map((key, index) => (
              <label className="question__answer-option-ar" key={index}>
                <input
                  type="checkbox"
                  checked={answer[count].values === values[index]}
                  value={values[index]}
                  onChange={e => handleAnswer(e)}
                  name={count}
                  className='armulti__checkbox'
                />
                <div className="question__answer-text-ar">
                  {key}
                </div>
              </label>
            )) }
          </div>
        </div>
      </div>
      <div className="apMultiChoice__bottom">
        {/* <div className="apMultiChoice__progressBar">
          <ProgressBar completed={progress} bgColor='#008375' />
        </div> */}
        <div className="ap__question-count" style={{ width: '100%' }}>
          {count+1} of {numTotalQuestions} questions
        </div>
      </div>
    </div>
  )
}

export default ARMultiChoice
