import React from 'react';
import SignedInLinks2 from './SignedInLinks2';
import SignedOutLinks2 from './SignedOutLinks2';

const Navbar = ({ signedIn }) => {

  return (
    <div>
      {(
        signedIn
      ) ? (
        <SignedInLinks2 />
      ) : (
        <SignedOutLinks2 />
      )}
    </div>
  )
}

export default Navbar;