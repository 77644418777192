import React from 'react';
// import { Table } from 'react-bootstrap';
// import ProgressBar from '../../../../ProgressBar/ProgressBar';
import { values, questionsA, questionsB } from './Configs';

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e.id === parseInt(item));
};

const CPMultiChoice2 = ({count, answer, setAnswer, last, setLast}) => {

  // If count is null, set it to 0.
  // For this assessment, count is the page count.
  if (count === null) {
    count = 0;
  }

  // Option A (left).
  const optionsA = [
    questionsA[count*5],
    questionsA[count*5+1],
    questionsA[count*5+2],
    questionsA[count*5+3],
    questionsA[count*5+4]
  ];

  // Option B (left).
  const optionsB = [
    questionsB[count*5],
    questionsB[count*5+1],
    questionsB[count*5+2],
    questionsB[count*5+3],
    questionsB[count*5+4]
  ];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // If already selected, clear it.
    const previousAnswer = answer[+name].values;
    const currentAnswer = e.target.value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    const itemIndex = getItemIndex(answer, name);

    const obj = {
      id: parseInt(name),
      values: value
    };

    if (itemIndex === -1) {
      setAnswer([...answer, obj]);
      return;
    }

    const newArr = [...answer];
    newArr[itemIndex] = obj;
    setAnswer(newArr);

    // Check if this item was selected already.
    if (last <= parseInt(name)) {
      // Update 'last' state.
      setLast(parseInt(name)+1);
    }
  }

  const numTotalQuestions = 90;
  // let progress = Math.round((count+1)/(numTotalQuestions/5)*100*10)/10;

  return (
    <div className='cp-main-box cp-main-box-assessment'>
      <div className="cp-table-wrapper">
        <table className='cp-table'>
          <tbody>
            { optionsA.map((key, index) => (
              <tr className='cp-table__row' key={index}>
                <td className='cp-table__number'>{count*5 + 1 + index}.</td>
                <td className='cp-table__item cp-table__item-1'>
                  {key}
                </td>
                <td className='cp-table__item cp-table__item-3'>
                  <div className="hr" />
                </td>
                <td className="cp-table__item cp-table__item-2">
                  {optionsB[index]}
                </td>
                <td className='cp-table__options'>
                  <div className="cp-question__answers">
                    <label className="cp-question__answer-option">
                      <div className="cp-question__answer-text">
                        S
                      </div>
                      <input
                        type="checkbox"
                        className='question__radio-button question__radio-button-cp'
                        checked={answer[count*5 + index].values === values[0]}
                        value={values[0]}
                        onChange={e => handleAnswer(e)}
                        name={count*5 + index}
                      />
                    </label>
                    <label className="cp-question__answer-option">
                      <div className="cp-question__answer-text">
                        D
                      </div>
                      <input
                        type="checkbox"
                        className='question__radio-button question__radio-button-cp'
                        checked={answer[count*5 + index].values === values[1]}
                        value={values[1]}
                        onChange={e => handleAnswer(e)}
                        name={count*5 + index}
                      />
                    </label>
                  </div>
                </td>
              </tr>
            )) }
          </tbody>
        </table>
      </div>
      <div className="apMultiChoice__bottom">
        {/* <div className="apMultiChoice__progressBar">
          <ProgressBar completed={progress} bgColor='#008375' />
        </div> */}
        <div className="ap__question-count" style={{ width: '100%' }}>
          {(count+1)*5} of {numTotalQuestions} questions
        </div>
      </div>
    </div>
  )
}

export default CPMultiChoice2
