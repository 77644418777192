import React, { useState } from 'react';
import { WIPDisplayStates } from "./Configs";
import './WIPInstructions.css';
import { ReadyPopup } from '../../../Utils/ReadyPopup';

const WIPInstructionsD = ({ onClick }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='wipInstructions'>
        <div className="wipInstructions__header">
          Assessment 2: Values Profiler
        </div>
        <div className='wipInstructions__subheader'>
          Instructions
        </div>
        <div className="wipInstructions__body2">
          Remember that there are no right or wrong answers -- you are trying to find out what is important to you.
          <br /><br />
          There will be a short video explanation before the assessment. There is no time limit. Take your time.
          When you're ready, click on the button below to begin.
        </div>
      <div className="wipInstructions__bottom wipInstructions__bottom-instructions">
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            onClick(WIPDisplayStates.INSTRUCTIONS_C);
          }}
        >
          Back
        </button>
        <button
          type='button'
          className="wipInstructions__button"
          onClick={() => {
            setOpen(o => !o);
          }}
        >
          Begin
        </button>
        <ReadyPopup
          onClick = {onClick}
          open={open}
          setOpen={setOpen}
          readyDestination = {WIPDisplayStates.VIDEO}
        />
      </div>
    </div>
  )
}

export default WIPInstructionsD
