import React, { useState, useEffect } from 'react';
// import { Row, Col } from 'react-bootstrap';
import { ResultDisplayStates } from './Configs';
import LoadingPage from '../pages/loadingPage/LoadingPage';
import NoneComplete from './NoneComplete';
import NotPurchased from './NotPurchased';
import SignedOut from './SignedOut';
// import AllResults from './AllResults';
import AllResults from './AllResults2';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

const ResultFrontPage = ({ user, order, resultsIndex, setResultsIndex, auth }) => {
  const [ display, setDisplay ] = useState(ResultDisplayStates.LOADING);


  useEffect(() => {
    try {
      // Check if user is signed in.
      if (auth.isLoaded) {
        if (auth.isEmpty) {
          // User isn't signed in.
          setDisplay(ResultDisplayStates.SIGNED_OUT);
        } else {
          if (user) {
            // User is signed in and exists in the database.
            // Check if assessment was purchased.
            if (!order) {
              setDisplay(ResultDisplayStates.NOT_PURCHASED);
            } else {
              if (user) {
                // Go directly to 'AllResults' if the user has completed all assessments.
                if (user[0].ipComplete === true || user[0].wipComplete === true || user[0].fpComplete === true) {
                  setDisplay(ResultDisplayStates.SINGLE_COMPLETE);
                } else {
                  // Tell the user to complete an assessment to see any results.
                  setDisplay(ResultDisplayStates.NONE_COMPLETE);
                }
              }
            }
          }
        }
      } else {
        setDisplay(ResultDisplayStates.LOADING);
      }

    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user, order, auth]);

  return (
    <div className='results'>
    {/* <div className='home-page-results'> */}
      {/* <Container> */}
        {/* {user && <Row> */}
        {/* {<Row>
          <Col> */}
            {display === ResultDisplayStates.LOADING && (
              <LoadingPage />
              )
            }
            {display === ResultDisplayStates.SIGNED_OUT && (
              <SignedOut />
            )}
            {display === ResultDisplayStates.NOT_PURCHASED && (
              <NotPurchased />
              )
            }
            {display === ResultDisplayStates.SINGLE_COMPLETE && (
              <AllResults user={user} resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} />
              // <NoneComplete />
              )
            }
            {display === ResultDisplayStates.NONE_COMPLETE && (
              <NoneComplete />
              )
            }
          {/* </Col>
        </Row>} */}
        {/* {!user && <div>
          <LoadingPage />
        </div>} */}
      {/* </Container> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    order: state.firestore.ordered.orders,
    user: state.firestore.ordered.users,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'orders', doc: props.auth.uid },
      { collection: 'users', doc: props.auth.uid },
    ]
  })
)(ResultFrontPage)