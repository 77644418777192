import React from 'react';

const maslowPic = `/images/scienceImages/science-maslow-full.png`;

const Maslow = () => {
  return (
    <div className="maslow">
      <div className="maslow__content">
        <div className="maslow__header">
          Maslow's Hierarchy of Needs
        </div>
        <div className="maslow__body maslow__body-white">
          The psychologist Abraham Maslow developed a hierarchy of needs to study how humans intrinsically partake in
          behavioral motivation. Maslow used the terms below to describe the pattern through which human motivations
          generally move (from the bottom to the top), as humans generally seek to fulfill the lowest order of needs first. This
          theory is foundational in understanding how drive and motivation are correlated when discussing human behavior. Maslow
          believed the final goal was to attain the fifth level or stage: self-actualization.
        </div>
        <div className="maslow__box">
          <div className="maslow__needs">
            <img src={maslowPic} alt='maslow' className='maslow__pic' />
            <div className="maslow__body maslow__body-blue">
              In a work setting, the Hierarchy of Needs is as follows:
            </div>
            <div className="maslow__body maslow__body-white maslow__body-bold">
              1. Physiological Needs
            </div>
            <div className="maslow__body maslow__body-blue maslow__body-indent">
              Basic issues of survival such as salary and stable employment
            </div>
            <div className="maslow__body maslow__body-white maslow__body-bold">
              2. Security Needs
            </div>
            <div className="maslow__body maslow__body-blue maslow__body-indent">
              Stable physical and emotional environment issues such as benefits, pension, safe work environment, and fair
              work practices
            </div>
            <div className="maslow__body maslow__body-white maslow__body-bold">
              3. "Belongingness" Needs
            </div>
            <div className="maslow__body maslow__body-blue maslow__body-indent">
              Social acceptance issues such as friendship, trust, affiliation, or cooperation
            </div>
            <div className="maslow__body maslow__body-white maslow__body-bold">
              4. Esteem Needs
            </div>
            <div className="maslow__body maslow__body-blue maslow__body-indent">
            Positive self-image, a sense of personal achievement, and respect and recognition issues such as job titles, 
            nice work spaces, and prestigious job assignments
            </div>
            <div className="maslow__body maslow__body-white maslow__body-bold">
              5. Self-Actualization Needs
            </div>
            <div className="maslow__body maslow__body-blue maslow__body-indent">
              "To become everything one is capable of becoming."
              Achievement issues such as workplace autonomy, challenging work, and subject matter expert status on the job
            </div>
          </div>
        </div>
        <div className="maslow__body maslow__body-white">
          Telos’ assessments seek to help move you upward in this hierarchy toward self-actualization and fulfillment by locating what you are good at (as others will recognize that and meet your esteem needs), what your interests are (increasing belongingness with others who share the same interests), and what is meaningful to you (increasing your sense of personal achievement).
        </div>
      </div>
    </div>
  )
}

export default Maslow