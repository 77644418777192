export const WIPDisplayStates = {
  INSTRUCTIONS_A: "INSTRUCTIONS_A",
  INSTRUCTIONS_B: "INSTRUCTIONS_B",
  INSTRUCTIONS_C: "INSTRUCTIONS_C",
  INSTRUCTIONS_D: "INSTRUCTIONS_D",
  VIDEO: "VIDEO",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  DEMOGRAPHIC_INFORMATION: "DEMOGRAPHIC_INFORMATION",
  TEST: "TEST"
};

export const WIPDNDOptions = [
  { id: "WIP-Option-0", text: "I make use of my abilities.", label: "optA" },
  {
    id: "WIP-Option-1",
    text: "I would be treated fairly by the company.",
    label: "optB",
  },
  { id: "WIP-Option-2", text: "I could be busy all the time.", label: "optC" },
  {
    id: "WIP-Option-3",
    text: "The job would provide an opportunity for advancement.",
    label: "optD",
  },
  {
    id: "WIP-Option-4",
    text: "I could give directions and instructions to others.",
    label: "optE",
  },
  {
    id: "WIP-Option-5",
    text: "The work could give me a feeling of accomplishment.",
    label: "optF",
  },
  {
    id: "WIP-Option-6",
    text: "My pay would compare well with that of other workers.",
    label: "optG",
  },
  {
    id: "WIP-Option-7",
    text: "My co-workers would be easy to get along with.",
    label: "optH",
  },
  { id: "WIP-Option-8", text: "I could try out my own ideas.", label: "optI" },
  { id: "WIP-Option-9", text: "I could work alone.", label: "opt" },
  {
    id: "WIP-Option-10",
    text:
      "I would never be pressured to do things that go against my sense of right and wrong.",
    label: "optK",
  },
  {
    id: "WIP-Option-11",
    text: "I could receive recognition for the work I do.",
    label: "optL",
  },
  {
    id: "WIP-Option-12",
    text: "I could make decisions on my own.",
    label: "optM",
  },
  {
    id: "WIP-Option-13",
    text: "The job would provide for steady employment.",
    label: "optN",
  },
  {
    id: "WIP-Option-14",
    // text: "I could do things for other people.",
    text: "I could help other people with the work I do.",
    label: "optO",
  },
  {
    id: "WIP-Option-15",
    text: "I have supervisors who would back up their workers with management.",
    label: "optP",
  },
  {
    id: "WIP-Option-16",
    text: "I have supervisors who train their workers well.",
    label: "optQ",
  },
  {
    id: "WIP-Option-17",
    text: "I could do something different every day.",
    label: "optR",
  },
  {
    id: "WIP-Option-18",
    text: "The job would have good working conditions.",
    label: "optS",
  },
  {
    id: "WIP-Option-19",
    text: "I could plan my work with little supervision.",
    label: "optT",
  },
  {
    id: "WIP-Option-20",
    text: "I would be looked up to by others in my company and my community.",
    label: "optU",
  },
];

export const WIPPrompts = [
  {
    id: "WIP-Prompt-1",
    order: [
      "WIP-Option-2",
      "WIP-Option-3",
      "WIP-Option-6",
      "WIP-Option-8",
      "WIP-Option-14",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-2",
    order: [
      "WIP-Option-1",
      "WIP-Option-5",
      "WIP-Option-14",
      "WIP-Option-16",
      "WIP-Option-17",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-3",
    order: [
      "WIP-Option-0",
      "WIP-Option-2",
      "WIP-Option-5",
      "WIP-Option-10",
      "WIP-Option-18",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-4",
    order: [
      "WIP-Option-0",
      "WIP-Option-4",
      "WIP-Option-7",
      "WIP-Option-13",
      "WIP-Option-14",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-5",
    order: [
      "WIP-Option-8",
      "WIP-Option-13",
      "WIP-Option-17",
      "WIP-Option-18",
      "WIP-Option-19",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-6",
    order: [
      "WIP-Option-0",
      "WIP-Option-3",
      "WIP-Option-9",
      "WIP-Option-16",
      "WIP-Option-19",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-7",
    order: [
      "WIP-Option-3",
      "WIP-Option-4",
      "WIP-Option-10",
      "WIP-Option-15",
      "WIP-Option-17",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-8",
    order: [
      "WIP-Option-0",
      "WIP-Option-1",
      "WIP-Option-8",
      "WIP-Option-12",
      "WIP-Option-15",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-9",
    order: [
      "WIP-Option-9",
      "WIP-Option-11",
      "WIP-Option-14",
      "WIP-Option-15",
      "WIP-Option-18",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-10",
    order: [
      "WIP-Option-10",
      "WIP-Option-12",
      "WIP-Option-14",
      "WIP-Option-19",
      "WIP-Option-20",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-11",
    order: [
      "WIP-Option-2",
      "WIP-Option-13",
      "WIP-Option-15",
      "WIP-Option-16",
      "WIP-Option-20",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-12",
    order: [
      "WIP-Option-5",
      "WIP-Option-6",
      "WIP-Option-7",
      "WIP-Option-15",
      "WIP-Option-19",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-13",
    order: [
      "WIP-Option-0",
      "WIP-Option-6",
      "WIP-Option-11",
      "WIP-Option-17",
      "WIP-Option-20",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-14",
    order: [
      "WIP-Option-2",
      "WIP-Option-7",
      "WIP-Option-9",
      "WIP-Option-12",
      "WIP-Option-17",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-15",
    order: [
      "WIP-Option-4",
      "WIP-Option-5",
      "WIP-Option-8",
      "WIP-Option-9",
      "WIP-Option-20",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-16",
    order: [
      "WIP-Option-4",
      "WIP-Option-6",
      "WIP-Option-12",
      "WIP-Option-16",
      "WIP-Option-18",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-17",
    order: [
      "WIP-Option-1",
      "WIP-Option-2",
      "WIP-Option-4",
      "WIP-Option-11",
      "WIP-Option-19",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-18",
    order: [
      "WIP-Option-1",
      "WIP-Option-3",
      "WIP-Option-7",
      "WIP-Option-18",
      "WIP-Option-20",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-19",
    order: [
      "WIP-Option-3",
      "WIP-Option-5",
      "WIP-Option-11",
      "WIP-Option-12",
      "WIP-Option-13",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-20",
    order: [
      "WIP-Option-1",
      "WIP-Option-6",
      "WIP-Option-9",
      "WIP-Option-10",
      "WIP-Option-13",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-21",
    order: [
      "WIP-Option-7",
      "WIP-Option-8",
      "WIP-Option-10",
      "WIP-Option-11",
      "WIP-Option-16",
    ],
    isDnd: true,
    isMulti: false,
  },
  {
    id: "WIP-Prompt-22",
    questionText:
      "I have supervisors who train their workers well.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-23",
    questionText:
      "I could try out my own ideas.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-24",
    questionText:
      "I would never be pressured to do things that go against my sense of right and wrong.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-25",
    questionText: "I could work alone.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-26",
    questionText:
      "My co-workers would be easy to get along with.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-27",
    questionText:
      "I could be busy all the time.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-28",
    questionText:
      "I would be treated fairly by the company.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-29",
    questionText:
      "I make use of my abilities.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-30",
    questionText:
      "My pay would compare well with that of other workers.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-31",
    questionText:
      "The job would have good working conditions.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-32",
    questionText:
      "I could give directions and instructions to others.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-33",
    questionText:
      "I could do things for other people.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-34",
    questionText:
      "I could make decisions on my own.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-35",
    questionText:
      "I could plan my work with little supervision.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-36",
    questionText:
      "I could receive recognition for the work I do.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-37",
    questionText:
      "The job would provide an opportunity for advancement.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-38",
    questionText:
      "I would be looked up to by others in my company and my community.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-39",
    questionText:
      "The job would provide for steady employment.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-40",
    questionText:
      "The work could give me a feeling of accomplishment.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-41",
    questionText:
      "I could do something different every day.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
  {
    id: "WIP-Prompt-42",
    questionText:
      "I have supervisors who would back up their workers with management.",
    answerOptions: [
      { label: "Yes", value: "Yes", isSelected: false },
      { label: "No", value: "No", isSelected: false },
    ],
    isDnd: false,
    isMulti: true,
  },
];
