import React from 'react';
import { connect } from 'react-redux';
import { trifectaResultSubmit, overlapCalculatedSubmit } from '../../../store/actions/submitActions';
// import firebase from '../../../config/fbConfig';
// import { calcIPAPOverlap, calcWIPAPOverlap, calcWIPIPOverlap, calcTrifectaOverlap } from '../Functions';
import { trifectaJobOverlapCalculate } from '../functionalizedCalculations/trifectaCalculations';

const TrifectaCalculate = ({ uid, trifectaResultSubmit, overlapCalculatedSubmit, apresults, ipresults, wipresults, auth }) => {

  // console.log('OVERLAP JOBS CALCULATE');

  // Calculate trifecta job overlap.
  try {
    if (apresults && ipresults && wipresults) {
      trifectaJobOverlapCalculate(
        uid, apresults[0].apSortedResults, ipresults[0].ipSortedResults,
        wipresults[0].wipSortedResults, trifectaResultSubmit, overlapCalculatedSubmit
      )
    }    
  } catch (error) {
    console.log('Error: ', error);
  }

  return (
    <div></div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trifectaResultSubmit: (result) => dispatch(trifectaResultSubmit(result)),
    overlapCalculatedSubmit: (result) =>
    dispatch(
      overlapCalculatedSubmit(result)
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrifectaCalculate)