import React, { useState, useEffect } from 'react';
import './APAssessment.css';
import ReactPlayer from 'react-player';
import { NavLink } from "react-router-dom";
import { giCompleteSubmit } from "../../../store/actions/submitActions";
import { connect } from "react-redux";
import { GIDisplayStates } from "./Configs";
import './APExplanationVideo.css';

const APExplanationVideo = ({ giCompleteSubmit }) => {
  const playThreshold = 85;     // Minimum percentage of video to be played before user can go to next page.
  const [ played, setPlayed ] = useState(0);
  const [ counter, setCounter ] = useState(100);
  const [ complete, setComplete ] = useState(false);
  const [ playing, setPlaying ] = useState(true);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    } else {
      setComplete(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter, setCounter]);

  const onDuration = (e) => {
    setCounter(e);
  }
 
  const onProgress = (e) => {
    setPlayed(e.played*100);

    // Pause the video with one second left to avoid showing related videos.
    if (e.playedSeconds > 111) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  }

  // Need to store 'general instructions complete = true' in user's Firestore database.
  const onClick = () => {
    giCompleteSubmit(); // Sends results to Firestore.
  }
  
  return (
    <div className='apVideo'>
      <div className="apVideo__video">
        <ReactPlayer
          url="https://youtu.be/noD9J-wkZE0"
          controls={true}
          playing={playing}
          width='100%'
          height='100%'
          onDuration={onDuration}
          onProgress={onProgress}
        />
      </div>
      <div className="apVideo__bottom">
          <div className="apVideo__text">
            Because these instructions are important for your ability to complete the assessment,
            the next button will be disabled until the instruction video finishes.
          </div>
        <NavLink to='/apassessment' style={{ marginLeft: 'auto' }}>
          <button
            type='button'
            className={"apVideo__button " + ((played < playThreshold || !complete) ? "apVideo__button-disabled" : "")}
            onClick={() => {
              onClick(GIDisplayStates.ASSESSMENT);
            }}
            disabled={(played < playThreshold) || (!complete)}
          >
            Begin
          </button>
        </NavLink>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    giCompleteSubmit: (result) =>
      dispatch(
        giCompleteSubmit(result)
      ),
  };
};

export default connect(null, mapDispatchToProps)(APExplanationVideo)