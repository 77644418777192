// import 'firebase/functions';  // Import the Firebase Functions module.
// import firebase from '../../config/fbConfig';
import { functions } from '../../config/fbConfig';

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    // Sign out.
    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' });
      // Reload the page to reset any state variables.
      window.location.reload();
    });

  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    // Generate new user (asynchronously).
    firebase.auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    ).then((resp) => {
      // Send verification email.
      resp.user.sendEmailVerification();

      // Set up user's basic account info.
      return firestore.collection('users').doc(resp.user.uid).set({
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        initials: newUser.firstName[0] + newUser.lastName[0],
        email: newUser.email,
        birthYear: newUser.birthYear,
        birthMonth: newUser.birthMonth,
        birthDay: newUser.birthDay,
        signupDate: new Date()
      })
    }).then(() => {
      // Send welcome email through SendGrid.
      try {
        // // Call the cloud function using its name.
        const callable = functions.httpsCallable('sendEmailTemplateModule');
        callable({ 
          to: newUser.email,
          templateId: process.env.REACT_APP_WELCOME_EMAIL_TEMPLATE_ID
        });
      } catch (error) {
        console.error('Error sending welcome email: ', error);
      }
    }).then(() => {
      dispatch({ type: 'SIGNUP_SUCCESS' })
    }).catch(err => {
      dispatch({ type: 'SIGNUP_ERROR', err })
    })
  }
}