import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../AR.css';

const InstructionsQ2 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');

  useEffect(() => {
    if (practiceAnswers[1].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[1].values === 'None of these') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers]);

  // Answer options.
  const answerOptions = [
    '$460',
    '$490',
    '$530',
    '$560',
    'None of these'
  ];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[1].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: 1,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === 1) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === 'None of these') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  return (
    <div className='ar-main-box'>
      <div className="ap-instructions-header">
        Assessments 3: Part 1 - Arithmetic Reasoning
      </div>
      <div className='ar-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Here are some arithmetic questions.
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Now read and answer Practice Question 2:
      </div>
      
      <div className="question-ar">
        <div className="question__number-ar">
          2.
        </div>
        <div className="question__body-ar">
          <div className="question__text-ar">
            Harry spends 1/3 of his monthly income on rent. He earns $1,560 per month. How much
            does he pay for rent?
          </div>
          <div className="question-ar__answerBody">
            <div className="question__answers-ar">
              {answerOptions.map((element, index) => (
                <label className="question__answer-option-ar" key={index}>
                  <input
                    type="checkbox"
                    className='question__radio-button'
                    checked={practiceAnswers[1].values === element}
                    name={1}
                    value={element}
                    onChange={e => handleAnswer(e)}
                  />
                  <div className="question__answer-text-ar">
                    {element}
                  </div>
                </label>
              ))}
            </div>
            { practiceAnswers[1].values !== '' || practiceAnswers[1].values.length > 0 ? (
              correct ? (
                <div className="display-answer">
                  Correct
                  <div className="display-answer__body">
                    That's right! In Practice Question 2, the correct answer is $520. However, $520 does
                    not appear in the answer column. Therefore, "None of these" is the correct answer.
                  </div>
                </div>
              ) : (
                <div className="display-answer">
                  Incorrect
                  <div className="display-answer__body">
                    In Practice Question 2, the correct answer is $520. However, $520 does not appear
                    in the answer column. Therefore, "None of these" is the correct answer.
                  </div>
                </div>
              )
            ) : (
              <div className="display-answer"></div>
            )
            }
          </div>
        </div>
      </div>
      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}  
        >
          Back
        </button>
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q34);
          }}
        >
          Next
        </button>
      </div>
      {/* { practiceAnswers[1].values !== '' || practiceAnswers[1].values.length > 0 ? (
          correct ? (
            <div className="display-answer">
              Correct
              <div className="display-answer__body">
                That's right! In Practice Question 2, the correct answer is $520. However, $520 does
                not appear in the answer column. Therefore, "None of these" is the correct answer.
              </div>
            </div>
          ) : (
            <div className="display-answer">
              Incorrect
              <div className="display-answer__body">
                In Practice Question 2, the correct answer is $520. However, $520 does not appear
                in the answer column. Therefore, "None of these" is the correct answer.
              </div>
            </div>
          )
      ) : (
        <div className="display-answer"></div>
      )
      } */}
    </div>
  )
}

export default InstructionsQ2
