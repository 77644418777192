import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { GIDisplayStates } from "./Configs";
import './APAssessment.css';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

const GeneralInstructions1 = ({ onClick, user }) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [ giComplete, SetGIComplete ] = useState(false);

  useEffect(() => {
    try {
      if (user) {
        if (user[0].giComplete) {
          SetGIComplete(user[0].giComplete);
        } else {
          SetGIComplete(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [user]);

  // Close popup.
  const popupClick = (e) => {
    setOpen(false);
  }

  return (
    <div className='ap-main-box'>
      <div className="ap-instructions-header">
        Assessment 3: Abilities Profiler
      </div>
      <div className='ap-instructions-subheader'>
        Instructions
      </div>
      <div className="ap-instructions-body">
        This assessment contains six separate parts. Each part has its own instructions
        and time limit. Read and listen carefully to all of the instructions before beginning
        each section as they will help you decide how fast you should work and when it is best
        to guess.
        <br /><br />
        Before you begin each part, you'll have a chance to work a few practice questions.
        <br /><br />
        If you finish before time is up, you may click back to previous questions in the same
        section and check the work you've just completed.
        <br /><br />
        <b>You should have scratch paper and a pencil ready for several of the sections.</b>
      </div>
      <div className="ap-row-2 ap-row-2-practice">
        {giComplete ? (
          <NavLink to='/apassessment' className='ipcomplete__navlink'>
            <button className="ap-instructions-button">
              Continue
            </button>
          </NavLink>
        ) : (
        <button type='button' className="ap-instructions-button" onClick={() => setOpen(o => !o)}>
          Begin
        </button>
        )}
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <div>
            <div className='ipInstructions__popup'>
              <button className='ipInstructions__close' onClick={popupClick}>
                &times;
              </button>
              <div className='ipInstructions__primary' onClick={closeModal}>
                Are You Sure?
              </div>
              <div className="ipInstructions__secondary">
                <button
                  className='ipInstructions__ready'
                  onClick={() => {
                    onClick(GIDisplayStates.APEXPLANATIONVIDEO);
                  }}
                >
                  I'm Ready!! Go Now {'>>'}
                </button>
                <button
                  onClick={() => setOpen(o => !o)}
                  className='ipInstructions__wait'
                >
                  No. I'll wait until later.
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    user: state.firestore.ordered.users
  }
}

export default connect(mapStateToProps, null)(GeneralInstructions1)
