import { 
  arCorrect, vaCorrect, saCorrect, cmCorrect, cpCorrect, fpCorrect,
  arConvertArray, vaConvertArray, saConvertArray, cmConvertArray,
  cpConvertArray, fpConvertArray, apPopulationEDSpecs, apPopulationCCSpecs
} from './CalcConfigs.js';
import firebase from '../../../config/fbConfig.js';
import { calcEuclideanDistance, scoreToPercentile } from '../Functions.js';
import corrcoeff from '../CalcCorrCoef.js';

// Function to calculate the user's AP scores.
export const apScoreCalculate = (uid, ar, va, sa, cm, cp, fp, apResultSubmit, apCalculatedSubmit) => {
  // Function contents go here.
  // Set up percentile variables.
  let arPercentile = null;
  let vaPercentile = null;
  let saPercentile = null;
  let cmPercentile = null;
  let cpPercentile = null;
  let fpPercentile = null;

  // console.log('AP CALCULATE');

  try {

    // Set up percent score variables.
    let vaPercentScore = null;
    let arPercentScore = null;
    let saPercentScore = null;
    let cmPercentScore = null;
    let cpPercentScore = null;
    let fpPercentScore = null;
    
    /*****************************************************/                     
    /*********** Arithemtic Reasoning scoring. ***********/
    /*****************************************************/

    // Map values of the AR test to an array called 'arValues'.
    let arData;
    let count;
    let arScore = 0;
    let arZScore;
    if (ar) {
      arData = ar.arAnswers;
      let arValues = [];

      for(let i = 0; i < Object.keys(arData).length; i++){
        count = i;
        arValues.push({
          id: arData[count].id,
          values: arData[count].values
        });
      }
      
      // Compare arValues to arCorrect. Count up the number of answers that are correct (equal).
      for (let i = 0; i < arValues.length; i++) {
        if (arCorrect[i] === arValues[i].values) {
          arScore = arScore + 1;
        }
      }
      arPercentScore = arScore/arCorrect.length*100;
      
      // Calculate converted AR score.
      let arPartialConvertedScore = 0;
      for (let i = 0; i < arConvertArray.length; i++) {
        if (arScore === i) {
          arPartialConvertedScore = arConvertArray[i];
        }
      }

      // Convert to an equivalent raw score using the equation on page 5 of 'AP-SPTM-deskp.pdf'.
      const arEquivScore = (0.465692*arPartialConvertedScore) + 2.344963;
      
      // Calculate AR z-score.
      arZScore = (arEquivScore - 11.426)/3.511;
      
      // Calculate AR percentile.
      arPercentile = GetZPercent(arZScore);
      arPercentile = Math.round(arPercentile*100);
      if (arPercentile < 1) {
        arPercentile = arPercentile + 1;
      }
      
      // Calculate AR into final converted score with a mean of 100 and a standard deviation of 20.
      // const arConvertedScore = (arZScore*20) + 100;
    }

    /*****************************************************/                     
    /************* Verbal ability scoring. ***************/
    /*****************************************************/

    // Map values of the VA test to an array called 'vaValues'.
    let vaData;
    let vaScore = 0;    // Raw score (# correct).
    let vaZScore;
    if (va) {
      vaData = va.vaAnswers;
      let vaValues = [];
      for(let i = 0; i < Object.keys(vaData).length; i++){
        count = i;
        vaValues.push({
          id: vaData[count].id,
          values: vaData[count].values
        });
      }
      
      // Convert answers into an array, which will retain its order.
      let matchId;
      let tempData;
      let tempDataF;
      let tempDataB;
      
      vaValues.map((key, index) => {
        if (key.values) {
          
          // New data format.
          if (typeof(key.values) === 'string') {
            matchId = vaValues.map(function(item) {
              if (item.id === index) {
                return item.values;
              } else {
                return null;
              }
            });
            tempData = matchId.find(el => el !== null);
            tempDataF = tempData[0] + tempData[2];    // Forward.
            tempDataB = tempData[2] + tempData[0];    // Backward.
            if (tempDataF === vaCorrect[index] || tempDataB === vaCorrect[index]) {
              vaScore = vaScore + 1;
            }
          } else {
            // Old data format. Read in answer. Use map to find the correctly ordered question.
            matchId = vaValues.map(function(item) {
              let searchIndex = index;
              if (item.id === searchIndex) {
                return item.values;
              } else {
                return null;
              }
            });
            tempData = matchId.find(el => el !== null);
            tempDataF = tempData[0] + tempData[1];    // Forward.
            tempDataB = tempData[1] + tempData[0];    // Backward.
            if (tempDataF === vaCorrect[index] || tempDataB === vaCorrect[index]) {
              vaScore = vaScore + 1;
            }
          }
        } else {
          vaScore = vaScore + 0;  // Unanswered question.
        }
        return null;
      });
      vaPercentScore = vaScore/vaCorrect.length*100;
      
      // Calculate converted VA score.
      let vaConvertedScore = 0;
      for (let i = 0; i < vaConvertArray.length; i++) {
        if (vaScore === i) {
          vaConvertedScore = vaConvertArray[i];
        }
      }

      // Calculate VA z-score.
      vaZScore = (vaConvertedScore - 100)/20;
      
      // Calculate VA percentile.
      vaPercentile = GetZPercent(vaZScore);
      vaPercentile = Math.round(vaPercentile*100);
      if (vaPercentile < 1) {
        vaPercentile = vaPercentile + 1;
      }
    }





    /*****************************************************/                     
    /************* Spatial ability scoring. **************/
    /*****************************************************/

    // Map values of the SA test to an array called 'saValues'.
    let saData;
    let saScore = 0;    // Raw score (# correct).
    let saZScore;
    if (sa) {
      saData = sa.saAnswers;
      let saValues = [];
      for(let i = 0; i < Object.keys(saData).length; i++){
        count = i;
        saValues.push({
          id: saData[count].id,
          values: saData[count].values
        });
      }
      
      // Convert answers into an array, which will retain its order.
      let matchId;
      let tempData;
      for(let i = 0; i < saValues.length; i++) {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = saValues.map(function(item) {
          let searchIndex = i;
          if (item.id === searchIndex) {
            return item.values;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);
        if (tempData === saCorrect[i]) {
          saScore = saScore + 1;
        }
      }
      saPercentScore = saScore/saCorrect.length*100;
      
      // Calculate converted SA score.
      let saConvertedScore = 0;
      for (let i = 0; i < saConvertArray.length; i++) {
        if (saScore === i) {
          saConvertedScore = saConvertArray[i];
        }
      }
      
      // Calculate SA z-score.
      saZScore = (saConvertedScore - 100)/20;
      
      // Calculate SA percentile.
      saPercentile = GetZPercent(saZScore);
      saPercentile = Math.round(saPercentile*100);
      if (saPercentile < 1) {
        saPercentile = saPercentile + 1;
      }
    }




    /*****************************************************/                     
    /*************** Computation scoring. ****************/
    /*****************************************************/

    // Map values of the CM test to an array called 'cmValues'.
    let cmData;
    let cmScore = 0;    // Raw score (# correct).
    let cmZScore;
    if (cm) {
      cmData = cm.cmAnswers;
      let cmValues = [];
      for(let i = 0; i < Object.keys(cmData).length; i++){
        count = i;
        cmValues.push({
          id: cmData[count].id,
          values: cmData[count].values
        });
      }
      
      // Convert answers into an array, which will retain its order.
      let matchId;
      let tempData;
      let cmCorrectCount = 0;
      for(let i = 0; i < cmValues.length; i++) {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = cmValues.map(function(item) {
          let searchIndex = i;
          if (item.id === searchIndex) {
            return item.values;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);
        if (tempData === cmCorrect[i]) {
          cmScore = cmScore + 1;    // +1 point if the answer is correct.
          cmCorrectCount = cmCorrectCount + 1;
        } else if (!tempData) {
          // cmScore = cmScore;        // Do nothing if the question is not answered.
        } else {
          cmScore = cmScore - 0.25; // -1/4 points if the answer is incorrect.
        }
      }
      cmPercentScore = cmCorrectCount/cmCorrect.length*100;

      // Round cmScore to the nearest next highest integer.
      cmScore = Math.ceil(cmScore);

      // Set lowest possible score to -4, not -10 (can be an area of improvement in the future?).
      if ( cmScore < -4 ) {
        cmScore = -4;
      }
      // Calculate partial converted CM score.
      // Table 4 in Appendix C in AP-SPTM-deskp.pdf)
      let cmPartialConvertedScore = 0;
      for (let i = 0; i < 45; i++) {
        if (cmScore === i-4) {
          cmPartialConvertedScore = cmConvertArray[i];
        }
      }

      // Calculate CM raw score.
      // (page 5 in AP-SPTM-deskp.pdf)
      // const cmRawScore = (0.467361 * cmPartialConvertedScore) - 15.135831;
      const cmRawScore = (0.467361 * cmPartialConvertedScore) - 9;
      
      // Calculate CM z-score.
      // (page 5 in AP-SPTM-deskp.pdf)
      cmZScore = (cmRawScore - 23.092) / 6.725;

      // Calculate CM converted score.
      // const cmConvertedScore = (cmZScore * 20) + 100;
      
      // Calculate CM percentile.
      cmPercentile = GetZPercent(cmZScore);
      cmPercentile = Math.round(cmPercentile*100);
      if (cmPercentile < 1) {
        cmPercentile = cmPercentile + 1;
      }
    }




    /*****************************************************/                     
    /*********** Clerical perception scoring. ************/
    /*****************************************************/

    // Map values of the CP test to an array called 'cpValues'.
    let cpData;
    let counter = 0;
    let cpScore = 0;    // Raw score (# correct).
    let cpZScore;
    if (cp) {
      cpData = cp.cpAnswers;
      let cpValues = [];
      
      if (cpData.length < 20) { // Old method.
        for(let i = 0; i < Object.keys(cpData).length; i++){
          for(let j = 0; j < 5; j++) {
            count = i;
            cpValues.push({
              id: counter,
              values: cpData[count].values[j]
            });
            counter = counter + 1;
          }
        }
      } else { // New data structure (90 individual answer options).
        cpValues = cpData.map((key, index) => {
          const obj = {
            id: index,
            values: key.values
          }
          return obj;
        });
      }
      
      // Convert answers into an array, which will retain its order.
      let matchId;
      let tempData;
      let cpCorrectCount = 0;
      cpValues.map((key, index) => {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = cpValues.map(function(item) {
          if (item.id === index) {
            return item.values;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);
        if (tempData === cpCorrect[index]) {
          cpScore = cpScore + 1;    // +1 point if the answer is correct.

          cpCorrectCount = cpCorrectCount + 1;
        } else if (!tempData) {
          // cpScore = cpScore;        // Do nothing if the question is not answered.
        } else {
          cpScore = cpScore - 1;    // -1 point if the answer is incorrect.
        }
        return null;
      });
      cpPercentScore = cpCorrectCount/cpCorrect.length*100;

      // Set lowest possible score to -8, not -90 (can be an area of improvement in the future?).
      if ( cpScore < -8 ) {
        cpScore = -8;
      }
      
      // Calculate partial converted CP score.
      let cpConvertedScore = 0;
      for (let i = 0; i < cpConvertArray.length; i++) {
        if (cpScore === i-8) {
          cpConvertedScore = cpConvertArray[i];
        }
      }

      // Calculate CP z-score.
      cpZScore = (cpConvertedScore - 100) / 20;
      
      // Calculate CP percentile.
      cpPercentile = GetZPercent(cpZScore);
      cpPercentile = Math.round(cpPercentile*100);
      if (cpPercentile < 1) {
        cpPercentile = cpPercentile + 1;
      }
    }

    /*****************************************************/                     
    /************* Form perception scoring. **************/
    /*****************************************************/

    // Map values of the FP test to an array called 'fpValues'.
    let fpData;
    let fpScore = 0;    // Raw score (# correct).
    let fpZScore;
    if (fp) {
      fpData = fp.fpAnswers;
      let fpValues = [];

      for(let i = 0; i < Object.keys(fpData).length; i++){
        count = i;
        fpValues.push({
          id: fpData[count].id,
          values: fpData[count].values
        });
      }
      
      // Convert answers into an array, which will retain its order.
      let matchId;
      let tempData;
      let fpCorrectCount = 0;
      for(let i = 0; i < fpValues.length; i++) {
        // Read in answer. Use map to find the correctly ordered question.
        matchId = fpValues.map(function(item) {
          let searchIndex = i;
          if (item.id === searchIndex) {
            return item.values;
          }
          else {
            return null;
          }
        });
        tempData = matchId.find(el => el !== null);
        if (tempData === fpCorrect[i]) {
          fpScore = fpScore + 1;    // +1 point if the answer is correct.

          fpCorrectCount = fpCorrectCount + 1;
        } else if (!tempData) {
          // fpScore = fpScore;        // Do nothing if the question is not answered.
        } else {
          fpScore = fpScore - 1/3;  // -1/3 points if the answer is incorrect.
        }
      }
      fpPercentScore = fpCorrectCount/fpCorrect.length*100;

      // Round fpScore to the nearest integer to account for decimals.
      fpScore = Math.round(fpScore);

      // Set lowest possible score to -2, not -14 (can be an area of improvement in the future?).
      if ( fpScore < -2 ) {
        fpScore = -2;
      }
      
      // Calculate partial converted FP score.
      let fpConvertedScore = 0;
      for (let i = 0; i < fpConvertArray.length; i++) {
        if (fpScore === i-2) {
          fpConvertedScore = fpConvertArray[i];
        }
      }

      // Calculate FP z-score.
      fpZScore = (fpConvertedScore - 100) / 20;
      
      // Calculate FP percentile.
      fpPercentile = GetZPercent(fpZScore);
      fpPercentile = Math.round(fpPercentile*100);
      if (fpPercentile < 1) {
        fpPercentile = fpPercentile + 1;
      }
    }

    // Submit data to Firebase.
    if (arPercentile > 0 && vaPercentile > 0 && saPercentile > 0 && cmPercentile > 0 && cpPercentile > 0 && fpPercentile > 0) {
      apResultSubmit({
        arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile,
        arPercentScore, vaPercentScore, saPercentScore, cmPercentScore, cpPercentScore, fpPercentScore, uid
      }); // Sends results to Firestore.
    }
    
    // Set 'apCalculated' to true in user's document.
    apCalculatedSubmit(uid);  // Sends calculated = true boolean to Firestore.

    return [arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile];


  } catch (error) {
    console.log('Error: ', error);
  }
}

// Function to convert Z-score to percentile.
function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);

  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
}

// Function to calculate the user's AP jobs.
export const apJobOverlapCalculate = async (
  uid, arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile
) => {
  // Function contents go here.
  try {
    // Gather data by calling the various async functions defined below.
    if (arPercentile !== null && vaPercentile !== null && saPercentile !== null && cmPercentile !== null && cpPercentile !== null && fpPercentile !== null) {
      // Only call functions and pass data.

      // Generate an array of O*Net doc IDs.
      const docIDs = await generateOnetDocIds();
      const filledDocs = await fetchAllOnetDocs(docIDs, arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile);

      // Sort by calcOutput (raw Euclidean distance).
      // const sortedResults = filledDocs.sort((a, b) => a.calcOutput < b.calcOutput ? 1 : -1);
      
      // Sort by apAverage (which is a percentile score).
      const sortedResults = filledDocs.sort((a, b) => a.apAverage > b.apAverage ? 1 : -1);

      // const shortResults = await grabTopResults(sortedResults);

      sortedResults.reverse().map((element, index) => {
        element.rank = index;		// Add 'rank' element to the object.
        return element;
      });

      await submitSortedResults(sortedResults, uid);

      return sortedResults;
    }
  } catch (error) {
    console.log('Error: ', error);
  }
}

// Fetch a single Onet doc from the Firebase database.
async function fetchOnetDoc(docId) {
  const onetDoc = await firebase.firestore().collection('onetData').doc(docId).get();
  const onetDocData = onetDoc.data();
  return onetDocData;
}

// Submit the top 'resultCount' number of results to Firebase.
async function submitSortedResults(sortedResults, uid) {
  // const connection = await firebase.firestore().collection('apresults').doc(auth.uid).set({
  await firebase.firestore().collection('apresults').doc(uid).set({
      apSortedResults: sortedResults
  }, { merge: true });
  
  // Add a boolean to the user's document called 'apJobsCalculated' and set it to true. This is used
  // in AllResults.js to determine whether to run the overlap calculations.
  await firebase.firestore().collection('users').doc(uid).set({
    apJobsCalculated: true
  }, { merge: true });
}

// Generate O*Net doc IDs.
async function generateOnetDocIds() {
  //E000 - E872
  const onetDocIds = [];
  const maxDocCount = 872;
  for (let i = 0; i < maxDocCount; i++) {
      const docId = `E${String(i).padStart(3, '0')}`;
      onetDocIds.push(docId);
  }
  const data = onetDocIds;

  return data;
}

// Calculate the Euclidean distance for each occupation.
async function calcDoc(onetDoc, ...Percentiles) {
  // Assessment types.
  const assessmentTypes = ['ar', 'va', 'sa', 'cm', 'cp', 'fp'];
  
  // Define a map (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Addition_assignment).
  // This maintains its order, unlike an array and unlike an object.
  const apDataMap = new Map();

  // Set up apDataMap for each assessment.
  assessmentTypes.forEach(type => {
    const onetVal = scoreToPercentile(onetDoc[`ap${type.toUpperCase()}`], type)*100;
    const userVal = Percentiles[assessmentTypes.indexOf(type)];
    apDataMap.set(type, { onetVal, userVal });
  });

  // Calculate Euclidean distance.
  const apEuclideanDistance = calcEuclideanDistance(apDataMap);

  ///// EXPERIMENTAL SECTION /////
  // Convert Euclidean distance to a percentile.
  // Convert apArrayCalcOutput to z-score.
  const apMean = apPopulationEDSpecs.mean;  // Mean AP Euclidean distance based on beta tester results.
  const apDev = apPopulationEDSpecs.standardDeviation;    // Standard deviation of the AP Euclidean distance based on beta tester results.
  const apZScore = (apEuclideanDistance - apMean)/apDev;  // Calculate AP Z-score.
  const apPercentile = 1 - GetZPercent(apZScore);           // Calculate AP percentile.

  // Calculate AP correlation coefficient.
  const apCorrelationCoefficient = corrcoeff(apDataMap);

  // Convert AP correlation coefficient to a percentile.
  const apCCMean = apPopulationCCSpecs.mean;
  const apCCDev = apPopulationCCSpecs.standardDeviation;
  const apCCZscore = (apCorrelationCoefficient - apCCMean)/apCCDev;
  const apCCPercentile = GetZPercent(apCCZscore);

  // Calculate the average AP score from Euclidean distance and correlation coefficient.
  const apAverage = (apPercentile + apCCPercentile)/2;

   // Calculate the match score, which is used in the occupation grid
  // to determine how the user scored against the broader population.
  const matchScore = apAverage;


  // Return Euclidean distance.
  return [apEuclideanDistance, apCorrelationCoefficient, apAverage, matchScore];
}

// Fetch all O*Net docs.
async function fetchAllOnetDocs(docIdArray, arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile) {
  try {
    let onetDoc = null;
    const returnVal = await Promise.all(docIdArray.map(async function stuff (id) {
      onetDoc = await fetchOnetDoc(id);
      if (!onetDoc) {
        console.error("Encountered error fetching doc, ID: ", id);
        return null;
      }
      if (!onetDoc.UID) {
        console.error("Encountered error: Doc does not have UID, Doc: ", onetDoc);
        return null;
      }
      if (onetDoc.UID !== id) {
        console.error({
          message: "Encountered error: the fetched doc id does not match the input id, Doc: ", 
          onetDoc,
          id
        });
        return null;
      }

      // Run Euclidean distance, correlation coefficient, and averaging calculations.
      const [calcOutput, correlationCoefficient, apAverage, matchScore] = await calcDoc(onetDoc, arPercentile, vaPercentile, saPercentile, cmPercentile, cpPercentile, fpPercentile);

      return {
        UID: onetDoc.UID,
        calcOutput,                           // Euclidean distance.
        matchScore,
        occupation: onetDoc.occupation,
        onetCode: onetDoc.code,
        jobZone: onetDoc.jobZone,
        brightOutlook: onetDoc.brightOutlook,
        correlationCoefficient,               // Correlation coefficient.
        apAverage                             // Combo of ED and CC scores.
      }
    }));        

    // Filter out any null elements.
    const filteredVals = returnVal.filter((element)=>element!==null);

    return filteredVals;
  }
  catch(error) {
    console.error("Encountered error processing O*Net docs: ", error);
  }
  return []
}