import React from 'react';
import { NavLink } from 'react-router-dom';
import './Results.css';

const SignedOut = () => {
  return (
    <div className='incomplete'>
      <div className="incomplete__title">
        Exciting things coming soon!
      </div>
      <div className="incomplete__subtitle">
        <NavLink to='/signin' className='incomplete__link'>
          Sign In
        </NavLink>
        <span>&nbsp;or&nbsp;</span>
        <NavLink to='/signup' className='incomplete__link'>
          Create an Account
        </NavLink>
        <span>&nbsp; to get started.</span>
      </div>      
    </div>
  )
}

export default SignedOut