import authReducer from "./authReducer";
import submitReducer from "./submitReducer";
import userReducer from "./userReducer";
import orderReducer from './orderReducer';
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  userState: userReducer,
  auth: authReducer,
  submit: submitReducer,
  order: orderReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
