import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Redirect, NavLink } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import './SignIn.css';
import { AiOutlineDoubleRight } from 'react-icons/ai';
import { useEffect } from 'react';
import Checkbox from 'react-custom-checkbox';
import * as Icon from 'react-icons/fi';

// Import image.
const exclaImage = `/images/signInImages/exclamation_icon.png`;

const SignIn2 = ({ authError, signIn, auth }) => {
  const [ state, setState ] = useState({
    email: '',
    password: '',
  });
  const [ showPassword, setShowPassword ] = useState(false);
  const [ staySigned, setStaySigned ] = useState(true);
  const [ location, setLocation ] = useState(useLastLocation());
  const [ visible, setVisible ] = useState(false);
  
  useEffect(() => {
    if (location) {
      if (location.pathname !== '/signin' && authError) {
        window.location.reload();
      }
    }
    setVisible(true);
  },[location, authError])

  useEffect(() => {
    setVisible(false);
  }, [])

  // If auth.uid does exist, then return a redirect (route guarding).
  if (auth.uid) return <Redirect to='/home' />

  // Fires when a user changes an input field. This is the proper way to update a single element of an object
  // using 'useState' in functional components.
  const handleChange = (e) => {
      setState(prevState => {
      return {
          ...prevState,
          [e.target.id]: e.target.value
      }
      });
  }

  // When the user submits the form.
  const handleSubmit = (e) => {
    e.preventDefault();
      setLocation(null);    // Set location to null when user clicks submit button to prevent the page from reloading in this case.
      signIn(state);

  }

  // When the user clicks the 'Show Password' option.
  const showPasswordChange = () => {
    setShowPassword(!showPassword);
  }
  
  // When the user clicks the 'Stay Signed In' option.
  const staySignedChange = () => {
    setStaySigned(!staySigned);
  }

  return (
    <div className="signin">
      <p className="signin__header">
          Sign In
      </p>
      <p className="signin__subheader">
          or&nbsp;
          <NavLink to='/signup' className='signin__link'>
              Create an Account
          </NavLink>
      </p>
      <form onSubmit={handleSubmit} className='sigin__form'>
        <div>
          <input
            className="signin__input"
            type='email'
            id='email'
            onChange={handleChange}
            placeholder='Email'
          />
        </div>
        <div className='signin__error'>
          { visible && authError ?
            <div className='signin__errorMessage'>
              <img src={process.env.PUBLIC_URL + exclaImage} alt="exclamation" className='signin__exclamation' />
              <div className="signin__errorText">
                Wrong password. Try again or click Forgot Password to reset it.
              </div>
            </div>
          :
            null
          }
        </div>
        <input
          className="signin__input signin__input-password"
          type={showPassword ? 'text' : 'password'}
          id='password'
          onChange={handleChange}
          placeholder='Password'
          style= {{ border: visible && authError ? 'red 1px solid' : '' }}
        />
        <div className='signin__options'>
          <div className='signin__selections'>
            <div className='signin__option'>
              <Checkbox
                checked={showPassword}
                onChange={showPasswordChange}
                className='signin__check'
                icon={
                  <div
                    className='signin__icon'
                  >
                    <Icon.FiCheck color="white" size={15} />
                  </div>
                }
                borderColor="#004e59"
                borderRadius={20}
                style={{ overflow: "hidden" }}
                size={18}
                label="Show Password"
              />
            </div>
            <div className='signin__option'>
              <Checkbox
                checked={staySigned}
                onChange={staySignedChange}
                className='signin__check'
                icon={
                  <div
                    className='signin__icon'
                  >
                    <Icon.FiCheck color="white" size={15} />
                  </div>
                }
                borderColor="#004e59"
                borderRadius={20}
                style={{ overflow: "hidden" }}
                size={18}
                label="Stay Signed In"
              />
            </div>
            <div className='signin__option'>
              <NavLink to='/forgotpassword' className='signin__forgot'>	{/*TODO: Create a proper 'forgot password' page (see GSE code). */}
                  Forgot Password?
              </NavLink>
            </div>
          </div>
          <div className='signin__button-box'>
            <button className='signin__button' onClick={handleSubmit}>
              {/* {'>>'} */}
              <AiOutlineDoubleRight  className='signin__arrow' />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};
  
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SignIn2);
