import React from 'react';
import './Error.css';

const bookPic = `/images/errorImages/404error_cropped.jpeg`;

const Error = () => {
  return (
    <div className='error'>
      <div className="error__title">
        Uh-Oh!
      </div>
      <div className="error__body">
        Now how did we get here...
      </div>
      <img src={bookPic} alt="book" className='error__image' />
      <div className="error__body">
        Looks like you've found a missing page.
      </div>
    </div>
  )
}

export default Error
