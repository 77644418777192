export const WIPDisplayStates = {
  INSTRUCTIONS: "INSTRUCTIONS",
  INSTRUCTIONS_Q1: "INSTRUCTIONS_Q1",
  INSTRUCTIONS_Q2: "INSTRUCTIONS_Q2",
  INSTRUCTIONS_Q3: "INSTRUCTIONS_Q3",
  INSTRUCTIONS_Q4: "INSTRUCTIONS_Q4",
  INSTRUCTIONS_Q5: "INSTRUCTIONS_Q5",
  INSTRUCTIONS_FINAL: "INSTRUCTIONS_FINAL",
  ASSESSMENT: "ASSESSMENT",
  COMPLETE: "COMPLETE",
  LOADING: "LOADING",
  DEMOGRAPHIC_INFORMATION: 'DEMOGRAPHIC_INFORMATION'
};

export const practiceInitialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''}
]

// Initial state.
export const initialState = [
  {id: 0, values: ''},
  {id: 1, values: ''},
  {id: 2, values: ''},
  {id: 3, values: ''},
  {id: 4, values: ''},
  {id: 5, values: ''},
  {id: 6, values: ''},
  {id: 7, values: ''},
  {id: 8, values: ''},
  {id: 9, values: ''},
  {id: 10, values: ''},
  {id: 11, values: ''},
  {id: 12, values: ''},
  {id: 13, values: ''},
  {id: 14, values: ''},
  {id: 15, values: ''},
  {id: 16, values: ''},
  {id: 17, values: ''},
  {id: 18, values: ''},
  {id: 19, values: ''}
];

export const values = [
  'A',
  'B',
  'C',
  'D'
];