import React from 'react';
import './OccupationGrid.css';
import OccupationGrid from './OccupationGrid';
import OccupationGridUncalculated from './OccupationGridUncalculated';
import LoadingPage from '../../../pages/loadingPage/LoadingPage';
import TrifectaCalculate from '../../trifecta/TrifectaCalculate';

const OccupationGridFrontPage = ({ user, apresults, ipresults, wipresults, trifectaresults }) => {
  return (
    <div>
      {
        user ? (  // Check that the user is logged in.
          // Check that all results are calculated.
          (user[0].overlapCalculated === true &&
          user[0].apCalculated === true && user[0].apJobsCalculated === true &&
          user[0].ipCalculated === true && user[0].ipJobsCalculated === true &&
          user[0].wipCalculated === true && user[0].wipJobsCalculated === true) ? (
          <>
            <OccupationGrid apresults={apresults} ipresults={ipresults} wipresults={wipresults} trifectaresults={trifectaresults} />

            {user[0].overlapCalculated !== true &&
            user[0].apCalculated === true && user[0].apJobsCalculated === true &&
            user[0].ipCalculated === true && user[0].ipJobsCalculated === true &&
            user[0].wipCalculated === true && user[0].wipJobsCalculated === true &&
              <TrifectaCalculate apresults={apresults} ipresults={ipresults} wipresults={wipresults} />
            }
          </>
          ) : (
            <OccupationGridUncalculated />
          )
        ) : (
          <LoadingPage /> // Wait for user auth to load, or display if user isn't logged in.
        )
      }
    </div>
  )
}

export default OccupationGridFrontPage;