import React, { useEffect } from 'react';

const Timer = ({ counter, setCounter }) => {
  // Prepend '0' for one digit numbers. For that the number
  // has to be converted to a string, as numbers don't have a length method.
  const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = time => {
    // Convert seconds into minutes and take the whole part.
    const minutes = Math.floor(time / 60);

    // Get the seconds left after converting minutes.
    const seconds = time % 60;

    // Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter, setCounter]);

  return (
    <div className='timer'>
      {counter === 0 ? '' : <div>{format(counter)}</div>}
    </div>
  )
}

export default Timer;
