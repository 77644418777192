import React from 'react';
import './Footer.css';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
	const location = useLocation();	// location.pathname is path

	// const numCols = 4;

	const colHeads = [
		'CONTACT US',
		'SERVICES',
		'INFORMATION',
		'ACCOUNT'
	];

	const col1 = [
		'(386) 244-9104',
		'hi@knowtelos.com',
		'Message Us'
	];
	// const col1_click = [false, false, true];
	const col1_url = [
		'/aboutus',
		'/aboutus',
		'/aboutus'
	]

	const col2 = [
		'Ordering & Payment',
		'Assessments',
		'Your Results',
		'Top Job Descriptions'
	];
	const col2_url = [
		'/payment',
		'/assessments',
		'/results',
		'/topjobs'
	]

	const col3 = [
		'About Us',
		'The Science of Telos',
		'Privacy Policy',
		'Terms & Conditions'
	];
	const col3_url = [
		'/aboutus',
		'/science',
		'/privacypolicy',
		'/termsandconditions'
	]

	const col4 = [
		'Manage Your Account'
	];
	const col4_url = [
		'/profile'
	]

	const colNames = [
		col1,
		col2,
		col3,
		col4
	]

	const urlNames = [
		col1_url,
		col2_url,
		col3_url,
		col4_url
	]

	return (
		<div className={"footer " + (location.pathname === '/results' ? 'footer-dark' : '')}>
			<div className="footer__top">
				{colHeads.map((element, index) => (
					<div className="footer__column" key={index}>
						<div className="footer__h1">{element}</div>
						{colNames[index].map((val, ind) => (
							(index === 0 && ind < 2) ? (
								<div className="footer__item footer__item-nolink" key={ind}>
									{val}
								</div>
							) : (
								(index === 2 && ind > 1) ? (
									<NavLink to={urlNames[index][ind]} className="footer__item" key={ind} target="_blank">
									{val}
									</NavLink>
								) : (
									<NavLink to={urlNames[index][ind]} className="footer__item" key={ind}>
									{val}
									</NavLink>
								)
							)
						))}
					</div>
				))}
			</div>
			<div className="footer__bottom">
				<div className="footer__hr" />
				<div className="footer__item footer__item-nolink">
					Copyright &copy; {new Date().getFullYear()} Telos Technologies LLC. All rights reserved.
				</div>
			</div>
				{/*
			<div className="footer-bottom">
				<p className="text-md-center">
						&copy;{new Date().getFullYear()} Telos
				</p>
			</div> */}
		</div>
	)
}

export default Footer
