import React, { useState } from 'react';
import './AboutUs.css';
import { useSpring, animated } from 'react-spring';
import { connect } from 'react-redux';
import { commentSubmit } from '../../../store/actions/submitActions';
import Popup from 'reactjs-popup';
import ReCAPTCHA from 'react-google-recaptcha';

// require('dotenv').config();

const AboutUs = ({ commentSubmit }) => {
  const [ open, setOpen ] = useState(false);
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ comment, setComment ] = useState('');
  const [ recaptcha, setRecaptcha ] = useState(false);
  // const [ loading, setLoading ] = useState(false);
  
  const [popupOpen, setPopupOpen] = useState(false);
  const closeModal = () => setOpen(false);

  // Close popup.
  const popupClick = (e) => {
    setPopupOpen(false);
  }

  // ReCAPTCHA funciton.
  const onChange = (value) => {
    // console.log('Captcha value: ', value);
    setRecaptcha(true);
  }

  // Pictures.
  const bretPic = `/images/aboutUsImages/Bret.png`;
  const elizabethPic = `/images/aboutUsImages/Elizabeth.png`;
  const peterPic = `/images/aboutUsImages/Peter.png`;
  const mailmanPic = `/images/aboutUsImages/Mailman.png`;

  const names = [
    'Bret Mahoney',
    'Elizabeth Weigel',
    'Peter Weigel'
  ];

  const pics = [
    bretPic,
    elizabethPic,
    peterPic
  ];

  const onClick = (e) => {
    setOpen(!open);
  }

  const openAnimation = useSpring({
    config: { duration: "300" },
    from: { opacity: "0", maxHeight: "5px" },
    to: { opacity: "1", maxHeight: open ? "625px" : "0px" },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    
    const text = `Comment from ${name} at ${email}:\n\n${comment}`;
    
    commentSubmit({
      to: process.env.REACT_APP_MAIL_TO,
      subject: subject,
      text: text
    });
    
    setName('');
    setEmail('');
    setSubject('');
    setComment('');
    
    // Set 'popupOpen' state to true.
    setPopupOpen(true);
  }

  return (
    <div className='about__container'>
      {popupOpen === true
      && 
        <div>
          <Popup open={popupOpen} closeOnDocumentClick onClose={closeModal}>
            <div>
              <div className='about__popup'>
                <button className='about__popupClose' onClick={popupClick}>
                  &times;
                </button>
                <div className='about__popupPrimary' onClick={closeModal}>
                  Thank you!<br />Your message has been sent.
                </div>
              </div>
            </div>
          </Popup>
        </div>
      }
      <div className="about__top">
        <h3>
          OUR MISSION
        </h3>
        <h1>
          So Why Do Any of This?
        </h1>
        <p>
          The decision to create Telos evolved over time. We had personally struggled with a lack
          of career guidance and making career changes ourselves. We had heard of friend after
          friend who were in the same boat. So we decided to do something about it. We set to work
          creating a one stop shop system for career guidance using valid and reliable methods. We
          combined assessments so that the overlapping areas would provide the best results for
          a path to a career that would help a person be the most satisfied and fulfilled. That’s our
          hope anyway. We believe true fulfillment comes from a variety of things and ways you live
          your life, but what you do for work is certainly a large component. In the future, we hope to
          edit and expand results and resources, to give people to best experience possible.
        </p>
      </div>
      <div className="about__middle">
        <h3>
          OUR TEAM
        </h3>
        <div className="about__abouts">
          {names.map((element, index) => (
            <div className='about__about' key={index}>
              <div className="about__pic">
                <img src={pics[index]} alt={`pic_${index}`} className='about__pic-self' />
              </div>
              <h1 style={{ textAlign: 'center' }}>
                {element}
              </h1>
              {index === 0 && 
              <div className='about__info'>
                <p>
                  {/* Bret is a jack of all trades. While he often
                  uses his leadership expertise in the
                  healthcare industry, he also builds decks,
                  creates podcasts, does video editing, and is
                  the best trivia guru you will meet. */}
                  Bret is a leadership coach, organizational designer, and scented candle
                  enthusiast who believes that we do our best work when we are challenged in a
                  safe and collaborative environment. His creative energy lives in his LEGO
                  collection and drawing connections between narrative fiction and operational
                  leadership strategies. 
                </p>
              </div>
              }
              {index === 1 && 
              <div className='about__info'>
                <p>
                  Elizabeth is an I-O Psychologist and
                  Graphic Designer who has a passion for
                  helping people (Relationships are #2 on
                  her Values Profiler results). She loves
                  exploring new ways of doing things,
                  jasmine tea, and a good statistical
                  method.
                </p>
              </div>
              }
              {index === 2 && 
              <div className='about__info'>
                <p>
                  Peter is an engineer with a PhD in photonics and a growing appreciation for
                  web development. He enjoys thinking about useful yet impossible
                  technologies, and figuring out how to turn them into realities.
                </p>
              </div>
              }
            </div>
          ))}
        </div>
      </div>
      <div className="about__bottom">
        <h3>
          CONTACT
        </h3>
        <div className="about__bottom-content">
          <img src={mailmanPic} alt="mailman-pic" className='content__pic-self' />
          <div className="about__bottom-left">
            <h1>
              We're Happy to Hear Your Comments and Questions
            </h1>
            <h2>
              General
            </h2>
            <a href="mailto:hi@knowtelos.com">
              hi@knowtelos.com
            </a>
            <h2>
              Careers
            </h2>
            <a href="mailto:workwithus@knowtelos.com">
              workwithus@knowtelos.com
            </a>
            <div
              style={{
                height:'.5px',
                backgroundColor: 'grey',
                width: '60%',
                maxWidth: '300px',
                marginTop: '25px',
                marginBottom: '25px'
              }}>
            </div>
            <h2>
              (386) 244-9104
            </h2>
            <button onClick={onClick} className='about__button'>
              <h4>
                Send us a message here
              </h4>
            </button>
            <animated.div
              style={openAnimation}
              className='about__collapsible'
            >
              <form className='about__form' onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={name}
                  placeholder='Name'
                  name='name'
                  onChange={e => setName(e.target.value)}
                  className='about__input'
                />
                <input
                  type="email"
                  value={email}
                  placeholder='Email'
                  name='email'
                  onChange={e => setEmail(e.target.value)}
                  className='about__input'
                />
                <input
                  type="text"
                  value={subject}
                  placeholder='Subject'
                  name='subject'
                  onChange={e => setSubject(e.target.value)}
                  className='about__input'
                />
                <textarea
                  type="text"
                  value={comment}
                  placeholder='Write Your Message'
                  name='comment'
                  onChange={e => setComment(e.target.value)}
                  className='about__input about__input-long'
                />
                <div className='about__recaptcha'>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                  />
                </div>
                <input
                  type='submit'
                  value='Send'
                  className={'about__submit ' + ((!name || !email || !subject || !comment || !recaptcha) ? ('about__submit-disabled') : (''))}
                  disabled = {
                    (!name || !email || !subject || !comment || !recaptcha) ? (true) : (false)
                  }
                />
              </form>
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    commentSubmit: (result) => dispatch(commentSubmit(result))
  }
}

export default connect(null, mapDispatchToProps)(AboutUs)
