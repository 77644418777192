import React, { useEffect, useState } from 'react';
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import IPChartNoData from '../IPChartNoData';
import IPCalculate from '../ip/IPCalculate';

import IPChart2 from '../IPChart2';


const Interests = ({ user, ip, ipresults }) => {
  const [ ipData, setIPData ] = useState(null);

  useEffect(() => {
    // IP results.
    try {
      if (ipresults && ipresults.length > 0 && (user[0].ipCalculated === true)) {
        let rAnswers = [];
        let iAnswers = [];
        let aAnswers = [];
        let sAnswers = [];
        let eAnswers = [];
        let cAnswers = [];

        if (ipresults[0].rAnswers && ipresults[0].iAnswers && ipresults[0].aAnswers && ipresults[0].sAnswers && ipresults[0].eAnswers && ipresults[0].cAnswers) {
          rAnswers = ipresults[0].rAnswers;
          iAnswers = ipresults[0].iAnswers;
          aAnswers = ipresults[0].aAnswers;
          sAnswers = ipresults[0].sAnswers;
          eAnswers = ipresults[0].eAnswers;
          cAnswers = ipresults[0].cAnswers;
        }
        
        const tempIPData = [
          { name: 'Realistic', color: '#F5EA61', value: rAnswers, id: 0},
          {name: 'Investigative', color: '#93D500', value: iAnswers, id: 1},
          {name: 'Artistic', color: '#007960', value: aAnswers, id: 2},
          {name: 'Social', color: '#009ADE', value: sAnswers, id: 3},
          {name: 'Enterprising', color: '#8CB7C9', value: eAnswers, id: 4},
          {name: 'Conventional', color: '#C2A6E1', value: cAnswers, id: 5}
        ];
        setIPData(tempIPData);
      }
    } catch (error) {
      console.log('Error: ', error);
    }

  }, [ipresults, user]);

  return (
    <div className='ipChart2__container'>
      {
        user ? (  // Check that the user is logged in.
          user[0].ipComplete ? ( // Check that the user has completed this assessment.
            (user[0].ipCalculated === true && user[0].ipJobsCalculated === true) ? ( // Check that the user's results have been calculated.
              ipData ? ( // Check that the user's results are loaded.
                <IPChart2 ipData={ipData} />
              ) : (
                <LoadingPage /> // Wait for results to load.
              ) 
            ) : (
              ip ? ( // Check for assessment answers.
                <IPCalculate ip={ip} />  // Calculate results.
              ) : (
                <LoadingPage /> // Wait for assessment answers to load.
              )
            )
          ) : (
            <IPChartNoData /> // Show if the user hasn't completed this assessment.
          )
        ) : (
          <LoadingPage /> // Wait for user auth to load, or display if user isn't logged in.
        )
      }
    </div>
  )
}

export default Interests;