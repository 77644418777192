import React, { useState, useEffect } from 'react';
// import IPMultiChoice from './IPMultiChoice';
import IPMultiChoice2 from './IPMultiChoice2';
import { connect } from 'react-redux';
import { ipSubmit, ipCompleteSubmit } from '../../../store/actions/submitActions';
// import { Container, Row, Col } from 'react-bootstrap';
import './IPAssessment.css';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { WIPDisplayStates, initialState } from './Configs';
import firebase from '../../../config/fbConfig';
// import { compose } from 'redux';
// import { firestoreConnect } from 'react-redux-firebase';

const IPAssessment = ({ ipSubmit, setDisplay, ipCompleteSubmit, currentPromptId, auth }) => {
  // Create question number counter.
  const [count, setCount] = useState(null);
  // Total number of questions.
  const numTotalQuestions = 60;

  const [ ipAnswers, setIPAnswers ] = useState([...initialState]);

  // Most recent question answered.
  const [ last, setLast ] = useState(0);
  
  // Watch currentPromptId for changes, update initial display order upon change.
  useEffect(() => {
    let isMounted = true; // Add boolean to handle no-op warning.
    // Get data from this user's 'ip' document, if it exists.
    const getData = firebase.firestore().collection('ip').doc(auth.uid).get().then((doc) => {
      if (isMounted) {
        let fbIPAnswers = null; // Firebase answers.
        if (doc.exists) {
          fbIPAnswers = doc.data().ipAnswers;
        }
        let filledIPAnswers = null;
        let countValue = 0;
        try {
          if (fbIPAnswers) {
            filledIPAnswers = fbIPAnswers.filter(e => e.values !== "");
            countValue = Math.round((Object.keys(filledIPAnswers).length + 1)/12) - 1;
          } else {
            filledIPAnswers = null;
            countValue = 0;
          }
          if (countValue < 0) {
            countValue = 0;
          }
          // Only update the count if the page has been reloaded, otherwise, allow count to get updated
          // when the user clicks the forward and back buttons.
          if (count === null) {
            setCount(countValue);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
        return [
          {
            id: 'ip',
            data: fbIPAnswers
          }
        ];
      }
    });

    const output = () => {
      getData.then((result) => {
        if (isMounted) {
          // User's stored data in Firebase.
          let storedResults = result[0].data;

          // Filled data.
          let filledIPAnswers = null;

          try {
            if (storedResults){
              filledIPAnswers = result[0].data.filter(e => e.values !== "");
            } else {
              filledIPAnswers = null;
            }
          } catch (error) {
            console.log('Error: ', error);
          }

          // Only set 'last' if the page has been reloaded, otherwise 'last' will be set when the user
          // selects an answer to a question.
          if (count === null) {
            try {
              if (storedResults) {
                setLast(filledIPAnswers.length);
              } else {
                setLast(0); // If no results are stored in Firebase.
              }
            } catch (error) {
              console.log('Error: ', error);
            }
          }

          // Combine Firebase stored answers with ipAnswers state array.
          let currentIPAnswers = null;
          try {
            if (filledIPAnswers) {
              // currentIPAnswers = Object.values([...ipAnswers, ...filledIPAnswers].reduce((result, {
              currentIPAnswers = Object.values([...initialState, ...filledIPAnswers].reduce((result, {
                id,
                ...rest
              }) => {
                result[id] = {
                  ...(result[id] || {}),
                  id,
                  ...rest
                };
                return result;
              }, {}));
            } else {
              currentIPAnswers = initialState; // If filledIPAnswers is null (can't be loaded from Firebase, or Firebase is empty).
            }
          } catch (error) {
            console.log('Error: ', error);
          }
          
          // Store Firebase data in ipAnswers state array.
          setIPAnswers(currentIPAnswers);
        }
      })
    }
    output();
  
    // Clean-up.
    return () => { isMounted = false };
  }, [auth, count]);

  const nextClick = (e) => {
    // Increment 'count'.
    setCount(count + 1);

    // Send all results up to this page to Firestore.
    ipSubmit({ ipAnswers });
  }

  const backClick = (e) => {
    setCount(count - 1);

    // Send all results up to this page to Firestore.
    ipSubmit ({ ipAnswers });
  }

  const submitClick = (e) => {
    ipSubmit({ ipAnswers });    // Sends results to Firestore.

    // Set 'ipComplete' to 'true' in user document.
    ipCompleteSubmit(); // Sends complete = true boolean to Firestore.

    // Updates display state.
    setDisplay(WIPDisplayStates.COMPLETE);


  }

  // Progress.
  // let progress = Math.round((last)/numTotalQuestions*100*10)/10;
  let progress = (count + 1)/5*100;

  // Check if next button can be clicked (requires all the questions in the current page to be answered).
  let check;
  let maxQuestions = (count+1)*12;
  if (((((last) % (maxQuestions)) % 12) === 0 && last >= maxQuestions) || last >= maxQuestions) {
    check = true;
  } else {
    check = false;
  }

  return (
    <div className="ipass">
      <div className="ipass__body">
        Read each question carefully and select your answer from strongly dislike to strongly like.
      </div>
      <IPMultiChoice2 count={count} answer={ipAnswers} setAnswer={setIPAnswers} last={last} setLast={setLast} />
      <div className='ipass__bottom'>
        <div className="ipass__progressBar">
          <ProgressBar completed={progress} bgColor='#009ADE74' />
        </div>
        <div className="ipass__pageCount">
          Page {count+1} of {Math.round(numTotalQuestions/12)}
        </div>
        <div className="ipass__questionCount">
          {last} of {numTotalQuestions} questions
        </div>
        <div className="ipass__buttonBox">
          { count > 0 && <button className='ipass__pageButton ipass__pageButton-back' onClick={backClick}><span>{'<<'}</span></button> }
          { check ? (
            count === 4 ? (
              <button className="ipass__pageButton ipass__pageButton-next" onClick={(e) => {submitClick()}}><span>{'>>'}</span></button>
            ) : (
              <button className="ipass__pageButton ipass__pageButton-next" onClick={nextClick}><span>{'>>'}</span></button>
            )
            ) : (
            <button className="ipass__pageButton ipass__pageButton-disabled" disabled><span>{'>>'}</span></button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { userState } = state;
  return {
    currentPromptId: userState.currentPromptId || "IP-Prompt-1",
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ipSubmit: (scores) => dispatch(ipSubmit(scores)),
    ipCompleteSubmit: (result) =>
      dispatch(
        ipCompleteSubmit(result)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IPAssessment)