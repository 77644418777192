import React from 'react';
import { WIPDisplayStates } from '../Configs';
import '../AR.css';

const calcPic = `/images/arImages/NoCaluculator-OnlyPaper - instructions.png`;

const InstructionsB = ({ setDisplay }) => {
  return (
    <div className='ar-main-box'>
      <div className="ap-instructions-header">
        Assessments 3: Part 1 - Arithmetic Reasoning
      </div>
      <div className='ar-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        While calculators aren't allowed as they would invalidate results,
        you should get some paper and pencil handy for calculations.
      </div>
      <img src={calcPic} alt="" className='instructionsB__image' />
      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS);
          }}
        >
          Back
        </button>
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsB
