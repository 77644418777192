import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../SA.css';
import variables from '../../../../../../variables.module.scss';

const InstructionsQ2 = ({ setDisplay }) => {

  // Images.
  const webImage = `/images/saImages/web/Spatial Reasoning Ins 2.png`;
  const mobileImage = `/images/saImages/mobile/Spatial Reasoning Ins m2.png`;

  // Check current width and height of screen.
  const [ screenSize, getDimension ] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    let isMounted = true;     // Note mutable flag.

    if (isMounted) {
      window.addEventListener('resize', setDimension);
    }

    return (() => {
      window.removeEventListener('resize', setDimension);
      isMounted = false;
    })
  }, [screenSize])

  // Set image type.
  let currentImage = webImage;
  if (screenSize.dynamicWidth <= parseFloat(variables.mobileView)+5) {
    currentImage = mobileImage;
  } else {
    currentImage = webImage;
  }

  return (
    <div className='sa-main-box sa-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 3 - Three-Dimensional Space
      </div>
      <div className='sa-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body sa-instructions-body">
        Now look at Practice Problem 2:
      </div>

      <div className="saInsctructions__question">
        <div className="sa__content sa__content-fixed">
          <img src={process.env.PUBLIC_URL + currentImage} alt="content" className="sa__choices" />
        </div>

        <div className="ap-instructions-body sa-instructions-body">
          At the left is another drawing of a flat shape. There are no indications on the
          flat shape to show where it's to be rolled. There are dotted lines where the
          circles meet the rectangle to show where it's to be folded. Notice that of the four
          different drawings on the right, only <b><i>Object C</i></b> can be made by both rolling
          and folding the flat shape.
        </div>
      </div>
      <div className="sa-row-2 sa-row-2-spaced">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q1);
          }}  
        >
          Back
        </button>
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q3);
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InstructionsQ2