import React, { useState } from 'react';
import './TopJobs.css';
import JobZones from './JobZones';
import JobResults from './JobResults';
import { connect } from "react-redux";
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useEffect } from 'react';

const TopJobs = ({ auth, apresults, ipresults, wipresults, trifectaresults, ip, wip, ar, cm, cp, sa, va, fp }) => {
  const [ signedIn, setSignedIn ] = useState(false);
  const [ assComplete, setAssComplete ] = useState(false);

  useEffect(() => {
    try {
      if (auth) {
        if (auth.isLoaded === true) {
          if (auth.isEmpty !== true) {
            // User is signed in.
            setSignedIn(true);

            if (trifectaresults) {
              // User has paid and has finished his assessments.
              setAssComplete(true);
            }
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [auth, trifectaresults])

  return (
    <div className='jobs-container'>
      <div>
        <JobZones />
      </div>
      {(signedIn === true && assComplete === true) &&
        <div id='job-results'>
          {/* <JobResults pageHeight={windowDimensions.height} /> */}
          <JobResults
            auth={auth}
            apresults={apresults}
            ipresults={ipresults}
            wipresults={wipresults}
            trifectaresults={trifectaresults}
            ip={ip}
            wip={wip}
            ar={ar}
            cm={cm}
            cp={cp}
            sa={sa}
            va={va}
            fp={fp}
          />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    wipresults: state.firestore.ordered.wipresults,
    ipresults: state.firestore.ordered.ipresults,
    apresults: state.firestore.ordered.apresults,
    trifectaresults: state.firestore.ordered.trifectaresults,
    ip: state.firestore.ordered.ip,
    wip: state.firestore.ordered.wip,
    ar: state.firestore.ordered.ar,
    cm: state.firestore.ordered.cm,
    cp: state.firestore.ordered.cp,
    fp: state.firestore.ordered.fp,
    sa: state.firestore.ordered.sa,
    va: state.firestore.ordered.va
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'wipresults', doc: props.auth.uid },
      { collection: 'ipresults', doc: props.auth.uid },
      { collection: 'apresults', doc: props.auth.uid },
      { collection: 'trifectaresults', doc: props.auth.uid },
      { collection: 'ip', doc: props.auth.uid },
      { collection: 'wip', doc: props.auth.uid },
      { collection: 'ar', doc: props.auth.uid },
      { collection: 'cm', doc: props.auth.uid },
      { collection: 'cp', doc: props.auth.uid },
      { collection: 'fp', doc: props.auth.uid },
      { collection: 'sa', doc: props.auth.uid },
      { collection: 'va', doc: props.auth.uid }
    ]
  })
)(TopJobs);