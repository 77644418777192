// AP config.

// Arithmetic reasoning (AR) correct answers. A = 1, B = 2, C = 3, D = 4, E = 5.
export const arCorrect = ['E', 'C', 'E', 'D', 'E', 'E', 'A', 'C', 'B', 'B', 'C', 'D', 'D', 'A', 'D', 'C', 'B', 'B'];

// Verbal ability (VA) correct answers. AB = 1, AC = 2, AD = 3, BC = 4, BD = 5, CD = 6.
export const vaCorrect = ['BD', 'AC', 'AD', 'AC', 'BC', 'AC', 'BC', 'BD', 'BC', 'BD', 'AB', 'AC', 'CD', 'BD', 'BD', 'BD', 'BC', 'AB', 'AD'];

// Spatial awareness (SA) correct answers. A = 1, B = 2, C = 3, D = 4.
export const saCorrect = ['C', 'A', 'A', 'D', 'A', 'C', 'D', 'A', 'B', 'D', 'A', 'B', 'C', 'A', 'D', 'C', 'A', 'C', 'B', 'B'];

// Computation (cm) correct answers. A = 1, B = 2, C = 3, D = 4, E = 5.
export const cmCorrect = ['C', 'A', 'D', 'C', 'B', 'B', 'E', 'C', 'B', 'A', 'E', 'E', 'C', 'A', 'B', 'E', 'A', 'A', 'C', 'D',
                  'C', 'E', 'C', 'E', 'B', 'A', 'B', 'D', 'B', 'E', 'D', 'C', 'A', 'A', 'D', 'B', 'C', 'D', 'D', 'B'];

// Name comparison, aka clerical perception (cp) correct answers. S = 1, D = 2.
export const cpCorrect = ['S', 'D', 'S', 'S', 'D', 'S', 'S', 'S', 'S', 'D', 'S', 'S', 'D', 'S', 'D', 'D', 'S', 'D', 'S', 'D',
                  'D', 'S', 'S', 'D', 'S', 'D', 'D', 'S', 'S', 'S', 'D', 'D', 'D', 'S', 'D', 'D', 'S', 'D', 'S', 'D',
                  'S', 'S', 'D', 'S', 'S', 'S', 'S', 'D', 'D', 'D', 'D', 'D', 'D', 'S', 'D', 'D', 'S', 'S', 'D', 'S',
                  'D', 'D', 'D', 'S', 'D', 'D', 'S', 'S', 'S', 'D', 'S', 'D', 'D', 'S', 'S', 'D', 'D', 'S', 'S', 'S',
                  'S', 'D', 'D', 'S', 'S', 'S', 'S', 'D', 'D', 'D'];

// Object matching, aka form perception (fp) correct answers. A = 1, B = 2, C = 3, D = 4.
export const fpCorrect = ['D', 'D', 'C', 'B', 'D', 'A', 'A', 'C', 'D', 'A', 'C', 'B', 'C', 'B', 'B', 'D', 'A', 'B', 'B', 'C', 'D',
                  'B', 'A', 'C', 'D', 'B', 'B', 'B', 'A', 'D', 'B', 'A', 'A', 'A', 'A', 'C', 'C', 'C', 'D', 'C', 'C', 'C'];

export const arConvertArray = [2, 2, 4, 5, 6, 8, 10, 11, 12, 14, 15, 17, 18, 19, 21, 23, 26, 28, 31];

export const vaConvertArray = [64, 64, 66, 69, 72, 75, 78, 81, 83, 85, 88, 91, 96, 100, 105, 108, 112, 117, 125, 134];

export const saConvertArray = [51, 51, 56, 60, 63, 66, 68, 71, 74, 78, 82, 86, 90, 93, 97, 102, 107, 112, 118, 126, 134];

export const cmConvertArray = [37, 39, 41, 42, 44, 45, 47, 48, 50, 52, 54, 56, 58, 60, 61, 63, 65, 67, 68, 70, 74, 76, 77, 79,
  81, 83, 85, 87, 89, 92, 94, 96, 98, 99, 101, 102, 103, 104, 105, 106, 106, 108, 110, 112, 114];

export const cpConvertArray = [53, 56, 58, 60, 63, 65, 66, 67, 68, 69, 70, 70, 71, 71, 72, 73, 74, 74,
  75, 76, 76, 77, 77, 77, 78, 78, 78, 79, 79, 80, 80, 80, 81, 81, 82, 82,
  83, 84, 85, 85, 86, 86, 87, 88, 89, 90, 90, 91, 92, 93, 94, 95, 96, 96,
  97, 98, 99, 100, 102, 104, 105, 105, 106, 107, 108, 109, 111, 112, 113,
  114, 116, 117, 119, 120, 121, 123, 124, 125, 126, 127, 128, 129, 132, 133,
  134, 136, 137, 138, 140, 142, 145, 147, 150, 152, 154, 156, 157, 160, 162];

export const fpConvertArray = [33, 38, 41, 43, 44, 46, 48, 49, 51, 53, 55, 58, 60, 63, 65, 68, 71,
  74, 77, 80, 83, 86, 89, 92, 95, 98, 102, 105, 108, 111, 114, 117,
  120, 124, 127, 131, 134, 137, 140, 143, 145, 146, 147, 148, 148];

// Population data from our beta testers for the Euclidean distance
// calculation on the ability profiler.
export const apPopulationEDSpecs = {
  'mean': 99.38,
  'standardDeviation': 33.997
};

// Population data from our beta testers for the AP correlation coefficient.
export const apPopulationCCSpecs = {
  'mean': 0.0113,
  'standardDeviation': 0.5270
};

// Population data from our beta testers for the trifecta score.
export const trifectaPopulationSpecs = {
  'mean': 0.479443,
  'standardDeviation': 0.160120
}