// import React, { useState, useEffect, useRef } from 'react';
import React from 'react';
import Holland from './Holland';
import Ikigai from './Ikigai';
import Maslow from './Maslow';
import ONet from './ONet';
// import { Link } from 'react-scroll';
// import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import './Science.css';
import Statistics from './Statistics';

const Science = () => {

  return (
    <div className="science">
      <div className="science__ikigai">
        <Ikigai />
      </div>

      <div style={{ height: '10px', backgroundColor: 'rgba(102, 45, 145, 1)' }}></div>
      <div style={{ height: '10px', backgroundColor: 'rgba(46, 24, 107, 1)' }}></div>

      <div className="science__maslow">
        <Maslow />
      </div>

      <div style={{ height: '10px', backgroundColor: 'rgba(46, 24, 107, 1)' }}></div>
      <div style={{ height: '10px', backgroundColor: 'rgba(102, 45, 145, 1)' }}></div>

      <div className="science__holland">
        <Holland />
      </div>

      <div style={{ height: '10px', backgroundColor: 'rgba(102, 45, 145, 1)' }}></div>
      <div style={{ height: '10px', backgroundColor: 'rgba(46, 24, 107, 1)' }}></div>

      <div className="science__onet">
        <ONet />
      </div>

      <div style={{ height: '10px', backgroundColor: 'rgba(46, 24, 107, 1)' }}></div>
      <div style={{ height: '10px', backgroundColor: 'rgba(102, 45, 145, 1)' }}></div>
      
      <div className="science__statistics">
        <Statistics />
      </div>
    </div>
  )
}

export default Science
