import React, { useState, useEffect } from 'react';
import './IPComplete.css';
// import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const IPComplete = ({ user }) => {
  const [ ipStatus, setIPStatus ] = useState(false);
  const [ wipStatus, setWIPStatus ] = useState(false);
  const [ fpStatus, setFPStatus ] = useState(false);

  useEffect(() => {
    if (user) {
      if (user[0]) {
        
        // Check if user has completed the IP assessment.
        if (user[0].ipComplete) {
          if (user[0].ipComplete === true) {
            setIPStatus(true);
          } else {
            setIPStatus(false);
          }
        }
        
        // Check if user has completed the WIP assessment.
        if (user[0].wipComplete) {
          if (user[0].wipComplete === true) {
            setWIPStatus(true);
          } else {
            setWIPStatus(false);
          }
        }

        // Check if user has completed the FP assessment.
        if (user[0].fpComplete) {
          if (user[0].fpComplete === true) {
            setFPStatus(true);
          } else {
            setFPStatus(false);
          }
        }
      }
    }
  }, [user])

  return (
    <div className='ipcomplete'>
      <div className="ipcomplete__header">
        Nice Work!
      </div>
      <div className="ipcomplete__body">
        You've completed the Interest Profiler. This measured what work you might
        like and find rewarding.
        <br /><br />
        Did you find it...interesting?
      </div>
      { (ipStatus === true && wipStatus === true && fpStatus === true) ? (
        <NavLink to='/results' className='ipcomplete__navlink'>
          <button className="ipcomplete__button">
            Continue
          </button>
        </NavLink>
      ) : (
        <NavLink to='/assessments' className='ipcomplete__navlink'>
          <button className="ipcomplete__button">
            Continue
          </button>
        </NavLink>
      ) }
    </div>
  )
}

export default IPComplete
