import './TableBody.css';

const TableBody = ({ columns, users, openPopup }) => {

  return (
   <tbody>
    {users.map((data, ind1) => {
     return (
      <tr key={ind1}>
        <td key={ind1} className='codeTableBody__cell'>{ind1+1}.</td>
        {columns.map(({ accessor }, ind2) => {
          const tData = data[accessor] ? data[accessor] : "——";
          if (accessor === 'resultsCalculated') {
            return (
              <td key={ind2}>
                { tData === 'Yes' ? (
                  <button
                    onClick={() => openPopup(ind1)}
                    className='tableBody__button'
                  >
                    {tData}
                  </button>
                ) : (
                  tData
                ) }
              </td>
            )
          } else {
            return (
              <td key={ind2}>{tData}</td>
            )
          }
        })}
      </tr>
     );
    })}

   </tbody>
  );
 };

export default TableBody;