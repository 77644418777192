import React, { useEffect, useState } from 'react';
import { pullAllCodes, createCode, updateArchiveStatus } from '../../../../store/actions/orderActions';
import { connect } from 'react-redux';
import Table from './Table';
import './CodeTable.css';
import Popup from './Popup';

const CodeTable = ({ pullAllCodes, user, createCode, orderStatus, updateArchiveStatus }) => {
  // For the code button input data.
  const [ codeState, setCodeState ] = useState({
    code: '',
    expirationDate: '',
    type: '',
    uses: '',
    timesUsed: 0,
    discountFraction: '',
    creator: '',
    creationDate: '',
    description: '',
    archived: false
  });

  const [ codeCheck, setCodeCheck ] = useState(false);
  const handleChange = (e) => {
    const { id, value } = e.target;

    // Check if the input is related to a date.
    if (id === 'expirationDate' || id === 'creationDate') {
      const parsedDate = new Date(value); // Parse the date string to a Date object

      // Set the time to 23:59:59 GMT.
      parsedDate.setUTCHours(23, 59, 59, 0);

      // Set the state.
      setCodeState(prevState => ({
        ...prevState,
        [id]: parsedDate
      }));
    } else if (id ==='uses' || id === 'timesUsed' || id === 'discountFraction') {
      const numericValue = Number(value.replace(/[^0-9]/g, ''));
      setCodeState(prevState => ({
        ...prevState,
        [id]: numericValue
      }));
    } else {
      // Set the state.
      setCodeState(prevState => ({
        ...prevState,
        [id]: value
      }));
    }

    // If the id === 'code', check if it matches any existing code. If so, prevent
    // the user from submitting and display an error message.
    if (id === 'code') {
      if (codeNames.includes(value)) {
        setCodeCheck(true);
      } else {
        setCodeCheck(false);
      }
    }
  }

  // For dropdowns.
  const handleOptionChange = (e) => {
    const { id, value } = e.target;

    if (id === 'type') {
      // If it's a student code, automatically set the discountFraction to 1.
      if (value === 'student') {
        setCodeState(prevState => ({
          ...prevState,
          'discountFraction': 1
        }));
      }
      // Also set the state as usual.
      setCodeState(prevState => ({
        ...prevState,
        [id]: value
      }));
    } else {
      // Check if the value is a numeric string (including decimals).
      const numericValue = /^\d+(\.\d+)?$/.test(value) ? parseFloat(value) : value;

      setCodeState(prevState => ({
        ...prevState,
        [id]: numericValue
      }));
    }
  };

  // For the user's name.
  const [ adminName, setAdminName ] = useState('');
  useEffect(() => {
    if (user) {
      if (user[0]) {
        if (user[0].firstName && user[0].lastName) {
          setAdminName(user[0].firstName + ' ' + user[0].lastName);
          setCodeState(prevState => ({
            ...prevState,
            'creator': user[0].firstName + ' ' + user[0].lastName
          }));
        } else {
          setAdminName('Error');
        }
      } else {
        setAdminName('Error');
      }
    } else {
      setAdminName('Error');
    }
  }, [user]);

  // For popup.
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);

    // Reset code state.
    setCodeState({
      code: '',
      expirationDate: '',
      type: '',
      uses: '',
      timesUsed: 0,
      discountFraction: '',
      creator: codeState.creator,
      creationDate: '',
      description: '',
      archived: false
    })
  };

  // Submit code.
  const submitCode = () => {
    createCode(codeState)
      .then(() => {
        // Close popup window.
        setIsPopupOpen(false);
      })
      .catch(error => {
        console.error('Error creating code: ', error);
      })
  }

  // Get all promo codes.
  const [ codes, setCodes ] = useState({});
  const [ arrayCodes, setArrayCodes ] = useState([]);
  useEffect(() => {
    try {
      // Call the action to pull the codes when the component mounts
      pullAllCodes().then(codesData => {
        // Here, you have access to the codesData retrieved from Firestore.
        setCodes(codesData);
      });
    } catch (error) {
      console.log('Error getting codes: ', error);
    }
  }, [pullAllCodes]);

  useEffect(() => {
    // Convert codes from object of objects to array of objects.
    const arrayOfObjects = Object.keys(codes).map(key => ({
      id: key,
      ...codes[key]
    }));
    
    setArrayCodes(arrayOfObjects);
    
  }, [codes]);

  // Get all the current code names.
  const [ codeNames, setCodeNames ] = useState([]);
  useEffect(() => {
    // Create a list of all the code names.
    Object.entries(codes).map((element) => {
      setCodeNames(prevState => [...prevState, Object.values(element)[1].code]);
      return null;
    })
  }, [codes]);

  // Discount options.
  const discounts = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

  // Code to handle the state of the archive checkbox.
  const [ showArchived, setShowArchived ] = useState(false);
  const handleShowArchived = (e) => {
    // Flip the value of showArchived.
    setShowArchived(!showArchived);
  }

  return (
    <div>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <div className="codePopup__header">
          Create a Code
        </div>
        <div className="codePopup__form">
          <div className="codePopup__row">
            <div className="codePopup__col">
              <div className="codePopup__inputLabel">
                Code Name
                <input
                  type="text"
                  id='code'
                  className='codePopup__input'
                  onChange={handleChange}
                  placeholder='Code name'
                />
              </div>
              <div className="codePopup__inputLabel">
                Code Type
                <select id='type' value={codeState.type} onChange={handleOptionChange}>
                  <option value="">--</option>
                  <option value="student">Student</option>
                  {/* <option value="general">General</option> */}
                </select>
              </div>
              <div className="codePopup__inputLabel">
                Code Creator
                <input
                  type='text'
                  id='creator'
                  onChange={handleChange}
                  value={adminName}
                  readOnly
                  className = 'codePopup__input codePopup__input-readOnly'
                />
              </div>
              <div className="codePopup__inputLabel">
                Description
                <textarea
                  type='text'
                  id='description'
                  onChange={handleChange}
                  placeholder='Description'
                  maxLength={100}
                  rows={3}
                  className = 'codePopup__input'
                />
              </div>
            </div>
            <div className="codePopup__col">
              <div className="codePopup__inputLabel">
                Expiration Date
                <input
                  type="date"
                  id='expirationDate'
                  className='codePopup__input codePopup__input-date'
                  onChange={handleChange}
                  placeholder='Expiration date'
                />
              </div>
              <div className="codePopup__inputLabel">
                Allowed Uses
                <input
                  type="input"
                  id='uses'
                  value={codeState.uses}
                  className='codePopup__input'
                  onChange={handleChange}
                  placeholder='Allowed uses'
                />
              </div>
              {/* <div className="codePopup__inputLabel">
                Total Uses
                <input
                  type="input"
                  id='timesUsed'
                  value={codeState.timesUsed}
                  placeholder='Allowed uses'
                  readOnly
                  className = 'codePopup__input codePopup__input-readOnly'
                />
              </div> */}
              <div className="codePopup__inputLabel">
                Discount
                { codeState.type === 'student' ? (
                  <input
                    type='text'
                    id='discountFraction'
                    onChange={handleChange}
                    value='100%'
                    readOnly
                    className = 'codePopup__input codePopup__input-readOnly'
                  />
                ) : (
                  <select
                    id='discountFraction'
                    value={codeState.discountFraction}
                    onChange={handleOptionChange}
                  >
                    {discounts.map((element, index) => (
                      <option
                        key={index}
                        value={element/100}
                      >
                        {element.toString() + '%'}
                      </option>
                    ))}
                  </select>
                ) }
              </div>
            </div>
          </div>
          {codeCheck ?
            <div className="codePopup__row codePopup__row-error">
              That code is currently in use.
            </div> :
            <div className="codePopup__row codePopup__row-error">
              
            </div>
          }
          <div className="codePopup__row">
          {codeState.code !== '' && codeState.expirationDate !=='' &&
          codeState.type !== '' && codeState.description !== '' && codeCheck === false
            ? 
              <button className="codePopup__button codePopup__button-submit" onClick={submitCode}>
                Submit
              </button>
            :
              <button className="codePopup__button codePopup__button-disabled" disabled>
                Submit
              </button>
            }
            <button
              className="codePopup__button codePopup__button-close"
              onClick={closePopup}
            >
              Cancel
            </button>
          </div>
        </div>
      </Popup>
      <div className="codeTable__header">
        <button
          className="codeTable__button codeTable__button-on"
          onClick={() => openPopup()}
        >
          Create Code
        </button>
        <span className="codeTable__archived">
          <input
            type='checkbox'
            checked={showArchived}
            onChange={handleShowArchived}
            className='codeTable__checkbox'
            style={{ color: showArchived ? 'green' : 'black' }}
          />
          &nbsp;Show Archived
        </span>
      </div>
      <Table codes={arrayCodes} updateArchiveStatus={updateArchiveStatus} showArchived={showArchived} />
    </div>
  )
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
    user: state.firestore.ordered.users,
    orderStatus: state.order
	};
};
  
const mapDispatchToProps = (dispatch) => {
	return {
		pullAllCodes: () => dispatch(pullAllCodes()),
    createCode: (code) => dispatch(createCode(code)),
    updateArchiveStatus: (codeName, archiveStatus) => dispatch(updateArchiveStatus(codeName, archiveStatus))
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CodeTable)