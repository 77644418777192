import React from 'react';
// import firebase from '../../../config/fbConfig';
// import { ipResultSubmit } from '../../../store/actions/submitActions';
// import corrcoeff from '../CalcCorrCoef';
import { connect } from 'react-redux';
import { ipJobOverlapCalculate } from '../functionalizedCalculations/ipCalculations';
// import { rescale } from '../Functions';

const IPJobOverlap = ({ uid, rAnswers, iAnswers, aAnswers, sAnswers, eAnswers, cAnswers }) => {

  // console.log('IP JOB CALCULATE');

  try {
    // Calculate IP job overlap.
    ipJobOverlapCalculate(
      uid,
      rAnswers, iAnswers, aAnswers,
      sAnswers, eAnswers, cAnswers
    );
  } catch (error) {
    console.log('Error: ', error);
  }

return (
  <div>
  </div>
)
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(IPJobOverlap)