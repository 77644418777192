import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Division = ({ elements }) => {

  return (
    <Container>
      <Row>
        <Col>
          <div className="cm-math-box">
            <div className="cm-math-box__header">
              DIVIDE
            </div>
            <div className="cm-calc-box-regular-margins" style={{ paddingTop: '20px' }}>
              <div className='cm-calc-box__divide'>
                <div className='cm-calc-box__divide-curve'>
                  {elements[1].toLocaleString('en-US', {maximumFractionDigits:2})}&nbsp;&nbsp;
                </div>
                <div className='cm-calc-box__divide-line'>
                  &nbsp;{elements[0].toLocaleString('en-US', {maximumFractionDigits:2})}&nbsp;
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Division
