import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import './Navbar.css';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
// import { useQueryClient } from "react-query";

const telosLogoMain = `/images/Telos-logo_on-light.png`;

const SignedInLinks2 = ({ user, signOut }) => {
	const [ expanded, setExpanded ] = useState(false);
	const [ firstName, setFirstName ] = useState('');
	const [ dropdown, setDropdown ] = useState(false);
	const [ admin, setAdmin ] = useState(false);

	const timerRef = useRef(null);

	const thisLocation = useLocation();
	let currentPath = thisLocation.pathname;
	const weight = {
		fontWeight: '800'
	}

	// Check if user is an admin.
	useEffect(() => {
		try {
			if (user) {
				if (user[0]) {
					if (user[0].admin) {
						if (user[0].admin === true) {
							setAdmin(true);
						} else {
							setAdmin(false);
						}
					}
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		}
	}, [user])

	// // For 'Results' dropdown on hover.
	// const [ show, setShow ] = useState(false);
	// const showDropdown = (e) => {
	// 	setShow(!show);
	// }
	// const hideDropdown = (e) => {
	// 	setShow(false);
	// }

	useEffect (() => {
		try {
			if(user) {
				if (user[0].firstName) {
					setFirstName(user[0].firstName);
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		}

		// Clean up async issues with setTimeout.
		return () => clearTimeout(timerRef.current);
	}, [user]);

	// Sign out and close hamburger menu (if its open).
	const onClick = (e) => {
			// Close hamburger menu.
			timerRef.current = setTimeout(() => {setExpanded(false)}, 0);

			// Sign out.
			signOut();
	}

	// Close hamburger menu (if it's open).
	const onClick2 = (e) => {
		timerRef.current = setTimeout(() => {setExpanded(false)}, 0);
	}

	// Flip the dropdown state.
	const dropdownClick = (e) => {
		setDropdown(!dropdown);
	}

	return (
		<Navbar collapseOnSelect expand="lg" bg="transparent" expanded={expanded}>
			<Container fluid className='navbar'>
				<NavLink to="/home">
						<img
							src={telosLogoMain}
							alt=''
							width='50px'
							height="auto"
							className="navbar__logo"
						/>
				</NavLink>
				<Navbar.Toggle
						aria-controls="responsive-navbar-nav"
						onClick={() => setExpanded(expanded ? false : true)}
				/>
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<div className='navbar__left'>
							<NavLink to='/assessments' className="navbar__link" onClick={onClick2}>
							{
								(
									currentPath === '/assessments'
								) ? (
									<div style={weight}>
										Assessments
									</div>
								) : (
									<div>
										Assessments
									</div>
								)
							}
							</NavLink>
							<NavLink to='/results' className="navbar__link" onClick={onClick2}>
							{
								(
									currentPath === '/results'
								) ? (
									<div style={weight}>
										Your Results
									</div>
								) : (
									<div>
										Your Results
									</div>
								)
							}
							</NavLink>
							<NavLink to='/science' className="navbar__link" onClick={onClick2}>
								{
									(
										currentPath === '/science'
									) ? (
										<div style={weight}>
											The Science
										</div>
									) : (
										<div>
											The Science
										</div>
									)
								}
							</NavLink>
							<NavLink to='/aboutus' className="navbar__link" onClick={onClick2}>
								{
									(
										currentPath === '/aboutus'
									) ? (
										<div style={weight}>
											About Us
										</div>
									) : (
										<div>
											About Us
										</div>
									)
								}
							</NavLink>
							<NavLink to='/payment' className="navbar__link" onClick={onClick2}>
								{
									(
										currentPath === '/payment'
									) ? (
										<div style={weight}>
											Purchase
										</div>
									) : (
										<div>
											Purchase
										</div>
									)
								}
							</NavLink>
						</div>
						<div className='navbar__right'>
							{/* <NavLink to='/signin' onClick={onClick} className="navbar__link navbar__link-right">
								Sign Out
							</NavLink> */}
							{/* <div className='navbar__name'>
								<NavLink to='/profile'>
									{firstName}
								</NavLink>
							</div> */}
							
							<div className='navbar__user'>
								<div className='navbar__name' onClick={dropdownClick}>
										{firstName} {dropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}
								</div>
								{
									dropdown &&
									<div className='navbar__userDropdown'>
										<NavLink className='navbar__userElement' to='profile' onClick={dropdownClick}>
											Account
										</NavLink>
										{admin &&
											<NavLink className='navbar__userElement' to='admin' onClick={dropdownClick}>
												Admin
											</NavLink>
										}
										<NavLink className='navbar__userElement' to='/signin' onClick={() => { onClick(); dropdownClick() }}>
											Sign Out
										</NavLink>
									</div>
								}
							</div>

						</div>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut())
	};
};

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		user: state.firestore.ordered.users
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect((props) => {
		return [
			{ collection: 'users', doc: props.auth.uid }
		]
	})
)(SignedInLinks2);