import React, { useState, useEffect } from 'react';
import './JobZones.css';

const JobZones = () => {
  const ColoredLine = ({ color, height=0.1 }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            opacity: 1,
            height: height
        }}
    />
  );

  // Text data for the page.
  const textContent = [
    {
      heading: 'little or no',
      education: 'Some of these occupations may require a high school diploma or GED certificate.',
      examples: "These occupations involve following instructions and helping others. Examples include taxi drivers, amusement and recreation attendants, counter and rental clerks, construction laborers, continuous mining machine operators, and waiters/waitresses."
    },
    {
      heading: 'some',
      education: 'These occupations usually require a high school diploma.',
      examples: "These occupations often involve using your knowledge and skills to help others. Examples include sheet metal workers, forest fire fighters, customer service representatives, physical therapist aides, salespersons (retail), and tellers."
    },
    {
      heading: 'medium',
      education: "Most occupations in this zone require training in vocational schools, related on-the-job experience, or an associate's degree.",
      examples: "These occupations usually involve using communication and organizational skills to coordinate, supervise, manage, or train others to accomplish goals. Examples include food service managers, electricians, agricultural technicians, legal secretaries, interviewers, and insurance sales agents."
    },
    {
      heading: 'considerable',
      education: "Most of these occupations require a four-year bachelor's degree, but some do not.",
      examples: "Many of these occupations involve coordinating, supervising, managing, or training others. Examples include accountants, sales managers, database administrators, teachers, chemists, environmental engineers, criminal investigators, and special agents."
    },
    {
      heading: 'extensive',
      education: "Most of these occupations require graduate school. For example, they may require a master's degree, and some require a Ph.D., M.D., or J.D. (law degree).",
      examples: "These occupations often involve coordinating, training, supervising, or managing the activities of others to accomplish goals. Very advanced communication and organizational skills are required. Examples include librarians, lawyers, aerospace engineers, wildlife biologists, school psychologists, surgeons, treasurers, and controllers."
    }
  ];

  const angletoradian = (angle) => {
    return angle * (Math.PI / 180);
  }
  const radius = 20;
  const diameter = radius * 2;
  const text = 'ZONE';
  const characters = text.split('');
  const angle = -215;
  const deltaangle = 100/characters.length;
  let angles = characters.map((val, i) => {
    let newAngle = angle + deltaangle * i;
    return newAngle;
  });

  const coords = characters.map((char, i) => {
    let xpos = radius * Math.cos(angletoradian(angles[i])) + 10;
    let ypos = radius * Math.sin(angletoradian(angles[i]));
    return [xpos, ypos];
  });

  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsBelowThreshold(window.innerWidth < 640); // Change 640 to your desired threshold
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call the handleResize function once to set the initial state
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div className='jobZones'>
      <div className="jobZones__topBox">
        <h1>
          A Job Zone is a group of occupations that are similar in these ways:
        </h1>
        <ul>
          <li>
            <span>How much education people need to do the job.</span>
          </li>
          <li>
            <span>How much related experience people need to do the job.</span>
          </li>
          <li>
            <span>How much on-the-job training people need to do the job.</span>
          </li>
        </ul>
        <p>
          In other words, occupations within a Job Zone require a similar level of preparation.
        </p>
      </div>
      {isBelowThreshold ? (
        <div className='jobZones__main'>
          {
            textContent.map((val, ind) => (
              <div className="jobZones__mainBox" key={ind}>
                <div className="jobZones__mainIcons">
                  {characters.map((char, i) => (
                    <span
                      key={`heading-span-${i}`}
                      style={{
                        height: radius,
                        transform: `translate(${coords[i][0]}px, ${coords[i][1]}px) rotate(${angles[i] - 270}deg)`
                      }}
                      className='jobZones__circularTextSpan'
                    >
                      {char}
                    </span>
                  ))}
                  <div className='jobZones__circularText' style={{ width: diameter, height: diameter }}>
                    {ind+1}
                  </div>
                  <div className="jobZones__midLeftText">
                    <h1>
                      Occupations that need <i>{val.heading}</i> preparation
                    </h1>
                  </div>
                </div>
                <div className="jobZones__belowText">
                  <p>
                    <b>
                      Education:&nbsp;
                    </b>
                    {val.education}
                  </p>
                  <p>
                    <b>
                      Examples:&nbsp;
                    </b>
                    {val.examples}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      ) : (
        <div className='jobZones__main'>
          {
            textContent.map((val, ind) => (
              <div className="jobZones__mainBox" key={ind}>
                <div className="jobZones__mainIcons">
                  {characters.map((char, i) => (
                    <span
                      key={`heading-span-${i}`}
                      style={{
                        height: radius,
                        transform: `translate(${coords[i][0]}px, ${coords[i][1]}px) rotate(${angles[i] - 270}deg)`
                      }}
                      className='jobZones__circularTextSpan'
                    >
                      {char}
                    </span>
                  ))}
                  <div className='jobZones__circularText' style={{ width: diameter, height: diameter }}>
                    {ind+1}
                  </div>
                  <div className="jobZones__midLeftLine">
                    <ColoredLine color='#635DC6' height='2px' />
                  </div>
                </div>
                <div className="jobZones__midLeftText">
                  <h1>
                    Occupations that need <i>{val.heading}</i> preparation
                  </h1>
                  <p>
                    <b>
                      Education:&nbsp;
                    </b>
                    {val.education}
                  </p>
                  <p>
                    <b>
                      Examples:&nbsp;
                    </b>
                    {val.examples}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      )}
    </div>
  )
}

export default JobZones