import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../CP.css';

const InstructionsQ678 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled6, setDisabled6 ] = useState(true);
  const [ disabled7, setDisabled7 ] = useState(true);
  const [ disabled8, setDisabled8 ] = useState(true);
  const [ correct6, setCorrect6 ] = useState(false);
  const [ correct7, setCorrect7 ] = useState(false);
  const [ correct8, setCorrect8 ] = useState(false);
  const [ currentQuestion6 ] = useState(5);
  const [ currentQuestion7 ] = useState(6);
  const [ currentQuestion8 ] = useState(7);

  useEffect(() => {
    if (practiceAnswers[currentQuestion6].values !== '') {
      setDisabled6(false);
    } else {
      setDisabled6(true);
    }

    if (practiceAnswers[currentQuestion7].values !== '') {
      setDisabled7(false);
    } else {
      setDisabled7(true);
    }

    if (practiceAnswers[currentQuestion8].values !== '') {
      setDisabled8(false);
    } else {
      setDisabled8(true);
    }

    if (practiceAnswers[currentQuestion6].values === 'D') {
      setCorrect6(true);
    } else {
      setCorrect6(false);
    }

    if (practiceAnswers[currentQuestion7].values === 'D') {
      setCorrect7(true);
    } else {
      setCorrect7(false);
    }

    if (practiceAnswers[currentQuestion8].values === 'S') {
      setCorrect8(true);
    } else {
      setCorrect8(false);
    }
  }, [practiceAnswers, currentQuestion6, currentQuestion7, currentQuestion8]);

  // Answer options.
  const answerOptions6 = [
    'S',
    'D'
  ];

  const answerOptions7 = [
    'S',
    'D'
  ];

  const answerOptions8 = [
    'S',
    'D'
  ];

  const handleAnswer = (e) => {
    let { name, value } = e.target;

    // Question 6
    if (name === String(currentQuestion6)) {
      const previousAnswer = practiceAnswers[currentQuestion6].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion6,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion6) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }


    // Question 7
    if (name === String(currentQuestion7)) {
      const previousAnswer = practiceAnswers[currentQuestion7].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion7,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion7) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }

    // Question 8
    if (name === String(currentQuestion8)) {
      const previousAnswer = practiceAnswers[currentQuestion8].values;
      const currentAnswer = value;
      if (previousAnswer === currentAnswer) {
        // Clear the selected answer.
        value = '';
      }

      // Create an object for this question's new answer.
      const obj = {
        id: currentQuestion8,
        values: value
      };

      // Create new array with the updated answer.
      const updatedAnswers = practiceAnswers.map((el, ind) => {
        if (ind === currentQuestion8) {
          // Update the value.
          return obj;
        } else {
          return el
        }
      });

      // Update this question.
      setPracticeAnswers(updatedAnswers);
    }
  }

  return (
    <div className='cp-main-box cp-main-box-practice'>
      <div className="ap-instructions-header">
        Assessments 3: Part 5 - Name Comparison
      </div>
      <div className='cp-main-box__header2'>
        Instructions
      </div>
      <div className="ap-instructions-body cm-instructions-body">
        Now do the next six practice questions in the same way. If the names are <i>exactly</i> the <i>same</i>, select
        the circle under <b>S</b>. If they are <i>different in any way</i>, select the oval under <b>D</b>.
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          6.
        </div>
        <div className="cp-question__content">
          Linda Small &ndash; Lynda Small
        </div>

        <div className="cp-question__answers">
          {
            answerOptions6.map((element, index) => (
              <label className="cp-question__answer-option" key={index}>
                <div className="cp-question__answer-text">
                  {element}
                </div>
                <input
                  type="checkbox"
                  className='question__radio-button question__radio-button-cp'
                  checked={practiceAnswers[currentQuestion6].values === element}
                  name={currentQuestion6}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            ))
          }
        </div>
        { practiceAnswers[currentQuestion6].values !== '' || practiceAnswers[currentQuestion6].values.length > 0 ? (
          correct6 ? (
            <div className="cp-display-answer cp-display-answer-q3">
              Correct
            </div>
          ) : (
            <div className="cp-display-answer cp-display-answer-q3">
              Incorrect
            </div>
          )
        ) : (
          <div className="cp-display-answer cp-display-answer-q3"></div>
        )}
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          7.
        </div>
        <div className="cp-question__content">
          Strong Ltd. &ndash; Strong Inc.
        </div>

        <div className="cp-question__answers">
          {
            answerOptions7.map((element, index) => (
              <label className="cp-question__answer-option" key={index}>
                <div className="cp-question__answer-text">
                  {element}
                </div>
                <input
                  type="checkbox"
                  className='question__radio-button question__radio-button-cp'
                  checked={practiceAnswers[currentQuestion7].values === element}
                  name={currentQuestion7}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            ))
          }
        </div>
        { practiceAnswers[currentQuestion7].values !== '' || practiceAnswers[currentQuestion7].values.length > 0 ? (
          correct7 ? (
            <div className="cp-display-answer cp-display-answer-q3">
              Correct
            </div>
          ) : (
            <div className="cp-display-answer cp-display-answer-q3">
              Incorrect
            </div>
          )
        ) : (
          <div className="cp-display-answer cp-display-answer-q3"></div>
        )}
      </div>

      <div className="cp-question">
        <div className="cp-question__number">
          8.
        </div>
        <div className="cp-question__content">
          James Reagon &ndash; James Reagon
        </div>

        <div className="cp-question__answers">
          {
            answerOptions8.map((element, index) => (
              <label className="cp-question__answer-option" key={index}>
                <div className="cp-question__answer-text">
                  {element}
                </div>
                <input
                  type="checkbox"
                  className='question__radio-button question__radio-button-cp'
                  checked={practiceAnswers[currentQuestion8].values === element}
                  name={currentQuestion8}
                  value={element}
                  onChange={e => handleAnswer(e)}
                />
              </label>
            ))
          }
        </div>
        { practiceAnswers[currentQuestion8].values !== '' || practiceAnswers[currentQuestion8].values.length > 0 ? (
          correct8 ? (
            <div className="cp-display-answer cp-display-answer-q3">
              Correct
            </div>
          ) : (
            <div className="cp-display-answer cp-display-answer-q3">
              Incorrect
            </div>
          )
        ) : (
          <div className="cp-display-answer cp-display-answer-q3"></div>
        )}
      </div>

      <div className="cp-row-2 cp-row-2-spaced cp-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q345);
          }}
        >
          Back
        </button>
        { disabled6 === true || disabled7 === true || disabled8 === true ? (
          <button
            type='button'
            disabled={disabled6 === true || disabled7 === true || disabled8 === true}
            className="ap-instructions-button"
            style={{ backgroundColor: 'grey' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q9);
            }}
          >
            Next
          </button>
        ) : (
          <button
            type='button'
            className="ap-instructions-button"
            style={{ backgroundColor: '#008375' }}
            onClick={() => {
              setDisplay(WIPDisplayStates.INSTRUCTIONS_Q9);
            }}
          >
            Next
          </button>
        ) }
      </div>
    </div>
  )
}

export default InstructionsQ678