import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import { NavLink } from 'react-router-dom';

const topPic = `images/landingPageImages/TelosPPC_top2_72dpi.png`;
const topPicMobile = `images/landingPageImages/TelosPPC_top_mobile_72dpi.png`;
const botPic = `images/landingPageImages/TelosPPC_bottom2_72dpi.png`
const botPicMobile = `images/landingPageImages/TelosPPC_bottom_mobile_72dpi.png`;

const LandingPage = () => {
  // Check page width.
  const [view, setView] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 640) {
        setView('mobile');
      } else if (screenWidth > 640 && screenWidth < 1024) {
        setView('tablet');
      } else {
        setView('pc');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set pic types.
  let currentTopPic = topPic;
  let currentBotPic = botPic;
  if (view === 'mobile') {
    currentTopPic = topPicMobile;
    currentBotPic = botPicMobile;
  } else {
    currentTopPic = topPic;
    currentBotPic = botPic;
  }

  return (
    <div className='landingPage'>
      <div className="landingPage__top">
        <img
          src={currentTopPic}
          alt='landing-page-top-pic'
          className='landingPage__topPic'
        />
        <NavLink to='/signup'>
          <button
            className="landingPage__button landingPage__button-button1"
          />
        </NavLink>
      </div>
      <div className="landingPage__bottom">
        <img src={currentBotPic} alt='landing-page-bot-pic' className='landingPage__botPic' />
        <NavLink to='/signup'>
          <button
            className="landingPage__button landingPage__button-button2"
          />
        </NavLink>
        <NavLink to='/signup'>
          <button
            className="landingPage__button landingPage__button-button3"
          />
        </NavLink>
        <NavLink to='/signup'>        
          <button
            className="landingPage__button landingPage__button-button4"
          />
        </NavLink>
      </div>
    </div>
  )
}

export default LandingPage