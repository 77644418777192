import React from 'react';
import { NavLink } from 'react-router-dom';
import './Results.css';

const NoneComplete = () => {
  return (
    <div className='incomplete'>
      <div className="incomplete__title">
        Exciting things coming soon!
      </div>
      <div className="incomplete__subtitle">
        <span>Finish&nbsp;</span>
        <NavLink to='/assessments' className='incomplete__link'>
          an assessment
        </NavLink>
        <span>&nbsp;to see results here.</span>
      </div>      
    </div>
  )
}

export default NoneComplete