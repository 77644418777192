import React, { useState, useEffect } from 'react';
import { WIPDisplayStates } from '../Configs';
import '../AR.css';

const InstructionsQ1 = ({ setDisplay, practiceAnswers, setPracticeAnswers }) => {
  const [ disabled, setDisabled ] = useState(true);
  const [ correct, setCorrect ] = useState(false);
  const [ btnColor, setBtnColor ] = useState('grey');

  useEffect(() => {
    if (practiceAnswers[0].values !== '') {
      setDisabled(false);
      setBtnColor('#008375');
    } else {
      setDisabled(true);
      setBtnColor('grey');
    }

    if (practiceAnswers[0].values === '12') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
  }, [practiceAnswers]);

  // Answer options.
  const answerOptions = [
    '3',
    '9',
    '12',
    '15',
    'None of these'
  ];

  const handleAnswer = (e) => {
    let { value } = e.target;

    const previousAnswer = practiceAnswers[0].values;
    const currentAnswer = value;
    if (previousAnswer === currentAnswer) {
      // Clear the selected answer.
      value = '';
    }

    // Create an object for this question's new answer.
    const obj = {
      id: 0,
      values: value
    };

    // Create new array with the updated answer.
    const updatedAnswers = practiceAnswers.map((el, ind) => {
      if (ind === 0) {
        // Update the value.
        return obj;
      } else {
        return el
      }
    });

    // Update this question.
    setPracticeAnswers(updatedAnswers);

    // Check if answer is correct.
    if (value === '12') {
      setCorrect(true);
    } else {
      setCorrect(false);
    }

    // Enable 'next' button click and change its color.
    setDisabled(false);
    setBtnColor('#008375');
  }

  return (
    <div className='ar-main-box'>
      <div className="ap-instructions-header">
        Assessments 3: Part 1 - Arithmetic Reasoning
      </div>
      <div className='ar-instructions-header2'>
        Instructions
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Here are some arithmetic questions.
      </div>
      <div className="ap-instructions-body ar-instructions-body">
        Read and answer Practice Question 1:
      </div>
      <div className="question-ar">
        <div className="question__number-ar">
          1.
        </div>
        <div className="question__body-ar">
          <div className="question__text-ar">
            It takes 1/2 hour to fill 1 tank. How many tanks can be filled in 6 hours?
          </div>
          <div className="question-ar__answerBody">
            <div className="question__answers-ar">
              {answerOptions.map((element, index) => (
                <label className="question__answer-option-ar" key={index}>
                  <input
                    type="checkbox"
                    className='question__radio-button'
                    checked={practiceAnswers[0].values === element}
                    name={0}
                    value={element}
                    onChange={e => handleAnswer(e)}
                    id={index}
                  />
                  <div className="question__answer-text-ar">
                    {element}
                  </div>
                </label>
              ))}
            </div>
            { practiceAnswers[0].values !== '' || practiceAnswers[0].values.length > 0 ? (
              correct ? (
                <div className="display-answer">
                  Correct
                  <div className="display-answer__body">
                    That's right! In Practice Question 1, the correct answer is 12.
                  </div>
                </div>
              ) : (
                <div className="display-answer">
                  Incorrect
                  <div className="display-answer__body">
                    In Practice Question 1, the correct answer is 12.
                  </div>
                </div>
              )
            ) : (
              <div className="display-answer"></div>
            )
            }
          </div>
        </div>
      </div>
      <div className="ap-row-2 ap-row-2_spaced ap-row-2-practice">
        <button
          type='button'
          className="ap-instructions-button"
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONSB);
          }}
        >
          Back
        </button>
        <button
          type='button'
          disabled={disabled}
          className="ap-instructions-button"
          style={{ backgroundColor: `${btnColor}` }}
          onClick={() => {
            setDisplay(WIPDisplayStates.INSTRUCTIONS_Q2);
          }}
        >
          Next
        </button>
      </div>
      {/* { practiceAnswers[0].values !== '' || practiceAnswers[0].values.length > 0 ? (
          correct ? (
            <div className="display-answer">
              Correct
              <div className="display-answer__body">
                That's right! In Practice Question 1, the correct answer is 12.
              </div>
            </div>
          ) : (
            <div className="display-answer">
              Incorrect
              <div className="display-answer__body">
                In Practice Question 1, the correct answer is 12.
              </div>
            </div>
          )
      ) : (
        <div className="display-answer"></div>
      )
      } */}
    </div>
  )
}

export default InstructionsQ1
