// File containing useful functions for the results section.

// import { apPopulationEDSpecs } from "./functionalizedCalculations/Configs";

import { trifectaPopulationSpecs } from "./functionalizedCalculations/CalcConfigs";



// Function to rescale a point t on range [a, b] to an equivalent point on range [c, d].
export function rescale([a, b], [c, d], t) {
  const out = c + (d-c)/(b-a)*(t-a);
  return out;
}

// Function to calculate Euclidean distance. Accepts a data map.
export function calcEuclideanDistance(dataMap) {

  // Calculate sums.
  let sumFull = 0;
  dataMap.forEach(({ onetVal, userVal }) => {
    sumFull += Math.pow((onetVal - userVal), 2)
  })

  // Return the Euclidean distance.
  return Math.pow(sumFull, 0.5);
}

// Take an O*Net occupation's AP score and convert it into a percentile,
// based on population statistics from the O*Net database.
export function scoreToPercentile(value, type) {
  const stats = {
    ar: { mean: 5.58, dev: 1.51 },
    va: { mean: 7.14, dev: 1.24 },
    sa: { mean: 5.63, dev: 1.18 },
    cm: { mean: 5.56, dev: 1.44 },
    cp: { mean: 5.58, dev: 0.85 },
    fp: { mean: 5.58, dev: 0.85 }
  };

  const { mean, dev } = stats[type];
  const zScore = (value - mean) / dev;

  // Return score as a percentile.
  return GetZPercent(zScore);
}


// Function to find overlap between IP and AP assessments, convert the correlation coefficient
// from the IP assessment to a 0-100 scale, convert the Euclidean distance from the AP assessment
// to a 0-100 scale, and calculate a combined score (0 = worst, 100 = best).
export function calcIPAPOverlap(ipArray, apArray) {
  // Find all UIDs that exist in both ipArray and apArray.
  let filterArray = [];
  if (ipArray !== undefined && apArray !== undefined) {
    filterArray = ipArray.filter(value => apArray.some(value2 => value.UID === value2.UID));
  }

  const output = filterArray.map((element, index) => {
    let ipArrayCalcOutput = null;
    let apArrayCalcOutput = null;
    
    // Get calcOutput from IP for this particular occupation.
    ipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        ipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from AP for this particular occupation.
    apArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        // apArrayCalcOutput = element2.calcOutput;
        apArrayCalcOutput = element2.apAverage;
      }
      return null;
    });

    // Convert ipArrayCalcOutput to z-score.
    const ipMean = -0.077131;   // Mean IP correlation coefficient based on beta tester results.
    const ipDev = 0.466503;     // Standard deviation of the IP correlation coefficient based on beta tester results.
    const ipZScore = (ipArrayCalcOutput - ipMean)/ipDev;  // Calculate IP Z-score.
    const ipPercentile = GetZPercent(ipZScore);           // Calculate IP percentile.

    // // Convert apArrayCalcOutput to z-score.
    // const apMean = apPopulationEDSpecs.mean;   // Mean AP Euclidean distance based on beta tester results.
    // const apDev = apPopulationEDSpecs.standardDeviation;    // Standard deviation of the AP Euclidean distance based on beta tester results.
    // const apZScore = (apArrayCalcOutput - apMean)/apDev;  // Calculate AP Z-score.
    // const apPercentile = 1 - GetZPercent(apZScore);           // Calculate AP percentile.
    
    // Calculate mean calcOutput (mean percentiles).
    let calcOutput = (ipPercentile + apArrayCalcOutput)/2;

    // // Convert ipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedIPScore = rescale([-1, 1], [0, 100], ipArrayCalcOutput);
    
    // // Convert apArrayCalcOutput from (240, 0) scale to (0, 100) scale.
    // let convertedAPScore = rescale([240, 0], [0, 100], apArrayCalcOutput);

    // // Calculate mean calcOutput (correlation coefficient).
    // let calcOutput = (convertedIPScore + convertedAPScore)/2;

    return {
      ...element,
      calcOutput: calcOutput
    }
  });
  return output;
}



// Function to find overlap between WIP and AP assessments, convert the correlation coefficient
// from the WIP assessment to a 0-100 scale, convert the Euclidean distance from the AP assessment
// to a 0-100 scale, and calculate a combined score (0 = worst, 100 = best).
export function calcWIPAPOverlap(wipArray, apArray) {
  // Find all UIDs that exist in both ipArray and apArray.
  let filterArray = [];
  if (wipArray !== undefined && apArray !== undefined) {
    filterArray = wipArray.filter(value => apArray.some(value2 => value.UID === value2.UID));
  }

  const output = filterArray.map((element, index) => {
    let wipArrayCalcOutput = null;
    let apArrayCalcOutput = null;
    
    // Get calcOutput from WIP for this particular occupation.
    wipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        wipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from AP for this particular occupation.
    apArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        // apArrayCalcOutput = element2.calcOutput;
        apArrayCalcOutput = element2.apAverage;
      }
      return null;
    });

    // Convert wipArrayCalcOutput to z-score.
    const wipMean = 0.016794;   // Mean WIP correlation coefficient based on beta tester results.
    const wipDev = 0.439068;    // Standard deviation of the WIP correlation coefficient based on beta tester results.
    const wipZScore = (wipArrayCalcOutput - wipMean)/wipDev;  // Calculate WIP Z-score.
    const wipPercentile = GetZPercent(wipZScore);             // Calculate WIP percentile.

    // // Convert apArrayCalcOutput to z-score.
    // const apMean = apPopulationEDSpecs.mean;   // Mean AP Euclidean distance based on beta tester results.
    // const apDev = apPopulationEDSpecs.standardDeviation;    // Standard deviation of the AP Euclidean distance based on beta tester results.
    // const apZScore = (apArrayCalcOutput - apMean)/apDev;  // Calculate AP Z-score.
    // const apPercentile = 1 - GetZPercent(apZScore);           // Calculate AP percentile.
    
    // Calculate mean calcOutput (mean percentile).
    let calcOutput = (wipPercentile + apArrayCalcOutput)/2;

    // // Convert wipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedWIPScore = rescale([-1, 1], [0, 100], wipArrayCalcOutput);
    
    // // Convert apArrayCalcOutput from (240, 0) scale to (0, 100) scale.
    // let convertedAPScore = rescale([240, 0], [0, 100], apArrayCalcOutput);

    // // Calculate mean calcOutput (correlation coefficient).
    // let calcOutput = (convertedWIPScore + convertedAPScore)/2;

    return {
      ...element,
      calcOutput: calcOutput
    }
  });
  return output;
}




// Function to find overlap between WIP and IP assessments, convert the correlation coefficient
// from the WIP assessment to a 0-100 scale, convert the correlation coefficient from the IP
// assessment to a 0-100 scale, and calculate a combined score (0 = worst, 100 = best).
export function calcWIPIPOverlap(wipArray, ipArray) {
  // Find all UIDs that exist in both ipArray and apArray.
  let filterArray = [];
  if (ipArray !== undefined && wipArray !== undefined) {
    filterArray = wipArray.filter(value => ipArray.some(value2 => value.UID === value2.UID));
  }
  const output = filterArray.map((element, index) => {
    let wipArrayCalcOutput = null;
    let ipArrayCalcOutput = null;
    
    // Get calcOutput from WIP for this particular occupation.
    wipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        wipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from IP for this particular occupation.
    ipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        ipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });

    // Convert wipArrayCalcOutput to z-score.
    const wipMean = 0.016794;   // Mean WIP correlation coefficient based on beta tester results.
    const wipDev = 0.439068;    // Standard deviation of the WIP correlation coefficient based on beta tester results.
    const wipZScore = (wipArrayCalcOutput - wipMean)/wipDev;  // Calculate WIP Z-score.
    const wipPercentile = (GetZPercent(wipZScore));           // Calculate WIP percentile.

    // Convert ipArrayCalcOutput to z-score.
    const ipMean = -0.077131;   // Mean IP correlation coefficient based on beta tester results.
    const ipDev = 0.466503;     // Standard deviation of the IP correlation coefficient based on beta tester results.
    const ipZScore = (ipArrayCalcOutput - ipMean)/ipDev;  // Calculate IP Z-score.
    const ipPercentile = GetZPercent(ipZScore);           // Calculate IP percentile.

    // Calculate mean calcOutput (mean percentile).
    let calcOutput = (wipPercentile + ipPercentile)/2;

    // // Convert wipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedWIPScore = rescale([-1, 1], [0, 100], wipArrayCalcOutput);
    
    // // Convert ipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedIPScore = rescale([-1, 1], [0, 100], ipArrayCalcOutput);

    // // Calculate mean calcOutput (correlation coefficient).
    // let calcOutput = (convertedWIPScore + convertedIPScore)/2;
    return {
      ...element,
      calcOutput: calcOutput
    }
  });
  return output;
}


// Function to convert Z-score to percentile.
function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  // Initialize variables for factorial, sum, term, and k.
  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;

  // Set a stopping criterion for the loop to avoid infinite loop.
  var loopStop = Math.exp(-23);

  // Loop to calculate the cumulative distribution function.
  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  // Add 0.5 to the sum as part of the CDF calculation.
  sum += 0.5;

  return sum;
}



// Function to find overlap between WIP, IP, and AP assessments, convert the correlation coefficient
// from the WIP assessment to a 0-100 scale, convert the correlation coefficient from the IP
// assessment to a 0-100 scale, convert the Euclidean distance from the AP assessment
// to a 0-100 scale, and calculate a combined score (0 = worst, 100 = best).
export function calcTrifectaOverlap(wipArray, ipArray, apArray) {
  // Find all UIDs that exist in both wipArray and ipArray.
  let filterArray1 = [];
  let filterArray2 = [];
  if (ipArray !== undefined && apArray !== undefined && wipArray !== undefined) {
    filterArray1 = wipArray.filter(value => ipArray.some(value2 => value.UID === value2.UID));

    // Find all UIDs that exist in wipArray, ipArray, and apArray.
    filterArray2 = apArray.filter(value => filterArray1.some(value2 => value.UID === value2.UID));
  }

  const output = filterArray2.map((element, index) => {
    let wipArrayCalcOutput = null;
    let ipArrayCalcOutput = null;
    let apArrayCalcOutput = null;
    
    // Get calcOutput from WIP for this particular occupation.
    wipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        wipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from IP for this particular occupation.
    ipArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        ipArrayCalcOutput = element2.calcOutput;
      }
      return null;
    });

    // Get calcOutput from AP for this particular occupation.
    apArray.map((element2, index2) => {
      if (element.UID === element2.UID) {
        // apArrayCalcOutput = element2.calcOutput;
        apArrayCalcOutput = element2.apAverage;
      }
      return null;
    });

    // Convert wipArrayCalcOutput to z-score.
    const wipMean = 0.016794;   // Mean WIP correlation coefficient based on beta tester results.
    const wipDev = 0.439068;    // Standard deviation of the WIP correlation coefficient based on beta tester results.
    const wipZScore = (wipArrayCalcOutput - wipMean)/wipDev;  // Calculate WIP Z-score.
    const wipPercentile = GetZPercent(wipZScore);             // Calculate WIP percentile.

    // Convert ipArrayCalcOutput to z-score.
    const ipMean = -0.077131;   // Mean IP correlation coefficient based on beta tester results.
    const ipDev = 0.466503;     // Standard deviation of the IP correlation coefficient based on beta tester results.
    const ipZScore = (ipArrayCalcOutput - ipMean)/ipDev;  // Calculate IP Z-score.
    const ipPercentile = GetZPercent(ipZScore);           // Calculate IP percentile.

    // // Convert apArrayCalcOutput to z-score.
    // const apMean = apPopulationEDSpecs.mean;   // Mean AP Euclidean distance based on beta tester results.
    // const apDev = apPopulationEDSpecs.standardDeviation;    // Standard deviation of the AP Euclidean distance based on beta tester results.
    // const apZScore = (apArrayCalcOutput - apMean)/apDev;  // Calculate AP Z-score.
    // const apPercentile = 1 - GetZPercent(apZScore);           // Calculate AP percentile.

    // Calculate mean calcOutput (mean percentile).
    let calcOutput = (wipPercentile + ipPercentile + apArrayCalcOutput)/3;

    // // Convert wipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedWIPScore = rescale([-1, 1], [0, 100], wipArrayCalcOutput);
    
    // // Convert ipArrayCalcOutput from (-1, 1) scale to (0, 100) scale.
    // let convertedIPScore = rescale([-1, 1], [0, 100], ipArrayCalcOutput);

    // // Convert apArrayCalcOutput from (240, 0) scale to (0, 100) scale.
    // let convertedAPScore = rescale([240, 0], [0, 100], apArrayCalcOutput);

    // // Calculate mean calcOutput (correlation coefficient).
    // let calcOutput = (convertedWIPScore + convertedIPScore + convertedAPScore)/3;

    // Calculate matchScore.
    let matchScore = calcOutput;

    // Trifecta population statistics from beta user.
    const trifectaMean = trifectaPopulationSpecs.mean;              // Mean trifecta percentile.
    const trifectaDev = trifectaPopulationSpecs.standardDeviation;  // Standard deviation trifecta percentile.

    // Calculate fit.
    const fitOptions = [
      'Excellent Fit',
      'Great Fit',
      'Good Fit'
    ];
    const fitCutoffs = [
      trifectaMean + trifectaDev*1.5,
      trifectaMean + trifectaDev,
      trifectaMean
    ];

    let fitScore = null;
    if (matchScore >= fitCutoffs[0]) {
      fitScore = fitOptions[0];
    } else if (matchScore >= fitCutoffs[1] && matchScore < fitCutoffs[0]) {
      fitScore = fitOptions[1];
    } else if (matchScore >= fitCutoffs[2] && matchScore < fitCutoffs[1]) {
      fitScore = fitOptions[2];
    }

    // Calculate career cluster.
    const careerCategories = [
      {
        name: 'Management',
        totalJobs: 50,
        color: '#F5EA61',
        code: 11
      },
      {
        name: 'Business and Financial Operations',
        totalJobs: 43,
        color: '#F4A81D',
        code: 13
      },
      {
        name: 'Computer and Mathematical',
        totalJobs: 29,
        color: '#FF6B00',
        code: 15
      },
      {
        name: 'Architecture and Engineering',
        totalJobs: 55,
        color: '#FFB4AB',
        code: 17
      },
      {
        name: 'Life, Physical, and Social Science',
        totalJobs: 57,
        color: '#C39B82',
        code: 19
      },
      {
        name: 'Community and Social Service',
        totalJobs: 14,
        color: '#FF647D',
        code: 21
      },
      {
        name: 'Legal Occupations',
        totalJobs: 7,
        color: '#DB0032',
        code: 23
      },
      {
        name: 'Education, Training, and Library',
        totalJobs: 57,
        color: '#FCA0C9',
        code: 25
      },
      {
        name: 'Arts, Design, Entertainment, Sports, and Media',
        totalJobs: 38,
        color: '#DF1683',
        code: 27
      },
      {
        name: 'Healthcare Practitioners and Technical',
        totalJobs: 78,
        color: '#E95EBE',
        code: 29
      },
      {
        name: 'Healthcare Support',
        totalJobs: 19,
        color: '#A05CBF',
        code: 31
      },
      {
        name: 'Protective Services',
        totalJobs: 24,
        color: '#C2A6E1',
        code: 33
      },
      {
        name: 'Food Preparation and Serving Related',
        totalJobs: 16,
        color: '#655DC6',
        code: 35
      },
      {
        name: 'Build and Grounds Cleaning and Maintenance',
        totalJobs: 8,
        color: '#8CB7C9',
        code: 37
      },
      {
        name: 'Personal Care and Service',
        totalJobs: 29,
        color: '#3D7CCA',
        code: 39
      },
      {
        name: 'Sales and Related Occupations',
        totalJobs: 21,
        color: '#0047BA',
        code: 41
      },
      {
        name: 'Office and Administrative Support',
        totalJobs: 51,
        color: '#009ADE',
        code: 43
      },
      {
        name: 'Farming, Fishing, and Forestry',
        totalJobs: 12,
        color: '#0099A8',
        code: 45
      },
      {
        name: 'Construction and Extraction',
        totalJobs: 61,
        color: '#007960',
        code: 47
      },
      {
        name: 'Installation, Maintenance, and Repair',
        totalJobs: 50,
        color: '#00AE42',
        code: 49
      },
      {
        name: 'Production',
        totalJobs: 107,
        color: '#93D500',
        code: 51
      },
      {
        name: 'Transportation and Material Moving',
        totalJobs: 47,
        color: '#FCD199',
        code: 53
      }
    ];

    let careerName = null;
    let careerColor = null;
    let careerCode = null;
    try {
      const variableToCheck = Number(element.onetCode.substring(0, 2));
      const index = careerCategories.findIndex(obj => obj.code === variableToCheck);
      careerName = careerCategories[index].name;
      careerColor = careerCategories[index].color;
      careerCode = careerCategories[index].code;
    } catch (error) {
      console.log('Error: ', error);
    }

    return {
      ...element,
      calcOutput: calcOutput,
      matchScore: matchScore,
      fitScore: fitScore,
      careerName: careerName,
      careerColor: careerColor,
      careerCode: careerCode
    }
  });
  return output;
}



// Function to find overlap between two arrays and calculate the average correlation coefficient of the overlapping objects.
export function filterAndMeanCorrCoeff(arrA, arrB) {
  // Find all UIDs that exist in both arrA and arrB.
  let filterArray = [];
  if (arrA !== undefined && arrB !== undefined) {
    filterArray = arrA.filter(value => arrB.some(value2 => value.UID === value2.UID));
  }
  const output = filterArray.map((element, index) => {
    let arrACalcOutput = null;
    let arrBCalcOutput = null;
    
    // Get calcOutput from first array.
    arrA.map((element2, index2) => {
      if (element.UID === element2.UID) {
        arrACalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from second array.
    arrB.map((element2, index2) => {
      if (element.UID === element2.UID) {
        arrBCalcOutput = element2.calcOutput;
      }
      return null;
    });

    // Calculate mean calcOutput (correlation coefficient).
    let calcOutput = (arrACalcOutput + arrBCalcOutput)/2;
    return {
      ...element,
      calcOutput: calcOutput
    }
  });
  return output;
}



// Function to find overlap between two arrays, assuming arrA is weighted by a factor of 2 for calcOutput.
export function filterAndMeanCorrCoeff2(arrA, arrB) {
  // Find all UIDs that exist in both arrA and arrB.
  let filterArray = [];
  if (arrA !== undefined && arrB !== undefined) {
    filterArray = arrA.filter(value => arrB.some(value2 => value.UID === value2.UID));
  }
  const output = filterArray.map((element, index) => {
    let arrACalcOutput = null;
    let arrBCalcOutput = null;
    
    // Get calcOutput from first array.
    arrA.map((element2, index2) => {
      if (element.UID === element2.UID) {
        arrACalcOutput = element2.calcOutput;
      }
      return null;
    });
    
    // Get calcOutput from second array.
    arrB.map((element2, index2) => {
      if (element.UID === element2.UID) {
        arrBCalcOutput = element2.calcOutput;
      }
      return null;
    });

    // Calculate mean calcOutput (correlation coefficient).
    let calcOutput = (arrACalcOutput*2 + arrBCalcOutput)/2;
    return {
      ...element,
      calcOutput: calcOutput
    }
  });
  return output;
}



// Function to convert a date string in "MM-DD-YY" format to a javascript Date().
export function prepareDate(date) {
  let [m, d, y] = date.split("-"); // Split the string
  return [y, m - 1, d]; // Return as an array with y,m,d sequence
}