import React, { useState, useEffect } from 'react';
import firebase from '../../../config/fbConfig';

const verifyPic = `/images/verifyImages/Email_Verification_Icon.png`;

const HomeUnverified = () => {
  const [ userEmail, setUserEmail ] = useState(null);

  const [user, setUser] = useState();
  const [dummy, setDummy] = useState(true);
  
  useEffect(() => {
    try {
      if (firebase.auth().currentUser) {
        setUserEmail(firebase.auth().currentUser.email);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Check every 1 second if the user has verified his email. If so, reload the page.
  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(curUser => setUser(curUser));
    return subscriber; // unsubscribe on unmount
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      setDummy(!dummy);
      try {
        if (user) {
          await user.reload();
          // If the user's email has been verified, reload the page to update everything.
          if (user.emailVerified === true) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  // Resend email verification.
  const resendEmail = (e) => {
    try {
      firebase.auth().currentUser.sendEmailVerification();
    } catch (error) {
      console.log('Error: ', error);
    }
  }


  return (
    <div className='homeUnverified'>
      <img src={verifyPic} alt="verify-pic" className='homeUnverified__image' />
      <div className="homeUnverified__body">
        We have sent an email to <b>{userEmail}</b>. Please verify your email
        address to continue. If your verification has expired,
        you can resend it by clicking the button below.
      </div>
      <div className="homeUnverified__bodyButton">
        <button className="homeUnverified__button" onClick = {resendEmail}>
          Resend Verification Email
        </button>
      </div>
    </div>
  )
}

export default HomeUnverified