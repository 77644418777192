import React from 'react'
import Navbar from './Navbar'
import NavbarDark from './NavbarDark'

const NavbarCheck = ({ location, signedIn, resultsIndex, setResultsIndex }) => {
  return (
    <div className='navbarCheck'>
      { (location.pathname === '/results') ? (
        signedIn ? (
          <NavbarDark signedIn={signedIn} resultsIndex={resultsIndex} setResultsIndex={setResultsIndex} />
        ) : (
          <NavbarDark signedIn={signedIn} />
        )
      ) : (
        <Navbar signedIn={signedIn} />
      ) }
    </div>
  )
}

export default NavbarCheck
