const initState = {
  userId: null,
  currentAssessmentId: null,
  currentPromptId: null,
};

export const userActionTypes = {
  SET_USER_ID: "SET_USER_ID",
  SELECT_ASSESSMENT: "SELECT_ASSESSMENT",
  SELECT_PROMPT: "SELECT_PROMPT",
};

const userReducer = (state = initState, { type, payload }) => {
  if (type === userActionTypes.SET_USER_ID) {
    return {
      ...state,
      userId: payload,
    };
  }
  if (type === userActionTypes.SELECT_ASSESSMENT) {
    return {
      ...state,
      currentAssessmentId: payload,
    };
  }
  if (type === userActionTypes.SELECT_PROMPT) {
    return {
      ...state,
      currentPromptId: payload,
    };
  }
  return state;
};

export default userReducer;
